import React from 'react';
import { Paper } from "@material-ui/core";

const TableImage = ({ value }) => {
  return (
    <div>
      {value ? <img style={{ maxWidth: 100 }} src={value} /> : "No image"}
    </div>
  )
}

export default TableImage;
