import { uniqueId } from "lodash";

const parseInitialValue = (value) => {
  if (!value) {
    return [{ id: uniqueId(), field: "", matchType: "~", text: "" }];
  }

  const regex = /(\S+)\s*([~=])\s*(.*?)\s*(?=and\s*\S+\s*[~=]|$)/g;
  let match;
  const criteria = [];

  while ((match = regex.exec(value)) !== null) {
    const [_, field, matchType, text] = match;
    criteria.push({ id: uniqueId(), field, matchType, text: text.trim() });
  }

  if (!criteria.length) {
    criteria.push({ id: uniqueId(), field: "", matchType: "~", text: value });
  }

  return criteria;
};

export default parseInitialValue;