import React, {useState} from "react";
import {useQuery} from "@apollo/react-hooks";
import {useParams} from "react-router-dom";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import LinearProgress from "@material-ui/core/LinearProgress";

import ActionModal from "./modals/action-modal";
import {ASSETS_COLLECTION_TABLE_QUERY, CREATE_INVENTORY_TRANSACTION_MUTATION,} from "../constants/graphql";
import useMutation from "../hooks/useMutation";
import useNotification from "../hooks/notification";
import SearchAutocomplete, {getAssetLabel} from "./SearchAutocomplete";

const InventoryTransactionModal = ({
  open,
  onClose,
  onSuccess,
  title,
  code: defaultCode,
  asset,
  quantityOrdered,
  quantityReceived,
}) => {
  const { projectId } = useParams();
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState(asset ? getAssetLabel(asset) : "");
  const [quantity, setQuantity] = useState(0);
  const [notes, setNotes] = useState("");
  const [code, setCode] = useState(defaultCode);
  const [errors, setErrors] = useState({});
  const [createTransactionMutation] = useMutation(CREATE_INVENTORY_TRANSACTION_MUTATION);
  const {
    loading: loadingAssets,
    data: { assetsCollection = [] } = {},
  } = useQuery(ASSETS_COLLECTION_TABLE_QUERY, { variables: { projectId, search } });
  const collection = assetsCollection.collection || [];

  const { notifySuccess, notifyError } = useNotification();

  const handleSubmit = () => {
    setLoading(true);
    setErrors({});
    createTransactionMutation({
      variables: {
        code: String(code),
        notes,
        quantity: parseInt(quantity),
      },
      onSuccess: data => {
        setLoading(false);
        notifySuccess("Asset successfully updated!");
        onSuccess(data);
        setCode(null);
        setQuantity(0);
      },
      onFailure: errors => {
        setErrors(errors);
        notifyError(errors.submit);
        setLoading(false);
      },
    });
  };

  const handleSelectAsset = asset => {
    setCode(asset.assetId);
  };

  return (
    <ActionModal open={open} maxWidth={900} onClose={onClose} title={title} disableTransition>
      <form onSubmit={handleSubmit} style={{ width: "100%", height: "100%" }}>
        <Box height={230}>
          <Box item xs={12}>
            <Box height={10}>{loadingAssets && <LinearProgress className="mt-2" />}</Box>
            <Grid container spacing={2}>
              <Grid item sm={6}>
                <SearchAutocomplete
                  selected={code}
                  value={search}
                  collection={collection}
                  onChange={setSearch}
                  selectAsset={handleSelectAsset}
                />
              </Grid>
              <Grid item sm={6}>
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Asset ID"
                  disabled
                  error={!!errors.code}
                  helperText={errors.code}
                  value={code || ""}
                  onChange={event => setCode(event.target.value)}
                />
              </Grid>
              <Grid item sm={6}>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="quantity"
                  label="Quantity"
                  value={quantity}
                  type="number"
                  error={!!errors.quantity}
                  helperText={errors.quantity}
                  onChange={event => setQuantity(event.target.value)}
                />
              </Grid>
              <Grid item sm={6}>
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Receiving Notes"
                  value={notes}
                  error={!!errors.notes}
                  helperText={errors.notes}
                  onChange={event => setNotes(event.target.value)}
                />
              </Grid>
              {quantityOrdered && (
                <Grid item sm={6}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    disabled
                    label="Quantity Ordered"
                    value={asset?.quantityOrdered || "0"}
                  />
                </Grid>
              )}
              {quantityReceived && (
                <Grid item sm={6}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    disabled
                    label="Quantity Received"
                    value={asset?.quantityReceived || "0"}
                  />
                </Grid>
              )}
            </Grid>
          </Box>
        </Box>
        <Box mt={5} display="flex" justifyContent="flex-end">
          <Button
            key="override"
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={loading}
          >
            Inventory Placement
          </Button>
        </Box>
      </form>
    </ActionModal>
  );
};

export default InventoryTransactionModal;
