import React, { useState } from "react";
import CreateNewFolderOutlinedIcon from "@mui/icons-material/CreateNewFolderOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import ButtonOld from "@material-ui/core/Button";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";

import ProjectActions from "./ProjectActions";
import ActionModal from "../../../components/modals/action-modal";
import useAuth from "../../../hooks/useAuth";
import ProjectInfoModal from "../../../components/project/ProjectInfoModal";

export default function ActionsModal() {
  const { user } = useAuth();
  const [open, setOpen] = useState(false);
  const [openInfo, setOpenInfo] = useState(false);
  const { settings } = user.company;

  const handleToggle = () => {
    setOpen(v => !v);
  };

  const handleToggleInfo = () => {
    setOpenInfo(v => !v);
  };

  return (
    <>
      <ButtonGroup variant="outlined" color="default" aria-label="outlined button group">
        <Tooltip title={`Add ${settings.filter_field1} or ${settings.filter_field2}`} arrow>
          <ButtonOld onClick={handleToggle}>
            <CreateNewFolderOutlinedIcon />
          </ButtonOld>
        </Tooltip>
        <Tooltip title={`${settings.filter_field2} Profile`} arrow>
          <ButtonOld onClick={handleToggleInfo}>
            <InfoOutlinedIcon />
          </ButtonOld>
        </Tooltip>
      </ButtonGroup>
      <ActionModal
        open={open}
        title="Add Folder"
        maxWidth={600}
        onClose={handleToggle}
        disableTransition
      >
        <ProjectActions />
        <Box mt={2} display="flex" justifyContent="flex-end">
          <Button variant="outlined" color="primary" onClick={handleToggle}>
            Close
          </Button>
        </Box>
      </ActionModal>
      {openInfo && <ProjectInfoModal onClose={handleToggleInfo} />}
    </>
  );
}
