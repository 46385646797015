import useCaptureUserFields from "src/hooks/useCaptureFields";
import { useCustomProjectFields } from "src/contexts/clients";
import useAuth from "src/hooks/useAuth";

export default function useCaptureFields(overrideDisplay) {
  const { user } = useAuth();
  const displayUserFields = useCaptureUserFields();
  const displayProjectFields = useCustomProjectFields();

  return overrideDisplay || user.company.settings.project_specific_fields
    ? displayProjectFields || displayUserFields
    : displayUserFields;
}
