import React from "react";
import { ButtonBase } from "@material-ui/core";
import FlipCameraIos from "@material-ui/icons/FlipCameraIos";

const SwitchCameraButton = ({ onClick }) => (
  <ButtonBase
    data-id="switch-camera"
    onClick={onClick}
    style={{ position: "absolute", left: 15, top: 15, zIndex: 99 }}
  >
    <FlipCameraIos htmlColor="white" fontSize="large" />
  </ButtonBase>
);

export default SwitchCameraButton;
