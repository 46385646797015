import React, {useMemo, useState} from "react";
import {UPDATE_USER_MUTATION} from "src/constants/graphql";
import useAuth from "src/hooks/useAuth";
import useNotification from "src/hooks/notification";
import useMutation from "src/hooks/useMutation";
import pick from "lodash/pick";
import keyBy from "lodash/keyBy";
import isEmpty from "lodash/isEmpty";

const USER_FIELDS = ["id", "width", "isFrozen", "visible"];

const mergeSettings = (defaultSettings, userSettings) => {
  if (isEmpty(userSettings)) return defaultSettings;
  if (defaultSettings.version !== userSettings.version) return defaultSettings;

  const settings = { ...defaultSettings, ...userSettings };
  const userFields = keyBy(userSettings.fields, "id");
  const defaultFields = keyBy(defaultSettings.fields, "id");
  const defaultFieldsIds = Object.keys(defaultFields);

  const newFields = defaultSettings.fields.filter(
    field => !Object.keys(userFields).includes(field.id)
  );

  const mergedFields = userSettings.fields.reduce((result, field) => {
    if (!defaultFieldsIds.includes(field.id)) return result;

    const newField = {
      ...defaultFields[field.id],
      ...pick(userFields[field.id], USER_FIELDS),
    };

    return [...result, newField];
  }, []);

  return {
    ...settings,
    fields: [...mergedFields, ...newFields],
  };
};

const useTablePro = ({ key, default: defaultSettings, setCollection }) => {
  const { user, setUser } = useAuth();
  const { notifyError } = useNotification();
  const [updateUser] = useMutation(UPDATE_USER_MUTATION);
  const [cacheVersion, setCacheVersion] = useState(1);
  const [disabledScroll, setDisabledScroll] = useState(false);

  const [settings, setSettings] = useState(
    key ? mergeSettings(defaultSettings, user[key]) : defaultSettings
  );

  const handleSetSettings = newSettings => {
    setSettings(newSettings);
    setCacheVersion(prev => prev + 1);
  };

  const updateSettings = newProps => {
    const oldSettings = { ...settings };
    const newSettings = { ...settings, ...newProps };

    handleSetSettings(newSettings);

    const storeSettings = {
      ...newSettings,
      fields: newSettings.fields.map(field => pick(field, ...USER_FIELDS)),
    };

    if (key) {
      return updateUser({
        variables: { [key]: storeSettings },
        onSuccess: data => {
          setUser({ ...user, [key]: data.user[key] });
        },
        onFailure: errors => {
          notifyError(errors);
          handleSetSettings(oldSettings);
        },
      });
    }
  };

  const fields = useMemo(() => settings.fields, [cacheVersion]);

  return useMemo(
    () => ({
      limit: settings.limit,
      size: settings.size,
      fields: fields,
      order: settings.order,
      updateSettings,
    }),
    [cacheVersion]
  );
};

export default useTablePro;
