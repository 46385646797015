import React from "react";
import snakeCase from "lodash/snakeCase";
import camelCase from "lodash/camelCase";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";

import labels, {benchFloorHoodWallBscOptions} from "../../constants/displayFields";
import Input from "../../components/form/Input";
import BooleanSelectField from "../../components/form/fields/BooleanSelectField";
import Autocomplete from "../../components/form/Autocomplete";
import {getOptions} from "../../utils/form";
import useAuth from "../../hooks/useAuth";
import DestinationRoomNumbersScrubber from "./DestinationRoomNumbers/DestinationRoomNumbersScrubber";
import DestinationRoomNumbersCapture from "./DestinationRoomNumbers/DestinationRoomNumbersCapture";

const nameField = name => camelCase(name);

const DESTINATION_STRING_FIELDS = [
  "newBuilding",
  "newRoom",
  "newFloor",
  "destinationEmployeeName",
  "destinationComments",
];

export default function FieldsDestination({ display, type, asset, disabledForm }) {
  const { user } = useAuth();
  const { settings: companySettings } = user.company;

  const buildFields = fields =>
    fields.reduce((result, key) => {
      const displayField = display[snakeCase(key)];
      return displayField ? [...result, snakeCase(key)] : result;
    }, []);

  const displaySection =
    display.new_building ||
    display.new_room ||
    display.new_floor ||
    display.destination_room_number ||
    display.destination_employee_name ||
    display.destination_comments ||
    display.destination_quantity ||
    display.vendor_required ||
    display.destination_number ||
    display.install_responsibility ||
    display.bench_floor_hood_wall_bsc ||
    display.siesmic_anchor_required;

  return displaySection ? (
    <Card>
      <CardContent>
        <Box mt={-1}>
          <Typography fontWeight={500} fontSize={18}>
            Destination
          </Typography>
        </Box>
        <Grid container spacing={1}>
          {buildFields(DESTINATION_STRING_FIELDS).map((fieldKey, index) => (
            <Grid key={`field-${index}`} item xs={12}>
              <Input name={nameField(fieldKey)} label={labels[fieldKey]} disabled={disabledForm} />
            </Grid>
          ))}
          {display.destination_room_number && (
            <Grid item xs={12}>
              {companySettings.multi_destination_room_number ? (
                type === "scrubber" ? (
                  <DestinationRoomNumbersScrubber
                    type={type}
                    entity={asset}
                    disabled={disabledForm}
                  />
                ) : (
                  <DestinationRoomNumbersCapture
                    type={type}
                    entity={asset}
                    disabled={disabledForm}
                  />
                )
              ) : (
                <Input
                  name={nameField("destinationRoomNumber")}
                  label={labels.destination_room_number}
                  disabled={disabledForm}
                />
              )}
            </Grid>
          )}
          {display.destination_quantity && (
            <Grid item xs={12}>
              <Input
                type="number"
                name={nameField("destination_quantity")}
                label={labels.destination_quantity}
                disabled={disabledForm}
              />
            </Grid>
          )}
          {display.vendor_required && (
            <Grid item xs={12}>
              <BooleanSelectField
                name={nameField("vendor_required")}
                label={labels.vendor_required}
                disabled={disabledForm}
              />
            </Grid>
          )}
          {display.destination_number && (
            <Grid item xs={12}>
              <Input
                name={nameField("destination_number")}
                label={labels.destination_number}
                disabled={disabledForm}
              />
            </Grid>
          )}
          {display.install_responsibility && (
            <Grid item xs={12}>
              <Input
                name={nameField("install_responsibility")}
                label={labels.install_responsibility}
                disabled={disabledForm}
              />
            </Grid>
          )}
          {display.bench_floor_hood_wall_bsc && (
            <Grid item xs={12}>
              <Autocomplete
                saveCustomOption
                name={nameField("bench_floor_hood_wall_bsc")}
                label={labels.bench_floor_hood_wall_bsc}
                options={getOptions(benchFloorHoodWallBscOptions)}
                disabled={disabledForm}
              />
            </Grid>
          )}
          {display.siesmic_anchor_required && (
            <Grid item xs={12}>
              <BooleanSelectField
                name={nameField("siesmic_anchor_required")}
                label={labels.siesmic_anchor_required}
                disabled={disabledForm}
              />
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  ) : null;
}
