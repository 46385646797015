import React from "react";
import { PlusCircle as PlusCircleIcon } from "react-feather";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import SvgIcon from "@mui/material/SvgIcon";
import Grid from "@mui/material/Grid";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import { ProjectActionsProvider, useProjectActions } from "../../../contexts/projectActions";
import { usePermissions } from "../../../contexts/permissions";
import useAuth from "../../../hooks/useAuth";

const ProjectActions = () => {
  const { user } = useAuth();
  const isMobile = useMediaQuery(theme => theme.breakpoints.down("sm"));
  const { handleToggleClient, handleToggleProject } = useProjectActions();
  const { can } = usePermissions();
  const { settings } = user.company;

  return (
    <Box
      style={{ width: "100%" }}
      display="flex"
      justifyContent={isMobile ? undefined : "flex-end"}
    >
      <Grid container spacing={1}>
        <Grid item xs={6}>
          {can("projects.write") && can("clients.write") && (
            <Button
              fullWidth
              id="addClientAndProjectModal"
              style={{ height: "100%" }}
              color="primary"
              variant="contained"
              onClick={handleToggleClient}
              startIcon={
                <SvgIcon fontSize="small">
                  <PlusCircleIcon />
                </SvgIcon>
              }
            >
              {/* isMobile ? `Add Client` : `Add Client and Project`*/}
              {isMobile
                ? `Add ${settings.filter_field1}`
                : `Add ${settings.filter_field1} and ${settings.filter_field2}`}
            </Button>
          )}
        </Grid>
        <Grid item xs={6}>
          {can("projects.write") && (
            <Button
              fullWidth
              color="primary"
              variant="outlined"
              onClick={handleToggleProject}
              startIcon={
                <SvgIcon fontSize="small">
                  <PlusCircleIcon />
                </SvgIcon>
              }
            >
              Add {settings.filter_field2}
            </Button>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default () => (
  <ProjectActionsProvider>
    <ProjectActions />
  </ProjectActionsProvider>
);
