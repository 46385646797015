import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

export default function Empty() {
  return (
    <>
      <Box
        component="img"
        borderRadius={3}
        width={400}
        maxWidth="100%"
        minHeight="258px"
        src="/empty-os&d.png"
      />
      <Typography mt={4} maxWidth={300} fontWeight={500} align="center">
        At times, shipments arrive with damages or discrepancies.
      </Typography>
      <Typography m={2} maxWidth={300} fontWeight={500} align="center">
        If applicable, document them below:
      </Typography>
    </>
  );
}
