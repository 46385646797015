import React from "react";
import { Form, Formik } from "formik";
import sumBy from "lodash/sumBy";
import { useHistory } from "react-router-dom";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import DialogContent from "@mui/material/DialogContent";
import ConfirmPalletQuantitiesTable from "./ConfirmPalletQuantitiesTable";

export default function ConfirmPalletQuantities({ shipment, shipmentItems }) {
  const history = useHistory();
  const totalWeight = shipment.totalWeight;
  const totalQuantity = sumBy(shipmentItems, v => v.asset.availableQuantity);
  const initialValues = shipmentItems.map(v => ({
    ...v,
    groupWeight: Math.round((totalWeight / totalQuantity) * v.asset.availableQuantity),
  }));

  const handleClose = () => {
    const pathname = window.location.pathname;
    const params = new URLSearchParams(window.location.search);
    params.delete("palletQuantities");
    history.push({ pathname, search: params.toString() });
  };

  return (
    <Dialog open fullWidth maxWidth="sm" onClose={handleClose}>
      <DialogTitle sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box width="48px" />
        <Typography fontSize={24} fontWeight={500} color="textPrimary">
          Confirm Pallet Quantities
        </Typography>
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Formik enableReinitialize initialValues={initialValues} onSubmit={() => {}}>
          <Form>
            <ConfirmPalletQuantitiesTable
              shipment={shipment}
              shipmentItems={shipmentItems}
              totalWeight={totalWeight}
              onClose={handleClose}
            />
          </Form>
        </Formik>
      </DialogContent>
    </Dialog>
  );
}
