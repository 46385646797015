import React from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";

import Input from "../../../components/form/Input";

export default function CreatedByEmail({ email, user: inventoryRequestUser }) {
  return (
    <Grid item xs={12} md={6}>
      {email ? (
        <Input name="email" label="Created by Email" />
      ) : (
        <TextField
          disabled
          label="Created by Email"
          value={inventoryRequestUser.email}
          fullWidth
          size="small"
          variant="outlined"
        />
      )}
    </Grid>
  );
}
