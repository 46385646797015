import React from "react";
import {TableCell, TableRow, Typography} from "@material-ui/core";

export default function NoRecordsTable({ configuration }) {
  return (
    <TableRow>
      <TableCell colSpan={configuration.fields.length + 2}>
        <Typography
          component="span"
          color="textSecondary"
          variant="h3"
          align="center"
          className="px-3 d-flex w-100 align-items-center justify-content-center"
        >
          No report history
        </Typography>
      </TableCell>
    </TableRow>
  );
}
