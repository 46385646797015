import React, {useCallback, useState} from "react";
import {useDropzone} from "react-dropzone";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import {Delete as RemoveIcon} from "@material-ui/icons";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Link from "@material-ui/core/Link";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import LinearProgress from "@material-ui/core/LinearProgress";

import {serverStrftime} from "../../utils/dates";
import useMutation from "../../hooks/useMutation";
import {ADD_ASSET_ATTACHMENT_MUTATION, REMOVE_ASSET_ATTACHMENT_MUTATION,} from "../../constants/graphql";

const Paper = ({ children }) => (
  <Box borderRadius={4} border="1px solid rgba(0, 0, 0, 0.23)">
    {children}
  </Box>
);

export default function Attachments({ asset, loading, assetAttachments, onDelete, onAdd }) {
  const [updating, setUpdating] = useState(false);
  const [addAttachment] = useMutation(ADD_ASSET_ATTACHMENT_MUTATION);
  const [removeAttachment] = useMutation(REMOVE_ASSET_ATTACHMENT_MUTATION);

  const onDrop = useCallback(
    acceptedFiles => {
      setUpdating(true);
      Promise.all(
        acceptedFiles.map(file =>
          addAttachment({
            variables: { assetId: asset.id, file },
          })
        )
      ).then(data => onAdd(data).then(() => setUpdating(false)));
    },
    [asset, addAttachment, setUpdating, onAdd]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const handleRemove = useCallback(
    id => {
      return removeAttachment({
        variables: { id, assetId: asset.id },
        onSuccess: () => {
          onDelete(id);
        },
      });
    },
    [asset, removeAttachment, onDelete]
  );

  return (
    <Box>
      <Box height={16} pt={0.75}>
        {(loading || updating) && <LinearProgress />}
      </Box>
      {assetAttachments.length > 0 && (
        <Box mb={2}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>File</TableCell>
                  <TableCell align="right">Date</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {assetAttachments.map((attachment, i) => (
                  <TableRow key={i}>
                    <TableCell>
                      <Link target="_blank" href={attachment.url}>
                        {attachment.fileName || "No name"}
                      </Link>
                    </TableCell>
                    <TableCell align="right">{serverStrftime(attachment.createdAt)}</TableCell>
                    <TableCell align="right">
                      <IconButton size="small" onClick={() => handleRemove(attachment.id)}>
                        <RemoveIcon fontSize="small" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}
      <div {...getRootProps()}>
        <Button fullWidth variant="outlined" style={{ textTransform: "none" }}>
          <Box p={2} display="flex" justifyContent="center">
            <input {...getInputProps()} />
            {isDragActive ? (
              <Typography>Drop the files here ...</Typography>
            ) : (
              <Typography>Drag and drop file here, or click to select file</Typography>
            )}
          </Box>
        </Button>
      </div>
    </Box>
  );
}
