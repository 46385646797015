import React from "react";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";

export default function SearchInput({ value, onChange, setSearch, onFocus, onBlur }) {
  const handleClear = () => {
    onChange("");
    setSearch("");
  };

  return (
    <TextField
      value={value || ""}
      onChange={v => onChange(v.target.value)}
      onFocus={onFocus}
      onBlur={onBlur}
      placeholder="Search"
      inputProps={{
        "data-id": "search-input",
      }}
      size="small"
      variant="outlined"
      InputProps={{
        endAdornment: (
          <InputAdornment>
            {value && (
              <IconButton onClick={handleClear}>
                <CloseIcon />
              </IconButton>
            )}
            <IconButton type="submit">
              <SearchIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
}
