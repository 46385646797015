import React from "react";
import {Formik} from "formik";
import Box from "@mui/material/Box";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DialogContent from "@mui/material/DialogContent";

import {useClients} from "../../../../contexts/clients";
import {fetchAssetValues} from "../../../../utils/asset";
import FormFields from "../../../capture/FormFields";

export default function AssetInfoModal({ asset, onToggle }) {
  const { clients } = useClients();
  const display = clients.flatMap(v => v.projects).find(v => v.id === asset.projectId)
    ?.fieldSettings;

  return (
    <>
      <DialogTitle sx={{ background: "#f4f6f8", display: "flex", justifyContent: "space-between" }}>
        <Box width="48px" />
        <Typography fontSize={24} fontWeight={500} color="textPrimary">
          Asset Details
        </Typography>
        <IconButton onClick={onToggle}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ background: "#f4f6f8" }}>
        <Box display="flex" flexGrow={1}>
          <Formik key={asset?.id} initialValues={fetchAssetValues(asset)}>
            <FormFields
              disabledForm
              type="orders"
              asset={asset}
              loading={false}
              overrideDisplay={display}
              displayClient={true}
              displayProject={true}
              defaultTab="reserved_inventory"
            />
          </Formik>
        </Box>
      </DialogContent>
    </>
  );
}
