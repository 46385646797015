import React, {useCallback} from "react";
import {useHistory} from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Paper from "@material-ui/core/Paper";
import Button from "@mui/material/Button";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

import useQueryParams from "../../../../hooks/useQuery";
import Dimensions from "../../../../views/inventory/requests/Dimensions";
import AddComponentModal from "./AddComponent/AddComponentModal";
import {getImage} from "../../../../views/shopping/Cart/CartItemFields";
import {useKitAssets} from "../../../../contexts/kit-assets";
import ItemId from "../../../../views/shopping/Orders/ItemId";
import AssetDetailsModal from "src/views/inventory/requests/asset-details/AssetDetailsModal";
import {ClientsProvider} from "../../../../contexts/clients";
import useAuth from "../../../../hooks/useAuth";

export default function KitBuilder({ disabled, entity, onClose }) {
  const { user } = useAuth();
  const history = useHistory();
  const query = useQueryParams();
  const { kit, loading, removeKitAsset } = useKitAssets();

  const handleToggle = useCallback(() => {
    if (query.get("kitId")) {
      history.push(window.location.pathname);
    } else {
      history.push({
        pathname: window.location.pathname,
        search: `?kitId=${entity.id}`,
      });
    }
  }, [query, history]);

  return (
    <Paper open fullWidth maxWidth="md" onClose={onClose}>
      <Box p={2}>
        <Typography fontWeight={500} fontSize={18}>
          Kit Builder
        </Typography>
        <Box my={2}>
          {loading ? (
            <Box p={3} borderRadius="4px" display="flex" justifyContent="center">
              <CircularProgress />
            </Box>
          ) : kit?.kitAssets?.length ? (
            <ClientsProvider>
              <Table>
                <TableHead>
                    <TableCell align="center">Image</TableCell>
                    <TableCell>Item ID</TableCell>
                    <TableCell>Description</TableCell>
                    <TableCell>Model</TableCell>
                    <TableCell>Dimensions</TableCell>
                    <TableCell>Required Quantity</TableCell>
                    <TableCell>{user.company.settings.quantity_label}</TableCell>
                    {!disabled && <TableCell />}
                </TableHead>
                <TableBody>
                  {kit?.kitAssets?.map(({ asset, ...item }, index) => {
                    const photo = getImage(asset);
                    const highlight = item.quantity > asset?.availableQuantity;
                    const background = highlight ? "#fffbf2" : undefined;
                    return (
                      <TableRow key={index} style={{ width: 90 }}>
                        <TableCell align="center" style={{ background }}>
                          {photo ? (
                            <img style={{ maxWidth: 90, maxHeight: 60 }} src={photo} alt="" />
                          ) : (
                            "No image"
                          )}
                        </TableCell>
                        <TableCell style={{ background }}>
                          {disabled ? (
                            <ItemId asset={asset} />
                          ) : (
                            <AssetDetailsModal asset={asset} />
                          )}
                        </TableCell>
                        <TableCell style={{ background }}>{asset.description}</TableCell>
                        <TableCell style={{ background }}>{asset.model}</TableCell>
                        <TableCell style={{ background }}>
                          <Dimensions dimensions={asset.dimensions} />
                        </TableCell>
                        <TableCell style={{ background }}>{item.quantity}</TableCell>
                        <TableCell style={{ background }}> {asset?.availableQuantity}</TableCell>
                        {!disabled && (
                          <TableCell align="right" style={{ background }}>
                            <IconButton color="inherit" onClick={() => removeKitAsset(item.id)}>
                              <Tooltip arrow title="Remove">
                                <HighlightOffIcon />
                              </Tooltip>
                            </IconButton>
                          </TableCell>
                        )}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </ClientsProvider>
          ) : (
            <Box p={3} bgcolor="#f4f6f8" borderRadius="4px" display="flex" justifyContent="center">
              Empty
            </Box>
          )}
        </Box>
        {!disabled && (
          <Button variant="outlined" onClick={handleToggle}>
            Add Component
          </Button>
        )}
        {query.get("kitId") === entity.id && <AddComponentModal onClose={handleToggle} />}
      </Box>
    </Paper>
  );
}
