import React, {useState} from "react";
import {useQuery} from "@apollo/react-hooks";
import {useHistory, useLocation} from "react-router-dom";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import CropFreeIcon from "@material-ui/icons/CropFree";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";

import useNotification from "../../hooks/notification";
import {ASSET_BY_BARCODE_QUERY} from "../../constants/graphql";
import BigButton from "../BigButton";
import ActionModal from "../modals/action-modal";
import {useStyles} from "../Page";
import Scanner from "./Scanner";

export default function LookupScanner({ onClose, clientId, projectId }) {
  const [open, setOpen] = useState(false);
  const [values, setValues] = useState({});
  const history = useHistory();
  const { pathname } = useLocation();
  const { notifyError } = useNotification();
  const { refetch } = useQuery(ASSET_BY_BARCODE_QUERY, {
    skip: true,
  });

  const classes = useStyles();

  const handleToggle = () => setOpen(v => !v);

  const handleScan = barcode => {
    refetch({
      barcode,
      projectId,
      clientId,
    })
      .then(data => {
        if (data?.data?.asset) {
          onClose();
          history.push(`${pathname}/${data?.data?.asset.id}?order=asc&sort=id`);
        }
      })
      .catch(() => {
        notifyError(`Barcode ${barcode} not found`);
        handleToggle();
        handleToggle();
      });
  };

  const handleSubmit = e => {
    if (values.barcode && e.key === "Enter") {
      handleScan(values.barcode);
    }
  };

  const handleChange = e => {
    e.target.name === "location"
      ? setValues({
          ...initialValues,
          [e.target.name]: e.target.value,
        })
      : setValues({
          ...values,
          [e.target.name]: e.target.value,
        });
  };

  if (open) {
    return <Scanner onDetected={handleScan} onClose={onClose} />;
  }

  return (
    <ActionModal
      open
      id="project"
      title="Lookup Scanner"
      maxWidth={440}
      onClose={onClose}
      disableTransition
    >
      <Box pt={1.5}>
        <BigButton onClick={handleToggle}>
          <CropFreeIcon fontSize="large" />
        </BigButton>

        <Box mb={1.5} />

        <FormControl fullWidth className={classes.margin}>
          <TextField
            name="barcode"
            label="Barcode"
            inputProps={{
              autoComplete: "off",
            }}
            value={values.barcode || ""}
            onChange={handleChange}
            onKeyPress={handleSubmit}
            variant="outlined"
          />
        </FormControl>
      </Box>
      <Box mt={3} display="flex" justifyContent="flex-end">
        <Button key="override" variant="outlined" onClick={onClose}>
          Close
        </Button>
      </Box>
    </ActionModal>
  );
}
