import React from "react";
import {Field} from "formik";
import TextField from "@mui/material/TextField";

import dispositions from "../../../constants/dispositions";
import DispositionAutocomplete from "src/views/inventory/requests/disposition/DispositionAutocomplete";

export default function DispositionField(props) {
  return (
    <Field {...props}>
      {({ field, form }) => {
        const handleChange = value => {
          form.setFieldValue(field.name, value ? value : "");
          if (props.onChange) props.onChange(value);
        };

        return (
          <DispositionAutocomplete
            value={field.value || ""}
            onChange={handleChange}
            size="small"
            freeSolo
            filterOptions={(options, state) => options}
            options={dispositions}
            renderInput={params => <TextField {...params} label="Dispositions" />}
          />
        );
      }}
    </Field>
  );
}
