import React from "react";

import {useUndo} from "../../../../contexts/undo";
import RackLocationsModal from "../RackLocationsModal";
import RackLocationsComponent from "./RackLocationsComponent";

export default function RackLocationsInput({ type, modal, onClose, entity }) {
  const { addAction } = useUndo();

  return (
    <>
      <RackLocationsModal
        open
        toggleModal={onClose}
        entity={entity}
        addAction={addAction}
      />
      <RackLocationsComponent entity={entity} />
    </>
  );
}
