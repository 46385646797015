import React, {useState} from "react";
import AddAPhotoIcon from "@material-ui/icons/AddAPhoto";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import LinearProgress from "@material-ui/core/LinearProgress";

import useNotification from "../../hooks/notification";
import {upload as fileStackUpload} from "../../utils/upload";
import Camera from "./Camera";

const cameraType = "default";

export default function ImageInput({ label, imageUrl, onUpload, disabled, filestack = false }) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const { notifyError } = useNotification();

  const toggleOpen = () => setOpen(v => !v);

  const onSuccess = () => {
    setLoading(false);
  };

  const onFailure = errors => {
    notifyError(errors);
    setLoading(false);
  };

  const uploadFile = file => {
    if (filestack) {
      return fileStackUpload(file).then(result => {
        onUpload(result.url, { onSuccess, onFailure });
      });
    } else {
      return onUpload(file, { onSuccess, onFailure });
    }
  };

  const handleDefaultUpload = e => {
    const file = e.target.files[0];
    setLoading(true);
    uploadFile(file).then(() => {
      setLoading(false);
    });
  };

  const handleTakePhoto = dataUri => {
    setLoading(true);
    fetch(dataUri)
      .then(res => res.blob())
      .then(file => {
        toggleOpen();
        uploadFile(file);
      })
      .catch(error => {
        setLoading(false);
        notifyError(error?.message || "Can't upload file");
      });
  };

  if (open) {
    return <Camera takePhoto={handleTakePhoto} toggleOpen={toggleOpen} />;
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      flexGrow={1}
      height="100%"
      justifyContent="space-between"
    >
      {cameraType === "default" && (
        <>
          <input
            type="file"
            accept="image/*"
            id="capture-button"
            capture="environment"
            style={{ display: "none" }}
            disabled={disabled}
            onChange={handleDefaultUpload}
          />
          <label htmlFor="capture-button">
            <Button
              style={{ height: 40 }}
              variant="contained"
              color="primary"
              fullWidth
              disabled={disabled}
              component="span"
            >
              <AddAPhotoIcon style={{ fontSize: 32 }} />
            </Button>
          </label>
        </>
      )}
      {cameraType === "react-webcam" && (
        <Button
          style={{ height: 40 }}
          variant="contained"
          color="primary"
          fullWidth
          disabled={disabled}
          data-id="camera-button"
          component="label"
          onClick={toggleOpen}
        >
          <AddAPhotoIcon style={{ fontSize: 32 }} />
        </Button>
      )}
      {loading && <LinearProgress className="mt-2" />}
    </Box>
  );
}
