import React from "react";

import {replaceCollection, replaceRecord} from "../components/TablePro/utils";
import {useAssetsCollection} from "../contexts/assetsCollection";
import {ASSETS_BULK_UPDATE_MUTATION} from "../constants/graphql";
import useMutation from "./useMutation";
import useNotification from "./notification";
import {useUndo} from "../contexts/undo";

const useAssetsTable = () => {
  const { addAction } = useUndo();
  const { notifyError } = useNotification();
  const [bulkUpdateAssets] = useMutation(ASSETS_BULK_UPDATE_MUTATION);
  const { setCollection, collection, setLoadingMutation } = useAssetsCollection();

  const handleUpdate = (id, values, { skipMutation } = {}) => {
    if (skipMutation) {
      replaceRecord(setCollection, { id, values });
    } else {
      handleBulkUpdate({ [id]: values });
    }
  };

  const handleBulkUpdate = (variables, saveUndo = true) => {
    if (saveUndo) {
      const [[id, values]] = Object.entries(variables);
      const updatedField = Object.entries(values)[0][0];
      const selected = collection.find(v => v.id === id);
      addAction([
        {
          [id]: { [updatedField]: selected[updatedField] },
        },
        variables,
      ]);
    }
    setLoadingMutation(true);
    replaceCollection(setCollection, variables);
    const input = Object.keys(variables).reduce(
      (result, id) => [...result, { id: parseInt(id), ...variables[id] }],
      []
    );

    bulkUpdateAssets({
      variables: { input },
      onSuccess: () => {
        setLoadingMutation(false);
      },
      onFailure: errors => {
        notifyError(errors);
        setCollection(collection);
        setLoadingMutation(false);
      },
    });
  };

  return {
    handleUpdate,
    handleBulkUpdate,
  };
};

export default useAssetsTable;
