import React from "react";
import {fabric} from "src/packs/fabric";

export default function useMobilePanAroundAndZoom(canvas, setZoom, isTouchDevice) {
  React.useEffect(() => {
    let pausePanning;
    let zoomStartScale;
    let currentX;
    let currentY;
    let xChange;
    let yChange;
    let lastX;
    let lastY;
    if (isTouchDevice && canvas) {
      canvas.on("touch:gesture", function(e) {
        if (e.e.touches && e.e.touches.length === 2) {
          pausePanning = true;
          const point = new fabric.Point(e.self.x, e.self.y);
          if (e.self.state === "start") {
            zoomStartScale = canvas.getZoom();
          }
          const delta = zoomStartScale * e.self.scale;
          const zoomPercentage = parseInt(delta * 100, 10);
          if (zoomPercentage >= 10 && zoomPercentage <= 300) {
            canvas.zoomToPoint(point, delta);
            setZoom(zoomPercentage);
          }
          pausePanning = false;
        }
      });
      canvas.on("touch:drag", function(e) {
        if (pausePanning === false && undefined !== e.e.layerX && undefined !== e.e.layerY) {
          currentX = e.e.layerX;
          currentY = e.e.layerY;
          xChange = currentX - lastX;
          yChange = currentY - lastY;
          if (Math.abs(currentX - lastX) <= 50 && Math.abs(currentY - lastY) <= 50) {
            const delta = new fabric.Point(xChange, yChange);
            canvas.relativePan(delta);
          }
          lastX = e.e.layerX;
          lastY = e.e.layerY;
        }
      });
      canvas.on("selection:created", function() {
        pausePanning = true;
      });
      canvas.on("selection:cleared", function() {
        pausePanning = false;
      });
    }
  }, [canvas, setZoom, isTouchDevice]);
}
