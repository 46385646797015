import React, {useState} from "react";
import AddAPhotoIcon from "@material-ui/icons/AddAPhoto";
import Box from "@material-ui/core/Box";
import Camera, {FACING_MODES} from "react-html5-camera-photo";
import styled from "@emotion/styled";
import Typography from "@material-ui/core/Typography";
import ButtonBase from "@material-ui/core/ButtonBase";
import LinearProgress from "@material-ui/core/LinearProgress";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import BigButton from "../../components/BigButton";
import SwitchCameraButton from "../../components/SwitchCameraButton";
import CloseCameraButton from "../../components/CloseCameraButton";
import useNotification from "../../hooks/notification";
import {upload as fileStackUpload} from "../../utils/upload";
import {getImage} from "../..//utils/images";

export const CameraContainer = styled.div`
  position: fixed;
  display: flex;
  z-index: 9999;
  width: 100%;
  background: #1b262c;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  div {
    width: 100%;
    video {
      display: flex;
      min-width: 100%;
      height: 100%;
    }
  }
  video {
    max-width: 100%;
  }
`;

export default function ImageInput({ label, imageUrl, onUpload, disabled, filestack = false }) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const { notifyError } = useNotification();
  const [facingMode, setFacingMode] = useState(FACING_MODES.ENVIRONMENT);
  const isMobile = useMediaQuery(theme => theme.breakpoints.down("sm"));
  const defaultHeight = isMobile ? 120 : 200;

  const toggleOpen = () => setOpen(v => !v);

  const onSuccess = () => {
    setLoading(false);
  };

  const onFailure = errors => {
    notifyError(errors);
    setLoading(false);
  };

  const handleTakePhoto = dataUri => {
    setLoading(true);

    fetch(dataUri)
      .then(res => res.blob())
      .then(file => {
        toggleOpen();

        if (filestack) {
          return fileStackUpload(file).then(result => {
            onUpload(result.url, { onSuccess, onFailure });
          });
        } else {
          onUpload(file, { onSuccess, onFailure });
        }
      })
      .catch(error => {
        setLoading(false);
        notifyError(error?.message || "Can't upload file");
      });
  };

  const toggleOpenMode = () =>
    setFacingMode(
      facingMode === FACING_MODES.ENVIRONMENT ? FACING_MODES.USER : FACING_MODES.ENVIRONMENT
    );

  if (open) {
    return (
      <CameraContainer>
        <Camera
          isMaxResolution
          idealFacingMode={facingMode}
          isImageMirror={false}
          onTakePhoto={handleTakePhoto}
        />
        <SwitchCameraButton onClick={toggleOpenMode} />
        <CloseCameraButton onClick={toggleOpen} />
      </CameraContainer>
    );
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      flexGrow={1}
      height="100%"
      justifyContent="space-between"
    >
      {imageUrl ? (
        <Box overflow="hidden" position="relative" clone>
          <ButtonBase
            disabled={loading || disabled}
            data-id="camera-button"
            style={{ height: defaultHeight }}
            onClick={toggleOpen}
          >
            <img src={getImage(imageUrl, "width", 400)} style={{ width: "100%" }} />
            <Box position="absolute">
              <AddAPhotoIcon fontSize="large" htmlColor="white" />
            </Box>
          </ButtonBase>
        </Box>
      ) : (
        <BigButton
          disabled={disabled}
          data-id="camera-button"
          component="label"
          onClick={toggleOpen}
          height={defaultHeight}
        >
          <AddAPhotoIcon fontSize="large" />
        </BigButton>
      )}
      {loading && <LinearProgress className="mt-2" />}
      <Typography align="center">{label || "Image"}</Typography>
    </Box>
  );
}
