import React from "react";
import {useLocation, useParams} from "react-router-dom";
import Box from "@mui/material/Box";

import AssetDetailsModal from "../../../../components/TablePro/components/assets/AssetDetailsModal";
import KitDetailsModal from "../../../../components/TablePro/components/assets/KitDetailsModal";
import {KitAssetsProvider} from "../../../../contexts/kit-assets";

export default function EditAssetModal({ entity, value, onClose }) {
  const { clientId } = useParams();
  const location = useLocation();
  const isKitting = location.pathname.includes("kitting");

  return (
    <>
      <Box underline="none" sx={{ cursor: "pointer" }} color="primary.main" className="unfocus">
        {value || ""}
      </Box>
      {isKitting ? (
        <KitAssetsProvider id={entity.id}>
          <KitDetailsModal entity={entity} onClose={onClose} />
        </KitAssetsProvider>
      ) : (
        <AssetDetailsModal clientId={clientId} entity={entity} onClose={onClose} />
      )}
    </>
  );
}
