import React, {useState} from "react";
import {Box, Button, Grid, IconButton, TextField} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/AddCircle";

import ActionModal from "../../modals/action-modal";

const initialPhysicalDimensions = {
  depth: "",
  width: "",
  height: "",
};

const DimensionsField = ({ field, onSubmit, value, nextRow, onClose }) => {
  const [fields, setFields] = useState(value?.length ? value : [initialPhysicalDimensions]);

  const handleAddNew = () => {
    setFields(fields => fields.concat(initialPhysicalDimensions));
  };

  const handleRemove = selectedIndex => {
    setFields(fields => fields.filter((v, index) => selectedIndex !== index));
  };

  const updateField = (index, variables) => {
    setFields(fields => [
      ...fields.slice(0, index),
      { ...fields[index], ...variables },
      ...fields.slice(index + 1),
    ]);
  };

  const handleSubmit = () => {
    onSubmit(fields);
    onClose();
    nextRow();
  };

  return (
    <ActionModal open maxWidth={460} onClose={onClose} title={field.label} disableTransition>
      <Grid container spacing={2}>
        {fields.map((v, index) => (
          <Grid item xs={12} key={index}>
            <Box display="flex" alignItems="center">
              <Grid container spacing={1}>
                <Grid item xs={4}>
                  <TextField
                    variant="outlined"
                    name={`dimensions.${index}.width`}
                    label="Width (in)"
                    value={fields[index]["width"]}
                    onChange={event => updateField(index, { width: event.target.value })}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    variant="outlined"
                    name={`dimensions.${index}.depth`}
                    label="Depth (in)"
                    value={fields[index]["depth"]}
                    onChange={event => updateField(index, { depth: event.target.value })}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    variant="outlined"
                    name={`dimensions.${index}.height`}
                    label="Height (in)"
                    value={fields[index]["height"]}
                    onChange={event => updateField(index, { height: event.target.value })}
                  />
                </Grid>
              </Grid>
              {fields.length > 1 && (
                <Box p={1} mr={-1} clone>
                  <IconButton
                    aria-label="delete"
                    variant="outlined"
                    onClick={() => handleRemove(index)}
                  >
                    <DeleteIcon color="action" />
                  </IconButton>
                </Box>
              )}
            </Box>
          </Grid>
        ))}
        <Grid item xs={12} container justify="center">
          <Box display="flex" justifyContent="center">
            <IconButton onClick={handleAddNew}>
              <AddIcon />
            </IconButton>
          </Box>
        </Grid>
      </Grid>
      <Box mb={3} />
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Button fullWidth key="override" variant="outlined" color="primary" onClick={onClose}>
            Close
          </Button>
        </Grid>
        <Grid item xs={8}>
          <Button
            fullWidth
            key="override"
            variant="contained"
            color="primary"
            onClick={handleSubmit}
          >
            Save
          </Button>
        </Grid>
      </Grid>
    </ActionModal>
  );
};

export default React.memo(DimensionsField);
