import React from "react";

export default function StartTimeTooltip({ children }) {
  return children;
  /* return (
    <Tooltip title="You must select a start date and start time at least 48 hours in advance">
      {children}
    </Tooltip>
  ); */
}
