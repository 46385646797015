import React, {useState} from "react";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import CropFreeIcon from "@material-ui/icons/CropFree";

import BigButton from "../BigButton";
import {useStyles} from "../Page";
import Scanner from "./Scanner";

const ScannerFields = ({ onChange, barcode, error, disabled, name }) => {
  const classes = useStyles();
  const [scanOpened, setScanOpened] = useState(false);
  const handleToggle = () => setScanOpened(v => !v);

  if (scanOpened) {
    return <Scanner onDetected={onChange} onClose={handleToggle} />;
  }

  return (
    <FormControl fullWidth className={classes.margin}>
      <Box mb={2}>
        <BigButton disabled={disabled} onClick={handleToggle}>
          <CropFreeIcon fontSize="large" />
        </BigButton>

        <Box mb={2}></Box>

        <FormControl fullWidth>
          <TextField
            disabled={disabled}
            name={name || "barcode"}
            label="Barcode"
            inputProps={{ autoComplete: "off" }}
            value={barcode || ""}
            onChange={event => onChange(event.target.value)}
            variant="outlined"
          />

          {error && (
            <Box mt={1}>
              <FormHelperText htmlFor="barcode" error={!!error}>
                {error}
              </FormHelperText>
            </Box>
          )}
        </FormControl>
      </Box>
    </FormControl>
  );
};

export default ScannerFields;
