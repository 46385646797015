import React from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import SvgIcon from "@mui/material/SvgIcon";
import Grid from "@mui/material/Grid";
import WarehouseOutlinedIcon from "@mui/icons-material/WarehouseOutlined";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";

export default function PaperComponent({ className, children, onToggle, onToggleScan }) {
  return (
    <>
      <Box
        className={className}
        minWidth={350}
        border="1px solid rgba(0, 0, 0, 0.23)"
        borderRadius={1}
      >
        <Paper>
          <Grid container spacing={1} sx={{ pt: 1 }}>
            <Grid item xs={6}>
              <Button
                fullWidth
                onMouseDown={onToggle}
                variant="outlined"
                sx={{ textTransform: "none" }}
                startIcon={
                  <SvgIcon fontSize="small">
                    <WarehouseOutlinedIcon />
                  </SvgIcon>
                }
              >
                Warehouse Map
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                fullWidth
                onMouseDown={onToggleScan}
                variant="outlined"
                sx={{ textTransform: "none" }}
                startIcon={
                  <SvgIcon fontSize="small">
                    <QrCodeScannerIcon />
                  </SvgIcon>
                }
              >
                Scan a Barcode
              </Button>
            </Grid>
          </Grid>

          {children}
        </Paper>
      </Box>
    </>
  );
}
