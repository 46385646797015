import React from "react";
import {Link as RouterLink, useParams} from "react-router-dom";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";

export default function Section({ title, step, children }) {
  const { section } = useParams();
  const selected = section === step;

  return (
    <Paper>
      <Box p={3}>
        <Box display="flex" justifyContent="space-between">
          <Typography variant="h3" color="textSecondary">
            {title}
          </Typography>
          {!selected && (
            <Link className="unfocus" to={`/shopping/cart/${ step }`} component={RouterLink}>
              Change
            </Link>
          )}
        </Box>
        {children}
      </Box>
    </Paper>
  );
}
