import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";

import ConfirmModal from "../../components/ConfirmModal";

export default function RemoveButton({ loading, onClick, ...props }) {
  return (
    <ConfirmModal onConfirm={onClick} title="Delete Template">
      <Tooltip arrow title="Delete Template">
        <IconButton disabled={loading} {...props}>
          {loading ? (
            <Box mr={0.5} clone>
              <CircularProgress size={20} />
            </Box>
          ) : (
            <DeleteOutlinedIcon />
          )}
        </IconButton>
      </Tooltip>
    </ConfirmModal>
  );
}
