import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import ButtonBase from "@mui/material/ButtonBase";
import AssetImage from "./AssetImage";

export default function Item({ value, images, info, onClick }) {
  return (
    <Box>
      <ButtonBase
        sx={{ width: "100%", justifyContent: "flex-start", textAlign: "left" }}
        onClick={onClick}
      >
        <Box display="flex" my={1.5}>
          <Box mr={3}>
            <AssetImage width={120} height={100} image={images[0]?.url} />
          </Box>
          <Box>
            <Typography fontWeight={500}>{value.asset.itemId}</Typography>
            <Typography mt={1}>{value.asset.description}</Typography>
            {info ? (
              <Box mt={1}>{info}</Box>
            ) : (
              <Typography mt={1}>Quantity: {value.quantity}</Typography>
            )}
          </Box>
        </Box>
      </ButtonBase>
      <Divider />
    </Box>
  );
}
