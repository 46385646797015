import React from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import Grid from "@material-ui/core/Grid";

const InputSkeleton = () => <Skeleton variant="rect" height={40} />;

const ModalSkeleton = () => (
  <Grid container spacing={1}>
    <Grid item xs={12}>
      <InputSkeleton />
    </Grid>
    <Grid item xs={12}>
      <InputSkeleton />
    </Grid>
    <Grid item xs={12}>
      <InputSkeleton />
    </Grid>
    <Grid item xs={12}>
      <InputSkeleton />
    </Grid>
    <Grid item xs={12}>
      <InputSkeleton />
    </Grid>
  </Grid>
);

export default ModalSkeleton;
