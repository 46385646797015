import React, {useCallback, useState} from "react";
import {makeStyles} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import FormHelperText from "@material-ui/core/FormHelperText";
import SvgIcon from "@material-ui/core/SvgIcon";
import TextField from "@material-ui/core/TextField";
import {Minus as MinusIcon, Plus as PlusIcon} from "react-feather";
import {useParams} from "react-router-dom";

import {useShopping} from "../../../contexts/shopping";
import useMutation from "../../../hooks/useMutation";
import {ADD_SHOPPING_INVENTORY_ITEM_MUTATION} from "../../../constants/graphql";
import {addInventoryFields} from "../Cart/CartItemFields";
import AddToCartAction from "./AddToCartAction";

export const useStyles = makeStyles(theme => ({
  quantityInput: {
    width: "70px",
    "& input": {
      fontSize: "0.875rem",
      padding: "10px 6px",
      height: "10px",
    },
  },
  quantityButtonMinus: {
    minWidth: "20px",
    height: "30px",
    marginRight: "-1px",
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    "& svg": {
      fontSize: "0.875rem",
    },
  },
  quantityButtonPlus: {
    minWidth: "20px",
    height: "30px",
    marginLeft: "-1px",
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    "& svg": {
      fontSize: "0.875rem",
    },
  },
}));

const AddToCart = ({ asset, defaultValues, size = "medium" }) => {
  const classes = useStyles({ size });
  const { projectId } = useParams();
  const {
    inventoryItems,
    setInventoryAsset,
    setInventoryItem,
    removeInventoryItem,
  } = useShopping();
  const inventoryItem = inventoryItems[asset.id];
  const [quantity, setQuantity] = useState(0);
  const [error, setError] = useState(null);
  const [addInventoryItem, { loading }] = useMutation(ADD_SHOPPING_INVENTORY_ITEM_MUTATION);
  const notAvailable = asset.availableQuantity <= 0;

  const updateQuantity = useCallback(
    value => {
      if (value >= 0 && value <= asset.availableQuantity) {
        setQuantity(value);
      }
    },
    [asset.availableQuantity, setQuantity]
  );

  const handleMinus = useCallback(() => updateQuantity(quantity - 1), [quantity, updateQuantity]);

  const handlePlus = useCallback(() => updateQuantity(quantity + 1), [quantity, updateQuantity]);

  const handleUpdate = useCallback(
    event => updateQuantity(event.target.value ? parseInt(event.target.value, 10) : 0),
    [quantity, updateQuantity]
  );

  const handleAddInventoryItem = () => {
    if (quantity === 0 && !inventoryItem) return;

    const variables = addInventoryFields({
      ...inventoryItem,
      quantity,
      projectId,
      assetId: asset.id,
      ...defaultValues,
    });

    return addInventoryItem({
      variables,
      onSuccess: ({ inventoryItem, asset }) => {
        setError(null);
        setQuantity(0);
        if (inventoryItem && asset) {
          setInventoryAsset(asset);
        }
        inventoryItem ? setInventoryItem(inventoryItem) : removeInventoryItem(asset.id);
      },
      onFailure: errors => {
        setError(errors.quantity || errors.asset || errors.submit);
      },
    });
  };

  return (
    <>
      <Box display="flex" justifyContent="space-between" width="100%">
        <Box>
          <Button
            disabled={notAvailable || loading}
            className={classes.quantityButtonMinus}
            variant="outlined"
            size="small"
            onClick={handleMinus}
          >
            <SvgIcon fontSize="small">
              <MinusIcon />
            </SvgIcon>
          </Button>
          <TextField
            disabled={notAvailable || loading}
            className={classes.quantityInput}
            color="secondary"
            variant="outlined"
            fullWidth
            type="number"
            size="small"
            inputProps={{ min: 0, step: 1 }}
            value={quantity === 0 ? "" : quantity}
            onChange={handleUpdate}
          />
          <Button
            disabled={notAvailable || loading}
            className={classes.quantityButtonPlus}
            variant="outlined"
            size="small"
            onClick={handlePlus}
          >
            <SvgIcon fontSize="small">
              <PlusIcon />
            </SvgIcon>
          </Button>
        </Box>
        <AddToCartAction
          asset={asset}
          notAvailable={notAvailable}
          addInventoryItem={handleAddInventoryItem}
        />
      </Box>
      {error && (
        <Box mt={1}>
          <FormHelperText htmlFor="barcode" error={!!error}>
            {error}
          </FormHelperText>
        </Box>
      )}
    </>
  );
};

export default AddToCart;
