import React, {useState} from "react";
import Box from "@mui/material/Box";

import AssetDetailsModal from "./AssetDetailsModal";
import KitDetailsModal from "./KitDetailsModal";

export default function ItemId({ asset }) {
  const [open, setOpen] = useState(false);

  const handleToggle = () => {
    setOpen(v => !v);
  };

  return (
    <>
      <Box
        underline="none"
        sx={{ cursor: "pointer" }}
        color="primary.main"
        className="unfocus"
        onClick={handleToggle}
      >
        {asset.itemId}
      </Box>
      {open && (
        <>
          {asset.type === "Asset" && <AssetDetailsModal asset={asset} onClose={handleToggle} />}
          {asset.type === "Kit" && <KitDetailsModal asset={asset} onClose={handleToggle} />}
        </>
      )}
    </>
  );
}
