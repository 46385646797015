import React, { useMemo } from "react";
import { useQuery } from "@apollo/react-hooks";
import { useParams } from "react-router-dom";
import { sortBy } from "lodash";
import CircularProgress from "@mui/material/CircularProgress";

import { CLIENTS_QUERY } from "../constants/graphql";
import { getClientByProjectId, getProject } from "../utils/client";
import { fetchSettings } from "../contexts/customFields";

const ClientsContext = React.createContext(null);

export const ClientsProvider = ({ children }) => {
  const { projectId, clientId } = useParams();

  const { loading, error, data: { clients = [] } = [] } = useQuery(CLIENTS_QUERY, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-first",
  });

  const currentProject = getProject(clients, projectId);

  const currentClient = clientId
    ? clients.find(client => client.id === clientId)
    : projectId
    ? getClientByProjectId(clients, projectId)
    : clients[0];

  const value = useMemo(
    () => ({
      currentProject: currentProject,
      clients: sortBy(clients, "name"),
      currentClient: currentClient && {
        ...currentClient,
        projects: sortBy(currentClient?.projects || [], "name"),
      },
    }),
    [clients, currentProject, currentClient]
  );

  if (loading) return <CircularProgress />;
  if (error) return <p>Error...</p>;

  return <ClientsContext.Provider value={value}>{children}</ClientsContext.Provider>;
};

export const useClients = () => React.useContext(ClientsContext);

export const useCustomProjectFields = (props = {}) => {
  const { clients } = useClients();
  const projectId = props.projectId || useParams().projectId;

  return useMemo(() => {
    return fetchSettings(clients, projectId);
  }, [clients, projectId]);
};
