import React, { useCallback } from "react";
import { useField } from "formik";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import Input from "../Input";

const initialDescr = {
  description: "",
  origin_qty: "",
  dest_qty: "",
  dest_room: "",
};

const fieldName = "notes.descriptions";

const NotesField = ({ isDonation, disabled }) => {
  const [field, , helpers] = useField({ name: fieldName });
  const fields = field.value || [];

  const handleAddNew = useCallback(() => {
    helpers.setValue(fields.concat(initialDescr));
  }, [helpers, fields]);

  const handleRemove = useCallback(
    (i) => helpers.setValue(fields.filter((v, index) => i !== index)),
    [helpers, fields]
  );

  return (
    <>
      {fields.map((v, index) => (
        <Grid item xs={12} key={index}>
          <Grid container spacing={1}>
            <Grid item xs={isDonation ? 8 : 3} md={isDonation ? 10 : 6}>
              <Input
                disabled={disabled}
                name={`${fieldName}.${index}.description`}
                label="Description"
              />
            </Grid>
            <Grid item xs={isDonation ? 4 : 9} md={isDonation ? 2 : 6}>
              <Box display="flex" alignItems="center">
                <Grid container spacing={1}>
                  <Grid item xs={isDonation ? 12 : 4}>
                    <Input
                      disabled={disabled}
                      name={`${fieldName}.${index}.origin_qty`}
                      label="qty"
                    />
                  </Grid>
                  {!isDonation && (
                    <>
                      <Grid item xs={4}>
                        <Input
                          disabled={disabled}
                          name={`${fieldName}.${index}.dest_qty`}
                          label="Dest qty"
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <Input
                          disabled={disabled}
                          name={`${fieldName}.${index}.dest_room`}
                          label="Dest Rm."
                        />
                      </Grid>
                    </>
                  )}
                </Grid>
                {fields.length > 1 && (
                  <Box p={1} mr={-1} clone>
                    <IconButton
                      disabled={disabled}
                      aria-label="delete"
                      variant="outlined"
                      onClick={() => handleRemove(index)}
                    >
                      <DeleteIcon color="action" />
                    </IconButton>
                  </Box>
                )}
              </Box>
            </Grid>
          </Grid>
        </Grid>
      ))}

      <Box mb={1}></Box>

      <Grid item xs={12}>
        <Button
          id={`addNew-${fieldName.split(".").join("-")}`}
          variant="outlined"
          onClick={handleAddNew}
        >
          Add New <AddIcon color="action" fontSize="small" />
        </Button>
      </Grid>
    </>
  );
};

export default React.memo(NotesField);
