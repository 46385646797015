export const useInfinityScroll = ({
  entities,
  totalCount,
  rowsCount,
  onLoadMore,
  loading,
}) => {
  const stubSize = Math.ceil(rowsCount / 2.4);
  const isLoadMore = entities.length < totalCount;
  const itemCount = isLoadMore ? entities.length + stubSize : entities.length;
  const loadMoreItems = loading || !isLoadMore ? onLoadMore : onLoadMore;
  const isItemLoaded = (index) => entities[index];

  return {
    itemCount,
    loadMoreItems,
    isItemLoaded,
  };
};
