import orderBy from "lodash/orderBy";

export default orderBy(
  [
    "Herman Miller",
    "Knoll",
    "Steelcase",
    "Hon",
    "Teknion",
    "Xerox",
    "Sharp",
    "HP",
    "Humanscale",
    "Workrite",
    "Konica Minolta",
    "Haworth",
    "Panasonic",
    "Hotpoint",
    "Equipto",
    "Graphtec",
    "Dayton",
    "Devon",
    "Avanti",
    "Colortrac",
    "Inmac",
    "Black & Decker",
    "Safco",
    "eBeam",
    "Staples",
    "Steel Master",
    "Oce",
    "Global",
    "Infotron",
    "GE",
    "Cuisinart",
    "Kenmore",
    "Keurig",
    "Magic Chef",
    "Tensco",
    "Canon",
    "Ergotron",
    "Mayline",
    "Fellowes",
    "Tennsco",
    "FireKing",
    "SentrySafe",
    "CRS",
    "Sunbeam",
    "Amona",
    "Kitchen Gourmet",
    "Anderson Hickey",
    "Proctor Silex",
    "Farberware",
    "Euro-pro",
    "Igloo",
    "Panafax",
    "Eastman",
    "Living Solutions",
    "Mr. Coffee",
    "Holmes",
    "Brother",
    "Samsung",
    "Uline",
    "Harbor",
    "Lorell",
    "Quartet",
    "Via",
    "Easton",
    "Epson",
    "Rubbermaid",
    "Master Vision",
    "Oster",
    "Globe Wernicke",
    "Haler",
    "Schwab",
    "APG",
    "Honeywell",
    "Swingline",
    "Ricoh",
    "Sanyo",
    "Swintec",
    "Leviton",
    "Bentson",
    "Admiral",
    "Stryker",
    "Grainger",
    "RBM",
    "Amplivox",
    "Lasko",
    "IBM",
    "Russ Bassett",
    "Tab",
    "Air King",
    "Neopost",
    "Eyecom",
    "Imagine Data",
    "Bunn",
    "Rittal",
    "APC",
    "eCopy",
    "Direct TV",
    "Gefen",
    "Alvin",
    "Synergistic",
    "Oasis",
    "Maytag",
    "Pure Ex",
    "Maker Bot",
    "Sentry",
  ],
  v => v.toLowerCase()
);
