import React, {useCallback} from "react";
import {useField} from "formik";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";

import Input from "../Input";

const initialPhysicalDimensions = {
  depth: "",
  width: "",
  height: ""
};

const PhysicalDimensionsField = ({ name }) => {
  const [field, , helpers] = useField({ name });
  const fields = field.value || [];

  const handleAddNew = useCallback(() => {
    helpers.setValue(fields.concat(initialPhysicalDimensions));
  }, [helpers, fields]);

  const handleRemove = useCallback(
    i => helpers.setValue(fields.filter((v, index) => i !== index)),
    [helpers, fields]
  );

  return (
    <>
      {fields.map((v, index) => (
        <Grid item xs={12} key={index}>
          <Box display="flex" alignItems="center">
            <Grid container spacing={1}>
              <Grid item xs={4}>
                <Input name={`${name}.${index}.width`} label="Width (in)" />
              </Grid>
              <Grid item xs={4}>
                <Input name={`${name}.${index}.depth`} label="Depth (in)" />
              </Grid>
              <Grid item xs={4}>
                <Input name={`${name}.${index}.height`} label="Height (in)" />
              </Grid>
            </Grid>
            {fields.length > 0 && (
              <Box p={1} mr={-1} clone>
                <IconButton
                  aria-label="delete"
                  variant="outlined"
                  onClick={() => handleRemove(index)}
                >
                  <DeleteIcon color="action" />
                </IconButton>
              </Box>
            )}
          </Box>
        </Grid>
      ))}
      <Grid item xs={12}>
        <Button
          id={`addNew-${name.split(".").join("-")}`}
          variant="outlined"
          onClick={handleAddNew}
        >
          Add New <AddIcon color="action" fontSize="small" />
        </Button>
      </Grid>
    </>
  );
};

export default React.memo(PhysicalDimensionsField);
