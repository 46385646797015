import React, {useMemo} from "react";

import {AssetsCollectionProvider} from "../../contexts/assetsCollection";
import {useCapture} from "../../contexts/capture";
import {useProjectInventory} from "../../contexts/projectInventory";

export default function CollectionProvider({ type, children }) {
  const { order } = useCapture();
  const { projectId, clientId } = useProjectInventory();

  const values = useMemo(() => {
    const variables = {};

    if (projectId) {
      variables.projectId = projectId;
    } else if (clientId) {
      variables.clientId = clientId;
    }

    if (order) {
      const [key, value] = order.split(".");
      variables.order = { [key]: value };
    }

    return variables;
  }, [projectId, clientId, order]);

  return (
    <AssetsCollectionProvider type={type} variables={values}>
      {children}
    </AssetsCollectionProvider>
  );
}
