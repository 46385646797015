import React from "react";
import Box from "@mui/material/Box";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const sizesDesktop = {
  "x-large": "10",
  large: "5",
  medium: "3",
  small: "2",
};

const sizesMobile = {
  "x-large": "8",
  large: "4",
  medium: "2",
  small: "2",
};

export default function DefaultViewComponent({ value, size }) {
  const isMobile = useMediaQuery(theme => theme.breakpoints.down("md"));

  return (
    <Box
      component="span"
      maxWidth="100%"
      maxHeight="100%"
      overflowWrap="break-word"
      textOverflow="ellipsis"
      overflow="hidden"
      minHeight="18px"
      sx={{
        display: "-webkit-box",
        "-webkit-line-clamp": isMobile ? sizesMobile[size] : sizesDesktop[size],
        "-webkit-box-orient": "vertical",
      }}
    >
      {value?.toString()}
    </Box>
  );
}
