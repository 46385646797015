import orderBy from "lodash/orderBy";

// Used for scrubber and capture page dropdown lists
export default orderBy(
  [
    "Dispose",
    "Donate",
    "Move",
    "Storage",
    "Recycle",
    "Resell",
  ],
  v => v.toLowerCase()
);

// Used by jexcel legacy scrubber
export const dispositions = [
  "DISPOSE",
  "DONATE",
  "MOVE",
  "STORAGE",
  "SHIPPING",
];

const DISPOSITION_OPTIONS = [
  ["dispose", "DISPOSE"],
  ["donate", "DONATE"],
  ["move", "MOVE"],
  ["storage", "STORAGE"],
  ["shipping", "SHIPPING"],
];


