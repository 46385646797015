import React, {useState} from "react";
import {useHistory} from "react-router-dom";
import startCase from "lodash/startCase";
import PopupState, {bindMenu, bindTrigger} from "material-ui-popup-state";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import MuiMenu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import FullScreenIcon from "@material-ui/icons/AspectRatio";
import SizeIcon from "@material-ui/icons/FormatSize";
import ColumnsIcon from "@material-ui/icons/ViewColumn";
import RefetchIcon from "@material-ui/icons/Cached";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FilterListIcon from "@material-ui/icons/FilterList";

import MultiSelect from "../MultiSelect";
import Menu from "../Menu";
import useQuery from "../../hooks/useQuery";
import {isReactComponent} from "../../utils/detect";

const SIZE_OPTIONS = ["small", "medium", "large", "x-large"];

const SizeLabel = ({ value }) => (
  <>
    <SizeIcon fontSize="small" color="action" />
    <Box mr={1} />
    <Typography fontWeight={600} color="textSecondary" variant="body2">
      {startCase(value)}
    </Typography>
  </>
);

const ColumnsLabel = () => (
  <>
    <ColumnsIcon fontSize="small" color="action" />
    <Box mr={1} />
    <Typography fontWeight={600} color="textSecondary" variant="body2">
      Columns
    </Typography>
  </>
);

const Filter = ({ refetch, fields, onChange, size, fullScreen, toggleFullScreen }) => {
  const query = useQuery();
  const history = useHistory();
  const avialbleFields = fields.filter(field => !field.inactive);
  const visibleFields = fields.filter(field => field.visible);

  const [visibleIds, setVisibleIds] = useState(visibleFields.map(field => field.id));

  const onFieldsChange = () => {
    const newFields = fields.map(field => ({
      ...field,
      visible: visibleIds.includes(field.id),
    }));

    onChange({ fields: newFields });
  };

  const onSizeChange = size => {
    onChange({ size });
  };

  return (
    <Box display="flex" justifyContent="flex-end" alignItems="center" style={{ minHeight: 46 }}>
      <Button style={{ textTransform: "none" }} size="small" onClick={toggleFullScreen}>
        <FullScreenIcon color="action" />
        <Box mr={1} />
        <Typography fontWeight={600} color="textSecondary" variant="body2">
          {fullScreen ? "Min Screen" : "Max Screen"}
        </Typography>
      </Button>
      <Box mr={1} />

      <Button style={{ textTransform: "none" }} size="small" onClick={refetch}>
        <RefetchIcon color="action" />
        <Box mr={1} />
        <Typography fontWeight={600} color="textSecondary" variant="body2">
          Refresh
        </Typography>
      </Button>

      <Box mr={1} />

      <Menu
        style={{ minWidth: 80 }}
        data-id="tableSize"
        options={SIZE_OPTIONS}
        value={size}
        label={SizeLabel}
        onSelect={onSizeChange}
      />
      <Box mr={1} />

      <MultiSelect
        style={{ minWidth: 80 }}
        data-id="columnsSize"
        label={<ColumnsLabel />}
        onClose={onFieldsChange}
        onChange={setVisibleIds}
        value={visibleIds}
        options={avialbleFields.map(field =>
          field.label && isReactComponent(field.label)
            ? [field.id, <field.label />]
            : [field.id, field.label]
        )}
      />
      <PopupState variant="popover" popupId="demo-popup-menu">
        {popupState => (
          <React.Fragment>
            <Button size="small" {...bindTrigger(popupState)}>
              <FilterListIcon fontSize="small" color="action" />
              <Box mr={1} />
              <Typography
                variant="body2"
                color="textSecondary"
                style={{ textTransform: "capitalize" }}
              >
                Filter
              </Typography>
            </Button>
            <MuiMenu {...bindMenu(popupState)}>
              <MenuItem onClick={popupState.close}>
                <FormControlLabel
                  style={{ margin: 0 }}
                  control={
                    <Checkbox
                      checked={query.get("missingItems") === "true"}
                      onChange={e => {
                        history.push({
                          pathname: window.location.pathname,
                          search: query.get("missingItems") === "true" ? "" : "?missingItems=true",
                        });
                      }}
                    />
                  }
                  label="Missing Items"
                />
              </MenuItem>
            </MuiMenu>
          </React.Fragment>
        )}
      </PopupState>
      <Box mr={2} />
    </Box>
  );
};

export default Filter;
