import React from "react";
import Grid from "@material-ui/core/Grid";
import ArrayField from "./ArrayField";

const FinishesField = ({ isDonation, disabled }) => {
  return isDonation ? (
    <Grid container spacing={1}>
      <Grid item xs={4}>
        <Grid container spacing={1}>
          <ArrayField name="notes.fabrics" label="Fabric" />
        </Grid>
      </Grid>
      <Grid item xs={4}>
        <Grid container spacing={1}>
          <ArrayField disabled={disabled} name="notes.laminates" label="Laminate" />
        </Grid>
      </Grid>
      <Grid item xs={4}>
        <Grid container spacing={1}>
          <ArrayField disabled={disabled} name="finishes" label="Finish" />
        </Grid>
      </Grid>
    </Grid>
  ) : (
      <ArrayField disabled={disabled} name="finishes" label="Finish" />
    );
};

export default React.memo(FinishesField);
