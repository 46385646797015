import React from "react";
import PropTypes from "prop-types";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Close from "@material-ui/icons/Close";

const ArrowBack = () => "<";

const useStyles = makeStyles(theme => {});

const FullscreenModal = ({
  maxWidth,
  onBack,
  onClose,
  showClose,
  title,
  actions,
  id,
  bodyClassName,
  disableTransition,
  children,
  disableFullScreen,
  ...rest
}) => {
  // Declare style hook to use classes
  const classes = useStyles({ maxWidth });
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [uuid] = React.useState(id);

  return (
    <Dialog
      {...rest}
      maxWidth={false}
      onClose={() => {
        if (onClose) {
          onClose();
        }
      }}
      PaperProps={{ classes: { root: classes.paperRoot } }}
      fullScreen={disableFullScreen ? false : fullScreen}
      classes={{
        paperFullScreen: classes.paperFullScreen,
        paperScrollPaper: classes.paperScrollPaper,
        scrollPaper: classes.scrollPaper,
      }}
      aria-labelledby={uuid}
      transitionDuration={disableTransition ? 0 : undefined}
    >
      <DialogTitle classes={{ root: classes.titleRoot }} id={uuid}>
        <Grid container alignItems="center" wrap="nowrap">
          <div style={{ minHeight: "64px" }} />
          <Grid item style={{ padding: "8px" }}>
            {onBack && (
              <IconButton onClick={() => onBack()}>
                <ArrowBack titleAccess="Back" />
              </IconButton>
            )}
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h2" color="textPrimary">
              {title}
            </Typography>
          </Grid>
          <Grid item style={{ padding: "8px" }}>
            {showClose && onClose && (
              <IconButton onClick={() => onClose()}>
                <Close titleAccess="Close" />
              </IconButton>
            )}
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent classes={{ root: classes.contentRoot }}>
        <Box className={classes.scrollbar}>{children}</Box>
      </DialogContent>
      {actions && <DialogActions className="m-0 p-sm-3">{actions}</DialogActions>}
    </Dialog>
  );
};

FullscreenModal.propTypes = {
  /** If true, the Dialog is open */
  open: PropTypes.bool,
  /** The Dialog's title */
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  /** The instructions for the dialog */
  actions: PropTypes.node,
  /** Function called when the Dialog closes () => {} */
  onClose: PropTypes.func,
  /** Override to hide the close button */
  showClose: PropTypes.bool,
  /** Function called when the Back button is pressed () => {} */
  onBack: PropTypes.func,
  /** Max width for the fullscreen modal on lgUp screens */
  maxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** If true, the modal fade transition will be removed */
  disableTransition: PropTypes.bool,
  /** Id to link the dialog title to the dialog's aria-labelledby attribute (will be randomly generated if not provided) */
  id: PropTypes.string,
  /** ClassName for the body scrollbar element */
  bodyClassName: PropTypes.string,
  children: PropTypes.node,
};

FullscreenModal.defaultProps = {
  open: true,
  showClose: true,
  disableTransition: false,
};

export default FullscreenModal;
