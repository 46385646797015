import React from "react";
import orderBy from "lodash/orderBy";
import Box from "@material-ui/core/Box";
import MenuItem from "@mui/material/MenuItem";
import {useQuery} from "@apollo/react-hooks";
import Checkbox from "@material-ui/core/Checkbox";
import Typography from "@mui/material/Typography";

import {CLIENTS_QUERY} from "../../constants/graphql";

export default function ProjectFilter({ selected, setSelected }) {
  const { data: { clients = [] } = {} } = useQuery(CLIENTS_QUERY);

  const groupedProjectsList = orderBy(clients, "name").map(client => [
    client.name,
    orderBy(client.projects, "name").map(({ id, name }) => [id, name]),
  ]);

  return groupedProjectsList.map(([clientName, projects]) => (
    <Box>
      <Typography ml={2} fontSize={14} fontWeight={500} color="textPrimary">
        {clientName}
      </Typography>
      {projects.map(([projectId, projectName]) => (
        <MenuItem
          sx={{ fontSize: 14 }}
          onClick={() => {
            setSelected(prevState =>
              prevState.includes(projectId)
                ? prevState.filter(v => v !== projectId)
                : [...prevState, projectId]
            );
          }}
        >
          <Box my={-1} ml={-1}>
            <Checkbox checked={selected.includes(projectId)} onChange={e => {}} value="" />
          </Box>
          {projectName}
        </MenuItem>
      ))}
    </Box>
  ));
}
