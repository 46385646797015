import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import LinearProgress from "@material-ui/core/LinearProgress";

import SearchInput from "./SearchInput";
import AdvancedSearch from "../AdvancedSearch";
import AdvancedSearchButton from "src/components/AdvancedSearch/AdvancedSearchButton";

const useStyles = makeStyles({
  wrapper: {
    overflow: "visible"
  },
  searchContainer: {
    position: "relative"
  },
  advancedSearchButton: {
    left: 4,
    width: "calc(100% - 8px)"
  }
});

const AssetsWrap = ({ height, loading, search, setSearch, children }) => {
  const [value, setValue] = useState(search);
  const [showAdvancedSearch, setShowAdvancedSearch] = useState(false);
  const [showAdvancedSearchButton, setShowAdvancedSearchButton] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    setValue(search);
  }, [search]);

  const handleSubmit = event => {
    event.preventDefault();
    setSearch(value);
  };

  const handleAdvancedSearchSubmit = value => {
    setValue(value);
    setSearch(value);
    setShowAdvancedSearch(false)
  };

  return (
    <Card className={classes.wrapper} style={{ height }}>
      <CardContent>
        <Grid container spacing={1}>
          <Grid item xs={12} className={classes.searchContainer}>
            <form onSubmit={handleSubmit}>
              <FormControl fullWidth>
                <SearchInput
                  value={value}
                  onChange={setValue}
                  setSearch={setSearch}
                  onFocus={() => setShowAdvancedSearchButton(true)}
                  // a small delay before the button is hidden, ensuring that the click event has time to process first
                  onBlur={() => setTimeout(() => setShowAdvancedSearchButton(false), 200)}
                />
              </FormControl>
            </form>
            {showAdvancedSearchButton &&
              <AdvancedSearchButton
                className={classes.advancedSearchButton}
                onClick={() => {
                  setShowAdvancedSearch(true)
                }}
              />
            }
            {showAdvancedSearch &&
              <AdvancedSearch
                initialValue={value}
                onSearch={handleAdvancedSearchSubmit}
                onCancel={() => setShowAdvancedSearch(false)}
              />
            }
          </Grid>
          <Grid item xs={12}>
            {loading ? <LinearProgress className="mt-2" /> : children}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default AssetsWrap;
