import React from "react";
import {Redirect} from "react-router-dom";
import PropTypes from "prop-types";
import useAuth from "../hooks/useAuth";
import {usePermissions} from "../contexts/permissions";

const DashboardGuard = ({ children }) => {
  const { isAuthenticated } = useAuth();
  const { can } = usePermissions();

  if (!isAuthenticated) {
    return <Redirect to="/login" />;
  }

  if (!can("pages.dashboard")) {
    return <Redirect to="/403" />;
  }

  return (
    <>
      {children}
    </>
  );
};

DashboardGuard.propTypes = {
  children: PropTypes.node
};

export default DashboardGuard;
