import React from "react";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";

export default function CartItemsHeader({ display }) {
  return (
    <TableHead>
      <TableRow>
        {display.ii_image && <TableCell>Image</TableCell>}
        {display.ii_item_id && <TableCell>Item ID</TableCell>}
        {display.ii_barcode && <TableCell>Barcode</TableCell>}
        {display.ii_description && <TableCell>Description</TableCell>}
        {display.ii_model && <TableCell>Model</TableCell>}
        {display.ii_dimensions && <TableCell>Dimensions</TableCell>}
        {display.ii_qtyRequested && <TableCell>Qty Requested </TableCell>}
        {display.ii_building && <TableCell>Building</TableCell>}
        {display.ii_floor && <TableCell>Floor</TableCell>}
        {display.ii_room && <TableCell>Room</TableCell>}
        {display.ii_po_number && <TableCell>PO Number</TableCell>}
        {display.ii_idsr_number && <TableCell>IDSR #</TableCell>}
        {display.ii_eoc_number && <TableCell>EOC</TableCell>}
        {display.ii_deliveryDate && <TableCell>Delivery Date</TableCell>}
        {display.ii_deliveryTime && <TableCell>Delivery Time</TableCell>}
        {display.sc_price && <TableCell>Price</TableCell>}
        <TableCell />
      </TableRow>
    </TableHead>
  );
}
