import {useEffect, useState} from "react";
import moment from "moment/moment";

const formatSeconds = s => {
  if (s > 45) {
    return 45;
  } else if (s > 30) {
    return 30;
  } else if (s > 15) {
    return 15;
  } else {
    return 0;
  }
};

export default function useTimer(startTime, endTime) {
  const [differenceInSeconds, setDifferenceInSeconds] = useState(0);
  const minutes = Math.floor(differenceInSeconds / 60);
  const seconds = differenceInSeconds % 60;

  useEffect(() => {
    const updateTimer = () => {
      const end = endTime ? endTime : moment(new Date());
      setDifferenceInSeconds(end.diff(startTime, "seconds"));
    };
    updateTimer();
    const interval = setInterval(() => {
      updateTimer();
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [startTime, endTime]);

  return [minutes, formatSeconds(seconds)];
}
