import React, {useState} from "react";
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import Input from "@material-ui/core/Input";
import Button from "@material-ui/core/Button";
import SearchIcon from "@material-ui/icons/Search";
import {makeStyles} from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles(theme => ({
  searchInput: {
    marginLeft: theme.spacing(2),
  },
  chip: {
    margin: theme.spacing(1),
    marginLeft: 0,
    marginRight: theme.spacing(2),
  },
}));

export default function Filter({ className, filter, disabled, onChange }) {
  const classes = useStyles();
  const [inputValue, setInputValue] = useState(filter.search);

  const handleInputChange = event => {
    event.persist();
    setInputValue(event.target.value);
  };

  const handleInputKeyup = event => {
    event.persist();
    if (event.keyCode === 13 && event.target.value) {
      setInputValue(event.target.value);
      handleSubmitInput();
    }
  };

  const handleSubmitInput = () => {
    onChange({ search: inputValue });
  };

  const handleClear = () => {
    setInputValue("");
    onChange({ search: "" });
  };

  return (
    <Card className={className}>
      <Box p={2} display="flex" alignItems="center">
        <SearchIcon />
        <Input
          name="assetSearch"
          disableUnderline
          fullWidth
          className={classes.searchInput}
          onChange={handleInputChange}
          onKeyUp={handleInputKeyup}
          placeholder="Enter a keyword"
          value={inputValue}
          disabled={disabled}
          endAdornment={
            <InputAdornment>
              {inputValue && (
                <IconButton onClick={handleClear}>
                  <CloseIcon />
                </IconButton>
              )}
            </InputAdornment>
          }
        />
        <Button disabled={disabled} variant="outlined" onClick={handleSubmitInput}>
          Search
        </Button>
      </Box>
    </Card>
  );
}

Filter.propTypes = {
  className: PropTypes.string,
};
