import {callerField, fieldAttrName} from "utils/caller";
import zipObject from "lodash/zipObject";
import merge from "lodash/merge";
import uniq from "lodash/uniq";
import sumBy from "lodash/sumBy";

export const removeCollection = ({ setCollection, setMetadata, ids }) => {
  setCollection(prev => prev.filter(record => !ids.includes(record.id)));

  setMetadata(prev => ({
    ...prev,
    totalCount: prev.totalCount - ids.length,
    ...(prev.totalIds ? { totalIds: prev.totalIds.filter(id => !ids.includes(id)) } : {}),
  }));
};

export const addRecord = ({ setCollection, setMetadata, record }) => {
  setCollection(prev => [record, ...prev]);

  setMetadata(prev => ({
    ...prev,
    totalCount: prev.totalCount + 1,
    totalIds: [record.id, ...prev.totalIds],
    ...(prev.totalIds ? { totalIds: [record.id, ...prev.totalIds] } : {}),
  }));
};

export const replaceCollection = (setter, variables) =>
  setter(prev => {
    const collection = prev;
    const ids = Object.keys(variables);

    return collection.map(entity =>
      ids.includes(entity.id) ? { ...entity, ...variables[entity.id] } : entity
    );
  });

export const replaceRecord = (setter, { id, values }) =>
  replaceCollection(setter, { [id]: values });

export const updateCollectionQuery = (collectionKey, { collection, metadata, onSuccess }) => (
  previousResultCache,
  { fetchMoreResult }
) => {
  const previousResult = {
    ...previousResultCache,
    [collectionKey]: {
      ...previousResultCache[collectionKey],
      collection,
      metadata,
    },
  };

  const prevCollection = previousResult[collectionKey].collection;
  const newCollection = fetchMoreResult[collectionKey].collection;

  const existAsset = prevCollection.find(item => item.id === newCollection[0]?.id);

  if (existAsset) return;

  const newCollectionData = updateCollectionData(
    previousResult[collectionKey],
    fetchMoreResult[collectionKey]
  );

  if (onSuccess) {
    onSuccess({ [collectionKey]: newCollectionData });
  }

  return Object.assign({}, previousResult, {
    ...previousResult,
    [collectionKey]: newCollectionData,
  });
};

const updateCollectionData = (previousResult, newResult) => {
  const prevCollection = previousResult.collection;
  const newCollection = newResult.collection;

  const existAsset = prevCollection.find(item => item.id === newCollection[0]?.id);

  if (existAsset) return;

  return {
    ...previousResult,
    collection: [...prevCollection, ...newCollection],
    metadata: newResult.metadata,
  };
};

// FIELDS FEATURE

export const sliceItems = (items, startIndex, endIndex) => {
  const reverse = startIndex > endIndex;
  const startFieldIndex = reverse ? endIndex : startIndex;
  const endFieldIndex = reverse ? startIndex : endIndex;

  return startFieldIndex === endFieldIndex
    ? [items[startFieldIndex]]
    : items.slice(startFieldIndex, endFieldIndex + 1);
};

export const editableFieldsFilter = field => field.visible && !field.inactive && !field.unEditable;

const visibleFieldsFilter = field => field.visible && !field.inactive;

export const parseCopyPaste = ({ collection, fields, copyDiapason, pasteDiaposon, cutMode }) => {
  const copiedFields = sliceItems(fields, copyDiapason[0][1], copyDiapason[1][1]).filter(
    editableFieldsFilter
  );

  let pasteFields = sliceItems(fields, pasteDiaposon[0][1], pasteDiaposon[1][1]).filter(
    editableFieldsFilter
  );

  if (copiedFields.length !== pasteFields.length) {
    pasteFields = copiedFields;
    // throw `INVALID INSERT: You have copied ${copiedFields.length} columns, but updating ${pasteFields.length} fields`;
  }

  const pasteCollections = sliceItems(collection, pasteDiaposon[0][0], pasteDiaposon[1][0]);

  const copiedCollections = sliceItems(collection, copyDiapason[0][0], copyDiapason[1][0]);

  const pasteFieldsKeys = pasteFields.map(fieldAttrName);
  const copiedFieldsKeys = copiedFields.map(fieldAttrName);

  const copiedVariables = pasteCollections.reduce((result, record, index) => {
    const values = copiedFields.map(field => {
      const copiedRecord =
        copiedCollections[index] || copiedCollections[copiedCollections.length - 1];

      return callerField(copiedRecord, field);
    });

    let recordVariables = zipObject(pasteFieldsKeys, values);

    return { ...result, [record.id]: recordVariables };
  }, {});

  let cutVariables = {};

  if (cutMode) {
    cutVariables = copiedCollections.reduce((result, record) => {
      const recordVariables = zipObject(
        copiedFieldsKeys,
        new Array(copiedFieldsKeys.length).fill(null)
      );

      return { ...result, [record.id]: recordVariables };
    }, {});
  }

  const variables = uniq([...Object.keys(copiedVariables), ...Object.keys(cutVariables)]).reduce(
    (result, id) => {
      const cutRecordVariables = cutVariables[id] || {};
      const copiedRecordVariables = copiedVariables[id] || {};

      return {
        ...result,
        [id]: merge(cutRecordVariables, copiedRecordVariables),
      };
    },
    {}
  );

  return variables;
};

export const parseFillDown = ({ fillDownDiapason, collection, fields }) => {
  const rootCoords = fillDownDiapason[0];
  const endCoords = fillDownDiapason[1];
  const copiedRecord = collection[rootCoords[0]];
  const pasteCollections = sliceItems(collection, rootCoords[0], endCoords[0]);
  const pasteFields = sliceItems(fields, rootCoords[1], endCoords[1]).filter(editableFieldsFilter);
  const pasteFieldsKeys = pasteFields.map(fieldAttrName);

  const copiedVariables = pasteCollections.reduce((result, record, index) => {
    const values = pasteFields.map(field => {
      return callerField(copiedRecord, field);
    });

    let recordVariables = zipObject(pasteFieldsKeys, values);

    return { ...result, [record.id]: recordVariables };
  }, {});

  return copiedVariables;
};

export const nearestEditableCoords = fields => {
  const nearestColumnIndex = fields.findIndex(editableFieldsFilter);

  return nearestColumnIndex && nearestColumnIndex >= 0 ? [0, nearestColumnIndex] : [];
};

export const visibleFieldsWidth = (fields, minWidth = 0) =>
  sumBy(fields.filter(visibleFieldsFilter), field => field.width || minWidth);
