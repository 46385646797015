import React from "react";

import SelectField from "./SelectField";

const OPTIONS = [
  ["YES", "YES"],
  ["NO", "NO"],
];

const CheckboxField = props => {
  return <SelectField {...props} options={OPTIONS} />;
};

export default CheckboxField;
