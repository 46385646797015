import * as ScanditSDK from "scandit-sdk";

export const scanningArea = {
  x: 0.3,
  y: 0.4,
  width: 0.4,
  height: 0.2,
};

export const enabledSymbologies = [
  ScanditSDK.Barcode.Symbology.CODABAR,
  ScanditSDK.Barcode.Symbology.CODE11,
  ScanditSDK.Barcode.Symbology.CODE25,
  ScanditSDK.Barcode.Symbology.CODE128,
  ScanditSDK.Barcode.Symbology.CODE39,
];

