import React, {useCallback, useContext, useState} from "react";
import {SnackbarProvider} from "notistack";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";

const BannerContext = React.createContext(null);

const InitOptions = { open: false, message: "", status: "" };

export const useBanner = () => useContext(BannerContext);

export const BannerProvider = ({ children }) => {
  const [options, setOptions] = useState(InitOptions);

  const handleClose = useCallback(() => {
    setOptions(o => ({ ...InitOptions, status: o.status }));
  }, [setOptions]);

  const displayBanner = message => {
    setOptions({ open: true, status: "success", ...message });
  };

  return (
    <>
      <BannerContext.Provider value={{ banner: options, displayBanner, closeBanner: handleClose }}>
        <SnackbarProvider>{children}</SnackbarProvider>
      </BannerContext.Provider>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={options.open}
      >
        <Box minWidth={200}>
          <Alert
            sx={{ background: "#282c33", color: "#fff" }}
            icon={false}
            variant="filled"
            onClose={handleClose}
            severity={options.status}
          >
            {options.message}
          </Alert>
        </Box>
      </Snackbar>
    </>
  );
};

export default BannerContext;
