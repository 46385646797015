import React, {useState} from "react";
import {useHistory} from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import FileCopyOutlined from "@material-ui/icons/FileCopyOutlined";
import {useMutation} from "@apollo/react-hooks";
import EditIcon from "@material-ui/icons/EditOutlined";
import Box from "@material-ui/core/Box";
import Tooltip from "@material-ui/core/Tooltip";

import GenerateReport from "../../views/favoriteReport/GenerateReport";
import useNotification from "../../hooks/notification";
import {DELETE_FAVORITE_REPORT} from "../../constants/graphql/mutations";
import RemoveButton from "./RemoveButton";

export default function FavouriteReportActions({ entity, setEntities }) {
  const [removeLoading, setRemoveLoading] = useState(false);
  const history = useHistory();
  const { notifySuccess, notifyError } = useNotification();
  const [removeAction] = useMutation(DELETE_FAVORITE_REPORT);
  const item = entity;

  const handleRemoveAction = () => {
    setRemoveLoading(true);
    return removeAction({
      variables: { id: entity.id },
    })
      .then(() => {
        setRemoveLoading(false);
        notifySuccess("Template has been successfully deleted");
        if (setEntities) {
          setEntities(prev => prev.filter(entityItem => entityItem.id !== entity.id));
        }
      })
      .catch(errors => {
        setRemoveLoading(false);
        notifyError(errors);
      });
  };

  return (
    <>
      <GenerateReport entity={entity} />
      {(item.payload.receiving_report || item.payload.predefined) ? (
        <Box display="inline-block" width={48 * 3} />
      ) : (
        <>
          <Tooltip arrow title="Duplicate Template">
            <IconButton
              onClick={() => {
                history.push(`/projects/${entity.projectId}/templates/duplicate/${entity.id}`);
              }}
            >
              <FileCopyOutlined />
            </IconButton>
          </Tooltip>
          <Tooltip arrow title="Edit Template">
            <IconButton
              onClick={() => history.push(`/projects/${entity.projectId}/templates/${entity.id}`)}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
          <RemoveButton loading={removeLoading} onClick={handleRemoveAction} />
        </>
      )}
    </>
  );
}
