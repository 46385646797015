import React, {useCallback} from "react";
import {useQuery} from "@apollo/react-hooks";

import {KIT_ASSETS_QUERY} from "../constants/graphql/queries";
import useMutation from "../hooks/useMutation";
import {REMOVE_KIT_ASSET_MUTATION} from "../constants/graphql/mutations";
import useNotification from "../hooks/notification";

const KitAssetsContext = React.createContext(null);

export const KitAssetsProvider = ({ children, id }) => {
  const { notifySuccess } = useNotification();
  const [remove] = useMutation(REMOVE_KIT_ASSET_MUTATION, {
    update(cache, data) {
      const kitAssetId = data.data.deleteKitAsset.kitAsset.id;
      const { asset } = cache.readQuery({ query: KIT_ASSETS_QUERY, variables: { id } });
      cache.writeQuery({
        query: KIT_ASSETS_QUERY,
        data: {
          asset: {
            ...asset,
            kitAssets: asset.kitAssets.filter(v => v.id !== kitAssetId),
          },
        },
      });
    },
  });
  const { refetch, loading, error, data: { asset = {} } = {} } = useQuery(KIT_ASSETS_QUERY, {
    variables: { id },
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-first",
  });

  const removeKitAsset = useCallback(
    id => {
      return remove({
        variables: { id },
        onSuccess() {
          notifySuccess("Asset was removed");
        },
      });
    },
    [notifySuccess, remove]
  );

  return (
    <KitAssetsContext.Provider value={{ kit: asset, loading, refetch, removeKitAsset }}>
      {children}
    </KitAssetsContext.Provider>
  );
};

export const useKitAssets = () => React.useContext(KitAssetsContext);
