import React from 'react';
import { Redirect } from 'react-router-dom';
import { usePermissions } from "src/contexts/permissions";
import useAuth from 'src/hooks/useAuth';

const CanGuard = (canRules) => ({ children }) => {
  const { isAuthenticated } = useAuth();
  const { can } = usePermissions();

  if (!isAuthenticated) {
    return <Redirect to="/login" />;
  }

  const canAccess = canRules.every(rule => can(rule))

  if (!canAccess) {
    return <Redirect to="/403" />;
  }

  return (
    <>
      {children}
    </>
  );
};

export default CanGuard
