import React from "react";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  root: () => ({
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    padding: "16px 12px",
    backgroundColor: theme.palette.background.dark,
  })
}));

const CaptureContainer = props => {
  const classes = useStyles();
  return (
    <Container maxWidth="lg" classes={{ root: classes.root }} {...props} />
  );
};

export default CaptureContainer;
