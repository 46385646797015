import React from "react";
import Box from "@mui/material/Box";

export default function Attachments({ entity }) {
  const assetAttachments = entity.attachments;

  return (
    <Box overflow="hidden" width="100%" height="100%">
      {assetAttachments.map(v => (
        <Box key={v.id}>{v.fileName}</Box>
      ))}
    </Box>
  );
}
