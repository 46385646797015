import React from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";

export default function CartItemsView({ value, onChange }) {
  return (
    <Box display="flex" justifyContent="flex-end" pt={2} pr={2}>
      <ButtonGroup>
        <Tooltip arrow title="Edit the detailed scope of each delivery location">
          <Button onClick={() => onChange("edit")}>
            <Typography color={value === "edit" ? "primary" : "textSecondary"}>Scope</Typography>
          </Button>
        </Tooltip>
        <Tooltip arrow title="Reorder the delivery sequence of assets within a campus">
          <Button onClick={() => onChange("reorder")}>
            <Typography color={value === "reorder" ? "primary" : "textSecondary"}>
              Shuffle
            </Typography>
          </Button>
        </Tooltip>
      </ButtonGroup>
    </Box>
  );
}
