import React, {useState} from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import FilterNav from "src/layouts/ProjectLayout/FilterNav";
import Button from "@mui/material/Button";

export default function SelectProjectModal({
  title,
  buttonLabel,
  description,
  selectedClientId,
  selectedProjectId,
  onSubmit,
  onClose,
}) {
  const [clientId, setClientId] = useState(selectedClientId);
  const [projectId, setProjectId] = useState(selectedProjectId);

  const selectProject = ({ clientId, projectId }) => {
    if (projectId) {
      setClientId(clientId);
      setProjectId(projectId);
    } else {
      setClientId(clientId);
    }
  };

  const selectClient = clientId => {
    setClientId(clientId);
  };

  const handleSubmit = () => {
    onSubmit({
      clientId,
      projectId: projectId === "all" ? null : projectId,
    });
  };

  return (
    <Dialog open fullWidth maxWidth="sm" onClose={onClose}>
      <DialogTitle>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box width={48} />
          <Typography variant="h2" color="textSecondary" component="span">
            {title}
          </Typography>
          <IconButton color="textPrimary" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {description && (
            <Box mb={2}>
              <Typography>{description}</Typography>
            </Box>
          )}
          <Box mr={-2}>
            <FilterNav
              md={12}
              clientId={clientId}
              projectId={projectId}
              onSelectProject={selectProject}
              onSelectClient={selectClient}
              hideActions
            />
          </Box>
          <Box mt={4} display="flex" justifyContent="flex-end">
            <Button disabled={!projectId} variant="contained" onClick={handleSubmit}>
              {buttonLabel}
            </Button>
          </Box>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
}
