import React, {useState} from "react";
import {Field} from "formik";
import isEmpty from "lodash/isEmpty";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";

import ArrayField from "../../components/TablePro/fields/ArrayField";
import labels from "../../constants/displayFields";

export default function FinishField(props) {
  const [open, setOpen] = useState(false);

  const handleToggle = () => {
    setOpen(v => !v);
  };

  return (
    <Field {...props}>
      {({ field, form, meta }) => {
        const value = field.value;
        const errorMessage = meta.error || form.errors[field.name];
        const error = (meta.touched || form.submitCount > 0) && errorMessage;

        return (
          <>
            <FormControl fullWidth>
              <TextField
                size="small"
                autoComplete="off"
                label={labels.finishes}
                value={isEmpty(value) ? "" : value.join(", ")}
                error={!!error}
                helperText={error}
                variant="outlined"
                margin="none"
                inputProps={{ onClick: handleToggle }}
                onKeyDown={e => {
                  if (e.key === "Enter") {
                    handleToggle();
                  }
                }}
              />
            </FormControl>
            {open && (
              <ArrayField
                field={{ label: labels.finishes }}
                onSubmit={v => {
                  form.setFieldValue(field.name, v);
                  handleToggle();
                }}
                value={value}
                nextRow={undefined}
                onClose={handleToggle}
              />
            )}
          </>
        );
      }}
    </Field>
  );
}
