import React, {useState} from "react";
import {Button, ListItemText, makeStyles, Menu, MenuItem} from "@material-ui/core";
import startCase from "lodash/startCase";

const useStyles = makeStyles(theme => ({
  menuButton: {
    textTransform: "none",
    letterSpacing: 0,
  },
}));

const MenuSelect = ({ label: Label, onSelect, value, options, ...props }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = event => setAnchorEl(event.currentTarget);
  const closeMenu = () => setAnchorEl(null);

  const handleSelect = option => {
    onSelect(option);
    closeMenu();
  };

  return (
    <div {...props}>
      <Button
        fullWidth
        className={classes.menuButton}
        aria-haspopup="true"
        onClick={openMenu}
      >
        {Label ? <Label value={value} /> : value}
      </Button>

      <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={closeMenu}>
        {options.map(option => (
          <MenuItem
            data-id="select-item"
            key={option}
            selected={value === option}
            onClick={() => handleSelect(option)}
          >
            <ListItemText primary={startCase(option)} />
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default MenuSelect;
