import React from "react";
import Box from "@material-ui/core/Box";
import Button from "@mui/material/Button";
import PopupState, {bindMenu, bindTrigger} from "material-ui-popup-state";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Menu from "@mui/material/Menu";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import ProjectFilter from "src/views/warehouse/ProjectFilter";
import useAuth from "src/hooks/useAuth";
import LocationStatus from "src/views/warehouse/LocationStatus";

export default function WarehouseFilter({ occupied, selected, setSelected, setOccupied }) {
  const { user } = useAuth();
  const { settings } = user.company;

  const allProjects = () => {
    setSelected([]);
  };

  return (
    <Box display="flex" justifyContent="space-between">
      <PopupState variant="popover" popupId="demo-popup-menu">
        {popupState => (
          <React.Fragment>
            <Button
              variant="outlined"
              color="primary"
              sx={{ textTransform: "none" }}
              {...bindTrigger(popupState)}
            >
              <FilterAltOutlinedIcon sx={{ mr: 1 }} />
              Filters
              <KeyboardArrowDownIcon
                sx={{ ml: 0.5, transform: popupState.open ? undefined : "rotate(180deg)" }}
              />
            </Button>
            <Menu {...bindMenu(popupState)} sx={{ maxHeight: "70vh", ".MuiList-root": { p: 0 } }}>
              <Accordion disableGutters>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>Location Height</AccordionSummary>
                <AccordionDetails>Coming Soon</AccordionDetails>
              </Accordion>
              <Accordion disableGutters>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>Location Status</AccordionSummary>
                <AccordionDetails sx={{ p: 0 }}>
                  <LocationStatus occupied={occupied} setOccupied={setOccupied} />
                </AccordionDetails>
              </Accordion>
              <Accordion disableGutters>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  {settings.filter_field2} Filter
                </AccordionSummary>
                <AccordionDetails sx={{ p: 0 }}>
                  <ProjectFilter selected={selected} setSelected={setSelected} />
                </AccordionDetails>
              </Accordion>
            </Menu>
          </React.Fragment>
        )}
      </PopupState>
      {selected.length > 0 && (
        <Button
          variant="contained"
          color="primary"
          onClick={allProjects}
          sx={{
            ml: 1,
            height: 36,
            textTransform: "none",
          }}
        >
          Clear Filters
        </Button>
      )}
    </Box>
  );
}
