import React, { createContext, useEffect, useState } from 'react';
import SplashScreen from 'src/components/SplashScreen';
import { FETCH_SHORT_USER } from "src/constants/graphql";
import { useQuery } from "@apollo/react-hooks";
import { isEmpty } from "lodash";

const initialState = {
  user: null,
  loading: true,
  accessToken: null,
};

const fetchInvitationToken = () => {
  let url = new URL(window.location.href);
  let params = new URLSearchParams(url.search.slice(1));

  return params.get('invitation-token');
}

const InvitationAuthContext = createContext({
  ...initialState,
});

export const InvitationProvider = ({ children }) => {
  const accessToken = fetchInvitationToken()
  const [state, setState] = useState({ ...initialState, accessToken: accessToken });

  const { loading } = useQuery(
    FETCH_SHORT_USER,
    {
      variables: { accessToken },
      onCompleted: ({ invitationUser }) => {
        if (invitationUser && invitationUser.email) {
          setState({ user: invitationUser, accessToken })
        } else {
          setState({ user: null, accessToken })
        }
      }
    }
  );

  if (loading) {
    return <SplashScreen />
  }

  return (
    <InvitationAuthContext.Provider value={{ ...state, }}>
      {children}
    </InvitationAuthContext.Provider>
  );
};

export default InvitationAuthContext;
