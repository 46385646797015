import React from "react";

export default function useGrab(editor, grabMode) {
  React.useEffect(() => {
    let isDragging = false;
    let lastPosX = 0;
    let lastPosY = 0;

    if (grabMode) {
      editor.discardActiveObject();
      editor.renderAll();
      editor.forEachObject(object => {
        object.selectable = false;
        object.hoverCursor = "default";
      });
    } else {
      editor.forEachObject(object => (object.selectable = true));
    }

    const mouseDown = opt => {
      const evt = opt.e;
      if (grabMode) {
        isDragging = true;
        lastPosX = evt.clientX;
        lastPosY = evt.clientY;
      }
    };

    const mouseMove = opt => {
      if (isDragging) {
        const e = opt.e;
        let vpt = editor.viewportTransform;

        if (vpt) {
          vpt[4] += e.clientX - lastPosX;
          vpt[5] += e.clientY - lastPosY;
        }

        editor.requestRenderAll();
        lastPosX = e.clientX;
        lastPosY = e.clientY;
      }
    };

    const mouseUp = () => {
      if (editor.viewportTransform) {
        editor.setViewportTransform(editor.viewportTransform);
        isDragging = false;
      }
    };

    if (editor) {
      editor.on("mouse:down", mouseDown);
      editor.on("mouse:move", mouseMove);
      editor.on("mouse:up", mouseUp);

      return () => {
        editor.off("mouse:down", mouseDown);
        editor.off("mouse:move", mouseMove);
        editor.off("mouse:up", mouseUp);
      };
    }
  }, [editor, grabMode]);
}
