import React from "react";
import PopupState, {bindMenu, bindTrigger} from "material-ui-popup-state";
import MenuItem from "@material-ui/core/MenuItem";
import ViewSidebarOutlinedIcon from "@mui/icons-material/ViewSidebarOutlined";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import MuiMenu from "@material-ui/core/Menu";
import ListItemText from "@material-ui/core/ListItemText";

const TABS = [
  {
    value: "floor",
    label: "Floor Plan View",
  },
  {
    value: "table",
    label: "Table View",
  },
];

export default function Views({ view, changeView }) {
  return (
    <PopupState variant="popover" popupId="demo-popup-menu">
      {popupState => (
        <React.Fragment>
          {/*}
          <MenuItem {...bindTrigger(popupState)}>
            <ViewSidebarOutlinedIcon
              sx={{ transform: "rotate(180deg)" }}
              fontSize="small"
              htmlColor="#546e7a"
            />
            <Box mr={1} />
            <Typography fontWeight={600} color="textSecondary" variant="body2">
              Views
            </Typography>
          </MenuItem>
          */}
          <MuiMenu {...bindMenu(popupState)}>
            {TABS.map(option => (
              <MenuItem
                key={option.value}
                data-id="select-item"
                selected={view === option.value}
                onClick={() => {
                  popupState.close();
                  changeView(option.value);
                }}
              >
                <ListItemText primary={option.label} />
              </MenuItem>
            ))}
          </MuiMenu>
        </React.Fragment>
      )}
    </PopupState>
  );
}
