import React from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from '../hooks/useAuth';
import { usePermissions } from "src/contexts/permissions";

const ShoppingGuard = ({ children }) => {
  const { isAuthenticated } = useAuth();
  const { can } = usePermissions();

  if (!isAuthenticated) {
    return <Redirect to="/login" />;
  }

  if (!can("pages.shopping")) {
    return <Redirect to="/login" />;
  }

  return (
    <>
      {children}
    </>
  );
};

ShoppingGuard.propTypes = {
  children: PropTypes.node
};

export default ShoppingGuard;
