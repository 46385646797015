import React, { isValidElement } from "react";

const isClassComponent = (component) =>
  typeof component === "function" && !!component.prototype.isReactComponent;

const isFunctionComponent = (Component) =>
  typeof Component === "function" && isValidElement(Component());

export const isReactComponent = (component) =>
  isClassComponent(component) || isFunctionComponent(component);
