import React, { useEffect } from "react";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  root: {
    position: "relative",
    width: "100%"
  },
  display: {
    position: "absolute",
    paddingLeft: 14,
    top: 2,
    left: 0,
    bottom: 2,
    background: "white",
    pointerEvents: "none",
    right: 22,
    display: "flex",
    alignItems: "center"
  },
  input: {}
}));

const formatDate = date =>
  moment(date, "yyyy-MM-DDThh:mm").format("MM/DD hh:mm");

function CustomDateTime({ defaultValue, inputRef, ...props }) {
  const classes = useStyles();
  const [value, setValue] = React.useState("");

  useEffect(() => {
    if (props.value && value !== formatDate(props.value)) {
      setValue(formatDate(props.value));
    }
  }, [props.value]);

  const handleChange = event => {
    setValue(formatDate(event.target.value));
    if (props.onChange) {
      props.onChange(event);
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.display}>{value}</div>
      <input
        style={{ padding: "10px 14px 10px 0px" }}
        className={classes.input}
        ref={inputRef}
        {...props}
        onChange={handleChange}
        value={value}
      />
    </div>
  );
}

export default CustomDateTime;
