import React, { useRef } from "react";
import Box from "@mui/material/Box";
import * as ScanditSDK from "scandit-sdk";
import { enabledSymbologies, scanningArea } from "../../../constants/scandit";
import CloseCameraButton from "../../CloseCameraButton";
import ScanditContainer from "./ScanditContainer";
import ScanditScanButton from "./ScanditScanButton";

const styles = {
  scandit: {
    position: "absolute",
    width: "100%",
    height: "100%",
  },
};

ScanditSDK.SymbologySettings.defaultActiveSymbolCounts = {
  ...ScanditSDK.SymbologySettings.defaultActiveSymbolCounts,
  code128: [4, 5, ...ScanditSDK.SymbologySettings.defaultActiveSymbolCounts.code128],
};

export function configureScandit(license) {
  if (!ScanditSDK.userLicenseKey) {
    ScanditSDK.configure(license, {
      engineLocation: "https://cdn.jsdelivr.net/npm/scandit-sdk@5.x/build/",
    });
  }
}

export default function ScanditScanner({ barcodeTrigger, onDetected, onClose }) {
  const pickerRef = useRef();

  const handleScan = () => {
    if (pickerRef.current) {
      pickerRef.current.resumeScanning();
    }
  };

  const handleClose = () => {
    pickerRef.current?.destroy();
    onClose();
  };

  React.useEffect(() => {
    ScanditSDK.BarcodePicker.create(document.getElementById("scandit-barcode-picker"), {
      playSoundOnScan: true,
      vibrateOnScan: true,
      scanningPaused: true,
      guiStyle: ScanditSDK.BarcodePicker.GuiStyle.VIEWFINDER,
      viewfinderArea: scanningArea,
      videoFit: "cover",
    }).then(function(barcodePicker) {
      const scanSettings = new ScanditSDK.ScanSettings({
        enabledSymbologies,
        codeDuplicateFilter: 1000,
        searchArea: scanningArea,
      });
      if (!barcodeTrigger) {
        barcodePicker.resumeScanning();
      }
      barcodePicker.applyScanSettings(scanSettings);
      barcodePicker.on("scan", scanResult => {
        onDetected(scanResult.barcodes[0].data);
        barcodePicker.destroy();
      });
      pickerRef.current = barcodePicker;
    });

    return () => {
      pickerRef.current?.destroy();
    };
  }, []);

  return (
    <ScanditContainer>
      <Box id="scandit-barcode-picker" sx={styles.scandit} />
      <Box>
        <CloseCameraButton onClick={handleClose} />
      </Box>
      <ScanditScanButton onClick={handleScan} />
    </ScanditContainer>
  );
}
