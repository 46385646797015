const timeOptions = [
  ...new Array(6)
    .fill()
    .map((v, i) => i + 6)
    .flatMap(i => [
      `${i < 10 ? `0${i}` : i}:00 am`,
      `${i < 10 ? `0${i}` : i}:15 am`,
      `${i < 10 ? `0${i}` : i}:30 am`,
      `${i < 10 ? `0${i}` : i}:45 am`,
    ]),
  "12:00 pm",
  "12:15 pm",
  "12:30 pm",
  "12:45 pm",
  ...new Array(10)
    .fill()
    .map((v, i) => i + 1)
    .flatMap(i => [
      `${i < 10 ? `0${i}` : i}:00 pm`,
      `${i < 10 ? `0${i}` : i}:15 pm`,
      `${i < 10 ? `0${i}` : i}:30 pm`,
      `${i < 10 ? `0${i}` : i}:45 pm`,
    ]),
  "11:00 pm",
];

export default timeOptions;
