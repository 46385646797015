import React from "react";
import Box from "@mui/material/Box";
import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";
import IconButton from "@mui/material/IconButton";

import {getBackgroundImage} from "../../../utils/images";

export default function ImagesSlider({ list, index, onChangeIndex }) {
  const handlePrev = e => {
    e.stopPropagation();
    onChangeIndex(prevState => (prevState === 0 ? list.length - 1 : prevState - 1));
  };

  const handleNext = e => {
    e.stopPropagation();
    onChangeIndex(prevState => (prevState + 1 >= list.length ? 0 : prevState + 1));
  };

  return (
    <>
      {list.length > 0 && (
        <>
          <Box
            position="absolute"
            borderRadius="40%"
            fontSize="16px"
            right={20}
            top={20}
            px="6px"
            py="4px"
            p2={0.25}
            color="white"
            bgcolor="rgb(0, 0, 0, 0.5)"
          >
            {index + 1}/{list.length}
          </Box>
          <Box
            top="calc(50% - 25px)"
            width="100%"
            position="absolute"
            display="flex"
            justifyContent="space-between"
          >
            {list.length > 1 ? (
              <IconButton onClick={handlePrev}>
                <ExpandCircleDownIcon
                  fontSize="large"
                  sx={{ color: "white", transform: "rotate(90deg)" }}
                />
              </IconButton>
            ) : (
              <div />
            )}
            {list.length > 1 && (
              <IconButton onClick={handleNext}>
                <ExpandCircleDownIcon
                  fontSize="large"
                  sx={{ color: "white", transform: "rotate(-90deg)" }}
                />
              </IconButton>
            )}
          </Box>
        </>
      )}
      <Box
        width="100%"
        height="0"
        pb="80%"
        sx={{
          backgroundImage: list.length
            ? getBackgroundImage(list[index]?.url, "width", 600)
            : undefined,
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
          backgroundPosition: "center",
          backgroundColor: "#f4f6f8",
        }}
      />
    </>
  );
}
