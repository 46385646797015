import React, {useEffect} from "react";
import {useQuery, useSubscription} from "@apollo/react-hooks";
import {orderBy} from "lodash";

import DateField from "../../components/TableEntities/Fields/DateField";
import {EntityTable} from "../../components/TableEntities";
import useAuth from "../../hooks/useAuth";
import {NEW_MESSAGE_SUBSCRIPTION, NOTIFICATIONS_QUERY} from "../../constants/graphql";
import NotificationLink from "./NotificationLink";

export default function ReportsTable({ entity, className }) {
  const { user } = useAuth();
  const { settings } = user.company;
  const { data: { notifications = [] } = {}, loading, refetch } = useQuery(NOTIFICATIONS_QUERY, {
    variables: { type: "report", ownerId: entity.id },
  });

  const notificationsList = orderBy(notifications, "createdAt", "desc");

  const configuration = {
    title: "Reports",
    key: "reports",
    fields: [
      {
        label: "Report Link",
        path: ".",
        component: NotificationLink,
      },
      /*
      {
        label: settings.filter_field1,
        path: "client.name",
      },
      {
        label: settings.filter_field2,
        path: "project.name",
        component: ({ value }) => value || "All Storage Locations",
      },
      {
        label: "User",
        path: "userEmail",
      },
      */
      {
        label: "Date Generated",
        path: "createdAt",
        component: DateField,
      },
    ],
  };

  useEffect(() => {
    refetch();
  }, []);

  useSubscription(NEW_MESSAGE_SUBSCRIPTION, {
    variables: { userId: user?.id },
    onSubscriptionData: () => {
      refetch();
    },
  });

  return (
    <EntityTable
      className={className}
      configuration={configuration}
      entities={notificationsList}
      loading={loading}
      page={0}
      limit={1000}
    />
  );
}
