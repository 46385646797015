import React from "react";
import moment from "moment/moment";
import {useHistory} from "react-router-dom";
import {Plus as PlusIcon} from "react-feather";
import Bpx from "@mui/material/Box";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import SvgIcon from "@material-ui/core/SvgIcon";

import Footer from "../Footer/Footer";
import Header from "../Header";
import Empty from "./Empty";
import getTotalReceivingUnitsLabel from "../Shipment/TotalReceivingUnits/getTotalReceivingUnitsLabel";
import useMutation from "../../../../hooks/useMutation";
import {UPDATE_SHIPMENT_MUTATION} from "../../../../constants/graphql/mutations";
import {serverFormatSeconds} from "../../../../utils/dates";
import {getShipmentValues} from "../../../../utils/shipment";
import OSAndDType from "../../../../components/capture/ReceivingWizard/OSAndD/OSAndDType";
import Item from "../../../../components/capture/ReceivingWizard/Items/Item";
import useQueryParams from "../../../../hooks/useQueryParams";
import OSAndDDetails from "./OSAndDDetails";

export default function OSAndD({ shipment }) {
  const history = useHistory();
  const params = useQueryParams();
  const openOSDType = params.get("openOSDType") === "true";
  const osdId = params.get("osdId");
  const [updateShipment] = useMutation(UPDATE_SHIPMENT_MUTATION);
  const totalPieces = shipment?.totalPieces || [];
  const input = getShipmentValues(shipment);
  const overagesShortagesDamages = shipment?.overagesShortagesDamages || [];

  const handleOpenOSDType = () => {
    const pathname = window.location.pathname;
    const params = new URLSearchParams(location.search);
    params.set("openOSDType", "true");
    history.push({ pathname, search: params.toString() });
  };

  const handleCloseOSDType = () => {
    const pathname = window.location.pathname;
    const params = new URLSearchParams(location.search);
    params.delete("openOSDType");
    history.push({ pathname, search: params.toString() });
  };

  const handleBack = () => {
    const pathname = window.location.pathname;
    const params = new URLSearchParams(window.location.search);
    params.set("step", "items");
    history.push({ pathname, search: params.toString() });
  };

  const handleSubmit = () => {
    return updateShipment({
      variables: {
        id: shipment.id,
        input: {
          ...input,
          completeTime: shipment.completeTime
            ? shipment.completeTime
            : moment(new Date()).format(serverFormatSeconds),
          finalStep: true,
        },
      },
    }).then(() => {
      const pathname = window.location.pathname;
      const params = new URLSearchParams(window.location.search);
      params.set("step", "confirmation");
      history.push({ pathname, search: params.toString() });
    });
  };  

  const handleEdit = id => {
    const pathname = window.location.pathname;
    const params = new URLSearchParams(window.location.search);
    params.set("osdId", id);
    history.push({ pathname, search: params.toString() });
  };

  return (
    <Bpx display="flex" flexDirection="column" justifyContent="space-between" minHeight="100%">
      <Header title="OS&D" info={getTotalReceivingUnitsLabel(totalPieces)} />
      <Bpx p={2}>
        <Box display="flex" flexDirection="column" alignItems="center">
          {overagesShortagesDamages.length === 0 ? (
            <Box mt={3} display="flex" flexDirection="column" alignItems="center">
              <Empty />
            </Box>
          ) : (
            <Box mb={5} width="100%">
              {overagesShortagesDamages.map(v => (
                <Item
                  value={v.shipmentItem}
                  images={v.attachments}
                  info={
                    <Box color="red" fontWeight={500}>
                      {v.osdType}, QTY: {v.osdQuantity}
                    </Box>
                  }
                  onClick={() => handleEdit(v.id)}
                />
              ))}
            </Box>
          )}
          <Box mt={1}>
            <Button
              variant="outlined"
              sx={{ textTransform: "none" }}
              onClick={handleOpenOSDType}
              startIcon={
                <SvgIcon fontSize="small">
                  <PlusIcon />
                </SvgIcon>
              }
            >
              Add OS&D
            </Button>
            {openOSDType && <OSAndDType onClose={handleCloseOSDType} shipment={shipment} />}
            {osdId && (
              <OSAndDDetails
                osdId={osdId}
                osd={overagesShortagesDamages.find(v => v.id === osdId)}
                shipment={shipment}
              />
            )}
          </Box>
        </Box>
      </Bpx>
      <Box mt={2}>
        <Footer step={2} shipment={shipment}>
          <div>
            <Button onClick={handleBack} variant="outlined" sx={{ mr: 1, textTransform: "none" }}>
              Back
            </Button>
            <Button onClick={handleSubmit} variant="contained" sx={{ textTransform: "none" }}>
              Submit
            </Button>
          </div>
        </Footer>
      </Box>
    </Bpx>
  );
}
