import React from "react";
import ButtonBase from "@material-ui/core/ButtonBase";

import LogoImg from "../images/logo.svg";
import getDashboardLink from "../utils/getDashboardLink";
import {useHistory} from "react-router-dom";

export default function Logo(props) {
  const history = useHistory();

  const handleClick = () => {
    const link = getDashboardLink();
    history.push(link);
  };

  return (
    <ButtonBase onClick={handleClick}>
      <img className={"logo"} alt="Logo" src={LogoImg} {...props} />
    </ButtonBase>
  );
}
