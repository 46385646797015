import React from "react";
import Label from "src/components/Label";

const BooleanField = ({ value, label = null }) => {
  const labelTag = label ? label : value ? "Yes" : "No";
  const color = value ? "success" : "error";

  return <Label color={color}>{labelTag}</Label>;
};

export default BooleanField;
