import React, {useEffect} from "react";
import orderBy from "lodash/orderBy";
import {useFormikContext} from "formik";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import ImageInput from "../../components/form/ImageInput";
import Input from "../../components/form/Input";
import BarCodeInput from "../../components/barcode/BarCodeInput";
import labels from "../../constants/displayFields";
import Autocomplete from "../../components/form/Autocomplete";
import {getOptions} from "../../utils/form";
import manufacturers from "../../constants/manufacturers";
import DimensionsField from "../../views/capture/DimensionsField";
import colors from "../../constants/colors";
import ImagesKit from "./Images/ImagesKit";
import useAuth from "../../hooks/useAuth";

export default function FormFieldsKitting({
  disabledForm,
  asset,
  updateAsset,
  createImage,
  setErrors,
  manufacturersOptions = [],
  colorsOptions = [],
}) {
  const { user } = useAuth();
  const { settings } = user.company;
  const { setFieldValue, values } = useFormikContext();

  useEffect(() => {
    if (!values.itemCode && asset.itemCode) {
      setFieldValue("itemCode", asset.itemCode);
    }
  }, [asset.itemCode, setFieldValue, values.itemCode]);

  const handleUpdateAssetField = field => (value, { onSuccess, onFailure } = {}) => {
    if (field === "barcode") {
      setFieldValue(field, value);
    }
    updateAsset({
      variables: { id: asset.id, input: { [field]: value } },
      onSuccess,
      onFailure: errors => {
        setErrors(errors);
        onFailure && onFailure(errors);
      },
    });
  };

  const handleCreateImage = (file, { onSuccess, onFailure } = {}) => {
    createImage({
      variables: { assetId: asset.id, image: file },
      onSuccess,
      onFailure,
    });
  };

  return (
    <Card>
      <CardContent>
        <Box mt={-1} mb={1}>
          <Typography fontWeight={500} fontSize={18}>
            Kit Info
          </Typography>
        </Box>
        <Grid container alignItems="flex-start" spacing={1}>
          <Grid container spacing={1} item md={6} xs={12}>
            <Grid item xs={12}>
              <ImagesKit disabled={disabledForm} asset={asset} />
            </Grid>
            {!disabledForm && (
              <>
                <Grid item xs={6}>
                  <ImageInput imageUrl={asset.photo} onUpload={handleCreateImage} />
                </Grid>
                <Grid item xs={6}>
                  <BarCodeInput
                    id={`barcodeInput-${asset.id}`}
                    barcode={asset.barcode}
                    onChange={handleUpdateAssetField("barcode")}
                  />
                </Grid>
              </>
            )}
          </Grid>
          <Grid container spacing={1} item md={6} xs={12}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                size="small"
                label={settings.filter_field1}
                value={asset.client.name}
                sx={{
                  fieldset: { borderColor: "#3C48A5" },
                  label: { color: "#3C48A5" },
                  input: { color: "#3C48A5" },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                size="small"
                label={settings.filter_field2}
                value={asset.project.name}
                sx={{
                  fieldset: { borderColor: "#3C48A5" },
                  label: { color: "#3C48A5" },
                  input: { color: "#3C48A5" },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField size="small" label="Kit ID" disabled value={asset.itemId} fullWidth />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Input
                fullWidth
                name="itemCode"
                label="Kit Code"
                disabled={disabledForm}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                fullWidth
                disabled={disabledForm}
                saveCustomOption
                name="manufacturer"
                label={labels.manufacturer}
                options={orderBy(getOptions(manufacturers, manufacturersOptions), v =>
                  v.value?.toLowerCase()
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Input
                fullWidth
                name="description"
                label={labels.description}
                disabled={disabledForm}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Input disabled={disabledForm} name="model" label={labels.model} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <DimensionsField name="dimensions" disabled={disabledForm} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                saveCustomOption
                name="color"
                label={labels.color}
                options={orderBy(getOptions(colors, colorsOptions), v => v.value)}
                disabled={disabledForm}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                size="small"
                label="Kit Quantity"
                disabled
                value={asset.availableQuantity}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                size="small"
                label="Reserved Quantity"
                disabled
                value={asset.reservedQuantity}
                fullWidth
              />
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
