import React from "react";
import {useFormikContext} from "formik";
import LinearProgress from "@material-ui/core/LinearProgress";
import Box from "@material-ui/core/Box";
import Button from "@mui/material/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";

import ActivityLog from "../../../../views/asset/ActivityLog";
import useNotification from "../../../../hooks/notification";
import CaptureSkeleton from "../../../../components/CaptureSkeleton";
import {useKitsCollection} from "../../../../contexts/kitsCollection";
import FormFieldsKitting from "../../../../views/capture/FormFieldsKitting";
import KitBuilder from "../../../../components/TablePro/components/assets/KitBuilder";

export default function FormPageKitting({ disabled, type, currentAsset, onCloseModal }) {
  const formProps = useFormikContext();
  const { notifyError } = useNotification();

  const { loading, loadingMutation, updateAsset, createImage } = useKitsCollection();

  const handleSubmitAction = ({
    variables,
    setSubmitting,
    setStatus,
    setErrors,
    submitAction,
    handleSubmit,
  }) => {
    handleSubmit();
    setSubmitting(true);

    return submitAction({
      variables: { ...variables, input: variables.input },
      onSuccess: () => {
        setSubmitting(false);
        setStatus({ success: true });
      },
      onFailure: errors => {
        setStatus({ success: false });
        setErrors(errors);
        notifyError(errors);
        setSubmitting(false);
      },
    });
  };

  const submitUpdateAsset = ({ values, ...props }) => () => {
    const variables = { id: currentAsset?.id, input: values };

    return handleSubmitAction({ ...props, variables, submitAction: updateAsset });
  };

  return (
    <form
      id="assetForm"
      className="d-flex flex-column flex-grow-1 justify-content-between justify-content-md-start"
      style={{ position: "relative" }}
    >
      {loading && (
        <Box py={1}>
          <LinearProgress className="mt-2" />
        </Box>
      )}
      {currentAsset?.id ? (
        <FormFieldsKitting
          disabledForm={disabled}
          type={type}
          asset={currentAsset}
          loading={loadingMutation}
          updateAsset={updateAsset}
          createImage={createImage}
          setErrors={formProps.setErrors}
          errors={formProps.errors}
        />
      ) : (
        loading && <CaptureSkeleton />
      )}
      <Box mb={2} />
      <KitBuilder disabled={disabled} entity={currentAsset} />
      <Box mb={2} />
      <Card>
        <CardContent>
          <ActivityLog type="kit" assetId={currentAsset.id} defaultTab="reserved_inventory" />
        </CardContent>
      </Card>
      <Box mb={2} />
      {!disabled && (
        <Box display="flex" justifyContent="flex-end">
          <Button sx={{ mr: disabled ? 0 : 1.5 }} variant="outlined" onClick={onCloseModal}>
            Close
          </Button>
          <Button variant="contained" color="primary" onClick={submitUpdateAsset(formProps)}>
            Save
          </Button>
        </Box>
      )}
    </form>
  );
}
