import React, {useState} from "react";
import orderBy from "lodash/orderBy";
import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import Button from "@mui/material/Button";

import ImageModal from "../../../components/images/ImageModal";
import useNotification from "../../../hooks/notification";
import {useAssets} from "../../../contexts/assets";
import ImageDND from "./ImageDND";
import ImagesSlider from "./ImagesSlider";

export default function ImagesItem({ asset }) {
  const { updateImage, bulkCreateImages, removeImage } = useAssets();
  const { notifyError } = useNotification();
  const [loading, setLoading] = useState(false);
  const list = orderBy(asset?.images || [], v => v.default, "desc");
  const [open, setOpen] = useState(null);
  const defaultIndex = list.findIndex(v => v.default);
  const [index, setIndex] = useState(defaultIndex === -1 ? 0 : defaultIndex);

  const handleOpen = () => {
    if (list.length > 0) {
      setOpen(true);
    }
  };

  const handleBulkCreateImages = ({ variables, onSuccess, onFailure }) => {
    bulkCreateImages({
      variables: { ...variables, assetId: asset?.id },
      onSuccess,
      onFailure,
    });
  };

  const handleUpload = images => {
    setLoading(true);
    handleBulkCreateImages({
      variables: { assetId: asset.id, images },
      onSuccess: () => {
        setLoading(false);
      },
      onFailure: errors => {
        notifyError(errors);
        setLoading(false);
      },
    });
  };

  const handleRemove = data => {
    setIndex(0);
    removeImage(data);
  };

  const handleDefaultUpload = e => {
    const files = e.target.files;
    handleUpload(files);
  };

  return (
    <Box px={{ md: 5, xs: 2 }}>
      <Box position="relative" onClick={handleOpen}>
        {list.length === 0 && (
          <>
            {loading && (
              <Box width="100%">
                <LinearProgress />
              </Box>
            )}
            <ImageDND onDrop={handleUpload} />
          </>
        )}
        <ImagesSlider list={list} index={index} onChangeIndex={setIndex} />
      </Box>
      {open && list[index] && (
        <ImageModal
          open
          closeOnSave={true}
          selectedId={list[index].id}
          asset={asset}
          images={list}
          onClose={() => setOpen(false)}
          onRemove={handleRemove}
          onUpdate={updateImage}
          onCreate={handleBulkCreateImages}
        />
      )}
      <Box mt={1.5}>
        <input
          type="file"
          accept="image/*"
          id="item-button"
          capture="environment"
          style={{ display: "none" }}
          onChange={handleDefaultUpload}
        />
        <label htmlFor="item-button" style={{ width: "100%", margin: 0 }}>
          <Button fullwidth variant="outlined" color="primary" fullWidth component="span">
            <AddAPhotoIcon />
          </Button>
        </label>
      </Box>
    </Box>
  );
}
