import React, {useCallback, useState} from "react";
import sortBy from "lodash/sortBy";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import {useQuery} from "@apollo/react-hooks";

import useMutation from "../../hooks/useMutation";
import {TRANSFER_ASSET_MUTATION} from "../../constants/graphql/mutations";
import ActionModal from "../../components/modals/action-modal";
import {CLIENTS_QUERY} from "../../constants/graphql";
import EntitySelect from "../../components/EntitySelect";
import useNotification from "../../hooks/notification";

const getProjects = (clients, selectedClient) => {
  const projectList = clients.reduce(
    (prev, v) => (v.id === selectedClient ? v.projects.filter(project => project.active) : prev),
    []
  );
  return sortBy(projectList, "name");
};

export default function TransferModal({ asset, handeRefetch, onTransfer, onClose }) {
  const { notifySuccess, notifyError } = useNotification();
  const [projectId, setProjectId] = useState(null);
  const [transferAsset] = useMutation(TRANSFER_ASSET_MUTATION);
  const { data: { clients = [] } = {}, refetch } = useQuery(CLIENTS_QUERY);
  let projectList = getProjects(clients, asset?.client?.id);

  const handleTransferAsset = useCallback(() => {
    transferAsset({
      variables: {
        assetId: asset?.id,
        projectId,
      },
      onSuccess: () => {
        notifySuccess("Asset successfully transferred");
        if (handeRefetch) {
          handeRefetch();
        }
        if (onTransfer) {
          onTransfer({ asset: asset, projectId });
        }
        onClose();
      },
      onFailure: () => {
        notifyError("Something went wrong");
      },
    });
  }, [asset, transferAsset, onClose, notifyError, notifySuccess]);

  return (
    <ActionModal open title="Transfer Asset" maxWidth={440} onClose={onClose} disableTransition>
      <EntitySelect
        fullWidth
        label="Project"
        value={projectId}
        suggestions={projectList}
        onChange={projectItem => {
          setProjectId(projectItem.id);
        }}
      />
      <Box mt={4} />
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <Button fullWidth variant="outlined" onClick={onClose}>
            Close
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            disabled={!projectId}
            fullWidth
            variant="contained"
            color="primary"
            onClick={handleTransferAsset}
          >
            Transfer
          </Button>
        </Grid>
      </Grid>
    </ActionModal>
  );
}
