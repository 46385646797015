import React, {useEffect, useState} from "react";
import clsx from "clsx";
import snakeCase from "lodash/snakeCase";
import {makeStyles} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import OrderTopIcon from "@material-ui/icons/ArrowUpward";
import OrderDownIcon from "@material-ui/icons/ArrowDownward";

import {isReactComponent} from "../../../utils/detect";
import ResizeBorder from "./ResizeBorder";
import TableCell from "../TableCell";

const useStyles = makeStyles(theme => ({
  sortContainer: {
    "&:hover $sortButton": {
      opacity: 1,
      visibility: "visible",
    },
  },
  sortButton: {
    padding: "0",
    opacity: 0,
    visibility: "hidden",

    "&.active": {
      opacity: 1,
      visibility: "visible",
    },
  },
  spacingColumn: {
    // minWidth: 20,
    flexGrow: 1,
    borderBottom: "unset",
  },
  tableHead: {
    position: "sticky",
    top: 0,
    display: "block",
    zIndex: 4,
    backgroundColor: theme.palette.common.white,
  },
  tableRow: {
    display: "flex",
  },
  fieldCell: {
    justifyContent: "space-between",
    backgroundColor: `${theme.palette.common.white} !important`,
    overflow: "hidden",
  },
  resizableContent: {
    width: "100%",
    position: "relative",
    // cursor: "move",
  },
  lockContainer: {
    "&:hover $lockButton": {
      opacity: 1,
      visibility: "visible",
    },
  },
  lockButton: {
    padding: 2,
    opacity: 0,
    visibility: "hidden",

    "& svg": {
      fontSize: 15,
    },
  },
  label: {
    marginRight: 6,
  },
}));

export const MIN_WIDTH = 200;
export const MIN_HEIGHT = 60;

export default function TableHeader({
  fields: initHeaders,
  order: initOrder,
  loading,
  onChange,
  fieldsProps = {},
  className,
  ...props
}) {
  const classes = useStyles();
  const [fields, setFields] = useState(initHeaders);
  const [order, setOrder] = useState(initOrder || {});
  const [disabledDragger, setDisabledDragger] = useState(false);

  const handleUpdate = newFields => onChange({ fields: newFields });

  useEffect(() => {
    setFields(initHeaders);
  }, [initHeaders]);

  useEffect(() => {
    setOrder(initOrder || {});
  }, [initOrder]);

  const onBorderResizing = (index, resizeWidth) => {
    setFields(fields =>
      Object.assign([], fields, {
        [index]: {
          ...fields[index],
          width: (fields[index].width || MIN_WIDTH) + resizeWidth,
        },
      })
    );
  };

  const onBorderStopReisizing = () => {
    handleUpdate(fields);
  };

  const toggleSort = fieldId => () => {
    const id = snakeCase(fieldId);

    if (loading) return;
    if (!order[id]) handleSortDesc(id);
    if (order[id] === "desc") handleSortAsc(id);
    if (order[id] === "asc") handleSortDesc(id);
  };

  const handleSortAsc = fieldId => {
    const newOrder = { [fieldId]: "asc" };
    setOrder(newOrder);
    onChange({ order: newOrder });
  };

  const handleSortDesc = fieldId => {
    const newOrder = { [fieldId]: "desc" };
    setOrder(newOrder);
    onChange({ order: newOrder });
  };

  return (
    <div className={clsx(classes.tableHead, className, "resizableWrap")} {...props}>
      <div className={classes.tableRow} data-test-id={"field-row"}>
        {fields.map((currentField, index) => {
          const field = {
            ...currentField,
            ...(fieldsProps[currentField.id] || {}),
          };

          return (
            <TableCell
              id={field.id}
              key={field.id}
              index={index}
              fields={fields}
              field={field}
              className={clsx(
                classes.fieldCell,
                classes.resizableContent,
                classes.lockContainer,
                classes.sortContainer
              )}
              style={{
                height: MIN_HEIGHT,
                width: field.width || MIN_WIDTH,
                minWidth: field.width || MIN_WIDTH,
              }}
            >
              <Box display="flex">
                <div className={classes.label}>
                  {field.label && isReactComponent(field.label) ? <field.label /> : field.label}
                </div>
              </Box>

              {!field.disableOrder && (
                <Box>
                  {!order[snakeCase(field.id)] && (
                    <IconButton onClick={toggleSort(field.id)} className={classes.sortButton}>
                      <OrderDownIcon color="disabled" fontSize="small" />
                    </IconButton>
                  )}

                  {order[snakeCase(field.id)] === "desc" && (
                    <IconButton
                      onClick={toggleSort(field.id)}
                      className={clsx(classes.sortButton, "active")}
                    >
                      <OrderDownIcon fontSize="small" />
                    </IconButton>
                  )}

                  {order[snakeCase(field.id)] === "asc" && (
                    <IconButton
                      onClick={toggleSort(field.id)}
                      className={clsx(classes.sortButton, "active")}
                    >
                      <OrderTopIcon fontSize="small" />
                    </IconButton>
                  )}
                </Box>
              )}

              <ResizeBorder
                onMouseMove={() => setDisabledDragger(true)}
                onMouseOut={() => setDisabledDragger(false)}
                onStopReisizing={onBorderStopReisizing}
                onResizing={onBorderResizing}
                index={index}
              />
            </TableCell>
          );
        })}
      </div>
    </div>
  );
}
