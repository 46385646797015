import React, {useCallback, useState} from "react";
import {Link as RouterLink} from "react-router-dom";
import moment from "moment";
import {makeStyles} from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Link from "@material-ui/core/Link";

import {useProjectInventory} from "../../../../contexts/projectInventory";
import StatusLabel from "../../../../components/shopping/RequestStatusLabel";
import useAuth from "../../../../hooks/useAuth";
import useMutation from "../../../../hooks/useMutation";
import {UPDATE_INVENTORY_REQUEST_MUTATION} from "../../../../constants/graphql/mutations";
import VaStatus from "../VaStatus";
import RequestRowAssets from "./RequestRowAssets";
import OrderActions from "./OrderActions";
import {isVaSubdomain} from "../../../../utils/inventoryRequests"; // Import isVaSubdomain

const useRowStyles = makeStyles(theme => ({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
}));

const serverFormat = "YYYY-MM-DD hh:mm A";

export function getWO({ workOrderNumber, revisionNumber }) {
  return revisionNumber ? `${workOrderNumber}.${revisionNumber}` : workOrderNumber;
}

export default function InventoryRequestRow({ inventoryRequestId, reFetch, display }) {
  const { user } = useAuth();
  const { inventoryRequests, updateInventoryRequest, removeInventoryItem } = useProjectInventory();
  const [saveInventoryRequest] = useMutation(UPDATE_INVENTORY_REQUEST_MUTATION);
  const inventoryRequest = inventoryRequests[inventoryRequestId];
  const inventoryRequestItems = inventoryRequest.inventoryItems;
  const requestUser = inventoryRequest?.user;
  const classes = useRowStyles();
  const [assetsOpened, setAssetsOpened] = useState(false);
  const toggleAssetsOpened = () => setAssetsOpened(!assetsOpened);
  const withRejectedItem = inventoryRequestItems.find(item => item.status === "rejected");
  const wizardMode = user.company?.settings?.wizard_mode;

  const isVA = isVaSubdomain(); // Check if it's a VA subdomain

  const deliveryTitle = inventoryRequest.multipleAddress
    ? "Multiple delivery"
    : inventoryRequest.addressLine;

  const accessLinkTitle = requestUser.accessLink?.id
    ? requestUser.accessLink.name
      ? `${requestUser.accessLink.name}`
      : "Access link"
    : null;

  const changeStatus = useCallback(
    key => v => {
      const input = {
        vaStatus: {
          ...inventoryRequest?.vaStatus,
          [key]: v.target.checked,
        },
      };
      return saveInventoryRequest({
        variables: { id: inventoryRequest.id, input },
        onSuccess: () => {
          updateInventoryRequest(inventoryRequestId, { vaStatus: input.vaStatus });
        },
      });
    },
    [saveInventoryRequest, inventoryRequest, inventoryRequestId, updateInventoryRequest]
  );

  const handleAllChangeInventoryItems = values => {
    updateInventoryRequest(inventoryRequest.id, {
      ...inventoryRequest,
      inventoryItems: inventoryRequest.inventoryItems.map(v => ({
        ...v,
        ...values,
      })),
    });
  };

  return (
    <>
      <TableRow
        id={`inventoryRequest-${inventoryRequest.id}`}
        className={classes.root}
        key={inventoryRequest.id}
      >
        {display.order_work_order_number && (
          <TableCell>
            {isVA ? "Task Order #" : "Order #"} {getWO(inventoryRequest)}
          </TableCell>
        )}
        {display.order_requested_at && (
          <TableCell>{moment(inventoryRequest.requestedAt, serverFormat).format("lll")}</TableCell>
        )}
        {display.order_requested_by && (
          <TableCell>
            {accessLinkTitle ? (
              <Link
                component={RouterLink}
                to={`/settings/links/${requestUser.accessLink.id}/edit`}
                variant="body2"
              >
                {inventoryRequest.email || accessLinkTitle}
              </Link>
            ) : inventoryRequest.email ? (
              inventoryRequest.email
            ) : (
              <Link component={RouterLink} to={`/user/${requestUser.id}/edit`} variant="body2">
                {requestUser.email}
              </Link>
            )}
          </TableCell>
        )}
        {display.order_start_date && <TableCell>{inventoryRequest.startDate}</TableCell>}
        {display.order_delivery_date && <TableCell>{inventoryRequest.deliveryDate}</TableCell>}
        {display.order_status && (
          <TableCell>
            <StatusLabel withRejectedItem={withRejectedItem} inventoryRequest={inventoryRequest} />
          </TableCell>
        )}
        {display.order_destination && (
          <TableCell>
            {wizardMode ? (
              <>
                {inventoryRequest.comment ? (
                  <Tooltip title={inventoryRequest.comment}>
                    <a href="src/views/inventory/requests/orders/RequestRow#">{deliveryTitle}</a>
                  </Tooltip>
                ) : (
                  deliveryTitle
                )}
              </>
            ) : (
              inventoryRequest.location?.split(",")[0]
            )}
          </TableCell>
        )}
        {display.order_pdf_report && (
          <TableCell>
            <a
              target="_blank"
              href={`/inventory_request_pdfs/${inventoryRequest.id}?token=${user.token}`}
            >
              Generate
            </a>
          </TableCell>
        )}
        {display.order_va_status && (
          <TableCell>
            <VaStatus
              inventoryRequest={inventoryRequest}
              changeStatus={changeStatus}
              reFetch={reFetch}
            />
          </TableCell>
        )}
        {display.order_actions && (
          <TableCell align="right">
            <OrderActions
              inventoryRequest={inventoryRequest}
              toggleAssets={toggleAssetsOpened}
              reFetch={reFetch}
              updateInventoryRequest={updateInventoryRequest}
              removeInventoryItem={removeInventoryItem}
              changeAllInventoryItems={handleAllChangeInventoryItems}
            />
          </TableCell>
        )}
      </TableRow>
      <TableRow>
        <RequestRowAssets
          assetsOpened={assetsOpened}
          inventoryRequest={inventoryRequest}
          reFetch={reFetch}
        />
      </TableRow>
    </>
  );
}
