import React from "react";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import MuiSelect from "@material-ui/core/Select";

const SelectField = ({ options = [], label, InputLabelProps, fieldName, error, ...props }) => {
  return (
    <FormControl fullWidth variant="outlined">
      <InputLabel {...InputLabelProps} id={fieldName}>
        {label}
      </InputLabel>
      <MuiSelect labelId={fieldName} id={fieldName} {...props}>
        {options.map(([value, label]) => (
          <MenuItem key={value} value={value}>
            {label}
          </MenuItem>
        ))}
      </MuiSelect>
      {error && (
        <FormHelperText htmlFor={fieldName} error={!!error}>
          {error}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default SelectField;
