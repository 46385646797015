import React from "react";
import Box from "@material-ui/core/Box";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@material-ui/core/Checkbox";

export default function LocationStatus({ occupied, setOccupied }) {
  const toggleOccupied = value => {
    setOccupied(v => (typeof v === "boolean" && v === value ? null : value));
  };

  return (
    <Box>
      <MenuItem sx={{ fontSize: 14 }} onClick={() => toggleOccupied(false)}>
        <Box my={-1} ml={-1}>
          <Checkbox checked={occupied === false} value="" />
        </Box>
        Empty
      </MenuItem>
      <MenuItem sx={{ fontSize: 14 }} onClick={() => toggleOccupied(true)}>
        <Box my={-1} ml={-1}>
          <Checkbox checked={occupied === true} value="" />
        </Box>
        Occupied
      </MenuItem>
    </Box>
  );
}
