import React from "react";
import moment from "moment/moment";
import Box from "@mui/material/Box";

import TimeButton from "./TimeButton";

const minutes = ["00", "15", "30", "45"];
const hours = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];

const initialDate = () =>
  moment()
    .set("hour", 8)
    .set("minute", 0);

function convert24hours(period, hour) {
  if (parseInt(hour) === 12) {
    return period === "PM" ? parseInt(hour) : parseInt(hour) - 12;
  } else if (period === "PM") {
    return parseInt(hour) + 12;
  }
  return parseInt(hour);
}

export default function TimePicker({ minDate, value, onChange }) {
  const selectedHour = value ? parseInt(value.format("hh")) : 8;
  const selectedMinute = value ? value.get("minute") : 0;
  const selectedPeriod = value ? value.format("A") : "AM";

  const isDisabled = (key, time) => {
    if (minDate) {
      const newDateTime = value ? moment(value) : initialDate();
      const v = convert24hours(selectedPeriod, time);
      newDateTime.set(key, v);
      return moment(minDate) > newDateTime;
    }

    return false;
  };

  const isPeriodDisabled = (key, period) => {
    if (minDate) {
      const newDateTime = value ? moment(value) : initialDate();
      const date = period === "AM" ? newDateTime.subtract(12, "hour") : newDateTime.add(12, "hour");
      return moment(minDate) > date;
    }

    return false;
  };

  const handleChangeHour = hour => {
    const newDateTime = value ? moment(value) : initialDate();
    const h = convert24hours(selectedPeriod, hour);
    newDateTime.set("hour", h);
    onChange(newDateTime);
  };

  const handleChangeMinute = minute => {
    const newDateTime = value ? moment(value) : initialDate();
    newDateTime.set("minute", minute);
    onChange(newDateTime);
  };

  const handleChangePeriod = period => {
    if (period === selectedPeriod) return;
    const newDateTime = value ? moment(value) : initialDate();
    onChange(period === "AM" ? newDateTime.subtract(12, "hour") : newDateTime.add(12, "hour"));
  };

  return (
    <>
      <Box width={80} my={2.5} maxHeight={320} overflow="auto">
        {hours.map(v => (
          <TimeButton
            key={v}
            selected={selectedHour === parseInt(v)}
            onClick={() => handleChangeHour(v)}
            disabled={isDisabled("hour", v)}
          >
            {v}
          </TimeButton>
        ))}
      </Box>
      <Box width={80} my={2.5}>
        {minutes.map(v => (
          <TimeButton
            key={v}
            selected={selectedMinute === parseInt(v)}
            onClick={() => handleChangeMinute(v)}
            disabled={isDisabled("minute", v)}
          >
            {v}
          </TimeButton>
        ))}
      </Box>
      <Box width={80} my={2.5}>
        {["AM", "PM"].map(v => (
          <TimeButton
            key={v}
            selected={selectedPeriod === v}
            onClick={() => handleChangePeriod(v)}
            disabled={isPeriodDisabled("minute", v)}
          >
            {v}
          </TimeButton>
        ))}
      </Box>
    </>
  );
}
