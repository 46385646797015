import React, {useCallback, useState} from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import FormHelperText from "@material-ui/core/FormHelperText";
import SvgIcon from "@material-ui/core/SvgIcon";
import TextField from "@material-ui/core/TextField";
import {makeStyles} from "@material-ui/core";
import {Minus as MinusIcon, Plus as PlusIcon} from "react-feather";

import useMutation from "../../../hooks/useMutation";
import {CREATE_KIT_ASSET_MUTATION} from "../../../constants/graphql/mutations";
import useQuery from "../../../hooks/useQuery";
import {useKitAssets} from "../../../contexts/kit-assets";
import useNotification from "../../../hooks/notification";

export const useStyles = makeStyles(theme => ({
  quantityInput: {
    width: "70px",
    "& input": {
      fontSize: "0.875rem",
      padding: "10px 6px",
      height: "10px",
    },
  },
  quantityButtonMinus: {
    minWidth: "20px",
    height: "30px",
    marginRight: "-1px",
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    "& svg": {
      fontSize: "0.875rem",
    },
  },
  quantityButtonPlus: {
    minWidth: "20px",
    height: "30px",
    marginLeft: "-1px",
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    "& svg": {
      fontSize: "0.875rem",
    },
  },
}));

export default function AddToKit({ asset, defaultValues, size = "medium" }) {
  const classes = useStyles({ size });
  const { notifySuccess } = useNotification();
  const [createKitAsset, { loading }] = useMutation(CREATE_KIT_ASSET_MUTATION);
  const [error, setError] = useState(null);
  const [quantity, setQuantity] = useState(0);
  const query = useQuery();
  const { refetch } = useKitAssets();
  const notAvailable = false;

  const handleAddInventoryItem = () => {
    if (quantity === 0) return;

    return createKitAsset({
      variables: { kitId: query.get("kitId"), input: { quantity, assetId: asset.id } },
      onSuccess: ({ kit, asset }) => {
        setError(null);
        setQuantity(0);
        refetch();
        notifySuccess("Component successfully added");
      },
      onFailure: errors => {
        setError(errors.quantity || errors.asset || errors.submit);
      },
    });
  };

  const updateQuantity = useCallback(
    value => {
      if (value >= 0) {
        setQuantity(value);
      }
    },
    [asset.availableQuantity, setQuantity]
  );

  const handleMinus = useCallback(() => updateQuantity(quantity - 1), [quantity, updateQuantity]);

  const handlePlus = useCallback(() => updateQuantity(quantity + 1), [quantity, updateQuantity]);

  const handleUpdate = useCallback(
    event => updateQuantity(event.target.value ? parseInt(event.target.value, 10) : 0),
    [quantity, updateQuantity]
  );

  return (
    <>
      <Box display="flex" justifyContent="space-between" width="100%">
        <Box>
          <Button
            disabled={notAvailable || loading}
            className={classes.quantityButtonMinus}
            variant="outlined"
            size="small"
            onClick={handleMinus}
          >
            <SvgIcon fontSize="small">
              <MinusIcon />
            </SvgIcon>
          </Button>
          <TextField
            disabled={notAvailable || loading}
            className={classes.quantityInput}
            color="secondary"
            variant="outlined"
            fullWidth
            type="number"
            size="small"
            inputProps={{ min: 0, step: 1 }}
            value={quantity === 0 ? "" : quantity}
            onChange={handleUpdate}
          />
          <Button
            disabled={notAvailable || loading}
            className={classes.quantityButtonPlus}
            variant="outlined"
            size="small"
            onClick={handlePlus}
          >
            <SvgIcon fontSize="small">
              <PlusIcon />
            </SvgIcon>
          </Button>
        </Box>
        <Button
          size="small"
          variant="outlined"
          disabled={notAvailable}
          onClick={handleAddInventoryItem}
        >
          Add to kit
        </Button>
      </Box>
      {error && (
        <Box mt={1}>
          <FormHelperText htmlFor="barcode" error={!!error}>
            {error}
          </FormHelperText>
        </Box>
      )}
    </>
  );
}
