import React, {useState} from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";

import useMutation from "../../hooks/useMutation";
import {useProjectInventory} from "../../contexts/projectInventory";
import useNotification from "../../hooks/notification";
import {usePermissions} from "../../contexts/permissions";
import InventoryTransactionModal from "../InventoryTransactionModal";
import ImportAssetsButton from "./ImportAssetsButton";
import ImportProcurementSheetButton from "./ImportProcurementSheetButton";

import {
  DOWNLOAD_ASSETS_IMAGES_MUTATION,
  EXPORT_DONATIONS_REPORT_MUTATION,
  EXPORT_EXCEL_DATA_MUTATION,
} from "src/constants/graphql";

import LookupScanner from "src/components/barcode/LookupScanner";
import MoreIcon from "@material-ui/icons/MoreVert";
import NewIcon from "@material-ui/icons/Add";
import {Description as ExcelIcon, Publish as ImportAssetsIcon} from "@material-ui/icons";
import MenuActions from "src/components/MenuActions";
import {useAssetsCollection} from "src/contexts/assetsCollection";

const reportGenerationNotification =
  "We started generating report. You'll receive notice when it's ready";

const MoreLabel = () => (
  <>
    <MoreIcon fontSize="small" color="action" />
    <Box mr={0.5} />
    <Typography fontWeight={600} color="textSecondary" variant="body2">
      More
    </Typography>
  </>
);

const TableBulkActions = ({
  selectedIds,
  totalCount,
  onCompleteBulk,
  moreActions,
  setDisabledScroll,
  refetch,
}) => {
  const { projectId, clientId } = useProjectInventory();
  const [exportDonationsReport] = useMutation(EXPORT_DONATIONS_REPORT_MUTATION);
  const [exportExcelData] = useMutation(EXPORT_EXCEL_DATA_MUTATION);
  const [downloadAssetsImages] = useMutation(DOWNLOAD_ASSETS_IMAGES_MUTATION);

  const { notifySuccess, notifyError } = useNotification();
  const { can } = usePermissions();
  const [openInventoryModal, setOpenInventoryModal] = useState(false);
  const onCloseInventoryModal = () => setOpenInventoryModal(false);
  const handleInventoryModal = () => setOpenInventoryModal(true);

  const { bulkRemoveAssets, createAsset } = useAssetsCollection();

  const [lookupScanner, setLookupScanner] = useState(false);
  const toggleLookupScanner = () => setLookupScanner(v => !v);

  const exportDonations = () => {
    exportDonationsReport({ variables: { projectId } });
    notifySuccess(reportGenerationNotification);
  };

  const exportExcel = () => {
    exportExcelData({ variables: { projectId } });
    notifySuccess(reportGenerationNotification);
  };

  const downloadImages = () => {
    downloadAssetsImages({ variables: { projectId } });
    notifySuccess(reportGenerationNotification);
  };

  const isSelected = selectedIds.length > 0;

  const handleBulkRemove = () => {
    setDisabledScroll(true);

    bulkRemoveAssets({
      variables: { ids: selectedIds },
      onSuccess: () => {
        onCompleteBulk();
        setDisabledScroll(false);
      },
      onFailure: errors => {
        notifyError(errors);
        setDisabledScroll(false);
      },
    });
  };

  const handleCreateAsset = () => {
    setDisabledScroll(true);

    createAsset({
      variables: { projectId: projectId, input: { projectId } },
      onSuccess: ({ asset }) => {
        setDisabledScroll(false);
      },
      onFailure: errors => {
        notifyError(errors);
      },
    });
  };

  const isMoreActions =
    moreActions &&
    projectId &&
    (can("features.export_assets") ||
      can("features.export_assets") ||
      can("features.import_assets"));

  return (
    <>
      {isSelected && can("assets.remove") && (
        <>
          <Button size="small" variant="contained" color="primary" onClick={handleBulkRemove}>
            {`Delete asset(s) (${selectedIds.length || totalCount})`}
          </Button>
        </>
      )}
      {isMoreActions && (
        <MenuActions
          id="moreActionsButton"
          style={{ textTransform: "none" }}
          size="small"
          label={<MoreLabel />}
        >
          {can("assets.write") && projectId && (
            <MenuItem data-id="addNewAsset" onClick={handleCreateAsset}>
              <Box mr={1}>
                <NewIcon />
              </Box>
              Add new
            </MenuItem>
          )}
          {can("features.export_assets") && projectId && (
            <MenuItem data-id="exportAssetsExcel" onClick={exportExcel}>
              <Box mr={1}>
                <ExcelIcon />
              </Box>
              Export Excel
            </MenuItem>
          )}
          {can("features.export_assets") && projectId && (
            <MenuItem data-id="downloadAssetsImages" onClick={downloadImages}>
              <Box mr={1}>
                <ExcelIcon />
              </Box>
              Download Assets Images
            </MenuItem>
          )}
          {can("features.import_assets") && projectId && (
            <ImportAssetsButton
              keepOpen
              component={MenuItem}
              label={
                <Box display="flex">
                  <Box mr={1}>
                    <ImportAssetsIcon />
                  </Box>
                  Import Assets
                </Box>
              }
              projectId={projectId}
              color="secondary"
              variant="outlined"
              size="small"
            />
          )}
          {can("features.import_assets") && projectId && (
            <ImportProcurementSheetButton
              keepOpen
              component={MenuItem}
              label={
                <Box display="flex">
                  <Box mr={1}>
                    <ImportAssetsIcon />
                  </Box>
                  Import Procurement Sheet
                </Box>
              }
              projectId={projectId}
              color="secondary"
              variant="outlined"
              size="small"
              refetchAssets={refetch}
            />
          )}
        </MenuActions>
      )}
      <InventoryTransactionModal
        title="Restock Inventory"
        open={openInventoryModal}
        onClose={onCloseInventoryModal}
        onSuccess={onCloseInventoryModal}
      />
      {lookupScanner && (
        <LookupScanner projectId={projectId} clientId={clientId} onClose={toggleLookupScanner} />
      )}
    </>
  );
};

export default TableBulkActions;
