import React, {useState} from "react";
import {makeStyles} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import camelCase from "lodash/camelCase";
import SvgMore from "@material-ui/icons/ExpandMore";
import {Link as RouterLink} from "react-router-dom";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import Tooltip from "@mui/material/Tooltip";

import caller from "../../utils/caller";
import {usePermissions} from "../../contexts/permissions";
import useNotification from "../../hooks/notification";
import useMutation from "../../hooks/useMutation";
import ConfirmModal from "../ConfirmModal";

const useRowStyles = makeStyles(theme => ({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
  nestedCell: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  nestedTable: {
    marginBottom: theme.spacing(2),
  },
}));

const RemoveButton = ({ loading, onClick, ...props }) => (
  <ConfirmModal onConfirm={onClick} title="Delete Record">
    <IconButton disabled={loading} {...props}>
      {loading ? (
        <Box mr={0.5} clone>
          <CircularProgress size={20} />
        </Box>
      ) : (
        <DeleteOutlinedIcon />
      )}
    </IconButton>
  </ConfirmModal>
);

export default function EntityTableRow({ configuration, setEntities, entity }) {
  const classes = useRowStyles();
  const tableWithActions =
    configuration.editLink || configuration.customLink || configuration.removeMutation;
  const [extendedTable, extendTable] = useState(false);
  const [removeLoading, setRemoveLoading] = useState(false);
  const toggleNestedTable = () => extendTable(v => !v);
  const { can } = usePermissions();
  const { notifySuccess, notifyError } = useNotification();

  const canWrite = configuration.accessKey ? can(`${configuration.accessKey}.write`) : true;

  const canRemove = configuration.accessKey ? can(`${configuration.accessKey}.destroy`) : true;

  const [removeAction] =
    canRemove && configuration.removeMutation ? useMutation(configuration.removeMutation) : [null];

  const handleRemoveAction = () => {
    setRemoveLoading(true);

    return removeAction({
      variables: { id: entity.id },
      onSuccess: () => {
        setRemoveLoading(false);
        notifySuccess("Record has been successfully removed!");
        if (setEntities) {
          setEntities(prev => prev.filter(entityItem => entityItem.id !== entity.id));
        }
      },
      onFailure: errors => {
        setRemoveLoading(false);
        notifyError(errors);
      },
    });
  };

  return (
    <>
      <TableRow
        id={`tableRow-${configuration.key}-${entity.id}`}
        className={configuration.nestedTable && classes.root}
      >
        {configuration.fields.map(field => {
          const value = caller(entity, field.path);

          const key = `tableCell-${configuration.key}-${entity.id}-${camelCase(field.label)}`;

          return (
            <TableCell id={key} key={key}>
              {field.component ? <field.component entity={entity} value={value} /> : value}
            </TableCell>
          );
        })}
        {configuration.nestedTable && (
          <TableCell>
            <Tooltip title={configuration.nestedTable.arrowTooltip}>
              <IconButton
                data-open={extendedTable}
                id={`nested-${configuration.key}-${entity.id}-${configuration.nestedTable.key}`}
                onClick={toggleNestedTable}
              >
                <SvgMore />
              </IconButton>
            </Tooltip>
          </TableCell>
        )}
        {tableWithActions && (
          <TableCell align="right">
            {configuration.customLink && (
              <configuration.customLink
                disabled={removeLoading}
                entity={entity}
                setEntities={setEntities}
              />
            )}
            {configuration.editLink && canWrite && (
              <IconButton
                disabled={removeLoading}
                id={`editLink-${configuration.key}-${entity.id}`}
                component={RouterLink}
                to={configuration.editLink.path(entity)}
              >
                <EditOutlinedIcon />
              </IconButton>
            )}
            {removeAction && (
              <RemoveButton
                id={`removeLink-${configuration.key}-${entity.id}`}
                loading={removeLoading}
                onClick={handleRemoveAction}
              />
            )}
          </TableCell>
        )}
      </TableRow>
      {configuration.nestedTable && (
        <TableRow>
          <TableCell className={classes.nestedCell} colSpan={configuration.fields.length + 2}>
            <Collapse in={extendedTable} timeout="auto" unmountOnExit>
              <configuration.nestedTable.component
                className={classes.nestedTable}
                entity={entity}
              />
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </>
  );
}
