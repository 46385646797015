import React from "react";
import TextField from "@material-ui/core/TextField";

import labels from "../../../constants/displayFields";

export default function MultipleDestinationRoomNumberInput({
  disabled,
  entity,
  toggleModal,
  onKeyDown,
}) {
  return (
    <TextField
      fullWidth
      multiline
      disabled={disabled}
      size="small"
      variant="outlined"
      label={labels.destination_room_number}
      value={entity.destinationRoomNumbers.map(v => `${v.room_number} (${v.sub_qty})`).join("\n")}
      onClick={toggleModal}
      onKeyDown={onKeyDown}
    />
  );
}
