import React from "react";
import {Router} from "react-router-dom";
import {createBrowserHistory} from "history";
import {create} from "jss";
import rtl from "jss-rtl";
import {jssPreset, StylesProvider, ThemeProvider} from "@material-ui/core";
import {ThemeProvider as Mui5ThemeProvider} from "@mui/material/styles";

import {NotificationProvider} from "../contexts/notification";
import GlobalStyles from "../components/GlobalStyles";
import ScrollReset from "../components/ScrollReset";
import {AuthProvider} from "../contexts/JWTAuthContext";
import {AccessLinkProvider} from "../contexts/AccessLinkContext";
import {PermissionsProvider} from "../contexts/permissions";
import ApolloProvider from "../share/ApolloProvider";
import {createTheme} from "../theme/index";
import routes, {renderRoutes} from "../routes";
import useSettings from "../hooks/useSettings";
import {CustomFieldsProvider} from "../contexts/customFields";
import {BannerProvider} from "../contexts/banner";
import appThemeMui5 from "../theme/appThemeMui5";

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });
const history = createBrowserHistory();

const AppContent = () => {
  const { settings } = useSettings();

  const theme = createTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    theme: settings.theme,
  });

  return (
    <ApolloProvider>
      <Mui5ThemeProvider theme={appThemeMui5}>
        <ThemeProvider theme={theme}>
          <StylesProvider jss={jss}>
            <BannerProvider>
              <NotificationProvider>
                <Router history={history}>
                  <AccessLinkProvider>
                    <AuthProvider>
                      <PermissionsProvider>
                        <CustomFieldsProvider>
                          <GlobalStyles />
                          <ScrollReset />
                          {renderRoutes(routes)}
                        </CustomFieldsProvider>
                      </PermissionsProvider>
                    </AuthProvider>
                  </AccessLinkProvider>
                </Router>
              </NotificationProvider>
            </BannerProvider>
          </StylesProvider>
        </ThemeProvider>
      </Mui5ThemeProvider>
    </ApolloProvider>
  );
};

export default AppContent;
