export const getProject = (clients, projectId) => {
  if (!projectId) return

  return clients
    .reduce((p, v) => [...p, ...v.projects], [])
    .find(v => v.id === projectId) || {};
}

export const getClientByProjectId = (clients, projectId) => {
  if (!projectId) return

  return clients.reduce((prev, client) => {
    if (client.projects.some(project => project.id === projectId)) {
      return client;
    }
    return prev;
  }, {});
}
