import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Checkbox,
  FormControlLabel,
  Menu,
  MenuItem,
  makeStyles,
} from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

const useStyles = makeStyles((theme) => ({
  root: {},
  menuItem: {
    padding: 0,
  },
  menuButton: {
    textTransform: "none",
    letterSpacing: 0,
  },
  formControlLabel: {
    padding: theme.spacing(0.5, 2),
    width: "100%",
    margin: 0,
  },
  tabMenu: {
    // marginTop: "55px",
  },
}));

const MultiSelect = ({
  onClose,
  label,
  options,
  value,
  onChange,
  disabled,
  ...props
}) => {
  const classes = useStyles();
  const anchorRef = useRef(null);
  const [openMenu, setOpenMenu] = useState(false);

  const handleMenuOpen = () => {
    setOpenMenu(true);
  };

  const handleMenuClose = () => {
    setOpenMenu(false);
    if (onClose) {
      onClose();
    }
  };

  const handleOptionToggle = (event) => {
    let newValue = [...value];

    if (event.target.checked) {
      newValue.push(event.target.value);
    } else {
      newValue = newValue.filter((item) => item !== event.target.value);
    }

    if (onChange) {
      onChange(newValue);
    }
  };

  return (
    <div {...props}>
      <Button
        fullWidth
        className={classes.menuButton}
        aria-haspopup="true"
        disabled={disabled}
        onClick={handleMenuOpen}
        ref={anchorRef}
      >
        {label}
      </Button>
      <Menu
        anchorEl={anchorRef.current}
        elevation={1}
        onClose={handleMenuClose}
        open={openMenu}
        // PaperProps={{ style: { width: 250 } }}
        PopoverClasses={{ root: classes.tabMenu }}
      >
        {options.map((option, index) => (
          <MenuItem
            className={classes.menuItem}
            key={`${index}-${label}`}
            data-id="select-item"
          >
            <FormControlLabel
              className={classes.formControlLabel}
              control={
                <Checkbox
                  disabled={disabled}
                  checked={value.indexOf(option[0]) > -1}
                  onChange={handleOptionToggle}
                  value={option[0]}
                />
              }
              label={option[1]}
            />
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default MultiSelect;
