import React, {useCallback, useMemo, useState} from "react";
import {useQuery} from "@apollo/react-hooks";
import keyBy from "lodash/keyBy";
import isEmpty from "lodash/isEmpty";
import filter from "lodash/filter";

import {SHOPPING_INVENTORY_REQUEST_QUERY} from "../constants/graphql";

const ShoppingContext = React.createContext(null);

export const ShoppingProvider = ({
  children,
  inventoryRequestQuery = SHOPPING_INVENTORY_REQUEST_QUERY,
}) => {
  const [inventoryRequest, setInventoryRequest] = useState(null);
  const [inventoryItems, setInventoryItems] = useState({});
  const [inventoryAssets, setInventoryAssets] = useState({});
  const [loading, setLoading] = useState(true);

  const updateInventoryAsset = (assetId, props) => {
    const inventoryAsset = inventoryAssets[assetId];
    if (!inventoryAsset) return;

    setInventoryAssets({
      ...inventoryAssets,
      [inventoryAsset.id]: { ...inventoryAsset, ...props },
    });
  };

  const setInventoryItem = inventoryItem => {
    setInventoryItems(prevItems => ({
      ...prevItems,
      [inventoryItem.id]: inventoryItem,
    }));
  };

  const setInventoryAsset = useCallback(
    item => {
      setInventoryAssets(prevItems => ({
        ...prevItems,
        [item.id]: item,
      }));
    },
    [setInventoryAssets]
  );

  const removeInventoryItem = itemId => {
    if (!itemId) return;
    setInventoryItems(filter(inventoryItems, item => item.id != itemId));
  };

  const { error, refetch } = useQuery(inventoryRequestQuery, {
    fetchPolicy: "no-cache",
    onCompleted: ({ shoppingInventoryRequest }) => {
      setInventoryRequest(shoppingInventoryRequest);
      setInventoryItems(shoppingInventoryRequest?.inventoryItems);
      setInventoryAssets(keyBy(shoppingInventoryRequest?.assets || [], "id"));
      setLoading(false);
    },
  });

  const reFetch = () => {
    refetch().then(({ data: { shoppingInventoryRequest } }) => {
      setInventoryRequest(shoppingInventoryRequest);
      setInventoryItems(shoppingInventoryRequest?.inventoryItems);
      setInventoryAssets(keyBy(shoppingInventoryRequest?.assets || [], "id"));
      setLoading(false);
    });
  };

  const value = useMemo(
    () => ({
      inventoryRequest,
      inventoryAssets,
      inventoryItems: keyBy(inventoryItems || [], "id"),
      loading,
      setInventoryRequest,
      setInventoryItem,
      setInventoryAsset,
      updateInventoryAsset,
      removeInventoryItem,
      cartIsEmpty: isEmpty(inventoryItems),
      error,
      refetch: reFetch,
    }),
    [setInventoryAsset, inventoryRequest, inventoryItems, inventoryAssets, loading, error, reFetch]
  );

  return <ShoppingContext.Provider value={value}>{children}</ShoppingContext.Provider>;
};

export const useShopping = () => React.useContext(ShoppingContext);
