import React, {useState} from "react";
import {fieldToTextField} from "formik-material-ui";
import {FastField} from "formik";
import map from "lodash/map";
import upperFirst from "lodash/upperFirst";
import Autocomplete from "@material-ui/lab/Autocomplete";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles(() => ({
  endAdornment: {
    top: "unset !important",
  },
}));

const FormikAutocomplete = ({ textFieldProps, saveCustomOption, ...props }) => {
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const {
    form: { setTouched, setFieldValue },
  } = props;
  const options = map(props.options || [], option => ({
    ...option,
    label: option.label || "No Label",
  }));
  const { error, helperText, ...field } = fieldToTextField(props);
  const { name } = field;

  const onOptionSelected = option => {
    if (typeof option === "string") {
      const selected = options.find(v => v.value === option);
      return selected ? selected.label : option;
    }

    return option ? option.label : "";
  };

  return (
    <Autocomplete
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      {...props}
      {...field}
      id={props.id ? props.id : `field-${name}`}
      data-id={`select${upperFirst(name)}`}
      onChange={(e, value) => {
        setFieldValue(name, value ? value.value : "");
        if (props.onChange) props.onChange(value);
      }}
      options={options}
      value={field.value || ""}
      getOptionSelected={onOptionSelected}
      getOptionLabel={onOptionSelected}
      onBlur={() => setTouched({ [name]: true })}
      classes={{
        endAdornment: classes.endAdornment,
      }}
      renderInput={inputProps => (
        <TextField
          {...inputProps}
          size="small"
          name={name}
          helperText={helperText}
          error={error}
          label={props.label}
          placeholder={props.placeholder}
          variant="outlined"
          margin="none"
          {...textFieldProps}
          onBlur={
            saveCustomOption
              ? value => {
                  setFieldValue(field.name, value.target.value);
                }
              : null
          }
          onKeyDown={e => {
            if (e.key === "Enter") {
              setOpen(true);
            }
          }}
        />
      )}
    />
  );
};

export default props => {
  return (
    <FormControl fullWidth>
      <FastField
        component={FormikAutocomplete}
        textFieldProps={{
          fullWidth: true,
          margin: "none",
          variant: "outlined",
        }}
        {...props}
      />
    </FormControl>
  );
};
