import React, {useState} from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import CircularProgress from "@mui/material/CircularProgress";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

import {ClientsProvider} from "../../../contexts/clients";
import ItemId from "../../../views/shopping/Orders/ItemId";
import {getImage} from "../../../views/shopping/Cart/CartItemFields";
import Dimensions from "../../../views/inventory/requests/Dimensions";
import useMutation from "../../../hooks/useMutation";
import {ADD_SHOPPING_INVENTORY_ITEM_MUTATION} from "../../../constants/graphql";
import {useShopping} from "../../../contexts/shopping";
import AssetQuantityField from "../../../views/inventory/requests/AssetQuantityField";
import useAuth from "../../../hooks/useAuth";

export default function AddAssetsModal({ title, assets, onClose }) {
  const { user } = useAuth();
  const [list, setList] = useState(assets.map(v => ({ id: v.id, quantity: v.quantity })));
  const [adding, setAdding] = useState(false);
  const [addInventoryItem] = useMutation(ADD_SHOPPING_INVENTORY_ITEM_MUTATION);
  const { setInventoryAsset, setInventoryItem, removeInventoryItem } = useShopping();
  const disabled = false;

  const handleChangeQuantity = (id, quantity) => {
    setList(v => v.map(v => (v.id === id ? { ...v, quantity } : v)));
  };

  const handleRemove = id => {
    setList(v => v.filter(v => v.id !== id));
  };

  const AddToCart = () => {
    setAdding(true);
    Promise.all(
      list.map(value => {
        const asset = assets?.find(v => v.id === value.id) || {};
        return addInventoryItem({
          variables: {
            assetId: asset.id,
            quantity: value.quantity,
            location: value.location || "",
          },
          onSuccess: ({ inventoryItem, asset }) => {
            if (inventoryItem && asset) {
              setInventoryAsset(asset);
            }
            inventoryItem ? setInventoryItem(inventoryItem) : removeInventoryItem(asset.id);
          },
        });
      })
    ).then(() => {
      setAdding(false);
      onClose();
    });
  };

  return (
    <Dialog open fullWidth maxWidth="lg" onClose={onClose} aria-labelledby="dialog-title">
      <DialogTitle>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box />
          <Typography fontSize={22} color="textPrimary" component="span">
            {title}
          </Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Box my={2}>
            {assets.length ? (
              <ClientsProvider>
                <Table stickyHeader>
                  <TableHead>
                    <TableCell align="center">Image</TableCell>
                    <TableCell>Item ID</TableCell>
                    <TableCell>Description</TableCell>
                    <TableCell>Model</TableCell>
                    <TableCell>Dimensions</TableCell>
                    <TableCell width={170}>Quantity</TableCell>
                    <TableCell>{user.company.settings.quantity_label}</TableCell>
                    <TableCell />
                  </TableHead>
                  <TableBody>
                    {list.map(value => {
                      const asset = assets?.find(v => v.id === value.id) || {};
                      const photo = getImage(asset);
                      const highlight = value.quantity > asset?.availableQuantity;
                      const background = highlight ? "#fffbf2" : undefined;
                      return (
                        <TableRow key={asset.id} style={{ width: 90 }}>
                          <TableCell align="center" style={{ background }}>
                            {photo ? (
                              <img style={{ maxWidth: 90, maxHeight: 60 }} src={photo} alt="" />
                            ) : (
                              "No image"
                            )}
                          </TableCell>
                          <TableCell style={{ background }}>
                            <ItemId asset={asset} />
                          </TableCell>
                          <TableCell style={{ background }}>{asset.description}</TableCell>
                          <TableCell style={{ background }}>{asset.model}</TableCell>
                          <TableCell style={{ background }}>
                            <Dimensions dimensions={asset.dimensions} />
                          </TableCell>
                          <TableCell style={{ background }}>
                            <Box width={140}>
                              <AssetQuantityField
                                quantity={value.quantity || ""}
                                onChange={quantity => handleChangeQuantity(asset.id, quantity)}
                                size="small"
                              />
                            </Box>
                          </TableCell>
                          <TableCell style={{ background }}>{asset.availableQuantity}</TableCell>
                          <TableCell align="right" style={{ background }}>
                            <IconButton color="inherit" onClick={() => handleRemove(asset.id)}>
                              <Tooltip arrow title="Remove">
                                <HighlightOffIcon />
                              </Tooltip>
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </ClientsProvider>
            ) : (
              <Box
                p={3}
                bgcolor="#f4f6f8"
                borderRadius="4px"
                display="flex"
                justifyContent="center"
              >
                Empty
              </Box>
            )}
          </Box>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Box display="flex" p={1.5}>
          <Button variant="outlined" onClick={onClose} color="primary">
            Close
          </Button>
          <Button
            disabled={adding || disabled}
            sx={{ ml: 1 }}
            variant="contained"
            onClick={AddToCart}
            color="primary"
          >
            Add to cart{" "}
            {adding && (
              <Box ml={1.5} clone>
                <CircularProgress size={18} style={{ color: "white" }} />
              </Box>
            )}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
}
