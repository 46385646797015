import React from "react";

import useDesktopPanAroundAndZoom from "src/hooks/planner/useDesktopPanAroundAndZoom";
import useMobilePanAroundAndZoom from "src/hooks/planner/useMobilePanAroundAndZoom";

const Scale = ({ editor }) => {
  const isTouchDevice =
    "ontouchstart" in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;
  const [zoom, setZoom] = React.useState(10);
  const settings = { zoomFrom: 10, zoomTo: 500 };
  useDesktopPanAroundAndZoom(editor, setZoom, !isTouchDevice, settings);
  useMobilePanAroundAndZoom(editor, setZoom, isTouchDevice);

  return null;
};

export default Scale;
