import React, {useState} from "react";
import Dialog from "@mui/material/Dialog";
import IconButton from "@material-ui/core/IconButton";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

import {CaptureProvider} from "../../../../contexts/capture";
import {ClientsProvider} from "../../../../contexts/clients";
import {AssetsProvider} from "../../../../contexts/assets";
import {ProjectInventoryProvider} from "../../../../contexts/projectInventory";
import CollectionProvider from "../../../capture/CollectionProvider";
import {KitAssetsProvider} from "../../../../contexts/kit-assets";
import KitDetailsModal from "../../../../components/TablePro/components/assets/KitDetailsModal";
import {KitsCollectionProvider} from "../../../../contexts/kitsCollection";
import {KITS_COLLECTION_TABLE_QUERY} from "../../../../constants/graphql/queries";
import AssetInfoModal from "./AssetInfoModal";

export default function AssetInfo({ asset }) {
  const [open, setOpen] = useState(false);

  const handleToggle = () => {
    setOpen(v => !v);
  };

  return (
    <>
      <IconButton onClick={handleToggle}>
        <InfoOutlinedIcon color="primary" />
      </IconButton>
      {open && (
        <>
          {asset.type === "Kit" && (
            <KitsCollectionProvider variables={{}} query={KITS_COLLECTION_TABLE_QUERY}>
              <KitAssetsProvider id={asset.id}>
                <KitDetailsModal disabled entity={asset} onClose={handleToggle} />
              </KitAssetsProvider>
            </KitsCollectionProvider>
          )}
          {asset.type === "Asset" && (
            <Dialog open fullWidth maxWidth="md" onClose={handleToggle}>
              <ProjectInventoryProvider>
                <CaptureProvider>
                  <ClientsProvider>
                    <CollectionProvider>
                      <AssetsProvider>
                        <AssetInfoModal asset={asset} open={open} onToggle={handleToggle} />
                      </AssetsProvider>
                    </CollectionProvider>
                  </ClientsProvider>
                </CaptureProvider>
              </ProjectInventoryProvider>
            </Dialog>
          )}
        </>
      )}
    </>
  );
}
