import React from "react";
import orderBy from "lodash/orderBy";

import Autocomplete from "../../../components/form/Autocomplete";
import labels from "../../../constants/displayFields";
import {useCategories} from "../SustainabilitySheetContext";

export default function CategoryField({ disabledForm }) {
  const categories = useCategories();

  const categoryOptions = orderBy(
    categories.map(value => ({ value, label: value })),
    v => v.value
  );

  return (
    <Autocomplete
      key={categoryOptions.join()}
      saveCustomOption
      name="category"
      label={labels.category}
      options={categoryOptions}
      disabled={disabledForm}
    />
  );
}
