import React from "react";
import {FastField} from "formik";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import MuiSelect from "@material-ui/core/Select";

const Select = ({ options = [], label, InputLabelProps, size, required, ...props }) => {
  return (
    <FormControl fullWidth variant="outlined" size={size} required={required}>
      <FastField {...props}>
        {({ field, form, meta }) => {
          const error = (meta.touched || form.submitCount > 0) && meta.error;

          return (
            <>
              <InputLabel id={`select-label-${field.name}`}>{label}</InputLabel>
              <MuiSelect
                {...props}
                data-id={field.name}
                labelId={`select-label-${field.name}`}
                label={label}
                id={field.name}
                value={field.value || ""}
                error={!!error}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                }}
                onChange={value => form.setFieldValue(field.name, value.target.value)}
              >
                {options.map(([value, label]) => (
                  <MenuItem key={value} value={value} data-id="select-item">
                    {label}
                  </MenuItem>
                ))}
              </MuiSelect>

              {error && (
                <FormHelperText htmlFor={field.name} error={!!error}>
                  {error}
                </FormHelperText>
              )}
            </>
          );
        }}
      </FastField>
    </FormControl>
  );
};

export default Select;
