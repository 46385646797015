import React, {forwardRef} from "react";
import {Helmet} from "react-helmet";
import PropTypes from "prop-types";
import {Container, makeStyles} from "@material-ui/core";

export const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  }
}));

const Page = forwardRef(({ children, title = "", ...rest }, ref) => {
  const classes = useStyles();

  return (
    <div ref={ref} className={classes.root} {...rest}>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Container maxWidth={false}>{children}</Container>
    </div>
  );
});

Page.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string
};

export default Page;
