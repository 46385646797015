import React from "react";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { useField } from "formik";

import labels from "src/constants/displayFields";
import DraggableTags from "../DraggableTags";
import Divider from "@material-ui/core/Divider";

import Checkbox from "src/components/form/Checkbox";

const items = {
  identifiers: [
    "status",
    "transferred_by",
    "comments",
    "signature",
    "received_by_email",
    "received_by_date",
  ],
  checkout: [
    "checkout",
    "checkout_agent",
    "checkout_status",
    "checkout_date",
  ],
  checkin: [
    "checkin",
    "checkin_agent",
    "checkin_status",
    "checkin_date",
  ]
}

const Layer1Fields = () => {
  const [checkout, , checkoutHelpers] = useField("checkout");
  const fields = checkout.value || [];

  const tags = fields.map(id => ({ id, content: labels[id] }));

  const handleRemove = id => {
    checkoutHelpers.setValue(checkout.value.filter(v => v !== id));
  };

  const handleChange = list => {
    checkoutHelpers.setValue(list.map(v => v.id));
  };

  const handleUpdate = value => {
    checkoutHelpers.setValue(
      fields.includes(value)
        ? fields.filter(v => v !== value)
        : fields.concat(value)
    );

    checkoutHelpers.setValue(list.map(v => v.id));
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Typography variant="body1" color="textSecondary">
          Layer 3
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {tags.length > 0 && (
          <DraggableTags
            tags={tags}
            onRemove={handleRemove}
            onChange={handleChange}
          />
        )}
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={2}>
            <Typography color="textSecondary">Identifiers</Typography>
          </Grid>
          <Grid item xs={12} md={2}>
            <Typography color="textSecondary">Checkout</Typography>
          </Grid>
          <Grid item xs={12} md={3}>
            <Typography color="textSecondary">Checkin</Typography>
          </Grid>

          <Grid item xs={12} md={3} />
          <Grid item xs={12} md={2} />
          <Grid item xs={12}>
            <Divider />
          </Grid>

          <Grid item xs={12} md={2}>
            <Box display="flex" flexDirection="column">
              {items.identifiers.map(value => (
                <Checkbox
                  key={value}
                  name={value}
                  label={labels[value]}
                  checked={fields.includes(value)}
                  onChange={() => handleUpdate(value)}
                />
              ))}
            </Box>
          </Grid>

          <Grid item xs={12} md={2}>
            <Box display="flex" flexDirection="column">
              {items.checkout.map(value => (
                <Checkbox
                  key={value}
                  name={value}
                  label={labels[value]}
                  checked={fields.includes(value)}
                  onChange={() => handleUpdate(value)}
                />
              ))}
            </Box>
          </Grid>

          <Grid item xs={12} md={3}>
            <Box display="flex" flexDirection="column">
              {items.checkin.map(value => (
                <Checkbox
                  key={value}
                  name={value}
                  label={labels[value]}
                  checked={fields.includes(value)}
                  onChange={() => handleUpdate(value)}
                />
              ))}
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Layer1Fields;
