import React from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import CaptureContainer from "src/components/CaptureContainer";

const InputSkeleton = () => <Skeleton variant="rect" height={40} />;
const BigButtonSkeleton = () => <Skeleton variant="rect" height={200} />;

const CaptureSkeleton = () => (
  <CaptureContainer>
    <Box
      display="flex"
      flexGrow={1}
      flexDirection="column"
      justifyContent="space-between"
    >
      <Grid container spacing={1}>
        {/* Client/Project Dropdown */}
        <Grid item xs={6}>
          <InputSkeleton />
        </Grid>
        <Grid item xs={6}>
          <InputSkeleton />
        </Grid>
        {/* Search and Asset Item */}
        <Grid item xs={12}>
          <InputSkeleton />
        </Grid>
        <Grid item xs={12}>
          <InputSkeleton />
        </Grid>
        <Grid item xs={12}>
          <InputSkeleton />
        </Grid>
        {/* Big buttons */}
        <Grid item xs={6}>
          <BigButtonSkeleton />
        </Grid>
        <Grid item xs={6}>
          <BigButtonSkeleton />
        </Grid>
        {/* Form fields */}
        <Grid item xs={12}>
          <InputSkeleton />
        </Grid>
        <Grid item xs={12}>
          <InputSkeleton />
        </Grid>
        {Array(6)
          .fill()
          .map((v, i) => (
            <React.Fragment key={i}>
              <Grid item xs={6}>
                <InputSkeleton />
              </Grid>
              <Grid item xs={6}>
                <InputSkeleton />
              </Grid>
            </React.Fragment>
          ))}
      </Grid>
      <Box mt={2} />
      {/* Form actions */}
      <Grid container spacing={1}>
        <Grid item xs={3}>
          <InputSkeleton />
        </Grid>
        <Grid item xs={3}>
          <InputSkeleton />
        </Grid>
        <Grid item xs={3}>
          <InputSkeleton />
        </Grid>
        <Grid item xs={3}>
          <InputSkeleton />
        </Grid>
      </Grid>
    </Box>
  </CaptureContainer>
);

export default CaptureSkeleton;
