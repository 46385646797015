import React from "react";
import Box from "@mui/material/Box";
import Progress from "../Progress";
import RaceMode from "./RaceMode";

export default function Footer({ step, shipment, children }) {
  return (
    <Box>
      <Progress step={step} />
      <Box px={2} py={1.5} display="flex" justifyContent="space-between" alignItems="center">
        <RaceMode startTime={shipment.startTime} completeTime={shipment.completeTime} />
        {children}
      </Box>
    </Box>
  );
}
