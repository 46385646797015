import React from "react";
import Label from "src/components/Label";
import lowerCase from "lodash/lowerCase";

const StatusLabel = ({ inventoryRequest, withRejectedItem }) => {
  let status = inventoryRequest.status;
  if (inventoryRequest.reviewStatus) status = inventoryRequest.reviewStatus;
  status = lowerCase(status);

  switch (status) {
    case "requested":
      return <Label color="secondary">opened</Label>;
    case "rejected":
      return <Label color="error">closed</Label>;
    case "checked out":
      return <Label color="warning">in progress</Label>;
    case "checked in":
      return <Label color="success">completed</Label>;
    case "approved":
      return <Label color="warning">in progress</Label>;
    case "partial":
      return <Label color="warning">in progress</Label>;
    default:
      return <Label color="warning">in progress</Label>;
  }
};

export default StatusLabel;
