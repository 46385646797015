import useAllFields from "./useAllFields";

export default function useLabels() {
  const fields = useAllFields();
  const entries = Object.entries(fields);

  return Object.fromEntries(
    entries.map(([key, list]) => [key, Object.fromEntries(list.map(([key, l, v]) => [key, v]))])
  );
}
