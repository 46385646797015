import {createTheme} from "@mui/material/styles";

const appThemeMui5 = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
      },
    },
  },
});

export default appThemeMui5;
