import {gql} from "apollo-boost";
import * as fragment from "./fragments";

export const ASSET_MUTATED_SUBSCRIPTION = gql`
  subscription AssetMutated($clientId: ID!) {
    assetMutated(clientId: $clientId) {
      ${fragment.ASSET_FRAGMENT}
    }
  }
`;

export const ASSET_DELETED_SUBSCRIPTION = gql`
  subscription AssetDeleted($clientId: ID!) {
    assetDeleted(clientId: $clientId) {
      ${fragment.ASSET_FRAGMENT}
    }
  }
`;

export const NEW_MESSAGE_SUBSCRIPTION = gql`
  subscription NewMessage($userId: ID!) {
    newMessage(userId: $userId) {
      text
      link
    }
  }
`;

export const MOVE_MATRIX_MUTATION_CREATED_SUBSCRIPTION = gql`
  subscription {
    moveMatrixCreated {
      ${fragment.MOVE_MATRIX_FRAGMENT}
    }
  }
`;

export const USER_INVITED_SUBSCRIPTION = gql`
  subscription UserInvited($clientId: ID!) {
    userInvited(clientId: $clientId) {
      ${fragment.USER_FRAGMENT}
    }
  }
`;

export const INVENTORY_ASSET_STOCKED = gql`
  subscription {
    inventoryAssetStocked {
      ${fragment.ASSET_FRAGMENT}
    }
  }
`;
