import React, {useState} from "react";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import AddIcon from "@material-ui/icons/Add";

import {usePermissions} from "../../../contexts/permissions";
import useIsOrderDisabled from "../../../hooks/useIsOrderDisabled";
import InventoryItemModal from "./InventoryItemModal";

export default function AddAsset({ values }) {
  const [open, setOpen] = useState(false);
  const { user } = usePermissions();
  const disabled = useIsOrderDisabled(values, user.company.settings.cut_off);

  return (
    <>
      <Box mt={2}>
        <Button disabled={disabled} variant="outlined" onClick={() => setOpen(true)}>
          Add <AddIcon color={disabled ? "disabled" : "action"} fontSize="small" />
        </Button>
      </Box>
      {open && <InventoryItemModal onClose={() => setOpen(false)} />}
    </>
  );
}
