import sortBy from "lodash/sortBy";

import displayFields from "../../../constants/displayFields";
import useAuth from "../../../hooks/useAuth";

export default function useGroupByFields() {
  const { user } = useAuth();
  const { settings } = user.company;

  return sortBy(
    [
      ["bench_floor_hood_wall_bsc", displayFields.bench_floor_hood_wall_bsc],
      ["category", displayFields.category],
      ["color", displayFields.color],
      ["department_name", displayFields.department_name],
      ["new_building", displayFields.new_building],
      ["destination_employee_name", displayFields.destination_employee_name],
      ["new_floor", displayFields.new_floor],
      ["destination_number", displayFields.destination_number],
      ["new_room", displayFields.new_room],
      ["destination_room_number", displayFields.destination_room_number],
      ["disposition", displayFields.disposition],
      ["finishes", displayFields.finishes],
      ["install_responsibility", displayFields.install_responsibility],
      ["lab_name", displayFields.lab_name],
      ["manufacturer", displayFields.manufacturer],
      ["model", settings.model_label || displayFields.model],
      ["existing_building", displayFields.existing_building],
      ["origin_employee_name", displayFields.origin_employee_name],
      ["existing_floor", displayFields.existing_floor],
      ["existing_room", displayFields.existing_room],
      ["origin_room_number", displayFields.origin_room_number],
      ["relocation_phase", "Relocation Phase"],
      ["seismic_anchor_REQD", `Seismic Anchor REQ'D`],
      ["subcategory", displayFields.subcategory],
      ["vendor_required", displayFields.vendor_required],
      ["warranty", displayFields.warranty],
      ["condition", displayFields.condition],
      ["power", displayFields.power],
      ["destination_room_numbers", displayFields.destination_room_numbers],
      ["inventory_items.disposition", displayFields.inventory_items_disposition],
    ],
    v => v[1].toLowerCase()
  );
}
