import React, {useEffect, useState} from "react";
import {useQuery} from "@apollo/react-hooks";
import {useHistory, useParams} from "react-router-dom";
import Box from "@mui/material/Box";

import {ClientsProvider} from "../../contexts/clients";
import TableEntities from "../../components/TableEntities";
import {FAVORITE_REPORTS_QUERY} from "../../constants/graphql";
import GenerateReportModal from "./GenerateReportModal";
import ReportsTable from "./ReportsTable";
import SelectProjectModal from "./SelectProjectModal";
import FavouriteReportActions from "./FavouriteReportActions";
import {FORMAT_OPTIONS, TYPE_OPTIONS,} from "../../views/favoriteReport/fields/FavouriteReportFields";
import {usePermissions} from "../../contexts/permissions";
import GenerateReport from "src/views/favoriteReport/GenerateReport";

const styleLabels = {
  landscape: "Landscape",
  portrait: "Portrait",
};

const pageSizeLabels = {
  "8.5x11": `Letter (8.5"x11")`,
  "11x17": `Tabloid (11"x17")`,
};

const reportFormatValues = Object.fromEntries(TYPE_OPTIONS);
const reportTypeValues = Object.fromEntries(FORMAT_OPTIONS);

export const adminConfiguration = {
  key: "templates",
  title: "Report Templates",
  newLink: {
    title: "New Template",
    path: () => `/projects/0/templates/new`,
  },
  fields: [
    {
      label: "Report Template Name",
      path: "payload.name",
    },
    /*
    {
      label: "Page Orientation",
      path: "styleLabel",
    },
    {
      label: "Page Size",
      path: "pageSizeLabel",
    },
    {
      label: "Report Format",
      path: "payload.type",
      component: ({ value }) => <span>{reportFormatValues[value]}</span>,
    },
    {
      label: "Report Type",
      path: "payload.format",
      component: ({ value }) => <span>{reportTypeValues[value]}</span>,
    },
    */
  ],
  nestedTable: {
    key: "reports",
    title: "Report History",
    arrowTooltip: "Expand History",
    component: ReportsTable,
  },
  customLink: FavouriteReportActions,
};

export const configuration = {
  key: "templates",
  title: "Report Templates",
  fields: [
    {
      label: "Report Template Name",
      path: "payload.name",
    },
  ],
  customLink: GenerateReport,
};

const reportClientId = "reportClientId";
const reportProjectId = "reportProjectId";

export default function PageContent() {
  const { user } = usePermissions();
  const defaultClientId = localStorage.getItem(reportClientId);
  const defaultProjectId = localStorage.getItem(reportProjectId);
  const [selectedClientId, setSelectedClientId] = useState(defaultClientId);
  const [selectedProjectId, setSelectedProjectId] = useState(defaultProjectId);
  const history = useHistory();
  const isAdmin = user.role === "admin";
  const { id } = useParams();
  const { data: { favoriteReports = [] } = {}, loading, refetch } = useQuery(
    FAVORITE_REPORTS_QUERY,
    {
      variables: {
        selectedClientId,
        selectedProjectId,
      },
      fetchPolicy: "no-cache",
    }
  );

  const reports = favoriteReports.map(v => ({
    ...v,
    styleLabel: styleLabels[v.payload.style],
    pageSizeLabel: pageSizeLabels[v.payload.page_size],
  }));

  const list = selectedClientId
    ? reports.filter(report =>
        report.notifications.some(notification =>
          notification?.client?.id === selectedClientId && selectedProjectId === "all"
            ? true
            : notification?.project?.id === selectedProjectId
        )
      )
    : reports;
  const [reportModal, setReportModal] = useState(false);
  const [selectedReport] = useState(null);

  const toggleReportModal = () => setReportModal(v => !v);

  const newReportTemplate = ({ projectId }) => {
    history.push(`/projects/${projectId}/templates/new`);
  };

  /*
  useEffect(() => {
    localStorage.setItem(reportClientId, selectedClientId || "");
    localStorage.setItem(reportProjectId, selectedProjectId || "");
  }, [selectedClientId, selectedProjectId]);
  */

  const handleChangeClient = newClientId => {
    setSelectedClientId(newClientId);
    setSelectedProjectId("all");
  };

  return (
    <ClientsProvider>
      <Box position="relative">
        <Box
          top={3}
          left={300}
          mb={{ xs: 2, md: 0 }}
          position={{ md: "absolute" }}
          width={{ md: "70%" }}
        >
          {/*
          {isAdmin && (
            <FilterNav
              addAllOption
              onSelectClient={handleChangeClient}
              clientId={selectedClientId}
              projectId={selectedProjectId}
              onSelectProject={({ projectId }) => setSelectedProjectId(projectId)}
              hideActions
            />
          )}
          */}
        </Box>
        <TableEntities
          loading={loading}
          entities={list}
          rowsPerPage={25}
          setEntities={refetch}
          configuration={isAdmin ? adminConfiguration : configuration}
          sort={{ order: "payload.name" }}
        />
        {reportModal && selectedReport && (
          <GenerateReportModal
            projectId={selectedReport.projectId}
            favoriteReportId={selectedReport.id}
            title={selectedReport.name}
            onSubmit={toggleReportModal}
            onClose={toggleReportModal}
          />
        )}
        {id && id === "new" && (
          <SelectProjectModal
            title="New Report Template"
            buttonLabel="Next"
            onSubmit={newReportTemplate}
            onClose={() => history.push("/insights")}
          />
        )}
      </Box>
    </ClientsProvider>
  );
}
