import React, {useEffect, useMemo} from "react";
import {useField} from "formik";

import useAuth from "../../../hooks/useAuth";
import constLabels from "../../../constants/displayFields";
import {getDisplayFields} from "../../../utils/asset";
import {useFavoriteReportFields} from "../../../contexts/FavoriteReportFields";
import useFilterFields from "../../../views/favoriteReport/fields/useFilterFields";
import DraggableTags from "../DraggableTags";

const ColumnOrderField = () => {
  const { user } = useAuth();
  const { toggleField } = useFavoriteReportFields();
  const [include, , includeHelpers] = useField("include");
  const [descriptiveInfo, , descriptiveInfoHelpers] = useField("descriptive_info");
  const [originInfo, , originInfoHelpers] = useField("origin_info");
  const [destinationInfo, , destinationInfoHelpers] = useField("destination_info");
  const [warehouseInfo, , warehouseInfoHelpers] = useField("warehouse_info");
  const [electricalInfo, , electricalInfoHelpers] = useField("electrical_info");
  const [plumbingInfo, , plumbingInfoHelpers] = useField("plumbing_info");
  const [mechanicalInfo, , mechanicalInfoHelpers] = useField("mechanical_info");
  const [otherInfo, , otherInfoHelpers] = useField("other_info");
  const [columnOrder, , columnOrderHelpers] = useField("column_order");
  const [inventoryItems, , inventoryItemsHelpers] = useField("inventory_items");
  const [orders, , ordersHelpers] = useField("orders");
  const [reservation, , reservationHelpers] = useField("reservation");
  const filterFields = useFilterFields();
  const filterFieldsObject = Object.fromEntries(filterFields);
  const labels = {
    ...constLabels,
    ...filterFieldsObject,
    barcode_number: filterFieldsObject.barcode,
  };

  const fields = useMemo(
    () =>
      getDisplayFields({
        include: include.value,
        descriptive_info: descriptiveInfo.value,
        origin_info: originInfo.value,
        destination_info: destinationInfo.value,
        warehouse_info: warehouseInfo.value,
        electrical_info: electricalInfo.value,
        plumbing_info: plumbingInfo.value,
        mechanical_info: mechanicalInfo.value,
        other_info: otherInfo.value,
        inventory_items: inventoryItems.value,
        orders: orders.value,
        reservation: reservation.value,
      }),
    [
      include.value,
      descriptiveInfo.value,
      originInfo.value,
      destinationInfo.value,
      warehouseInfo.value,
      electricalInfo.value,
      plumbingInfo.value,
      mechanicalInfo.value,
      otherInfo.value,
      inventoryItems.value,
      orders.value,
      reservation.value,
    ]
  );

  useEffect(() => {
    const newFields = fields.filter(v => !columnOrder.value.includes(v));
    const deleteFields = columnOrder.value.filter(v => !fields.includes(v));

    if (newFields.length > 0) {
      columnOrderHelpers.setValue(columnOrder.value.concat(newFields));
    }

    if (deleteFields.length > 0) {
      columnOrderHelpers.setValue(columnOrder.value.filter(v => !deleteFields.includes(v)));
    }
  }, [fields]);

  const handleChangeTags = tags => {
    columnOrderHelpers.setValue(tags.map(v => v.id));
  };

  const handleRemove = id => {
    toggleField(id, false);

    if (include?.value?.hasOwnProperty(id)) {
      includeHelpers.setValue({ ...include.value, [id]: false });
    }
    if (descriptiveInfo?.value?.hasOwnProperty(id)) {
      descriptiveInfoHelpers.setValue({
        ...descriptiveInfo.value,
        [id]: false,
      });
    }
    if (originInfo?.value?.hasOwnProperty(id)) {
      originInfoHelpers.setValue({ ...originInfo.value, [id]: false });
    }
    if (warehouseInfo?.value?.hasOwnProperty(id)) {
      warehouseInfoHelpers.setValue({ ...warehouseInfo.value, [id]: false });
    }
    if (electricalInfo?.value?.hasOwnProperty(id)) {
      electricalInfoHelpers.setValue({ ...electricalInfo.value, [id]: false });
    }
    if (plumbingInfo?.value?.hasOwnProperty(id)) {
      plumbingInfoHelpers.setValue({ ...plumbingInfo.value, [id]: false });
    }
    if (mechanicalInfo?.value?.hasOwnProperty(id)) {
      mechanicalInfoHelpers.setValue({ ...mechanicalInfo.value, [id]: false });
    }
    if (otherInfo?.value?.hasOwnProperty(id)) {
      otherInfoHelpers.setValue({ ...otherInfo.value, [id]: false });
    }
    if (inventoryItems?.value?.hasOwnProperty(id)) {
      inventoryItemsHelpers.setValue({ ...inventoryItems.value, [id]: false });
    }
    if (orders?.value?.hasOwnProperty(id)) {
      ordersHelpers.setValue({ ...inventoryItems.value, [id]: false });
    }
    if (reservation?.value?.hasOwnProperty(id)) {
      reservationHelpers.setValue({ ...inventoryItems.value, [id]: false });
    }
    if (destinationInfo?.value?.hasOwnProperty(id)) {
      destinationInfoHelpers.setValue({
        ...destinationInfo.value,
        [id]: false,
      });
    }
  };

  const tags = columnOrder.value.map(id => ({
    id,
    content: labels[id],
  }));

  return (
    tags.length > 0 && (
      <DraggableTags tags={tags} onRemove={handleRemove} onChange={handleChangeTags} />
    )
  );
};

export default ColumnOrderField;
