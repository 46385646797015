import React from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

import Input from "../../../components/form/Input";
import useAuth from "../../../hooks/useAuth";
import useWarehouseLocations from "../../../hooks/warehouse/useWarehouseLocations";
import StorageLocationAutocomplete from "./StorageLocationAutocomplete/StorageLocationAutocomplete";
import Actions from "./Actions";

const schema = Yup.object().shape({
  rackLocations: Yup.array().of(
    Yup.object().shape({
      rack_location: Yup.string().required("Required"),
    })
  ),
});

export default function RackLocationsForm({ asset, toggleModal, rackLocations, onSubmit }) {
  const { user } = useAuth();
  const [edit, setEdit] = React.useState(false);
  const { warehouseLocations, loading: loadingWarehouseLocations } = useWarehouseLocations(
    asset.client.id
  );

  const toggleEdit = () => setEdit(v => !v);

  return (
    <Formik initialValues={{ rackLocations }} onSubmit={onSubmit} validationSchema={schema}>
      {({ values, handleSubmit, setFieldValue }) => (
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            {values.rackLocations.map((location, index) => (
              <React.Fragment key={index}>
                <Grid item sm={7}>
                  {user.company.settings.warehouse_restock_modal ? (
                    <StorageLocationAutocomplete
                      index={index}
                      disabled={!edit}
                      name="rackLocations"
                      value={values.rackLocations[index]}
                      loading={loadingWarehouseLocations}
                      warehouseLocations={warehouseLocations}
                      setFieldValue={setFieldValue}
                    />
                  ) : (
                    <Input
                      key={edit}
                      disabled={!edit}
                      name={`rackLocations[${index}].rack_location[0]`}
                        label={`Storage Location ${index + 1}`}
                    />
                  )}
                </Grid>
                <Grid item sm={5}>
                  <Input
                    disabled
                    name={`rackLocations[${index}].sub_qty`}
                      label={`Sub QTY ${index + 1}`}
                  />
                </Grid>
              </React.Fragment>
            ))}
          </Grid>
          <Box mt={4} />
          <Actions edit={edit} toggleModal={toggleModal} toggleEdit={toggleEdit} />
        </form>
      )}
    </Formik>
  );
}
