import { useState, useEffect } from "react";

const headerPadding = 64;
const footerPadding = 29;

function getWindowDimensions(containerRef) {
  const { innerWidth: width, innerHeight: height } = window;
  const topHeight =
    containerRef.current?.getBoundingClientRect()?.top || height;

  return {
    width,
    height: height - topHeight - footerPadding,
  };
}

function getWindowDimensionsSticky(containerRef) {
  const { innerWidth: width, innerHeight: height } = window;
  const topHeight = containerRef.current?.offsetTop || height;

  return {
    width,
    height: height - topHeight - footerPadding - headerPadding,
  };
}

export default function useWindowDimensions(containerRef, cache = []) {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions(containerRef)
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions(containerRef));
    }

    handleResize();

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [containerRef.current?.getBoundingClientRect()?.top, ...cache]);

  return windowDimensions;
}

export const useWindowDimensionsSticky = (containerRef, cache = []) => {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensionsSticky(containerRef)
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensionsSticky(containerRef));
    }

    handleResize();

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [containerRef.current?.getBoundingClientRect()?.top, ...cache]);

  return windowDimensions;
};
