import React from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import SvgIcon from "@material-ui/core/SvgIcon";
import TextField from "@material-ui/core/TextField";
import {makeStyles} from "@material-ui/core";
import {Minus as MinusIcon, Plus as PlusIcon} from "react-feather";

const useStyles = makeStyles(theme => ({
  quantityInput: ({ size }) => ({
    width: size == "medium" ? "90px" : "70px",
    "& input":
      size == "small"
        ? {
            fontSize: "0.875rem",
            padding: "10px 6px",
            height: "10px",
          }
        : {},
  }),
  quantityButtonMinus: ({ size }) => ({
    minWidth: size == "medium" ? "40px" : "20px",
    height: size == "medium" ? "40px" : "30px",
    marginRight: "-1px",
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    "& svg":
      size == "small"
        ? {
            fontSize: "0.875rem",
          }
        : {},
  }),
  quantityButtonPlus: ({ size }) => ({
    minWidth: size == "medium" ? "40px" : "20px",
    height: size == "medium" ? "40px" : "30px",
    marginLeft: "-1px",
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    "& svg":
      size == "small"
        ? {
            fontSize: "0.875rem",
          }
        : {},
  }),
}));

const AssetQuantityField = ({
  disabled,
  quantity,
  onChange,
  size = "medium",
  quantityProps = {},
  displayButtons = true,
}) => {
  const classes = useStyles({ size });

  const incrementQuqantity = () => {
    const newQuantity = quantity ? parseInt(quantity) + 1 : 1;
    onChange(newQuantity);
  };

  const decrementQuqantity = () => {
    const newQuantity = quantity ? parseInt(quantity) - 1 : -1;
    newQuantity >= 0 && onChange(newQuantity);
  };

  return (
    <FormControl>
      <Box>
        {displayButtons && (
          <Button
            disabled={disabled}
            className={classes.quantityButtonMinus}
            variant="outlined"
            size="small"
            onClick={decrementQuqantity}
          >
            <SvgIcon fontSize="small">
              <MinusIcon />
            </SvgIcon>
          </Button>
        )}
        <TextField
          disabled={disabled}
          className={classes.quantityInput}
          color="secondary"
          variant="outlined"
          fullWidth
          type="number"
          size="small"
          inputProps={{ min: 0, step: 1 }}
          onChange={event => onChange(parseInt(event.target.value, 10))}
          value={quantity || ""}
          {...quantityProps}
        />
        {displayButtons && (
          <Button
            disabled={disabled}
            className={classes.quantityButtonPlus}
            variant="outlined"
            size="small"
            onClick={incrementQuqantity}
          >
            <SvgIcon fontSize="small">
              <PlusIcon />
            </SvgIcon>
          </Button>
        )}
      </Box>
    </FormControl>
  );
};

export default AssetQuantityField;
