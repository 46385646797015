import React, {useState} from "react";
import Webcam from "react-webcam";
import IconButton from "@mui/material/IconButton";
import Box from "@material-ui/core/Box";
import styled from "@emotion/styled";

import SwitchCameraButton from "../../components/SwitchCameraButton";
import CloseCameraButton from "../../components/CloseCameraButton";

export const CameraContainer = styled.div`
  position: fixed;
  display: flex;
  z-index: 9999;
  width: 100%;
  background: #1b262c;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  div {
    width: 100%;
    video {
      display: flex;
      min-width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  video {
    width: 100%;
    max-width: 100%;
  }
`;

const FACING_MODES = {
  USER: "user",
  ENVIRONMENT: "environment",
};

export default function Camera({ takePhoto, toggleOpen }) {
  const [facingMode, setFacingMode] = useState(FACING_MODES.ENVIRONMENT);

  const videoConstraints = {
    height: { ideal: 99999 },
    width: { ideal: 9999 },
    facingMode,
  };

  const toggleFacingMode = () =>
    setFacingMode(
      facingMode === FACING_MODES.ENVIRONMENT ? FACING_MODES.USER : FACING_MODES.ENVIRONMENT
    );

  return (
    <CameraContainer>
      <Webcam audio={false} screenshotFormat="image/jpeg" videoConstraints={videoConstraints}>
        {({ getScreenshot }) => (
          <IconButton
            style={{ position: "absolute", left: "calc(50% - 29px)", bottom: "50px", zIndex: 99 }}
            onClick={e => {
              e.preventDefault();
              const imageSrc = getScreenshot();
              takePhoto(imageSrc);
            }}
          >
            <Box p="4px" border="3px solid white" borderRadius="50%" minWidth={58} minHeight={58}>
              <Box p="4px" bgcolor="white" borderRadius="50%" minWidth={50} minHeight={50}></Box>
            </Box>
          </IconButton>
        )}
      </Webcam>
      <SwitchCameraButton onClick={toggleFacingMode} />
      <CloseCameraButton onClick={toggleOpen} />
    </CameraContainer>
  );
}
