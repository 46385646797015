import React from "react";
import Typography from "@material-ui/core/Typography";

export default function Label({ children }) {
  return (
    <Typography component="span" color="textPrimary" style={{ fontWeight: 500 }}>
      {children}
    </Typography>
  );
}
