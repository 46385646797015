import React from "react";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";

import ActionModal from "../../components/modals/action-modal";
import InviteForm from "./InviteForm";

export default function ScannerModal({ client, onClose, onSuccess }) {
  return (
    <ActionModal
      open
      id="inviteUserModal"
      title="Invite User"
      maxWidth={440}
      onClose={onClose}
      disableTransition
    >
      <Box mt={3} mb={1.5}>
        <InviteForm client={client} onSuccess={onSuccess} />
      </Box>
    </ActionModal>
  );
}
