import React, {useContext} from "react";
import Box from "@mui/material/Box";
import {useQuery} from "@apollo/react-hooks";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";
import Backdrop from "@mui/material/Backdrop";
import LinearProgress from "@mui/material/LinearProgress";

import LocationInfo from "./LocationInfo";
import LocationSnapshot from "./LocationSnapshot";
import {WAREHOUSE_LOCATION} from "../../../../constants/graphql/queries";
import {SelectLocationContext} from "../../../../views/capture/RackLocations/WarehouseModal";
import {WarehouseContext} from "../../../..//views/warehouse/WarehouseList";

const DisableTransitionComponent = ({ children }) => children;

export default function EditLocationDetails({ entity, value, onClose }) {
  const selectLocation = useContext(SelectLocationContext);
  const warehouse = useContext(WarehouseContext);
  const { data: { warehouseLocation = {} } = {}, loading } = useQuery(WAREHOUSE_LOCATION, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-first",
    variables: { id: entity.id },
  });

  return (
    <>
      <Box underline="none" sx={{ cursor: "pointer" }} color="primary.main" className="unfocus">
        {value || ""}
      </Box>
      <Dialog
        open
        fullWidth
        maxWidth="md"
        onClose={onClose}
        TransitionComponent={DisableTransitionComponent}
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: { sx: { transition: "none !important" } },
        }}
      >
        <DialogTitle
          sx={{ background: "#f4f6f8", display: "flex", justifyContent: "space-between" }}
        >
          <Box width="48px" />
          <Typography fontSize={24} fontWeight={500} color="textPrimary">
            Location Details
          </Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ background: "#f4f6f8" }}>
          {loading ? (
            <LinearProgress />
          ) : (
            <>
              <LocationInfo item={warehouseLocation} />
              <Box mt={2} />
            </>
          )}
          <LocationSnapshot entity={entity} />
          <Box mt={2} display="flex" justifyContent="flex-end">
            {selectLocation ? (
              <Button
                variant="contained"
                onClick={() =>
                  selectLocation({
                    ...warehouseLocation,
                    warehouseId: warehouse.warehouseId,
                  })
                }
              >
                Select Location
              </Button>
            ) : (
              <Button variant="outlined" onClick={onClose}>
                Close
              </Button>
            )}
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}
