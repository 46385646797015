import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Box, Grid, makeStyles, SvgIcon, Typography } from "@material-ui/core";
import Button from "@mui/material/Button";
import { PlusCircle as PlusCircleIcon } from "react-feather";
import { usePermissions } from "src/contexts/permissions";

const useStyles = makeStyles(theme => ({
  root: {},
  action: {
    marginBottom: theme.spacing(1),
    "& + &": {
      marginLeft: theme.spacing(1),
    },
  },
}));

const Header = ({ configuration }) => {
  const classes = useStyles();
  const { can } = usePermissions();
  const canWrite = configuration.accessKey ? can(`${configuration.accessKey}.write`) : true;

  return (
    <Grid container spacing={1}>
      <Grid item container xs={6}>
        <Box mb={3}>
          <Typography variant="h2" color="textSecondary">
            {configuration.title || "Table"}
          </Typography>
        </Box>
      </Grid>
      <Grid item container xs={6}>
        <Grid container spacing={3} justify="flex-end" className={classes.root}>
          {configuration.newLink && configuration.newLink.path && canWrite && (
            <Grid item>
              <Button
                id={`newLink-${configuration.key}`}
                color="primary"
                variant="contained"
                component={RouterLink}
                to={configuration.newLink.path()}
                className={classes.action}
                startIcon={
                  <SvgIcon fontSize="small">
                    <PlusCircleIcon />
                  </SvgIcon>
                }
              >
                {configuration.newLink.title || "New Record"}
              </Button>
            </Grid>
          )}
          {configuration.newLink && configuration.newLink.onClick && canWrite && (
            <Grid item>
              <Button
                id={`newButton-${configuration.key}`}
                color="primary"
                variant="contained"
                onClick={configuration.newLink.onClick}
                className={classes.action}
                startIcon={
                  <SvgIcon fontSize="small">
                    <PlusCircleIcon />
                  </SvgIcon>
                }
              >
                {configuration.newLink.title || "New Record"}
              </Button>
            </Grid>
          )}
          {configuration.customActionComponent && (
            <Grid item>
              <configuration.customActionComponent className={classes.action} />
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Header;
