import React, {useState} from "react";
import camelCase from "lodash/camelCase";
import {makeStyles} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Skeleton from "@material-ui/lab/Skeleton";

import ConfirmDeleteModal from "../../components/images/ConfirmDeleteModal";
import {useCapture} from "../../contexts/capture";
import {getBackgroundImage} from "../../utils/images";
import AssetItemLabel from "./AssetItemLabel";

const useStyles = makeStyles(theme => ({
  root: props => ({
    display: "flex",
    justifyContent: "flex-start",
    border: 0,
    boxSizing: "border-box",
    borderBottom: "1px solid #DDDBDA",
    background: props.selected ? "#f4f6f8" : "white",
    height: 58,
    padding: 0,
    paddingTop: 0,
    paddingBottom: 0,
    textAlign: "left",
    maxWidth: "100%",
    overflow: "hidden",
    borderRadius: 0,
    textTransform: "none",
  }),
  imageContainer: {
    display: "flex",
    overflow: "auto",
    "-ms-overflow-style": "none",
  },
}));

export const AssetItemStub = () => {
  const classes = useStyles();

  return (
    <Box mt={1}>
      <Button fullWidth className={classes.root} variant="outlined" onClick={() => {}}>
        <Grid container spacing={1} alignItems="center">
          <Grid item xs={6} container wrap="nowrap" justify="flex-start">
            <Skeleton variant="rect" height="20" width="100%" />
          </Grid>
          <Grid item xs={6} container wrap="nowrap" justify="flex-end" alignItems="center">
            <Skeleton variant="rect" height="20" width="30%" />
          </Grid>
        </Grid>
      </Button>
    </Box>
  );
};

export default function AssetItem({
  currentAsset,
  setCurrentAsset,
  onRemove,
  onOpenImageModal,
  asset,
  onSelectImage,
  bulkProps,
}) {
  const { order } = useCapture();
  const [deleteAsset, setDeleteAsset] = useState(false);
  const selected = currentAsset?.id === asset.id;
  const classes = useStyles({ selected });
  const Checkbox = bulkProps.component;
  const selectedField = order ? camelCase(order.split(".")[0]) : undefined;

  const handleDelete = () =>
    onRemove({
      variables: {
        id: asset.id,
        projectId: asset.projectId,
      },
    });

  const handleToggleConfirmDeleteModal = () => setDeleteAsset(!deleteAsset);

  const image =
    asset.images?.length > 0 ? asset.images.find(v => v.default) || asset.images[0] : null;

  return (
    <>
      <Box>
        <Button
          id={`assetItem-${asset.id}`}
          fullWidth
          className={classes.root}
          variant="outlined"
          onClick={() => setCurrentAsset(asset)}
        >
          <Checkbox entity={asset} />
          <Box
            ml={2}
            width={67}
            minWidth={67}
            height={50}
            minHeight={50}
            borderRadius="4px"
            bgcolor="#f4f6f8"
            sx={{
              backgroundImage: getBackgroundImage(image?.url, "width", 120),
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain",
              backgroundPosition: "center",
            }}
            onClick={e => {
              e.stopPropagation();
              setCurrentAsset(asset);
              onSelectImage(image.id);
              onOpenImageModal(asset.id);
            }}
          />
          <Box ml="22px" overflow="hidden" display="flex" flexDirection="column" flexGrow={1}>
            {selectedField && <AssetItemLabel asset={asset} />}
            {selectedField !== "itemId" && (
              <Typography lineHeight={1} variant="body2">
                {asset.itemId}
              </Typography>
            )}
            <Box width="100%" maxWidth="100%">
              <Typography
                lineHeight={1}
                style={{
                  width: "100%",
                  maxWidth: "100%",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  paddingBottom: "2px",
                }}
                component="div"
                variant="body2"
              >
                {asset.description}
              </Typography>
            </Box>
          </Box>
        </Button>
      </Box>
      {deleteAsset && (
        <ConfirmDeleteModal
          title="Delete Asset"
          descr="Are you sure you want to delete asset?"
          onClose={handleToggleConfirmDeleteModal}
          onDelete={handleDelete}
        />
      )}
    </>
  );
}
