export const isAdminUser = email =>
  [
    "shawn@layer3dev.com",
    "guadalupe@visionsmgmt.com",
    "admin@visions.com",
    "jordan@layer3dev.com",
    "daniel.lopez@holitna.com",
    "isaac.padilla@holitna.com",
    "ryan.dayal@holitna.com",
    "tiffany.masura@holitna.com",
  ].includes(email.toLowerCase());
