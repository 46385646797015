import React from "react";
import { CircularProgress } from "@material-ui/core";

const FullPageLoader = () => (
  <div className="d-flex flex-column flex-grow-1 align-items-center justify-content-center">
    <CircularProgress />
  </div>
);

export default FullPageLoader;
