import camelCase from "lodash/camelCase";

const toTitleCase = (text) => {
  const result = text.replace(/([A-Z])/g, " $1");
  return result.charAt(0).toUpperCase() + result.slice(1);
};

// This method converts snake case attributes into CamelCase
// Example: notes_dimension[1][another_attributes] => notesDimension[1][anotherAttributes]
const parseFieldName = (name) =>
  name
    .replace(/\[/g, " ")
    .replace(/\]/g, " ")
    .split(" ")
    .map(_.trim)
    .filter(_.trim)
    .map(camelCase)
    .join(".");

export const toString = (errors) => {
  if (typeof errors !== "object" || errors === null) {
    return errors;
  }

  const parsedErrors = Object.keys(errors).reduce((result, errorKey) => {
    return [`${toTitleCase(errorKey)} ${errors[errorKey]}`, ...result];
  }, []);

  return parsedErrors.join(", ");
};

export const mapKeys = (errors, mapper) =>
  Object.keys(errors).reduce((result, errorKey) => {
    const field = mapper[errorKey] || errorKey;

    result[field] = errors[errorKey];

    return result;
  }, {});

export default (errors) =>
  errors.reduce((result, error) => {
    const field =
      error.field === "base" ? "submit" : parseFieldName(error.field);
    result[field] = field === "submit" ? error.message : `${error.message}`;

    return result;
  }, {});
