import React, {useCallback, useRef} from "react";
import groupBy from "lodash/groupBy";
import orderBy from "lodash/orderBy";
import styled from "@emotion/styled";
import ButtonBase from "@material-ui/core/ButtonBase";
import FlipCameraIos from "@material-ui/icons/FlipCameraIos";


import CloseCameraButton from "../CloseCameraButton";
import {useQuagga} from "./useQuaggaHook";

export const CameraContainer = styled.div`
  position: fixed;
  display: flex;
  z-index: 1999;
  width: 100%;
  background: #1b262c;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  video {
    display: flex;
    min-width: 100%;
    height: 100%;
  }
`;

const Scanner = ({ onDetected, onClose }) => {
  const codes = useRef([]);

  const handleSubmit = useCallback(
    code => {
      onDetected(code);
      stopQuagga();
    },
    [onDetected]
  );

  const handleDetect = useCallback(
    result => {
      const { code } = result.codeResult;
      const groupCodes = Object.entries(groupBy(codes.current));
      const sortedCoeds = orderBy(groupCodes, v => v[1].length, "desc");
      const [bestCodes = []] = sortedCoeds;
      const [bestCode, bestCodeList] = bestCodes || [];

      if (codes.current.length >= 2 && bestCodeList.length > codes.current.length * 0.8) {
        codes.current = [];
        return handleSubmit(bestCode);
      }
      codes.current.push(code);
    },
    [handleSubmit, codes]
  );

  const [stopQuagga, toggleMode] = useQuagga(handleDetect);

  return (
    <CameraContainer id="interactive" className="viewport">
      <CloseCameraButton onClick={onClose} />
      <ButtonBase
        onClick={toggleMode}
        style={{ position: "absolute", left: 15, top: 15, zIndex: 99 }}
      >
        <FlipCameraIos htmlColor="white" fontSize="large" />
      </ButtonBase>
    </CameraContainer>
  );
};

export default Scanner;
