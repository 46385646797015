import React from "react";
import {useHistory} from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";
import SelectImpactedItem from "./SelectImpactedItem";
import useQueryParams from "../../../../hooks/useQueryParams";

export default function OSAndDType({ shipment, onClose }) {
  const params = useQueryParams();
  const history = useHistory();
  const osdType = params.get("osdType");

  const handleSelect = value => {
    const pathname = window.location.pathname;
    const params = new URLSearchParams(location.search);
    params.set("osdType", value);
    history.push({ pathname, search: params.toString() });
  };

  const handleClose = () => {
    const pathname = window.location.pathname;
    const params = new URLSearchParams(location.search);
    params.delete("osdType");
    history.push({ pathname, search: params.toString() });
  };

  return (
    <Dialog
      open
      fullWidth
      PaperProps={{
        style: { width: 300 },
      }}
      maxWidth={false}
      onClose={onClose}
    >
      <DialogTitle sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box width="48px" />
        <Typography fontSize={24} fontWeight={500} color="textPrimary">
          OS&D Type
        </Typography>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box mb={4} display="flex" flexDirection="column" px={4}>
          <Button sx={{ mt: 2 }} variant="outlined" onClick={() => handleSelect("Overage")}>
            Overage
          </Button>
          <Button sx={{ mt: 5 }} variant="outlined" onClick={() => handleSelect("Shortage")}>
            Shortage
          </Button>
          <Button sx={{ mt: 5 }} variant="outlined" onClick={() => handleSelect("Damage")}>
            Damage
          </Button>
        </Box>
      </DialogContent>
      {osdType && <SelectImpactedItem type={osdType} shipment={shipment} onClose={handleClose} />}
    </Dialog>
  );
}
