import React, {useState} from "react";
import {Box, Input, makeStyles, Paper} from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";

import AdvancedSearchButton from "src/components/AdvancedSearch/AdvancedSearchButton";
import AdvancedSearch from "src/components/AdvancedSearch";

const useStyles = makeStyles(theme => ({
  searchContainer: {
    position: "relative",
  },
  searchInput: {
    minWidth: 400,
    padding: `${theme.spacing(0.5)}px ${theme.spacing(2)}px`,
  },
  button: {
    marginTop: 4,
  },
}));

export default function TableSearch({ placeholder, onChange, value: initialValue, disabled }) {
  const classes = useStyles();
  const [value, setValue] = useState(initialValue);
  const [showAdvancedSearch, setShowAdvancedSearch] = useState(false);
  const [showAdvancedSearchButton, setShowAdvancedSearchButton] = useState(false);

  const handleInputKeyup = event => {
    event.persist();

    if (event.keyCode === 13 && event.target.value) {
      setValue(event.target.value);
    }
  };

  const handleInputChange = event => {
    event.persist();
    setValue(event.target.value);
  };

  const handleSubmit = event => {
    event.preventDefault();
    onChange(value);
  };

  const handleAdvancedSearchSubmit = value => {
    setValue(value);
    onChange(value);
    setShowAdvancedSearch(false);
  };

  const handleClear = () => {
    setValue("");
    onChange("");
  };

  return (
    <Box className={classes.searchContainer}>
      <Paper>
        <form onSubmit={handleSubmit}>
          <Input
            className={classes.searchInput}
            name="tableSearch"
            inputProps={{
              "data-skip-navigation-effect": true,
            }}
            disableUnderline
            fullWidth
            onChange={handleInputChange}
            onKeyUp={handleInputKeyup}
            placeholder={placeholder || "Enter a keyword"}
            value={value || ""}
            disabled={disabled}
            endAdornment={
              <InputAdornment>
                {value && (
                  <IconButton onClick={handleClear}>
                    <CloseIcon />
                  </IconButton>
                )}
                <IconButton type="submit">
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            }
            onFocus={() => {
              // setShowAdvancedSearchButton(true);
            }}
            // a small delay before the button is hidden, ensuring that the click event has time to process first
            onBlur={() => setTimeout(() => setShowAdvancedSearchButton(false), 200)}
          />
        </form>
        {showAdvancedSearchButton && (
          <AdvancedSearchButton
            className={classes.button}
            onClick={() => {
              setShowAdvancedSearch(true);
            }}
          />
        )}
        {showAdvancedSearch && (
          <AdvancedSearch
            initialValue={value}
            onSearch={handleAdvancedSearchSubmit}
            onCancel={() => setShowAdvancedSearch(false)}
          />
        )}
      </Paper>
    </Box>
  );
}
