import React from "react";

import useAuth from "../../hooks/useAuth";
import {useAssetsCollection} from "../../contexts/assetsCollection";
import AvailableQuantityInput from "./AvailableQuantityInput";

export default function ScrubberAvailableQuantityInput({ display, asset, disabledForm }) {
  const { user } = useAuth();
  const { collection } = useAssetsCollection();
  const item = collection.find(v => v.id === asset.id);

  return item ? (
    <AvailableQuantityInput
      display={display}
      user={user}
      asset={item}
      disabledForm={disabledForm}
    />
  ) : null;
}
