import React, {useState} from "react";
import Button from "@material-ui/core/Button";
import ConfirmDeleteModal from "../../components/images/ConfirmDeleteModal";
import useNotification from "../../hooks/notification";
import {usePermissions} from "../../contexts/permissions";

export default function RemoveAssets({
  label = "Delete Assets",
  selectedIds,
  totalCount,
  onCompleteBulk,
  setDisabledScroll,
  bulkRemoveAssets,
}) {
  const { notifyError } = useNotification();
  const { can } = usePermissions();
  const isSelected = selectedIds.length > 0;
  const [showModal, setShowModal] = useState(false);

  const handleBulkRemove = () => {
    setDisabledScroll(true);
    bulkRemoveAssets({
      variables: { ids: selectedIds },
      onSuccess: () => {
        onCompleteBulk();
        setDisabledScroll(false);
        setShowModal(false);
      },
      onFailure: errors => {
        notifyError(errors);
        setDisabledScroll(false);
        setShowModal(false);
      },
    });
  };

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <>
      {isSelected && can("assets.remove") && (
        <>
          <Button size="small" variant="contained" color="primary" onClick={handleOpenModal}>
            {`${label} (${selectedIds.length || totalCount})`}
          </Button>
          {showModal && (
            <ConfirmDeleteModal
              title="Delete Assets"
              descr="Are you sure you want to delete the selected assets?"
              onClose={handleCloseModal}
              onDelete={handleBulkRemove}
            />
          )}
        </>
      )}
    </>
  );
}
