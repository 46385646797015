import React from "react";
import {FastField} from "formik";
import FormControl from "@material-ui/core/FormControl";
import ButtonGroup from "@mui/material/ButtonGroup";
import Button from "@mui/material/Button";

export default function ButtonGroupField({ options = [], ...props }) {
  return (
    <FormControl fullWidth variant="outlined">
      <FastField {...props}>
        {({ field, form }) => {
          return (
            <ButtonGroup
              sx
              variant="outlined"
              onClick={value => {
                form.setFieldValue(field.name, value.target.value);
              }}
            >
              {options.map(([value, label]) => (
                <Button
                  key={value}
                  value={value}
                  variant={field.value === value ? "contained" : "outlined"}
                  sx={{ textTransform: "none" }}
                >
                  {label}
                </Button>
              ))}
            </ButtonGroup>
          );
        }}
      </FastField>
    </FormControl>
  );
}
