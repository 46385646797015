import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Box from "@material-ui/core/Box";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import CloseIcon from "@material-ui/icons/Close";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";

import Attachments from "./Attachments";

export default function AttachmentModal({inventoryRequestId, onClose}) {
  return (
    <Dialog open fullWidth maxWidth="md" onClose={onClose}>
      <DialogTitle>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="h2" color="textPrimary" component="span">
            Attachments
          </Typography>
          <IconButton color="textPrimary" onClick={onClose}>
            <CloseIcon/>
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Attachments inventoryRequestId={inventoryRequestId}/>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
}
