import orderBy from "lodash/orderBy";

import {addHours} from "../views/shopping/Cart/CartItemFields";

function low(value) {
  return (value || "").toLowerCase();
}

export function searchInventory(inventoryRequests, search) {
  const list = orderBy(Object.values(inventoryRequests), "requestedAt", "desc");
  return search
    ? list.filter(v => {
        const designer = v.designer || "";
        const workOrderNumber = v.workOrderNumber || "";
        const requestUser = v?.user;
        const accessLinkTitle = requestUser.accessLink?.id
          ? requestUser.accessLink.name
            ? `${requestUser.accessLink.name}`
            : "Access link"
          : null;
        const email = accessLinkTitle
          ? v.email || accessLinkTitle
          : v.email
          ? v.email
          : requestUser.email;

        const isAssetId = v.inventoryItems.some(item => item.asset.assetId.includes(search));
        const isItemId = v.inventoryItems.some(item => item.asset.itemId.includes(search));
        const isDescription = v.inventoryItems.some(item =>
          low(item.asset.description).includes(low(search))
        );
        const isModel = v.inventoryItems.some(item => low(item.asset.model).includes(low(search)));
        const isIdsrNumber = v.inventoryItems.some(item =>
          low(item.idsrNumber).includes(low(search))
        );
        const isWorkOrderNumber = workOrderNumber.toLowerCase().includes(search.toLowerCase());
        const isEmail = email.toLowerCase().includes(search.toLowerCase());
        const isDesigner = designer.toLowerCase().includes(search.toLowerCase());
        return (
          isWorkOrderNumber ||
          isEmail ||
          isAssetId ||
          isItemId ||
          isDescription ||
          isModel ||
          isIdsrNumber ||
          isDesigner
        );
      })
    : list;
}

export function getDate(startDate, startTime) {
  const date = new Date(`${startDate.replaceAll("-", "/")} ${startTime}`);
  const year = date.getFullYear();
  const month = date.getMonth();
  const days = date.getDate();
  const hours = date.getHours();
  const minutes = date.getMinutes();
  return new Date(year, month, days, hours, minutes);
}

export function isTimeLessThan48Hours(input) {
  if (input.startDate && input.startTime) {
    const start = new Date(`${input.startDate.replaceAll("-", "/")} ${input.startTime}`);
    if (start.getTime() < addHours(48).getTime()) {
      return true;
    }
  }
  return false;
}

const PNC = "pnc.glimpseware.com";
const BOX = "box.glimpseware.com";
const ARMSTRONG = "armstrong.glimpseware.com";
const ATLAS = "atlas.glimpseware.com";
const SALESFORCE = "salesforce.glimpseware.com";
const DEMO = "demo.glimpseit.com";
const VA_SUBDOMAINS = ["va.glimpseware.com", "va.glimpseit.com", "va.test.com:3000"];

const isPNC = PNC === window.location.host;
const isATLAS = ATLAS === window.location.host;
const isARMSTRONG = ARMSTRONG === window.location.host;
const isDEMO = ARMSTRONG === window.location.host;

const isBoxDemo = [PNC, BOX, ATLAS, ARMSTRONG, SALESFORCE, DEMO].includes(window.location.host);
const isVaSubdomain = () => VA_SUBDOMAINS.includes(window.location.host);

export { isBoxDemo, isPNC, isATLAS, isARMSTRONG, isDEMO, isVaSubdomain };
