import React from "react";
import useGrab from "src/hooks/planner/useGrab";

export default function useDesktopPanAroundAndZoom(editor, setZoom, isDesktop, settings) {
  const [grabMode, setGrabMode] = React.useState(false);
  useGrab(editor, grabMode);

  React.useEffect(() => {
    const keyDown = event => {
      if (event.code === "Space") {
        const activeObject = editor.getActiveObject();
        if (activeObject && activeObject.isEditing) {
          // If a text box is being edited, do not toggle grab mode
          return;
        }

        if (editor.defaultCursor !== "pointer") {
          editor.defaultCursor = "pointer";
          editor.setCursor("pointer");
          setGrabMode(true);
        }
      }
    };

    const keyUp = event => {
      if (event.code === "Space") {
        editor.defaultCursor = "auto";
        editor.setCursor("auto");
        setGrabMode(false);
      }
    };

    document.addEventListener("keydown", keyDown);
    document.addEventListener("keyup", keyUp);

    return () => {
      document.removeEventListener("keydown", keyDown);
      document.removeEventListener("keyup", keyUp);
    };
  }, [editor]);

  React.useEffect(() => {
    if (editor && isDesktop) {
      let lastPosX = 0;
      let lastPosY = 0;

      function continuePan(e) {
        let vpt = editor.viewportTransform;
        if (vpt) {
          vpt[4] += e.clientX - lastPosX;
          vpt[5] += e.clientY - lastPosY;
        }
        editor.requestRenderAll();
        lastPosX = e.clientX;
        lastPosY = e.clientY;
      }

      const contextMenu = event => {
        if (event.preventDefault !== undefined) event.preventDefault();
        if (event.stopPropagation !== undefined) event.stopPropagation();
      };

      editor.on("mouse:down", event => {
        if (event.button === 3) { // Right-click
          document.addEventListener("contextmenu", contextMenu);
          lastPosX = event.e.clientX;
          lastPosY = event.e.clientY;
          editor.defaultCursor = "pointer";
          editor.setCursor("pointer");
          document.addEventListener("mousemove", continuePan);
        }
      });

      editor.on("mouse:up", event => {
        if (event.button === 3) { // Right-click
          document.removeEventListener("contextmenu", contextMenu);
          editor.defaultCursor = "auto";
          editor.setCursor("auto");
          document.removeEventListener("mousemove", continuePan);
        }
      });
    }
  }, [editor, isDesktop]);

  React.useEffect(() => {
    if (editor && isDesktop) {
      editor.on("mouse:wheel", function(opt) {
        opt.e.preventDefault();
        opt.e.stopPropagation();
        if (opt.e.ctrlKey) {
          const delta = opt.e.deltaY;
          let zoom = editor.getZoom();
          zoom *= 0.999 ** (delta * 10);
          if (zoom > 20) zoom = 20;
          if (zoom < 0.01) zoom = 0.01;
          const zoomPercentage = parseInt(zoom * 100, 10);
          if (zoomPercentage >= settings.zoomFrom && zoomPercentage <= settings.zoomTo) {
            editor.zoomToPoint({ x: opt.e.offsetX, y: opt.e.offsetY }, zoom);
            opt.e.preventDefault();
            opt.e.stopPropagation();
            setZoom(zoomPercentage);
          }
        } else {
          const e = opt.e;
          const vpt = this.viewportTransform;
          vpt[4] -= e.deltaX;
          vpt[5] -= e.deltaY;
          this.requestRenderAll();
        }
      });
    }
  }, [editor, setZoom, isDesktop]);
}
