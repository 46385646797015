import React, {useMemo} from "react";
import {useField} from "formik";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import PopupState, {bindMenu, bindTrigger} from "material-ui-popup-state";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import {useParams} from "react-router-dom";
import {useQuery} from "@apollo/react-hooks";

import Autocomplete from "../../../components/form/Autocomplete";
import Input from "../../../components/form/Input";
import movers from "../../../constants/movers";
import {LOOKUPS_QUERY} from "../../../constants/graphql/queries";
import displayFields, {
  benchFloorHoodWallBscOptions,
  exhHeaderOptions,
  localGasTanksOptions,
  nemaConfigOptions,
  phaseOptions,
  plugConnectionOptions,
  solveWasteCollOptions,
  voltsOptions,
} from "../../../constants/displayFields";
import {getOptions} from "src/utils/form";
import manufacturers from "src/constants/manufacturers";
import colors from "src/constants/colors";
import BooleanSelectField from "src/components/form/fields/BooleanSelectField";
import {warrantyOptions} from "src/views/capture/FormFields";
import dispositions from "src/constants/dispositions";
import useFilterFields from "src/views/favoriteReport/fields/useFilterFields";

const FilterFields = ({ name }) => {
  const fields = useFilterFields();
  const [field, , helpers] = useField({ name });
  const { projectId } = useParams();
  const { data = {} } = useQuery(LOOKUPS_QUERY, { variables: { projectId } });
  const labels = Object.fromEntries(fields);

  const fieldTypes = useMemo(() => ({
    disposition: {
      type: "select",
      label: "Filter By Disposition",
      options: getOptions(dispositions),
    },
    barcode: { type: "input", label: labels.barcode },
    origin_employee: { type: "input", label: "Filter By Orign Employee" },
    destination_employee: {
      type: "input",
      label: "Filter By Destination Employee",
    },
    checkout: {
      type: "select",
      label: "Filter By Mover",
      options: getOptions(movers),
    },
    bench_floor_hood_wall_bsc: {
      type: "select",
      label: displayFields.bench_floor_hood_wall_bsc,
      options: getOptions(benchFloorHoodWallBscOptions),
    },
    manufacturer: {
      type: "select",
      label: displayFields.manufacturer,
      options: getOptions(manufacturers),
    },
    color: {
      type: "select",
      label: displayFields.color,
      options: getOptions(colors),
    },
    warranty: {
      type: "select",
      label: displayFields.warranty,
      options: getOptions(warrantyOptions),
    },
    siesmic_anchor_required: { type: "boolean" },
    vendor_required: { type: "boolean" },
    volts: {
      type: "select",
      label: displayFields.volts,
      options: getOptions(voltsOptions),
    },
    phase: {
      type: "select",
      label: displayFields.phase,
      options: getOptions(phaseOptions),
    },
    nema_config: {
      type: "select",
      label: displayFields.nema_config,
      options: getOptions(nemaConfigOptions),
    },
    plug_connection: {
      type: "select",
      label: displayFields.plug_connection,
      options: getOptions(plugConnectionOptions),
    },
    local_gas_tanks: {
      type: "select",
      label: displayFields.local_gas_tanks,
      options: getOptions(localGasTanksOptions),
    },
    solve_waste_coll: {
      type: "select",
      label: displayFields.solve_waste_coll,
      options: getOptions(solveWasteCollOptions),
    },
    exh_header: {
      type: "select",
      label: displayFields.exh_header,
      options: getOptions(exhHeaderOptions),
    },
    standby_power: { type: "boolean" },
    normal_power: { type: "boolean" },
    dedicated_power: { type: "boolean" },
    ups: { type: "boolean" },
    standby_power_sb: { type: "boolean" },
    hse_co: { type: "boolean" },
    hse_vac: { type: "boolean" },
    local_vac_pump: { type: "boolean" },
    icw: { type: "boolean" },
    ihw: { type: "boolean" },
    pcw_supply: { type: "boolean" },
    pcw_return: { type: "boolean" },
    ro: { type: "boolean" },
    di: { type: "boolean" },
    floor_sink: { type: "boolean" },
    floor_drain: { type: "boolean" },
    vac_pump_vent_plumbing: { type: "boolean" },
    exh_ceiling_blast_gate: { type: "boolean" },
    exh_snorkel_arm_plumbing: { type: "boolean" },
    exhaust: { type: "boolean" },
    vac_pump_vent_mechanical: { type: "boolean" },
    exh_snorkel_arm_mechanical: { type: "boolean" },
  }));

  return (
    <>
      <PopupState variant="popover" popupId="add-filter">
        {popupState => (
          <div>
            <Button fullWidth variant="contained" color="primary" {...bindTrigger(popupState)}>
              Add Filter +
            </Button>
            <Menu
              {...bindMenu(popupState)}
              anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
              transformOrigin={{ vertical: "top", horizontal: "left" }}
            >
              {fields.map(([value, label]) => (
                <MenuItem
                  key={value}
                  onClick={() => {
                    helpers.setValue(field.value.concat({ type: value, value: "" }));
                    popupState.close();
                  }}
                >
                  {label}
                </MenuItem>
              ))}
            </Menu>
          </div>
        )}
      </PopupState>
      <Box mt={3} />
      {field.value.length > 0 ? (
        field.value.map((v, i) => {
          const { type, label, options } = fieldTypes[v.type] || {
            type: "input",
            label: labels[v.type],
          };
          return (
            <React.Fragment key={i}>
              {i > 0 && <Box mt={1} />}
              <Box display="flex" alignItems="center">
                {type === "select" ? (
                  <Autocomplete
                    name={`${name}.${i}.value`}
                    label={label || displayFields[v.type]}
                    options={options}
                    saveCustomOption
                  />
                ) : type === "boolean" ? (
                  <BooleanSelectField
                    name={`${name}.${i}.value`}
                    label={label || displayFields[v.type]}
                  />
                ) : type === "input" ? (
                  <Input name={`${name}.${i}.value`} label={label} />
                ) : null}
                <IconButton
                  id={`delete-${name}-${i}-value`}
                  variant="outlined"
                  onClick={() => helpers.setValue(field.value.filter((v, index) => i !== index))}
                >
                  <DeleteIcon color="action" />
                </IconButton>
              </Box>
            </React.Fragment>
          );
        })
      ) : (
        <Typography align="center" color="textSecondary">
          Empty
        </Typography>
      )}
    </>
  );
};

export default FilterFields;
