import React, {useCallback, useState} from "react";
import IconButton from "@material-ui/core/IconButton";
import {useMutation} from "@apollo/react-hooks";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import Tooltip from "@material-ui/core/Tooltip";

import {GENERATE_PROJECT_REPORT_MUTATION} from "../../constants/graphql";
import useNotification from "../../hooks/notification";
import SelectProjectModal from "./SelectProjectModal";
import SelectReceivingModal from "./SelectReceivingModal";
import {GENERATE_PROJECT_REPORT_BY_CLIENT_MUTATION} from "../../constants/graphql/mutations";
import {useBanner} from "../../contexts/banner";
import {adminConfiguration} from "./Page";

export default function GenerateReport({ entity, selectedClientId, selectedProjectId }) {
  const [open, setOpen] = useState(false);
  const [openReceiving, setOpenReceiving] = useState(false);
  const { notifySuccess, notifyError } = useNotification();
  const { displayBanner } = useBanner();
  const [generateProjectReport] = useMutation(GENERATE_PROJECT_REPORT_MUTATION);
  const [generateProjectReportByClient] = useMutation(GENERATE_PROJECT_REPORT_BY_CLIENT_MUTATION);
  const item = entity;

  const handleToggle = () => {
    if (item.payload.receiving_report) {
      setOpenReceiving(v => !v);
    } else {
      if (selectedClientId && selectedProjectId) {
        handleSubmitReport({ projectId: selectedProjectId, clientId: selectedClientId });
      } else {
        setOpen(v => !v);
      }
    }
  };

  const handleClose = () => {
    if (item.payload.receiving_report) {
      setOpenReceiving(false);
    } else {
      setOpen(false);
    }
  };

  const handleOpenRecords = id => {
    const element = document.getElementById(
      `nested-${adminConfiguration.key}-${id}-${adminConfiguration.nestedTable.key}`
    );
    if (element?.getAttribute("data-open") === "false") {
      element.click();
    }
  };

  const handleSubmitReport = useCallback(
    async ({ projectId, clientId, startDate, endDate, receivingNotes }) => {
      let filtersObject = {};
      const filters = item.payload.filters || [];

      filters.map(({ type, value }) => {
        if (!filtersObject[type]) filtersObject[type] = [];
        filtersObject[type] = [value, ...filtersObject[type]];
      });

      if (startDate) {
        filtersObject.start_date = startDate.format("MM/DD/YYYY");
      }

      if (endDate) {
        filtersObject.end_date = endDate.format("MM/DD/YYYY");
      }

      const generateReportQuery = projectId
        ? generateProjectReport({
            variables: {
              projectId,
              favoriteReportId: item.id,
              filters: filtersObject,
              receivingNotes,
            },
          })
        : generateProjectReportByClient({
            variables: {
              clientId,
              favoriteReportId: item.id,
              filters: filtersObject,
              receivingNotes,
            },
          });

      return generateReportQuery
        .then(() => {
          displayBanner({ message: "Generating file...", type: "report" });
          handleClose();
          handleOpenRecords(item.id);
        })
        .catch(e => {
          console.log(e);
          notifyError("Something went wrong");
        });
    },
    [notifySuccess, handleClose, notifyError]
  );

  return (
    <>
      <Tooltip arrow title="Generate Report">
        <IconButton onClick={handleToggle}>
          <AddCircleOutlineOutlinedIcon />
        </IconButton>
      </Tooltip>
      {open && (
        <SelectProjectModal
          selectedClientId={selectedClientId}
          selectedProjectId={selectedProjectId}
          title="Generate Report"
          description={`Template: ${item.payload.name}`}
          buttonLabel="Generate Report"
          onSubmit={handleSubmitReport}
          onClose={handleToggle}
        />
      )}
      {openReceiving && (
        <SelectReceivingModal
          selectedClientId={selectedClientId}
          selectedProjectId={selectedProjectId}
          name={item.payload.name}
          title="Generate Report"
          description={`Template: ${item.payload.name}`}
          buttonLabel="Generate Report"
          onSubmit={handleSubmitReport}
          onClose={handleToggle}
        />
      )}
    </>
  );
}
