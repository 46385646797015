import React from "react";
import orderBy from "lodash/orderBy";
import camelCase from "lodash/camelCase";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import LinearProgress from "@mui/material/LinearProgress";

import Autocomplete from "../../components/form/Autocomplete";
import manufacturers from "../../constants/manufacturers";
import { getOptions } from "../../utils/form";
import labels from "../../constants/displayFields";
import Input from "../../components/form/Input";
import colors from "../../constants/colors";
import Select from "../../components/form/Select";
import { warrantyOptions } from "../../views/capture/FormFields";
import RadioGroup from "../../components/form/RadioGroup";
import { powerOptions } from "../../constants/asset";
import DimensionsField from "../../views/capture/DimensionsField";
import FinishField from "../../views/capture/FinishField";
import dispositions from "../../constants/dispositions";
import { useSustainabilitySheetLoading } from "./SustainabilitySheetContext";
import CategoryField from "./form/CategoryField";
import SubcategoryField from "./form/SubcategoryField";
import useLabels from "../../hooks/useLabels";

const nameField = name => camelCase(name);

export default function FieldsDescriptive({
  type,
  display,
  disabledForm,
  colorsOptions = [],
  manufacturersOptions = [],
  dispositionOptions = [],
  fullWidth,
}) {
  const isItem = type === "item";
  const md = fullWidth ? 12 : 4;
  const l = useLabels();
  const sustainabilitySheetLoading = useSustainabilitySheetLoading();
  const displaySection =
    display.manufacturer ||
    display.description ||
    display.model ||
    display.dimensions ||
    display.color ||
    display.condition ||
    display.disposition ||
    display.category ||
    display.subcategory ||
    display.unit_cost ||
    display.warranty ||
    display.description_comments ||
    display.power ||
    display.finishes;

  return displaySection ? (
    <Card style={{ height: "100%" }}>
      <CardContent>
        <Box mt={-1} mb={1}>
          <Typography fontWeight={500} fontSize={18}>
            Descriptive
          </Typography>
        </Box>
        <Box mt={-1} height={8}>
          {sustainabilitySheetLoading && <LinearProgress />}
        </Box>
        <Grid container spacing={1}>
          {display.manufacturer && (
            <Grid item xs={12} md={md}>
              <Autocomplete
                disabled={disabledForm}
                saveCustomOption
                name={nameField("manufacturer")}
                label={labels.manufacturer}
                options={orderBy(getOptions(manufacturers, manufacturersOptions), v =>
                  v.value?.toLowerCase()
                )}
              />
            </Grid>
          )}
          {display.description && (
            <Grid item xs={12} md={md}>
              <Input
                name={nameField("description")}
                label={labels.description}
                disabled={disabledForm}
              />
            </Grid>
          )}
          {display.model && (
            <Grid item xs={12} md={md}>
              <Input
                name={nameField("model")}
                label={l.descriptiveInfo.model}
                disabled={disabledForm}
              />
            </Grid>
          )}
          {display.dimensions && (
            <Grid item xs={12} md={md}>
              <DimensionsField name="dimensions" disabled={disabledForm} />
            </Grid>
          )}
          {display.color && (
            <Grid item xs={12} md={md}>
              <Autocomplete
                saveCustomOption
                name={nameField("color")}
                label={labels.color}
                options={orderBy(getOptions(colors, colorsOptions), v => v.value)}
                disabled={disabledForm}
              />
            </Grid>
          )}
          {display.condition && (
            <Grid item xs={12} md={md}>
              <Input
                name={nameField("condition")}
                label={labels.condition}
                disabled={disabledForm}
              />
            </Grid>
          )}
          {display.finishes && (
            <Grid item xs={12} md={md}>
              <FinishField name="finishes" disabled={disabledForm} />
            </Grid>
          )}
          {display.disposition && (
            <Grid item xs={12} md={md}>
              <Autocomplete
                disabled={disabledForm}
                saveCustomOption
                name={nameField("disposition")}
                label={labels.disposition}
                options={orderBy(getOptions(dispositions, dispositionOptions), v =>
                  v.value?.toLowerCase()
                )}
              />
            </Grid>
          )}
          {display.category && (
            <Grid item xs={12} md={md}>
              <CategoryField disabled={disabledForm} />
            </Grid>
          )}
          {display.subcategory && (
            <Grid item xs={12} md={md}>
              <SubcategoryField disabled={disabledForm} />
            </Grid>
          )}
          {!isItem && display.unit_cost && (
            <Grid item xs={12} md={md}>
              <Input
                name="unitCost"
                label={labels.unit_cost}
                type="number"
                step="0.01"
                disabled={disabledForm}
              />
            </Grid>
          )}
          {!isItem && display.warranty && (
            <Grid item xs={12} md={md}>
              <Select
                size="small"
                name="warranty"
                label={labels.warranty}
                options={warrantyOptions}
                disabled={disabledForm}
              />
            </Grid>
          )}
          {display.description_comments && (
            <Grid item xs={12} md={md}>
              <Input
                name={nameField("description_comments")}
                label={l.descriptiveInfo.description_comments}
                disabled={disabledForm}
              />
            </Grid>
          )}
          {!isItem && display.weight && (
            <Grid item xs={12} md={md}>
              <Input name="weight" label={labels.weight} disabled={disabledForm} />
            </Grid>
          )}
          {display.power && (
            <Grid item xs={12}>
              <Box mt={1}>
                <Typography color="textSecondary">Power</Typography>
              </Box>
              <RadioGroup row name="notes.power" options={powerOptions} disabled={disabledForm} />
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  ) : null;
}
