import orderBy from "lodash/orderBy";

const COLORS = orderBy([
  "Beige",
  "Black",
  "Blue",
  "Brown",
  "Green",
  "Grey",
  "Orange",
  "Pattern",
  "Red",
  "White",
  "Yellow",
  "Walnut",
  "Mahagony",
  "Oak",
  "Cherry",
  "Maple",
  "Purple",
  "Tan",
  "Silver",
  "Cork",
  "Pine",
  "Mahogany",
  "Mauve",
  "Dark Cherry",
  "Clear",
  "Ash",
]);

export const COLORS_OPTIONS = COLORS.map(color => [color, color]);

export default COLORS;
