import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";

export default function Header({ title, info }) {
  return (
    <Box>
      <Box p={2} display="flex" justifyContent="space-between" alignItems="center">
        <Typography fontSize={18} fontWeight={500}>
          {title}
        </Typography>
        <Typography>{info}</Typography>
      </Box>
      <Divider />
    </Box>
  );
}
