import React, {createRef} from "react";
import {Formik} from "formik";
import {useParams} from "react-router-dom";

import {fetchAssetValues} from "../../utils/asset";
import {useAssets} from "../../contexts/assets";
import FormPage from "./FormPage";

export const formRef = createRef();

export default function Form() {
  const { currentAsset } = useAssets();
  const { clientId } = useParams();
  const nav = window.location.pathname.split("/")[1];
  const displayClient = nav === "clients" && clientId === "null";
  const displayProject = nav === "clients";

  return (
    <Formik
      key={currentAsset?.id}
      initialValues={fetchAssetValues(currentAsset)}
      onSubmit={() => {}}
      enableReinitialize
      innerRef={formRef}
    >
      <FormPage type="capture" displayClient={displayClient} displayProject={displayProject} />
    </Formik>
  );
}
