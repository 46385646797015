import React, {useState} from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import PublishIcon from "@material-ui/icons/Publish";
import LinearProgress from "@material-ui/core/LinearProgress";

import useNotification from "../hooks/notification";

export default function UploadButton({ onUpload, disabled, name, accept = "image/*", ...props }) {
  const { notifyError } = useNotification();
  const [loading, setLoading] = useState(false);

  const handleUpload = event => {
    setLoading(true);
    onUpload(event.target.files[0], {
      onSuccess: () => {
        setLoading(false);
      },
      onFailure: errors => {
        notifyError(errors);
        setLoading(false);
      },
    });
  };

  return (
    <>
      <Box display="none" clone>
        <input
          name={name}
          disabled={disabled || loading}
          accept={accept}
          id={`contained-button-file-${name}`}
          onChange={handleUpload}
          type="file"
        />
      </Box>
      <Box mb={1} width="100%" clone>
        <label htmlFor={`contained-button-file-${name}`}>
          <Button
            style={{ height: 40 }}
            variant="contained"
            disabled={disabled}
            fullWidth
            component="span"
            {...props}
            color="primary"
          >
            <PublishIcon fontSize="large" />
          </Button>
        </label>
      </Box>

      <Box>{loading && <LinearProgress />}</Box>
    </>
  );
}
