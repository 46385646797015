import React from "react";
import Users from "./users/Users";
import Settings from "./settings/Settings";
import Reports from "./reports/Reports";
import Inbound from "./inbound/Inbound";
import Billing from "./billing/Billing";
import Outbound from "./outbound/Outbound";

export default function ProjectTabs({ selected, onClose }) {
  switch (selected) {
    case "settings":
      return <Settings onClose={onClose} />;
    case "users":
      return <Users onClose={onClose} />;
    case "reports":
      return <Reports onClose={onClose} />;
    case "inbound":
      return <Inbound onClose={onClose} />;
    case "billing":
      return <Billing onClose={onClose} />;
    case "outbound":
      return <Outbound onClose={onClose} />;
    default:
      return null;
  }
}
