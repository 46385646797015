import React, {useState} from "react";
import {Form, Formik} from "formik";
import Box from "@mui/material/Box";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Dialog from "@mui/material/Dialog";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import CircularProgress from "@mui/material/CircularProgress";

import CheckboxInput from "../../components/form/Checkbox";
import useFields from "../../hooks/useFields";
import Order from "../../components/TablePro/Order";
import useAuth from "src/hooks/useAuth";

const REORDER_TAB = 99999;
const hiddenScrubberFields = ["barcode"];

export default function FieldsModal({
  type,
  initialValues,
  reorderFields,
  onChangeOrder,
  onToggle,
  onSubmit,
}) {
  const [submitLoading, setSubmitLoading] = useState(false);
  const [selected, setSelected] = useState(0);
  const fields = useFields();
  const { user } = useAuth();
  const { settings } = user.company;
  const options = fields[selected]?.list
    ?.filter(([name]) => {
      if (type === "scrubber") {
        return !hiddenScrubberFields.includes(name);
      }
      return true;
    })
    .map(([name, ...rest]) => {
      if (type === "scrubber") {
        if (name === "photo") {
          return ["photo_carousel", ...rest];
        } else if (name === "barcode") {
          return ["barcode_scanner", ...rest];
        } else if (name === "barcode_number") {
          return ["barcode", ...rest];
        } else {
          return [name, ...rest];
        }
      } else {
        return [name, ...rest];
      }
    });

  const handleSave = values => {
    setSubmitLoading(true);
    return onSubmit(values).then(() => {
      setSubmitLoading(false);
    });
  };

  return (
    <Dialog open maxWidth="sm" fullWidth onClose={onToggle}>
      <DialogTitle sx={{ background: "#f4f6f8" }}>
        <Typography color="textSecondary" variant="h2" align="center">
          {settings.filter_field2} Fields
        </Typography>
      </DialogTitle>
      <DialogContent sx={{ background: "#f4f6f8" }}>
        <DialogContentText>
          <Formik enableReinitialize initialValues={initialValues} onSubmit={handleSave}>
            {({ submitForm }) => (
              <Form>
                <Box display="flex">
                  <List component={Paper} sx={{ minWidth: { xs: undefined, md: 170 } }}>
                    {reorderFields && (
                      <ListItemButton
                        selected={selected === REORDER_TAB}
                        onClick={() => {
                          submitForm().then(() => {
                            setSelected(REORDER_TAB);
                          });
                        }}
                      >
                        Reorder Columns
                      </ListItemButton>
                    )}
                    {fields.map((v, i) => (
                      <ListItemButton
                        key={i}
                        selected={i === selected}
                        onClick={() => setSelected(i)}
                      >
                        {v.label}
                      </ListItemButton>
                    ))}
                  </List>
                  <Box pl={2} width="100%">
                    {selected === REORDER_TAB ? (
                      <Paper>
                        <Box p={1.5}>
                          <Order fields={reorderFields} onChange={onChangeOrder} reorderFields={true} />
                        </Box>
                      </Paper>
                    ) : (
                      options.map(([name, category, label]) => (
                        <CheckboxInput key={name} name={name} category={category} label={label} />
                      ))
                    )}
                  </Box>
                </Box>
                <Box mt={3} display="flex" justifyContent="flex-end">
                  {submitLoading && (
                    <Box mr={1.5} display="flex" alignItems="center">
                      <CircularProgress color="primary" size={22} />
                    </Box>
                  )}
                  <Button
                    onClick={() => {
                      submitForm().then(() => {
                        onToggle();
                      });
                    }}
                    type="button"
                    disabled={submitLoading}
                    variant="contained"
                    color="primary"
                  >
                    Save
                  </Button>
                </Box>
              </Form>
            )}
          </Formik>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
}
