import sortBy from "lodash/sortBy";

import displayFields from "../../../constants/displayFields";
import useAuth from "../../../hooks/useAuth";

export default function useFilterFields() {
  const { user } = useAuth();
  const { settings } = user.company;

  return sortBy(
    [
      ["amps_watts", displayFields.amps_watts],
      ["ase", displayFields.ase],
      ["asset_id", displayFields.asset_id],
      ["bench_floor_hood_wall_bsc", displayFields.bench_floor_hood_wall_bsc],
      ["bms", settings.bms_label || displayFields.bms],
      ["btu", displayFields.btu],
      ["category", displayFields.category],
      [
        "capital_asset_number",
        settings.capital_asset_number_label || displayFields.capital_asset_number,
      ],
      ["cfm", displayFields.cfm],
      ["color", displayFields.color],
      ["condition", displayFields.condition],
      ["data_port_qty", settings.data_port_qty_label || displayFields.data_port_qty],
      ["dedicated_power", displayFields.dedicated_power],
      ["department_name", displayFields.department_name],
      ["description", displayFields.description],
      [
        "description_comments",
        settings.description_comments_label || displayFields.description_comments,
      ],
      ["new_building", displayFields.new_building],
      ["destination_comments", displayFields.destination_comments],
      ["destination_employee_name", displayFields.destination_employee_name],
      ["new_floor", displayFields.new_floor],
      ["destination_number", displayFields.destination_number],
      ["destination_quantity", displayFields.destination_quantity],
      ["new_room", displayFields.new_room],
      ["destination_room_number", displayFields.destination_room_number],
      ["di", displayFields.di],
      ["dimensions", displayFields.dimensions],
      ["disposition", displayFields.disposition],
      ["exh_ceiling_blast_gate", displayFields.exh_ceiling_blast_gate],
      ["exh_header", displayFields.exh_header],
      ["exh_other", displayFields.exh_other],
      ["exh_snorkel_arm_mechanical", displayFields.exh_snorkel_arm_mechanical],
      ["exh_snorkel_arm_plumbing", displayFields.exh_snorkel_arm_plumbing],
      ["exhaust", displayFields.exhaust],
      ["finishes", displayFields.finishes],
      ["floor_drain", displayFields.floor_drain],
      ["floor_sink", displayFields.floor_sink],
      ["hertz", displayFields.hertz],
      ["hse_co", displayFields.hse_co],
      ["hse_vac", displayFields.hse_vac],
      ["icw", displayFields.icw],
      ["id", displayFields.id],
      ["ihw", displayFields.ihw],
      ["install_responsibility", displayFields.install_responsibility],
      ["item_code", displayFields.item_code],
      ["lab_name", displayFields.lab_name],
      ["list_exh_type", displayFields.list_exh_type],
      ["local_gas_tanks", displayFields.local_gas_tanks],
      ["manufacturer", displayFields.manufacturer],
      ["model", settings.model_label || displayFields.model],
      ["nema_config", displayFields.nema_config],
      ["normal_power", displayFields.normal_power],
      ["existing_building", displayFields.existing_building],
      ["origin_comments", displayFields.origin_comments],
      ["origin_cubby_number", displayFields.origin_cubby_number],
      ["origin_employee_name", displayFields.origin_employee_name],
      ["existing_floor", displayFields.existing_floor],
      ["quantity", displayFields.quantity],
      ["existing_room", displayFields.existing_room],
      ["origin_room_number", displayFields.origin_room_number],
      ["pallet_location", displayFields.pallet_location],
      ["pcw_return", displayFields.pcw_return],
      ["pcw_supply", displayFields.pcw_supply],
      ["phase", displayFields.phase],
      ["plug_connection", displayFields.plug_connection],
      ["plug_quantity", displayFields.plug_quantity],
      ["ii_po_number", displayFields.ii_po_number],
      ["power", displayFields.power],
      ["price", displayFields.price],
      ["quantity_ordered", displayFields.quantity_ordered],
      ["quantity_received", displayFields.quantity_received],
      ["rack_locations", displayFields.rack_locations],
      ["reserved_quantity", displayFields.reserved_quantity],
      ["ro", displayFields.ro],
      ["serial_number", settings.serial_number_label || displayFields.serial_number],
      ["siesmic_anchor_required", displayFields.siesmic_anchor_required],
      ["solve_waste_coll", displayFields.solve_waste_coll],
      ["standby_power", displayFields.standby_power],
      ["standby_power_sb", displayFields.standby_power_sb],
      ["subcategory", displayFields.subcategory],
      ["tss_number", settings.tss_number_label || displayFields.tss_number],
      ["unit_cost", displayFields.unit_cost],
      ["ups", displayFields.ups],
      ["vac_pump_vent_plumbing", displayFields.vac_pump_vent_plumbing],
      ["vac_pump_vent_mechanical", displayFields.vac_pump_vent_mechanical],
      ["vault_location", displayFields.vault_location],
      ["vendor_required", displayFields.vendor_required],
      ["volts", displayFields.volts],
      ["warranty", displayFields.warranty],
      ["barcode", settings.barcode_label || displayFields.barcode_number],
      ["move_notes", settings.move_notes_label || displayFields.move_notes],
      ["other_utility", settings.other_utility_label || displayFields.other_utility],
      ["available_quantity", displayFields.available_quantity],
      ["local_vac_pump", displayFields.local_vac_pump],
      ["checkout", "Checkout"],
    ],
    v => v[1].toLowerCase()
  );
}
