import * as filestack from "filestack-js";

// TODO
const client = filestack.init("AfWdjAUhrRxCY0qQkXWBsz");

const filestackUpload = (file) => {
  return client.upload(file);
};

export const upload = filestackUpload;
