import React, {useMemo} from "react";
import {useField} from "formik";
import Alert from "@material-ui/lab/Alert";
import Box from "@material-ui/core/Box";

import {getDisplayFields} from "src/utils/asset";

const NotificationField = ({}) => {
  const [include] = useField("include");
  const [descriptiveInfo] = useField("descriptive_info");
  const [originInfo] = useField("origin_info");
  const [destinationInfo] = useField("destination_info");
  const [warehouseInfo] = useField("warehouse_info");
  const [electricalInfo] = useField("electrical_info");
  const [plumbingInfo] = useField("plumbing_info");
  const [mechanicalInfo] = useField("mechanical_info");
  const [otherInfo] = useField("other_info");

  const fields = useMemo(
    () =>
      getDisplayFields({
        include: include.value,
        descriptive_info: descriptiveInfo.value,
        origin_info: originInfo.value,
        destination_info: destinationInfo.value,
        warehouse_info: warehouseInfo.value,
        electrical_info: electricalInfo.value,
        plumbing_info: plumbingInfo.value,
        mechanical_info: mechanicalInfo.value,
        other_info: otherInfo.value,
      }),
    [
      include.value,
      descriptiveInfo.value,
      originInfo.value,
      destinationInfo.value,
      warehouseInfo.value,
      electricalInfo.value,
      plumbingInfo.value,
      mechanicalInfo.value,
      otherInfo.value,
    ]
  );

  const message =
    fields.length > 10
      ? "11x17 and Landscape"
      : fields.length <= 10 && fields.length >= 6
      ? "11x17 and Portrait"
      : fields.length < 6 && fields.length >= 4
      ? "8.5 x 11 and Landscape"
      : null;

  return (
    <>
      {message && (
        <Alert severity="warning">
          You need to use page size {message} mode to fit all the columns
        </Alert>
      )}
      <Box mt={2} />
    </>
  );
};

export default NotificationField;
