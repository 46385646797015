import React, { useState, useMemo } from "react";
import useAuth from "src/hooks/useAuth";
import caller from "utils/caller";
import flatten from "lodash/flatten";

const PermissionsContext = React.createContext(null);

export const PermissionsProvider = ({ children }) => {
  const { user, company: { settings } = {}, company } = useAuth();
  const { role, permissions } = user || {};

  const companyHasAccess = (feature) =>
    Object.keys(settings).includes(feature)
      ? settings[feature] === "true" || settings[feature] === true
      : true;

  const value = useMemo(
    () => ({
      user,
      permissions,
      companyPermissions: company?.permissions || {},
      settings,
      can: (rule) => {
        return flatten([rule]).every((rule) => {
          const node = rule.split(".")[0];
          const feature = rule.split(".")[1];

          if (node == "role")
            return ["admin", "super_admin"].includes(role)
              ? true
              : role == feature;
          if (node == "settings") return companyHasAccess(feature);

          const userHasAccess = ["admin", "super_admin"].includes(role)
            ? true
            : caller(permissions, `${role}.${rule}`);

          return companyHasAccess(feature) && userHasAccess;
        });
      },
    }),
    [permissions, settings]
  );

  return (
    <PermissionsContext.Provider value={value}>
      {children}
    </PermissionsContext.Provider>
  );
};

export const usePermissions = () => React.useContext(PermissionsContext);
