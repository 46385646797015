import React, {useState} from "react";
import {useParams} from "react-router-dom";
import moment from "moment/moment";
import {useMutation} from "@apollo/react-hooks";
import Paper from "@material-ui/core/Paper";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import {serverFormat} from "../../../utils/dates";
import {DELETE_INVENTORY_REQUEST_MUTATION} from "../../../constants/graphql/mutations";
import {INVENTORY_REQUESTS_QUERY} from "../../../constants/graphql";
import OutboundActions from "./OutboundActions";

export default function OutboundTable({ list, onEdit, refetch }) {
  const { projectId } = useParams();
  const [removing, setRemoving] = useState(false);
  const [deleteInventoryRequest] = useMutation(DELETE_INVENTORY_REQUEST_MUTATION, {
    refetchQueries: [
      { query: INVENTORY_REQUESTS_QUERY, variables: { projectId: parseInt(projectId) } },
    ],
  });

  const handleDelete = id => {
    setRemoving(true);
    return deleteInventoryRequest({
      variables: { id },
    }).then(() => {
      setRemoving(false);
    });
  };

  return (
    <>
      <Box height={3}>{removing && <LinearProgress />}</Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Order #</TableCell>
              <TableCell>Submitted On</TableCell>
              <TableCell>Submitted By</TableCell>
              <TableCell align="right" sx={{ width: 112 }}>
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {list.map(item => (
              <TableRow key={item.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell component="th" scope="row">
                  {item.workOrderNumber}
                </TableCell>
                <TableCell>{moment(item.createdAt, serverFormat).format("lll")}</TableCell>
                <TableCell>{item.user.email}</TableCell>
                <TableCell align="right" sx={{ width: 112 }}>
                  <OutboundActions
                    item={item}
                    onEdit={onEdit}
                    onRemove={() => handleDelete(item.id)}
                    refetch={refetch}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
