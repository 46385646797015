import React from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";

export default function Actions({ edit, toggleEdit, toggleModal }) {
  return edit ? (
    <Box display="flex" justifyContent="flex-end">
      <Box mr={1}>
        <Button color="primary" variant="outlined" onClick={toggleEdit}>
          Edit
        </Button>
      </Box>
      <Button color="primary" variant="contained" type="submit">
        Save
      </Button>
    </Box>
  ) : (
    <Box display="flex" justifyContent="flex-end">
      <Box mr={1}>
        <Button color="primary" variant="outlined" onClick={toggleEdit}>
          Edit
        </Button>
      </Box>
      <Button color="primary" variant="contained" onClick={toggleModal}>
        Close
      </Button>
    </Box>
  );
}
