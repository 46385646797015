import useAuth from "./useAuth";
import {
  destinationInfo,
  electricalInfo,
  getDescriptiveInfo,
  getIdentifiers,
  getOtherInfo,
  getWarehouseInfo,
  mechanicalInfo,
  originInfo,
  plumbingInfo,
} from "../constants/fields";

export default function useAllFields() {
  const { user } = useAuth();
  const { settings } = user.company;

  return {
    identifiers: getIdentifiers(settings),
    descriptiveInfo: getDescriptiveInfo(settings),
    warehouseInfo: getWarehouseInfo({ availableQuantity: settings.quantity_label }),
    originInfo,
    destinationInfo,
    electricalInfo,
    plumbingInfo,
    mechanicalInfo,
    otherInfo: getOtherInfo(settings),
  };
}
