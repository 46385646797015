import React from "react";
import {FastField} from "formik";
import {makeStyles} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

import RadioGroup from "../../../components/form/RadioGroup";

const SPACING = 2;

const useStyles = makeStyles(theme => {
  return {
    checkboxGroup: {
      display: "flex",
      flexDirection: "row",
      width: `calc(100% + ${theme.spacing(SPACING) * 2}px)`,
      margin: `-${theme.spacing(SPACING)}px`,
    },
    checkboxCard: {
      height: "100%",
      position: "relative",
    },
    checkboxButton: {
      padding: `${theme.spacing(SPACING)}px`,
      '& input[type="radio"]': {
        cursor: "pointer",
        opacity: 0,
        height: "100%",
        width: "100%",
        position: "absolute",
        left: 0,
        right: 0,
        zIndex: 1000,
      },
      "& label": {
        height: "100%",
        width: "100%",
        display: "block",
        position: "relative",
        padding: "16px 8px",
        boxShadow: theme.shadows[2],
        backgroundColor: theme.palette.common.white,
      },
      '& input[type="radio"]:checked + label': {
        backgroundColor: theme.palette.success.light,
      },
      '& input[type="radio"]:focus + label': {},
    },
  };
});

export default function RoomTypeCheckbox(props) {
  const classes = useStyles();
  const {
    option: { plan, name },
  } = props;
  const currency = "$";

  return (
    <Grid item xs={3} className={classes.checkboxButton}>
      <div className={classes.checkboxCard}>
        <FastField type="radio" {...props.option} />
        <label htmlFor={name}>
          <Box
            textAlign="center"
            fontWeight="fontWeightBold"
            fontSize="h4.fontSize"
            color="text.primary"
            mb={1}
          >
            {plan.name}
          </Box>

          <Box textAlign="center" mb={1}>
            <Typography display="inline" variant="h4" color="textPrimary">
              {`${currency}${plan.amount}`}
            </Typography>
            <Typography variant="h4" display="inline" color="textPrimary">
              /mo
            </Typography>
          </Box>

          <Box textAlign="center">
            <Typography display="inline" variant="h6" color="textPrimary">
              {plan.description}
            </Typography>
          </Box>
        </label>
      </div>
    </Grid>
  );
}

const RoomTypeCheckboxWrap = ({ children }) => <Grid container>{children}</Grid>;

const SubscriptionPlans = ({ companyPlans, ...inputProps }) => {
  const classes = useStyles();

  const options = companyPlans.map(plan => ({
    plan: plan,
    name: `${inputProps.name}`,
    value: plan.id,
  }));

  return (
    <>
      <RadioGroup
        {...inputProps}
        component={RoomTypeCheckbox}
        componentWrap={RoomTypeCheckboxWrap}
        options={options}
        className={classes.checkboxGroup}
      />
    </>
  );
};
