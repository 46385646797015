import React, {useState} from "react";

const findNextSelectedCell = (fields, currentFieldIndex) => {
  const nextEditableIndex = fields.findIndex(
    (fieldItem, index) => index > currentFieldIndex && !fieldItem.inactive && fieldItem.visible
  );

  return nextEditableIndex;
};

const findPrevSelectedCell = (fields, currentFieldIndex) => {
  let prevEditableIndex;

  for (let index = currentFieldIndex - 1; index >= 0; index--) {
    const fieldItem = fields[index];

    if (!fieldItem.inactive && fieldItem.visible) {
      prevEditableIndex = index;
      break;
    }
  }

  return prevEditableIndex;
};

const useSelectableCells = (defaultCoords = []) => {
  const [selectedCoords, setSelectedCoords] = useState(defaultCoords);

  const nextSelectedCell = (fields, rowIndex, columnIndex) => {
    const nextSelectedIndex = findNextSelectedCell(fields, columnIndex);

    if (nextSelectedIndex === 0 || (nextSelectedIndex && nextSelectedIndex >= 0)) {
      setSelectedCoords([rowIndex, nextSelectedIndex]);
    } else {
      nextSelectedCell(fields, rowIndex + 1, 0);
    }
  };

  const prevSelectedCell = (fields, rowIndex, columnIndex) => {
    const prevSelectedIndex = findPrevSelectedCell(fields, columnIndex);

    if (prevSelectedIndex === 1 || (prevSelectedIndex && prevSelectedIndex >= 0)) {
      setSelectedCoords([rowIndex, prevSelectedIndex]);
    } else if (rowIndex - 1 >= 0) {
      prevSelectedCell(fields, rowIndex - 1, fields.length);
    }
  };

  const nextSelectedRow = (rowIndex, columnIndex) => {
    setSelectedCoords([rowIndex + 1, columnIndex]);
  };

  const prevSelectedRow = (rowIndex, columnIndex) => {
    if (rowIndex - 1 >= 0) {
      setSelectedCoords([rowIndex - 1, columnIndex]);
    }
  };

  return {
    selectedCoords,
    setSelectedCoords,
    prevSelectedCell,
    prevSelectedRow,
    nextSelectedCell,
    nextSelectedRow,
  };
};

export default useSelectableCells;
