import React, {useEffect} from "react";
import clsx from "clsx";
import {NavLink, useHistory} from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Toolbar from "@material-ui/core/Toolbar";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import IconButton from "@material-ui/core/IconButton";
import Hidden from "@material-ui/core/Hidden";
import Drawer from "@material-ui/core/Drawer";
import Box from "@material-ui/core/Box";
import AppBar from "@material-ui/core/AppBar";
import Divider from "@material-ui/core/Divider";
import MenuIcon from "@material-ui/icons/Menu";
import SettingsIcon from "@material-ui/icons/Settings";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import {useQuery} from "@apollo/react-hooks";

import {NAV_BAR_ITEMS} from "../../../routes";
import {THEMES} from "../../../constants";
import Logo from "../../../components/Logo";
import {usePermissions} from "../../../contexts/permissions";
import Account from "./TopBar/Account";
import Notifications from "./TopBar/Notifications";
import getDashboardLink from "../../../utils/getDashboardLink";
import useAuth from "../../../hooks/useAuth";
import {CLIENTS_QUERY} from "../../../constants/graphql";

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  appBar: {
    maxHeight: 64,
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: theme.palette.background.default,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.up("lg")]: {
      width: `calc(100% - ${theme.spacing(8)}px)`,
    },
    boxShadow: "none",
    ...(theme.name === THEMES.LIGHT
      ? {
          backgroundColor: theme.palette.primary.main,
        }
      : {}),
    ...(theme.name === THEMES.ONE_DARK
      ? {
          backgroundColor: theme.palette.background.default,
        }
      : {}),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    [theme.breakpoints.up("lg")]: {
      width: `calc(100% - ${drawerWidth}px)`,
    },
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuIcon: {
    color: theme.palette.background.default,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    borderRight: "none",
  },
  drawerPaper: {
    borderRight: "none",
  },
  mobileDrawer: {
    width: drawerWidth + 25,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    borderRight: "none",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    borderRight: "none",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(8) + 1,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: theme.spacing(0, 1),
    boxShadow: "none",
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    ...(theme.name === THEMES.LIGHT
      ? {
          backgroundColor: theme.palette.primary.main,
        }
      : {}),
    ...(theme.name === THEMES.ONE_DARK
      ? {
          backgroundColor: theme.palette.background.default,
        }
      : {}),
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  listItem: {
    display: "flex",
  },
  listItemIcon: {
    marginRight: 20,
  },
  listItemTitle: {},
  listItemWrap: {
    display: "block",
    color: theme.palette.text.secondary,
    "&.active": {
      color: theme.palette.secondary.main,
      "& $listItem": {
        backgroundColor: theme.palette.background.dark,
        color: theme.palette.secondary.main,
      },
      "& $listItemIcon": {
        color: theme.palette.secondary.main,
      },
      "& $listItemTitle": {
        fontWeight: theme.typography.fontWeightMedium,
      },
    },
  },
}));

export default function MiniDrawer() {
  const classes = useStyles();
  const history = useHistory();
  const { can } = usePermissions();
  const [open, setOpen] = React.useState(localStorage.getItem("openedDrawer") == "true");
  const { user } = useAuth();

  // TODO: remove hardcoded navigation to shopping cart Launch Data
  /*
  const isVA = user.company.name === "va";
  const { data: { clients = [] } = {} } = useQuery(CLIENTS_QUERY, {
    skip: !isVA,
  });
  const launchData = clients.flatMap(v => v.projects).find(v => v.name === "Abbott Ave Whse");
  const launchDataId = launchData ? launchData.id : null;
  */

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    localStorage.setItem("openedDrawer", open);
  }, [open]);

  const content = (
    <List>
      {NAV_BAR_ITEMS.map((section, index) => {
        const isAvailable = section.canRules ? section.canRules.every(rule => can(rule)) : true;
        if (!isAvailable) return;

        return (
          <Tooltip key={`${index}`} arrow placement="right" title={open ? "" : section.title}>
            <NavLink
              className={classes.listItemWrap}
              activeClassName={classes.active}
              to={section.to}
              isActive={(match, location) => {
                if (match) {
                  return match.isExact;
                } else if (
                  section.to === "/dashboard" &&
                  (/\/projects\/[a-z0-9_-]+\/assets/.test(window.location.pathname) ||
                    /\/clients\/[a-z0-9_-]+\/assets/.test(window.location.pathname))
                ) {
                  return true;
                } else if (
                  section.to === "/dashboard" &&
                  (/\/projects\/[a-z0-9_-]+\/capture-inventory/.test(window.location.pathname) ||
                    /\/clients\/[a-z0-9_-]+\/capture-inventory/.test(window.location.pathname))
                ) {
                  return true;
                } else if (
                  section.to === "/planners" &&
                  window.location.pathname.indexOf("/planner") === 0
                ) {
                  return true;
                } else if (
                  section.to === "/warehouse" &&
                  window.location.pathname.indexOf("/warehouse") === 0
                ) {
                  return true;
                } else if (
                  section.to === "/insights" &&
                  /\/projects\/[a-z0-9_-]+\/templates\/[a-z0-9_-]+/.test(window.location.pathname)
                ) {
                  return true;
                }
                return false;
              }}
              onClick={event => {
                if (section.to === "/dashboard") {
                  event.preventDefault();
                  history.push(getDashboardLink());
                }
                /*
                if (isVA && launchDataId && section.to === "/shopping") {
                  event.preventDefault();
                  history.push(`/shopping/${launchDataId}`);
                }
                */
              }}
              exact
            >
              <ListItem className={classes.listItem} button key={index}>
                <ListItemIcon className={classes.listItemIcon}>
                  <section.icon />
                </ListItemIcon>

                <ListItemText className={classes.listItemTitle} primary={section.title} />
              </ListItem>
            </NavLink>
          </Tooltip>
        );
      })}
    </List>
  );

  return (
    <>
      <AppBar position="fixed" className={clsx(classes.appBar, { [classes.appBarShift]: open })}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <MenuIcon />
          </IconButton>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerClose}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: !open,
            })}
          >
            <ChevronLeftIcon />
          </IconButton>
          <Box ml={2} flexGrow={1} />
          {can("role.admin") && (
            <Tooltip title="Settings">
              <IconButton to="/settings/clients" component={NavLink} style={{ color: "white" }}>
                <SettingsIcon />
              </IconButton>
            </Tooltip>
          )}
          <Notifications />
          <Box display="flex" borderRadius="8px" bgcolor="white" ml={1}>
            {user.company.labelsLogoUrl ? (
              <>
                <Box m={0.5} pl={0.5} display="flex" alignItems="center">
                  <img
                    src={user.company.labelsLogoUrl}
                    alt=""
                    style={{ maxHeight: 40, maxWidth: 120 }}
                  />
                </Box>
                <Box ml={0.5} pl={0.5} borderLeft="1px solid rgba(224, 224, 224, 1)">
                  <Account />
                </Box>
              </>
            ) : (
              <Account />
            )}
          </Box>
        </Toolbar>
      </AppBar>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={handleDrawerClose}
          open={open}
          variant="temporary"
        >
          <div className={classes.toolbar}>
            <Logo />
            <IconButton
              color="default"
              aria-label="open drawer"
              onClick={handleDrawerClose}
              edge="start"
              className={clsx(classes.menuIcon, {
                [classes.hide]: !open,
              })}
            >
              <ChevronLeftIcon />
            </IconButton>
          </div>
          <Divider />
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          open
          variant="persistent"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          })}
          classes={{
            paper: clsx(classes.drawerPaper, {
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            }),
          }}
        >
          <div className={classes.toolbar}>
            <Logo />
          </div>
          <Divider />
          {content}
        </Drawer>
      </Hidden>
    </>
  );
}
