import uniq from "lodash/uniq";

export const getOptions = (staticList, dynamicList = []) =>
  uniq([...staticList, ...dynamicList]).map(value => {
    const optionValue = Array.isArray(value) ? value[0] : value;
    const optionLabel = Array.isArray(value) ? value[1] : value;

    return {
      value: optionValue,
      label: optionLabel,
    };
  });

export const isOtherInfoEnabled = display =>
  display.data_port_qty || display.bms || display.other_utility || display.move_notes;

export const isMechanicalInfoEnabled = display =>
  display.ase ||
  display.exhaust ||
  display.vac_pump_vent_mechanical ||
  display.exh_snorkel_arm_mechanical ||
  display.cfm;

export const isPlumbingInfoEnabled = display =>
  display.hse_co ||
  display.local_gas_tanks ||
  display.hse_vac ||
  display.local_vac_pump ||
  display.icw ||
  display.ihw ||
  display.pcw_supply ||
  display.pcw_return ||
  display.ro ||
  display.di ||
  display.floor_sink ||
  display.floor_drain ||
  display.solve_waste_coll ||
  display.exh_header ||
  display.vac_pump_vent_plumbing ||
  display.exh_ceiling_blast_gate ||
  display.exh_snorkel_arm_plumbing ||
  display.exh_other ||
  display.list_exh_type;

export const isElectricalInfoEnabled = display =>
  display.volts ||
  display.amps_watts ||
  display.btu ||
  display.hertz ||
  display.phase ||
  display.nema_config ||
  display.plug_quantity ||
  display.standby_power ||
  display.normal_power ||
  display.dedicated_power ||
  display.ups ||
  display.standby_power_sb ||
  display.plug_connection;
