import React, {useEffect, useState} from "react";
import orderBy from "lodash/orderBy";
import groupBy from "lodash/groupBy";
import {useFormikContext} from "formik";
import {useParams} from "react-router-dom";
import Box from "@material-ui/core/Box";

import {useShopping} from "../../../../contexts/shopping";
import CartItemsView from "../../../inventory/requests/CartItemsView";
import NextButton from "./NextButton";
import Section from "./Section";
import LocationsReorder from "./LocationsReorder";
import LocationNotes from "./LocationNotes";

export function useLocations() {
  const { loading, inventoryItems } = useShopping();
  const { values } = useFormikContext();
  const [list, setList] = useState([]);

  useEffect(() => {
    const locationOrder = values.locationOrder || {};
    const newList = Object.entries(
      groupBy(
        orderBy(Object.values(inventoryItems), "position").map((v, index) => ({ ...v, index })),
        "location"
      )
    );
    const orderedList = orderBy(newList, item => locationOrder[item[0].split(",")[0]]);
    setList(orderedList);
  }, [values, inventoryItems, loading]);
  return [list, setList];
}

export default function Step4({ step }) {
  const [view, setView] = useState("edit");
  const { section } = useParams();
  const [list, setList] = useLocations();
  const selected = section === step;

  return (
    <Section title={`${step} Review Items and Scope`} step={step}>
      {selected ? (
        <Box mt={3} display="flex" flexDirection="column">
          {list.length > 0 && (
            <Box mr={-2}>
              <CartItemsView value={view} onChange={setView} />
            </Box>
          )}
          <Box display="flex" flexDirection="column" mt={3} />
          {view === "edit" ? (
            <LocationNotes list={list} />
          ) : (
            <LocationsReorder items={list} setItems={setList} />
          )}
          <Box mt={3}>
            <NextButton step={+step + 1} />
          </Box>
        </Box>
      ) : (
        <Box ml={35} mt={2}>
          {section > 4 && <LocationNotes disabled list={list} />}
        </Box>
      )}
    </Section>
  );
}
