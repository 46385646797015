import React, {useState} from "react";
import Barcode from "react-barcode";
import CropFreeIcon from "@material-ui/icons/CropFree";
import ButtonBase from "@material-ui/core/ButtonBase";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import LinearProgress from "@material-ui/core/LinearProgress";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import Scanner from "./Scanner";
import BigButton from "../../components/BigButton";

const BarCodeInput = ({ barcode: defaultCode, onChange, id }) => {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const isMobile = useMediaQuery(theme => theme.breakpoints.down("sm"));
  const defaultHeight = isMobile ? 120 : 200;
  const toggleOpen = () => setOpen(v => !v);
  const handleScan = code => {
    setLoading(true);
    onChange(code, {
      onSuccess: () => {
        setLoading(false);
      },
      onFailure: () => {
        setLoading(false);
      },
    });
    setOpen(false);
  };
  if (open) {
    return <Scanner onDetected={handleScan} onClose={toggleOpen} />;
  }
  return (
    <Box id={id} display="flex" flexDirection="column" height="100%" justifyContent="space-between">
      {defaultCode ? (
        <ButtonBase style={{ width: "100%", height: defaultHeight }} onClick={toggleOpen}>
          <Barcode value={defaultCode} />
        </ButtonBase>
      ) : (
        <BigButton height={defaultHeight} loading={!!loading} onClick={toggleOpen}>
          <CropFreeIcon fontSize="large" />
        </BigButton>
      )}
      {loading && <LinearProgress className="mt-2" />}
      <Typography align="center">Barcode</Typography>
    </Box>
  );
};

export default BarCodeInput;
