import React, {useState} from "react";
import {Link, useParams} from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Paper from "@material-ui/core/Paper";
import {useQuery} from "@apollo/react-hooks";
import {PROJECT_BY_ID} from "src/constants/graphql/queries";
import CircularProgress from "@mui/material/CircularProgress";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import {SvgIcon} from "@material-ui/core";
import {PlusCircle as PlusCircleIcon} from "react-feather";
import {CLIENT_USERS} from "../../../constants/graphql";
import InviteFormModal from "../../../views/users/InviteFormModal";
import {ClientsProvider} from "../../../contexts/clients";

export default function Users() {
  const { projectId, clientId } = useParams();
  const [open, setOpen] = useState(false);
  const { data: { project } = {}, loading: loadingProject, refetch: refetchProject } = useQuery(
    PROJECT_BY_ID,
    {
      variables: { id: projectId },
      skip: !projectId,
    }
  );
  const { data: { clientUsers } = {}, loading: loadingClient, refetch: refetchClient } = useQuery(
    CLIENT_USERS,
    {
      variables: { clientId },
      skip: !!projectId,
    }
  );
  const users = (projectId ? project?.users : clientUsers) || [];

  const handleRefetch = () => {
    if (projectId) {
      refetchProject();
    } else {
      refetchClient();
    }
  };

  const loading = projectId ? loadingProject : loadingClient;

  const handleToggle = () => {
    setOpen(v => !v);
  };

  const handleInviteUser = () => {
    handleRefetch();
    handleToggle();
  };

  return (
    <Paper>
      <Box p={2} display="flex" justifyContent="space-between" alignItems="center">
        <Typography fontWeight={500}>User Access</Typography>
        <Button
          variant="contained"
          sx={{ textTransform: "none" }}
          onClick={handleToggle}
          startIcon={
            <SvgIcon fontSize="small">
              <PlusCircleIcon />
            </SvgIcon>
          }
        >
          Invite User
        </Button>
      </Box>
      {loading ? (
        <Box display="flex" justifyContent="center">
          <CircularProgress color="primary" />
        </Box>
      ) : (
        <Box p={2}>
          <TableContainer component={Paper}>
            <Table aria-label="users table">
              <TableHead>
                <TableRow>
                  <TableCell>User Name</TableCell>
                  <TableCell>User Email</TableCell>
                  <TableCell>Permission Role</TableCell>
                  <TableCell align="right">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {users.map(row => (
                  <TableRow
                    key={row.name}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {row.name}
                    </TableCell>
                    <TableCell>{row.email}</TableCell>
                    <TableCell>{row.role}</TableCell>
                    <TableCell align="right">
                      <Link to={`/settings/users/${row.id}/edit`}>
                        <EditOutlinedIcon color="action" />
                      </Link>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <ClientsProvider>
            {open && <InviteFormModal onSuccess={handleInviteUser} onClose={handleToggle} />}
          </ClientsProvider>
        </Box>
      )}
    </Paper>
  );
}
