import React from "react";
import orderBy from "lodash/orderBy";
import {useFormikContext} from "formik";

import Autocomplete from "../../../components/form/Autocomplete";
import labels from "../../../constants/displayFields";
import {useSubcategories, useSustainabilitySheet} from "../SustainabilitySheetContext";

export default function SubcategoryField({ disabledForm }) {
  const { values, setFieldValue } = useFormikContext();

  const subcategories = useSubcategories(values.category);
  const list = useSustainabilitySheet(values.category);

  const subcategoryOptions = orderBy(
    subcategories.map(value => ({ value, label: value })),
    v => v.value
  );

  const handleChange = value => {
    const selected = list.find(
      v => v.category === values.category && v.subcategory === value.value
    );
    if (selected) {
      const unitCost =
        typeof selected.unitCost === "string"
          ? selected.unitCost.replaceAll("$", "").trim()
          : selected.unitCost;
      setFieldValue("unitCost", parseInt(unitCost));
      setFieldValue("weight", selected.unitWeight);
    }
  };

  return (
    <Autocomplete
      key={subcategoryOptions.join()}
      saveCustomOption
      name="subcategory"
      label={labels.subcategory}
      options={subcategoryOptions}
      disabled={disabledForm}
      onChange={handleChange}
    />
  );
}
