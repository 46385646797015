import React, {useState} from "react";
import {Field, useFormikContext} from "formik";
import moment from "moment";
import TextField from "@mui/material/TextField";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";

import useAuth from "../../../../hooks/useAuth";
import labels from "../../../../constants/displayFields";

export default function DeliveryDateField({ minDate }) {
  const { user } = useAuth();
  const [openDeliveryDate, setOpenDeliveryDate] = useState(false);
  const { values, setFieldValue } = useFormikContext();
  const dateName = user.company.settings.date_name;

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <Field name="deliveryDate">
        {({ field, form, meta }) => {
          const errorMessage = meta.error || form.errors[field.name];
          const error = (meta.touched || form.submitCount > 0) && errorMessage;

          return (
            <DatePicker
              open={openDeliveryDate}
              onOpen={() => setOpenDeliveryDate(true)}
              onClose={() => setOpenDeliveryDate(false)}
              label={dateName || labels.order_delivery_date}
              minDate={minDate}
              value={values.deliveryDate || null}
              onChange={date => {
                const dateFormat = "yyyy-MM-DDTHH:mm:ss";
                setFieldValue("deliveryDate", moment(date).format(dateFormat));
              }}
              renderInput={inputProps => (
                <TextField
                  fullWidth
                  size="small"
                  variant="outlined"
                  {...inputProps}
                  error={error}
                  helperText={errorMessage}
                  autoComplete="off"
                  onClick={e => {
                    setOpenDeliveryDate(true);
                  }}
                />
              )}
            />
          );
        }}
      </Field>
    </LocalizationProvider>
  );
}
