import React, {useEffect, useState} from "react";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";

import {useStyles} from "../Page";

const BarcodeField = ({ barcode: defaultCode, disabled, error, onSave, ...props }) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [code, setCode] = useState(defaultCode);

  const handleDetectedBarcode = async code => {
    setLoading(true);

    await onSave(code);

    setLoading(false);
  };

  useEffect(() => {
    if (code == defaultCode) return;
    const timeOutId = setTimeout(() => handleDetectedBarcode(code), 700);
    return () => clearTimeout(timeOutId);
  }, [code]);

  useEffect(() => {
    setCode(defaultCode);
  }, [defaultCode]);

  return (
    <FormControl fullWidth className={classes.margin}>
      <TextField
        {...props}
        error={!!error}
        helperText={error}
        placeholder="Barcode"
        label="Barcode"
        disabled={disabled || loading}
        value={code || ""}
        onChange={(e, v) => setCode(e.target.value)}
        variant="outlined"
      />
    </FormControl>
  );
};

export default BarcodeField;
