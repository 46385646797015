function randInterval(min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

const rand = () => Math.random() < 0.2;

const getRandList = () => [
  [rand(), rand()],
  [rand(), rand()],
  [rand(), rand()],
  [rand(), rand()],
];

const list = [
  {
    name: "ROW01",
    list: new Array(randInterval(10, 12)).fill().map(v => getRandList()),
  },
  {
    name: "ROW02",
    list: new Array(randInterval(10, 12)).fill().map(v => getRandList()),
  },
  {
    name: "ROW03",
    list: new Array(randInterval(10, 12)).fill().map(v => getRandList()),
  },
  {
    name: "ROW04",
    list: new Array(randInterval(10, 12)).fill().map(v => getRandList()),
  },
  {
    name: "ROW05",
    list: new Array(9).fill().map(v => getRandList()),
  },
  {
    name: "ROW06",
    list: new Array(9).fill().map(v => getRandList()),
  },
];

export default list;
