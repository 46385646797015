import React from "react";
import { FastField } from "formik";
import Radio from "@material-ui/core/Radio";
import RadioGroupMui from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  radioForm: {
    marginBottom: 0,
  }
}));

const onChangeField = (field, onChange = null) => (event) => {
  field.onChange(event)
  if (onChange) { onChange(field.name, event.target.value) }
}

const DefaultRadioComponent = ({ option, labelPlacement, disabled, classes, size }) =>
  <FormControlLabel
    className={classes.radioForm}
    labelPlacement={labelPlacement}
    value={option[0]}
    control={<Radio size={size} mb={0} disabled={disabled} color="primary" />}
    label={<Typography color="textSecondary" variant="body2">{option[1]}</Typography>}
  />

const DefaultRadioComponentWrap = ({ row, label, name, disabled, onChange, children }) =>
  <FastField name={name}>
    {({ field, form, meta }) =>
      <RadioGroupMui
        disabled={disabled}
        row={row}
        aria-label={label}
        name={name}
        {...field}
        onChange={onChangeField(field, onChange)}
        value={field.value || ""}
      >
        {children}
      </RadioGroupMui>
    }
  </FastField>

const RadioGroup = ({
  label,
  labelPlacement = "end",
  size = "medium",
  row = false,
  options,
  control,
  component,
  componentWrap,
  className,
  name,
  disabled,
  onChange,
}) => {
  const withoutRadioLabel = !!component;
  const RadioComponent = component || DefaultRadioComponent;
  const RadioComponentWrap = componentWrap || DefaultRadioComponentWrap;
  const classes = useStyles();

  return (
    <FormControl className={className} fullWidth>
      {label && <Box mb={size == "small" ? 0 : 2}>
        <FormLabel component="legend">{label}</FormLabel>
      </Box>}

      <RadioComponentWrap
        disabled={disabled}
        row={row}
        label={label}
        name={name}
        onChange={onChange}
      >
        {options.map((option) => (
          <Box key={option[0]} mb={0} clone>
            <RadioComponent
              classes={classes}
              size={size}
              disabled={disabled}
              name={name}
              option={option}
              withoutRadioLabel={withoutRadioLabel}
              labelPlacement={labelPlacement}
            />
          </Box>
        ))}
      </RadioComponentWrap>
    </FormControl>
  );
};

export default React.memo(RadioGroup);
