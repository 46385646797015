import React, {useState} from "react";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";

import ActionModal from "./modals/action-modal";

const ConfirmModal = ({ onConfirm, title, children }) => {
  const [modalOpened, setModalOpened] = useState(false);

  const closeModal = () => setModalOpened(false);
  const openModal = () => setModalOpened(true);

  return (
    <>
      {React.cloneElement(children, {
        ...children.props,
        onClick: openModal,
      })}

      <ActionModal
        title={title || "Are you confirm action?"}
        open={modalOpened}
        onClose={closeModal}
        maxWidth={440}
        disableTransition
      >
        <Box mt={3} display="flex" justifyContent="space-between">
          <Button size="large" key="override" variant="outlined" onClick={closeModal}>
            Cancel
          </Button>

          <Button
            size="large"
            key="override"
            variant="contained"
            color="primary"
            onClick={() => onConfirm().then(() => closeModal())}
          >
            Delete
          </Button>
        </Box>
      </ActionModal>
    </>
  );
};

export default ConfirmModal;
