import React from "react";
import Button from "@mui/material/Button";

export default function TimeButton({ disabled, selected, children, onClick }) {
  return (
    <Button
      variant={selected ? "contained" : "text"}
      color={selected ? undefined : "inherit"}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </Button>
  );
}
