import React, { useState } from "react";
import { Field, useFormikContext } from "formik";
import TextField from "@material-ui/core/TextField";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import SvgIcon from "@mui/material/SvgIcon";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import ScanditScanner, { configureScandit } from "../barcode/ScanditScanner/ScanditScanner";
import useAuth from "../../hooks/useAuth";

const ScannerInput = props => {
  const { user } = useAuth();
  const { setFieldValue } = useFormikContext();
  const [openScan, setOpenScan] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  configureScandit(user.settings.scandid_license);

  const handleToggleScan = () => {
    setOpenScan(v => !v);
  };

  const handleDetectedCode = code => {
    setFieldValue(props.name, code);
    handleToggleScan();
  };

  return (
    <Box position="relative">
      <Field
        {...props}
        render={({ field, form, meta }) => {
          const errorMessage = meta.error || form.errors[field.name];
          const error = (meta.touched || form.submitCount > 0) && errorMessage;

          return (
            <TextField
              fullWidth
              {...field}
              value={field.value || ""}
              {...props}
              size="small"
              autoComplete="off"
              error={!!error}
              helperText={error}
              variant="outlined"
              onFocus={event => {
                setAnchorEl(event.currentTarget);
              }}
              onBlur={() => {
                setAnchorEl(null);
              }}
            />
          );
        }}
      />
      {open && (
        <Box zIndex={999} mt={0.5} top="100%" position="absolute" left={0} right={0}>
          <Box bgcolor="white">
            <Button
              fullWidth
              onMouseDown={handleToggleScan}
              variant="outlined"
              startIcon={
                <SvgIcon fontSize="small">
                  <QrCodeScannerIcon />
                </SvgIcon>
              }
            >
              Scan a Barcode
            </Button>
          </Box>
        </Box>
      )}
      {openScan && (
        <ScanditScanner
          barcodeTrigger={user.company.settings.barcode_trigger}
          onDetected={handleDetectedCode}
          onClose={handleToggleScan}
        />
      )}
    </Box>
  );
};

export default ScannerInput;
