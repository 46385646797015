import React from "react";
import Box from "@material-ui/core/Box";

import {useAssetsCollection} from "../../../../contexts/assetsCollection";
import useMutation from "../../../../hooks/useMutation";
import {ASSETS_BULK_UPDATE_MUTATION} from "../../../../constants/graphql";
import {replaceCollection} from "../../../../components/TablePro/utils";
import ActionModal from "../../../../components/modals/action-modal";
import MultipleDestinationRoomNumberForm
  from "../../../../components/TablePro/fields/assets/MultipleDestinationRoomNumberForm";

export default function MultipleDestinationRoomNumberModal({ open, toggleModal, entity }) {
  const { setCollection, setLoadingMutation } = useAssetsCollection();
  const [bulkUpdateAssets] = useMutation(ASSETS_BULK_UPDATE_MUTATION);

  const handleSave = values => {
    setLoadingMutation(true);
    replaceCollection(setCollection, { [entity.id]: values });
    bulkUpdateAssets({
      variables: { input: { id: entity.id, ...values } },
      onSuccess: () => {
        setLoadingMutation(false);
        toggleModal();
      },
    });
  };

  return open ? (
    <ActionModal
      open
      title="Destination Room Number"
      maxWidth={540}
      onClose={toggleModal}
      disableTransition
    >
      <Box mt={1} />
      <MultipleDestinationRoomNumberForm
        destinationRoomNumbers={entity.destinationRoomNumbers || []}
        toggleModal={toggleModal}
        onSubmit={handleSave}
      />
    </ActionModal>
  ) : null;
}
