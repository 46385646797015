import React from "react";
import moment from "moment";
import Box from "@mui/material/Box";
import useTimer from "../../../../hooks/shipment/useTimer";

import {serverFormatSeconds} from "src/utils/dates";

export default function RaceMode({ startTime, completeTime }) {
  const start = moment(startTime, serverFormatSeconds);
  const end = completeTime ? moment(completeTime, serverFormatSeconds) : completeTime;
  const [minutes, seconds] = useTimer(start, end);

  return (
    <Box display="flex" fontWeight={500}>
      Race Mode
      <Box ml={2}>
        {minutes} m {seconds} s
      </Box>
    </Box>
  );
}
