import { useMutation } from "@apollo/react-hooks";
import parseErrors from "src/utils/parseErrors";

export default (
  mutation,
  { onFailure: onFailureGlobal, onSuccess: onSuccessGlobal, ...rest } = {}
) => {
  const [mutationFunc, ...otherFuncs] = useMutation(mutation, rest);

  const mutationFuncWrap = (
    { onSuccess, onFailure, ...props } = {},
    ...restProps
  ) =>
    mutationFunc(props, ...(restProps || []))
      .then((response) => {
        const { data } = response;
        const responseData = Object.values(data)[0];

        if (responseData.errors) {
          const parsedErrors = parseErrors(responseData.errors);
          if (onFailureGlobal) onFailureGlobal(parsedErrors);
          if (onFailure) onFailure(parsedErrors);
        } else {
          if (onSuccessGlobal) onSuccessGlobal(responseData);
          if (onSuccess) onSuccess(responseData);
        }

        return response;
      })
      .catch((error) => {
        if (onFailureGlobal) onFailureGlobal({ submit: error?.message });
        if (onFailure) onFailure({ submit: error?.message });
      });

  return [mutationFuncWrap, ...otherFuncs];
};
