import React, {useState} from "react";
import PropTypes from "prop-types";
import PerfectScrollbar from "react-perfect-scrollbar";
import orderBy from "lodash/orderBy";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import Divider from "@material-ui/core/Divider";
import LinearProgress from "@material-ui/core/LinearProgress";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@mui/material/Typography";
import TableSortLabel from "@material-ui/core/TableSortLabel";

import Header from "./Header";
import NoRecordsTable from "./NoRecordsTable";
import EntityTableRow from "./EntityTableRow";

export const applyPagination = (entities, page, limit) => {
  return entities.slice(page * limit, page * limit + limit);
};

export const EntityTable = ({
  className,
  configuration,
  entities,
  setEntities,
  page,
  limit,
  loading,
  sort,
}) => {
  const [orderDirection, setorderDirection] = useState(sort?.direction || "asc");
  const [valueToOrderBy, setvalueToOrderBy] = useState(sort?.order);

  const handleSort = value => {
    setvalueToOrderBy(value);
    setorderDirection(v => (v === "asc" ? "desc" : "asc"));
  };

  const tableWithActions =
    configuration.editLink || configuration.customLink || configuration.removeMutation;

  let strategy = "renderTable";
  if (entities.length === 0) {
    strategy = "noRecords";
  }
  if (loading) {
    strategy = "loading";
  }

  return (
    <Table className={className}>
      <TableHead>
        <TableRow>
          {configuration.fields.map(({ label, path }, index) => (
            <TableCell key={`tablecell-${configuration.title}-${index}`}>
              {sort ? (
                <TableSortLabel
                  active={valueToOrderBy === path}
                  direction={valueToOrderBy === path ? orderDirection : "asc"}
                  onClick={() => handleSort(path)}
                >
                  <Typography variant="body1" fontWeight={500}>
                    {label}
                  </Typography>
                </TableSortLabel>
              ) : (
                <Typography variant="body1" fontWeight={500}>
                  {label}
                </Typography>
              )}
            </TableCell>
          ))}
          {configuration.nestedTable && (
            <TableCell>
              <Typography variant="body1" fontWeight={500}>
                {configuration.nestedTable.title}
              </Typography>
            </TableCell>
          )}
          {tableWithActions && (
            <TableCell align="right">
              <Typography variant="body1" fontWeight={500}>
                Actions
              </Typography>
            </TableCell>
          )}
        </TableRow>
      </TableHead>
      <TableBody>
        {strategy === "loading" && (
          <TableRow>
            <TableCell colSpan={configuration.fields.length + 2}>
              <LinearProgress className="mt-2" />
            </TableCell>
          </TableRow>
        )}
        {strategy === "noRecords" && <NoRecordsTable configuration={configuration} />}
        {strategy === "renderTable" &&
          applyPagination(
            orderBy(entities, valueToOrderBy, orderDirection),
            page,
            limit
          ).map((entity, index) => (
            <EntityTableRow
              configuration={configuration}
              entity={entity}
              setEntities={setEntities}
              key={`${entity.id || index}`}
            />
          ))}
      </TableBody>
    </Table>
  );
};

const Results = ({ setEntities, entities, configuration, loading, sort, rowsPerPage = 10 }) => {
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(rowsPerPage);
  const handlePageChange = (event, newPage) => setPage(newPage);
  const handleLimitChange = event => setLimit(parseInt(event.target.value));

  return (
    <>
      <Header configuration={configuration} />
      <div>
        <Typography color="textSecondary" gutterBottom variant="body2">
          {entities.length} Records found. Page {page + 1} of {Math.ceil(entities.length / limit)}
        </Typography>
        <Card>
          <Divider />
          <PerfectScrollbar>
            <Box minWidth={1150}>
              <EntityTable
                configuration={configuration}
                setEntities={setEntities}
                entities={entities}
                page={page}
                limit={limit}
                loading={loading}
                sort={sort}
              />
            </Box>
          </PerfectScrollbar>
          <TablePagination
            component="div"
            count={entities.length}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleLimitChange}
            page={page}
            rowsPerPage={limit}
            rowsPerPageOptions={[5, 10, 25, 50]}
          />
        </Card>
      </div>
    </>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  entities: PropTypes.array.isRequired,
};

Results.defaultProps = {
  entities: [],
};

export default Results;
