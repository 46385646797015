import React from 'react';
import { Typography } from "@material-ui/core";

const UrlField = ({ value }) => {
  if (!value) return <div>No link</div>

  return (
    <Typography color="primary" variant="body2">
      <a target="_blank" href={value}>{"Go to link"}</a>
    </Typography>
  )
};

export default UrlField;
