import React from "react";

import {sortImages} from "../../../utils/images";
import {useAssetsCollection} from "../../../contexts/assetsCollection";
import ImageModal from "../../images/ImageModal";
import ImageComponent from "../../TablePro/fields/ImageComponent";

export default function ImageInput({ entity, value, onClose }) {
  const { updateImage, removeImage, bulkCreateImages } = useAssetsCollection();
  const images = sortImages(entity?.images);

  const handleBulkCreateImages = ({ variables, onSuccess, onFailure }) => {
    bulkCreateImages({
      variables: { ...variables, assetId: entity?.id },
      onSuccess,
      onFailure,
    });
  };

  return (
    <>
      <ImageComponent value={value} />
      <ImageModal
        open={true}
        asset={entity}
        images={images}
        selectedId={images[0]?.id}
        onClose={onClose}
        onRemove={removeImage}
        onUpdate={updateImage}
        onCreate={handleBulkCreateImages}
      />
    </>
  );
}
