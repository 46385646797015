import React, { useEffect, useMemo, useState } from "react";
import { useFormikContext } from "formik";
import groupBy from "lodash/groupBy";
import orderBy from "lodash/orderBy";
import PerfectScrollbar from "react-perfect-scrollbar";
import TableBody from "@material-ui/core/TableBody";
import Table from "@material-ui/core/Table";
import Card from "@material-ui/core/Card";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";

import Input from "../../../components/form/Input";
import { usePermissions } from "../../../contexts/permissions";
import CartItemsHeader from "../../shopping/Cart/CartItemsHeader";
import CartItemFields from "./CartItemFields";
import CartItemsReorder from "./CartItemsReorder";
import CartItemsView from "./CartItemsView";

export function useLocations(inventoryItems) {
  const { values } = useFormikContext();
  const [list, setList] = useState([]);

  useEffect(() => {
    const locationOrder = values.locationOrder || {};
    const newList = Object.entries(
      groupBy(
        orderBy(Object.values(inventoryItems), "position").map((v, index) => ({ ...v, index })),
        "location"
      )
    );
    const orderedList = orderBy(newList, item => locationOrder[item[0].split(",")[0]]);
    setList(orderedList);
  }, [values, inventoryItems]);
  return [list, setList];
}

export default function CartItems({
  items,
  display,
  submitCounter,
  removeInventoryItem,
  updateInventoryItem,
  reFetch,
  locationNotesDisabled, // Disables location notes in inventory request modal if within cutoff window
}) {
  const { values, setFieldValue } = useFormikContext();
  const { user } = usePermissions();
  const wizardMode = user.company?.settings?.wizard_mode;
  const [view, setView] = useState("edit");
  const orderedItems = useMemo(() => {
    return wizardMode
      ? items.map((v, index) => ({ ...v, index }))
      : items.map((v, index) => ({ ...v, location: values.location, index }));
  }, [wizardMode, items, values.location]);
  const [orderedList, setOrderedList] = useLocations(orderedItems);

  return (
    <Card>
      {wizardMode && (
        <Box mb={2}>
          <CartItemsView value={view} onChange={setView} />
        </Box>
      )}
      {view === "edit" ? (
        <PerfectScrollbar>
          <Box minWidth={700}>
            {orderedList.map(([value, list], index) => (
              <Box
                key={value}
                mt={index === 0 ? 0 : 3}
                borderRadius={3}
                p={2}
                border="1px solid #3f51b5"
              >
                {wizardMode && (
                  <>
                    <Input
                      type="text"
                      name={`locationNotes[${value.split(",")[0]}]`}
                      label={`${value.split(",")[0]} Scope`}
                      disabled={locationNotesDisabled}
                    />
                    <Box mt={2} />
                  </>
                )}
                <Paper>
                  <Table>
                    <CartItemsHeader display={display} />
                    <TableBody>
                      {list.map(item => {
                        const asset = item.asset;
                        const price = asset.price * item.quantity;
                        return (
                          <CartItemFields
                            key={`${item.id}-${asset.id}`}
                            submitCounter={submitCounter}
                            inventoryItem={item}
                            price={price}
                            display={display}
                            asset={asset}
                            reFetch={reFetch}
                            removeInventoryItem={removeInventoryItem}
                            setFieldValue={setFieldValue}
                          />
                        );
                      })}
                    </TableBody>
                  </Table>
                </Paper>
              </Box>
            ))}
          </Box>
        </PerfectScrollbar>
      ) : (
        <CartItemsReorder
          items={orderedList}
          setOrderedList={setOrderedList}
          display={display}
          reFetch={reFetch}
        />
      )}
    </Card>
  );
}
