import React, {useState} from "react";
import MuiSelect from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import {makeStyles} from "@material-ui/core";

import {
  useOnEscEffect,
  useOnReFocuse,
  useOnShiftEnterEffect,
  useOnShiftTabEffect,
  useOnTabEffect,
} from "../../../utils/keyEffects";

const useStyles = makeStyles(theme => ({
  muiSelect: {
    width: "100%",
  },
}));

export default function SelectField({
  field,
  onSubmit,
  setValue,
  value,
  innerRef,
  onRevert,
  setEdit,
  rowIndex,
  columnIndex,
  nextRow,
  prevRow,
  nextColumn,
  prevColumn,
  onClose,
  onChange,
  options,
  ...props
}) {
  const classes = useStyles({ field });
  const [open, setOpen] = useState(true);

  const handleClose = () => {
    setOpen(false);
    onRevert();
    onClose();
  };

  const onSubmitPrevRow = () => {
    setOpen(false);
    onSubmit();
    prevRow();
  };

  const onSubmitNextCol = () => {
    setOpen(false);
    onSubmit();
    nextColumn();
  };

  const onSubmitPrevCol = () => {
    setOpen(false);
    onSubmit();
    prevColumn();
  };

  const handleSubmit = value => {
    setOpen(false);
    onSubmit(value);
    nextRow();
  };

  useOnShiftEnterEffect(innerRef, onSubmitPrevRow);
  useOnTabEffect(innerRef, onSubmitNextCol);
  useOnShiftTabEffect(innerRef, onSubmitPrevCol);
  useOnReFocuse(innerRef, handleClose);
  useOnEscEffect(innerRef, handleClose);

  return (
    <FormControl fullWidth variant="outlined" size="small">
      <MuiSelect
        open={open}
        onClick={() => setOpen(true)}
        ref={innerRef}
        data-id={field.id}
        labelId={field.id}
        id={field.id}
        value={value}
        {...props}
        displayEmpty
        onClose={handleClose}
        onChange={event => handleSubmit(event.target.value)}
        classes={{
          selectMenu: classes.muiSelect,
        }}
      >
        <MenuItem data-parent-id={field.id} data-id={`${field.id}-none-item`} value="">
          <em>None</em>
        </MenuItem>

        {options.map(([optionValue, label]) => (
          <MenuItem
            key={optionValue}
            value={optionValue}
            data-parent-id={field.id}
            data-select-id={field.id}
            data-id={`${field.id}-${optionValue}-item`}
          >
            {label}
          </MenuItem>
        ))}
      </MuiSelect>
    </FormControl>
  );
}
