import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import FilterListIcon from "@material-ui/icons/FilterList";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
  button: {
    position: "absolute",
    width: "100%",
    height: 40,
    top: "100%",
    left: 0,
    right: 0,
    zIndex: 2,
    borderRadius: 0,
    color: theme.palette.text.secondary,
    background: theme.palette.background.default,
    justifyContent: "flex-start",
    textTransform: "none",
    "&:hover": {
      backgroundColor: theme.palette.background.dark,
    }
  },
}));

const AdvancedSearchButton = ({ onClick, className }) => {
  const classes = useStyles();

  return (
    <Button
      variant="outlined"
      className={clsx(classes.button, className)}
      startIcon={<FilterListIcon/>}
      onClick={onClick}
    >
      Advanced Search
    </Button>
  );
};

AdvancedSearchButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string
};

export default AdvancedSearchButton;
