import React from "react";
import moment from "moment";
import PopupState, {bindMenu, bindTrigger} from "material-ui-popup-state";
import {useQuery} from "@apollo/react-hooks";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import LinearProgress from "@mui/material/LinearProgress";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CloseIcon from "@mui/icons-material/Close";
import MenuItem from "@material-ui/core/MenuItem";
import MuiMenu from "@material-ui/core/Menu";
import ListItemText from "@material-ui/core/ListItemText";

import {ASSET_REPLAY_LOGS_QUERY} from "../../constants/graphql/queries";

import ReservedInventoryList from "./ReservedInventoryList";
import AssetReplayLogRackLocation from "src/views/asset/AssetReplayLogRackLocation";
import useAuth from "src/hooks/useAuth";

const DATE_FORMAT = "MM/DD/yyyy hh:mm A";
const serverFormat = "yyyy-MM-DDThh:mm:ss Z";

export default function AssetReplayLogs({ assetId }) {
  const [selected, setSelected] = React.useState("");
  const { user } = useAuth();
  const { settings } = user.company;
  const { data: { assetReplayLogs = [] } = {}, loading } = useQuery(ASSET_REPLAY_LOGS_QUERY, {
    variables: { assetId },
    fetchPolicy: "network-only",
  });

  return (
    <>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box>
          <Typography fontWeight={500} fontSize={18}>
            Asset Replay Log
          </Typography>
          <Box my={1} fontSize={14}>
            {assetReplayLogs.length} Records
          </Box>
        </Box>
        <Box>
          {selected === "reserved_inventory" && (
            <Button
              variant="outlined"
              color="primary"
              sx={{ textTransform: "none" }}
              onClick={() => setSelected("")}
            >
              Reserved inventory
              <CloseIcon sx={{ ml: 0.5 }} />
            </Button>
          )}
        </Box>
        <Box>
          <PopupState variant="popover" popupId="demo-popup-menu">
            {popupState => (
              <React.Fragment>
                {settings.reservations && (
                  <Button
                    {...bindTrigger(popupState)}
                    variant="outlined"
                    color="primary"
                    sx={{ textTransform: "none" }}
                  >
                    <FilterAltIcon sx={{ mr: 0.5 }} />
                    Filter
                    <KeyboardArrowDownIcon sx={{ ml: 0.5 }} />
                  </Button>
                )}
                <MuiMenu
                  {...bindMenu(popupState)}
                  anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                  transformOrigin={{ vertical: "top", horizontal: "left" }}
                >
                  <MenuItem
                    data-id="select-item"
                    onClick={() => {
                      setSelected("reserved_inventory");
                      popupState.close();
                    }}
                  >
                    <ListItemText primary="Reserved inventory" />
                  </MenuItem>
                </MuiMenu>
              </React.Fragment>
            )}
          </PopupState>
        </Box>
      </Box>
      {selected === "reserved_inventory" ? (
        <Box display="flex" mb={2}>
          <ReservedInventoryList assetId={assetId} />
        </Box>
      ) : (
        <Box display="flex" mb={2} sx={{ overflowY: "auto" }}>
          {loading ? (
            <LinearProgress className="mt-2" />
          ) : assetReplayLogs.length === 0 ? (
            <Box display="flex" justifyContent="center">
              Empty
            </Box>
          ) : (
            <Box>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Date</TableCell>
                      <TableCell align="center">Logged By</TableCell>
                      <TableCell align="center">Record Quantity</TableCell>
                      <TableCell align="center">Order Number</TableCell>
                      <TableCell align="center">Available Quantity</TableCell>
                      {settings.reservations && (
                        <TableCell align="center">Reserved Quantity</TableCell>
                      )}
                      <TableCell align="left" style={{ minWidth: 160 }}>
                        Storage Location(s)
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {assetReplayLogs.map((row, index) => (
                      <TableRow
                        key={index}
                        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                      >
                        <TableCell component="th" scope="row">
                          {moment(row.performedAt, serverFormat).format(DATE_FORMAT)}
                        </TableCell>
                        <TableCell align="center">{row.user?.email}</TableCell>
                        <TableCell align="center">{row.quantity}</TableCell>
                        <TableCell align="center">{row.woNumber}</TableCell>
                        <TableCell align="center">{row.availableQty}</TableCell>
                        {settings.reservations && (
                          <TableCell align="center">{row.reservedQty}</TableCell>
                        )}
                        <TableCell align="left">
                          <AssetReplayLogRackLocation rackLocations={row.rackLocations} />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          )}
        </Box>
      )}
    </>
  );
}