import React, { useState } from "react";
import Box from "@material-ui/core/Box";
import LinearProgress from "@material-ui/core/LinearProgress";
import Button from "@material-ui/core/Button";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";

import useAuth from "../../hooks/useAuth";
import ScanditScanner, { configureScandit } from "./ScanditScanner/ScanditScanner";

const BarCodeInput = ({ onChange, id }) => {
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  configureScandit(user.settings.scandid_license);

  const toggleOpen = () => setOpen(v => !v);

  const handleScan = code => {
    setLoading(true);

    onChange(code, {
      onSuccess: () => {
        setLoading(false);
      },
      onFailure: () => {
        setLoading(false);
      },
    });

    setOpen(false);
  };

  if (open) {
    return (
      <ScanditScanner
        barcodeTrigger={user.company.settings.barcode_trigger}
        onDetected={handleScan}
        onClose={toggleOpen}
      />
    );
  }

  return (
    <Box id={id} display="flex" flexDirection="column" height="100%" justifyContent="space-between">
      <Button
        style={{ height: 40 }}
        variant="contained"
        color="primary"
        fullWidth
        loading={!!loading}
        onClick={toggleOpen}
      >
        <QrCodeScannerIcon style={{ fontSize: 32 }} />
      </Button>
      {loading && <LinearProgress className="mt-2" />}
    </Box>
  );
};

export default BarCodeInput;
