import React, {useEffect, useState} from "react";

const generateKeyboardEffect = condition => (
  ref,
  callback,
  { condition: customCondition, eventType = "keydown" } = {}
) => {
  useEffect(() => {
    if (callback) {
      const listener = event => {
        if (callback && condition(event) && (customCondition ? customCondition(event) : true)) {
          event.preventDefault();
          callback();
        }
      };
      document.addEventListener(eventType, listener);

      return () => {
        document.removeEventListener(eventType, listener);
      };
    }
  }, [ref, callback]);
};

export const useOnEscEffect = generateKeyboardEffect(event => event.keyCode === 27);

export const useOnTabEffect = generateKeyboardEffect(
  event => event.keyCode === 9 && !event.shiftKey
);

export const useDeleteEffect = generateKeyboardEffect(
  event => event.keyCode === 46 || event.keyCode === 8
);

export const useOnShiftTabEffect = generateKeyboardEffect(
  event => event.keyCode === 9 && event.shiftKey
);

export const useOnEnterEffect = generateKeyboardEffect(
  event => event.keyCode === 13 && !event.shiftKey
);

export const useOnShiftEnterEffect = generateKeyboardEffect(
  event => event.keyCode === 13 && event.shiftKey
);

export const useLeaveEffect = allProps => {
  const val = React.useRef();

  React.useEffect(() => {
    val.current = allProps;
  }, [allProps]);

  React.useLayoutEffect(() => {
    return () => {
      const props = val.current;
      const currentValue = props.value;
      const previousValue = props.entity[props.field.id];
      if (currentValue !== previousValue) {
        props.onSubmit();
        console.log("save", currentValue, previousValue);
      }
    };
  }, []);
};

export const useOnAlphaNumEffect = (ref, callback) => {
  useEffect(() => {
    if (callback) {
      const listener = event => {
        const input = String.fromCharCode(event.keyCode);
        const isValid = /[a-zA-Z0-9-_ ]/.test(input);
        if (event.metaKey || event.ctrlKey) {
          return null;
        }

        if (callback && input && isValid) {
          event.preventDefault();
          callback(event.key);
        }
      };
      document.addEventListener("keydown", listener);

      return () => {
        document.removeEventListener("keydown", listener);
      };
    }
  }, [ref, callback]);
};

export const useOnReFocuse = (ref, callback) => {
  useEffect(() => {
    const listener = event => {
      if (
        !callback ||
        !ref.current ||
        ref.current.contains(event.target) ||
        (ref.current.getAttribute("data-id") &&
          ref.current.getAttribute("data-id") === event.target.getAttribute("data-parent-id"))
      ) {
        return;
      }
      callback();
    };
    document.addEventListener("mouseup", listener);
    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);
    return () => {
      document.removeEventListener("mouseup", listener);
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
    };
  }, [ref, callback]);
};

export const useOnCopyPasteEffect = (ref, { onCopy, onPaste, onCut }) => {
  const [ctrlDown, setCtrlDown] = useState(false);
  const ctrlKey = 17;
  const cmdKey = 91;
  const pasteKey = 86; // button "V"
  const copyKey = 67; // button: "C"
  const cutKey = 88; // button: "X"

  useEffect(() => {
    const ctlrDownListener = event => {
      if (event.keyCode == ctrlKey || event.keyCode == cmdKey) {
        setCtrlDown(true);
      }
    };

    const ctlrUpListener = event => {
      if (event.keyCode == ctrlKey || event.keyCode == cmdKey) {
        setCtrlDown(false);
      }
    };

    const actionListener = event => {
      if (ctrlDown && event.keyCode == copyKey) {
        onCopy && onCopy();
      }

      if (ctrlDown && event.keyCode == cutKey) {
        onCut && onCut();
      }

      if (ctrlDown && event.keyCode == pasteKey) {
        onPaste && onPaste();
      }

      return;
    };

    document.addEventListener("keydown", ctlrDownListener);
    document.addEventListener("keyup", ctlrUpListener);
    document.addEventListener("keydown", actionListener);
    return () => {
      document.removeEventListener("keydown", ctlrDownListener);
      document.removeEventListener("keyup", ctlrUpListener);
      document.removeEventListener("keydown", actionListener);
    };
  }, [ref, onCopy, onPaste]);
};

export const useNavigationEffect = (ref, { up, right, left, down }) => {
  useEffect(() => {
    const listener = event => {
      if (event.target.getAttribute("data-skip-navigation-effect")) {
        return;
      }

      if ([37, 38, 39, 40].includes(event.keyCode)) {
        switch (event.keyCode) {
          case 37:
            if (left) {
              event.preventDefault();
              left();
            }
            break;
          case 38:
            if (up) {
              event.preventDefault();
              up();
            }
            break;
          case 39:
            if (right) {
              event.preventDefault();
              right();
            }
            break;
          case 40:
            if (down) {
              event.preventDefault();
              down();
            }
            break;
        }
      }
    };

    document.addEventListener("keydown", listener);

    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [ref, up, right, left, down]);
};
