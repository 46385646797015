import React, {useCallback, useRef} from "react";
import sortBy from "lodash/sortBy";
import {useQuery} from "@apollo/react-hooks";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CloseIcon from "@material-ui/icons/Close";

import {GET_LOCATIONS_QUERY} from "../../../constants/graphql/queries";
import {filterOptions} from "../Cart/InventoryItemAddress";

export const initialValues = { location: "", building: "", room: "", poNumber: "" };

export default function DefaultItems({ values, onChange }) {
  const { data: { locations = [] } = {}, loading } = useQuery(GET_LOCATIONS_QUERY);
  const buildingRef = useRef();
  const roomRef = useRef();
  const poNumberRef = useRef();
  const options = sortBy(locations.map(v => ({ value: `${v.name}, ${v.address}`, label: v.name })));

  const onOptionSelected = option => {
    if (typeof option === "string") {
      const selected = options.find(v => v.value === option);
      return selected ? selected.label : option;
    }

    if (option.inputValue) {
      return option.label;
    }

    return option ? option.label : "";
  };

  const handleChangeField = useCallback(
    name => e => {
      onChange(v => ({ ...v, [name]: e.target.value }));
    },
    [onChange]
  );

  const handleClear = useCallback(() => {
    onChange(initialValues);
    buildingRef.current.value = "";
    roomRef.current.value = "";
    poNumberRef.current.value = "";
  }, [onChange]);

  return (
    <Box position="relative">
      <Card>
        <Box p={1.5} height={68.5} display="flex" alignItems="center">
          <Grid container spacing={1}>
            <Grid item xs={4}>
              <Autocomplete
                size="small"
                freeSolo
                filterOptions={filterOptions}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                getOptionSelected={onOptionSelected}
                getOptionLabel={onOptionSelected}
                onChange={(e, value) => {
                  const location = value?.inputValue || value?.value || "";
                  const [, street = "", city = "", zipCode = ""] = location.split(",");
                  const code = zipCode.trim().split(" ");
                  onChange(v => ({
                    ...v,
                    street: street.trim(),
                    city: city.trim(),
                    zip: code[code.length - 1],
                    location: value?.value || "",
                  }));
                }}
                options={options}
                value={values.location}
                renderInput={inputProps => (
                  <TextField {...inputProps} label="Location" variant="outlined" margin="none" />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                inputRef={buildingRef}
                label="Building"
                placeholder="Building"
                defaultValue={values.building}
                size="small"
                onBlur={handleChangeField("building")}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                inputRef={roomRef}
                label="Room"
                placeholder="Room"
                defaultValue={values.room}
                size="small"
                onBlur={handleChangeField("room")}
              />
            </Grid>
            {/* 
            <Grid item xs={3}>
              <TextField
                inputRef={poNumberRef}
                label="PO Number"
                placeholder="PO Number"
                defaultValue={values.poNumber}
                size="small"
                onBlur={handleChangeField("poNumber")}
              />
            </Grid>
            */}
          </Grid>
        </Box>
      </Card>
      {(values.building || values.room || values.poNumber || values.location) && (
        <Box position="absolute" right={-24} top={-24}>
          <IconButton onClick={handleClear}>
            <CloseIcon />
          </IconButton>
        </Box>
      )}
    </Box>
  );
}
