import React, {useRef} from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

export default function MultipleDestinationRoomNumberComponent({ entity }) {
  const destinationRoomNumbers = entity.destinationRoomNumbers || [];
  const ref = useRef();

  const handleClick = () => {
    const event = new MouseEvent("dblclick", {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    ref.current.parentElement.dispatchEvent(event);
  };

  return (
    <>
      <Box
        ref={ref}
        width="100%"
        height="100%"
        minHeight={18}
        overflow="auto"
        onClick={handleClick}
      >
        {destinationRoomNumbers.map((v, i) => (
          <Typography key={i} fontSize="0.875rem">
            {v.room_number} ({v.sub_qty})
          </Typography>
        ))}
      </Box>
    </>
  );
}
