import React, {useEffect, useMemo, useRef, useState} from "react";
import {useQuery, useSubscription} from "@apollo/react-hooks";
import isEqual from "lodash/isEqual";

import {
  ADD_ASSET_IMAGE_MUTATION,
  ASSETS_BULK_REMOVE_MUTATION,
  ASSETS_COLLECTION_TABLE_QUERY,
  BULK_CREATE_ASSET_IMAGES_MUTATION,
  CREATE_ASSET_MUTATION,
  DELETE_IMAGE_MUTATION,
  INVENTORY_ASSET_STOCKED,
  REMOVE_ASSET_MUTATION,
  UPDATE_ASSET_MUTATION,
  UPDATE_IMAGE_MUTATION,
} from "../constants/graphql";
import useMutation from "../hooks/useMutation";
import useNotification from "../hooks/notification";
import useQueryParams from "../hooks/useQuery";
import {addRecord, removeCollection, replaceRecord, updateCollectionQuery,} from "../components/TablePro/utils";

const NOTIFICATIONS = {
  assetCreated: "New Asset was added",
  assetUpdated: "Asset was updated",
  assetRemoved: "Asset was removed",
  imagesUploaded: "Images have been uploaded",
};

const AssetsCollectionContext = React.createContext(null);

export const AssetsCollectionProvider = ({
  children,
  variables = {},
  query = ASSETS_COLLECTION_TABLE_QUERY,
  type,
}) => {
  const mounted = useRef(false);
  const queryParams = useQueryParams();
  const { notifyError, notifySuccess } = useNotification();
  const [loading, setLoading] = useState(true);
  const [loadingMutation, setLoadingMutation] = useState(false);
  const [collection, setCollection] = useState([]);
  const [metadata, setMetadata] = useState({});

  const [createAsset] = useMutation(CREATE_ASSET_MUTATION);
  const [updateAsset] = useMutation(UPDATE_ASSET_MUTATION);
  const [removeAsset] = useMutation(REMOVE_ASSET_MUTATION);
  const [removeImage] = useMutation(DELETE_IMAGE_MUTATION);
  const [createImage] = useMutation(ADD_ASSET_IMAGE_MUTATION);
  const [updateImage] = useMutation(UPDATE_IMAGE_MUTATION);
  const [bulkRemoveAssets] = useMutation(ASSETS_BULK_REMOVE_MUTATION);
  const [bulkCreateImages] = useMutation(BULK_CREATE_ASSET_IMAGES_MUTATION);

  const [search, setSearch] = useState(variables.search);
  const [limit, setLimit] = useState(variables.limit || 40);
  const hasMissingItems = queryParams.get("missingItems") === "true";

  const collectionFilter = {
    ...variables,
    search: search,
    limit,
    hasMissingItems,
    page: variables.page || 1,
    type,
  };

  const { fetchMore, refetch } = useQuery(query, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-first",
    variables: collectionFilter,
    onCompleted(data) {
      onFetch(data);
    },
  });

  const onFetch = ({ assetsCollection = {} } = {}) => {
    setCollection(assetsCollection?.collection || []);
    setMetadata(assetsCollection?.metadata || {});
    setLoading(false);
  };

  const handeRefetch = () => {
    setLoading(true);

    refetch(collectionFilter)
      .then(({ data }) => {
        onFetch(data);
      })
      .catch(() => setLoading(false));
  };

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
    } else {
      handeRefetch();
    }
  }, [...Object.values(collectionFilter)]);

  const onLoadMore = () => {
    fetchMore({
      variables: { ...collectionFilter, page: metadata.currentPage + 1 },
      updateQuery: updateCollectionQuery("assetsCollection", {
        collection,
        metadata,
        onSuccess: onFetch,
        onFailure: () => {
          setLoading(false);
        },
      }),
    });
  };

  useSubscription(INVENTORY_ASSET_STOCKED, {
    onSubscriptionData: ({ subscriptionData: { data: { inventoryAssetStocked } = {} } = {} }) => {
      const existingAsset = collection?.find(asset => asset.id === inventoryAssetStocked.id);

      if (!existingAsset) return;

      const newAsset = { ...existingAsset, ...inventoryAssetStocked };

      if (isEqual(Object.values(existingAsset).sort(), Object.values(newAsset).sort())) {
        return;
      }

      replaceRecord(setCollection, {
        id: inventoryAssetStocked.id,
        values: newAsset,
      });
    },
  });

  const handleUpdateAsset = ({ variables, onSuccess, onFailure }) => {
    setLoadingMutation(true);

    updateAsset({
      variables,
      onSuccess: ({ asset }) => {
        replaceRecord(setCollection, {
          id: asset.id,
          values: asset,
        });

        setLoadingMutation(false);
        notifySuccess(NOTIFICATIONS.assetUpdated);
        if (onSuccess) onSuccess({ asset });
      },
      onFailure: errors => {
        notifyError(errors);
        setLoadingMutation(false);
        if (onFailure) onFailure(errors);
      },
    });
  };

  const handleCreateAsset = ({ variables, onSuccess, onFailure }) => {
    setLoadingMutation(true);

    return createAsset({
      variables: variables,
      onSuccess: ({ asset }) => {
        addRecord({ setCollection, setMetadata, record: asset });
        setLoadingMutation(false);
        notifySuccess(NOTIFICATIONS.assetCreated);
        if (onSuccess) onSuccess({ asset });
      },
      onFailure: errors => {
        setLoadingMutation(false);
        if (onFailure) onFailure(errors);
      },
    });
  };

  const handleRemoveAsset = ({ variables, onSuccess, onFailure }) => {
    setLoadingMutation(true);

    return removeAsset({
      variables,
      onSuccess: ({ asset }) => {
        removeCollection({ setCollection, setMetadata, ids: [asset.id] });
        setLoadingMutation(false);
        notifySuccess(NOTIFICATIONS.assetRemoved);
        onSuccess && onSuccess({ asset });
      },
      onFailure: errors => {
        setLoadingMutation(false);
        onFailure && onFailure(errors);
      },
    });
  };

  const handleRemoveImage = ({ variables, onSuccess, onFailure }) => {
    setLoadingMutation(true);

    removeImage({
      variables,
      onSuccess: ({ asset }) => {
        replaceRecord(setCollection, {
          id: asset.id,
          values: { images: asset.images, photo: asset.photo },
        });
        setLoadingMutation(false);
        onSuccess && onSuccess({ asset });
      },
      onFailure: errors => {
        notifyError(errors);
        setLoadingMutation(false);
        onFailure && onFailure(errors);
      },
    });
  };

  const handleCreateImage = ({ variables, onSuccess, onFailure }) => {
    setLoadingMutation(true);
    const defaultImage = collection.find(v => v.id === variables.assetId).images.length === 0;

    createImage({
      variables: { ...variables, default: defaultImage },
      onSuccess: ({ asset }) => {
        replaceRecord(setCollection, {
          id: asset.id,
          values: { images: asset.images, photo: asset.photo },
        });

        setLoadingMutation(false);
        onSuccess && onSuccess({ asset });
      },
      onFailure: errors => {
        notifyError(errors);
        setLoadingMutation(false);
        onFailure && onFailure(errors);
      },
    });
  };

  const handleBulkCreateImages = ({ variables, onSuccess, onFailure }) => {
    setLoadingMutation(true);

    bulkCreateImages({
      variables,
      onSuccess: ({ asset }) => {
        replaceRecord(setCollection, {
          id: asset.id,
          values: { images: asset.images, photo: asset.photo },
        });

        setLoadingMutation(false);
        onSuccess && onSuccess({ asset });
        notifySuccess(NOTIFICATIONS.imagesUploaded);
      },
      onFailure: errors => {
        notifyError(errors);
        setLoadingMutation(false);
        onFailure && onFailure(errors);
      },
    });
  };

  const handleUpdateImage = ({ variables, onSuccess, onFailure }) => {
    setLoadingMutation(true);

    updateImage({
      variables,
      onSuccess: ({ asset }) => {
        replaceRecord(setCollection, {
          id: asset.id,
          values: { images: asset.images, photo: asset.photo },
        });
        setLoadingMutation(false);
        onSuccess && onSuccess({ asset });
      },
      onFailure: errors => {
        notifyError(errors);
        setLoadingMutation(false);
        onFailure && onFailure(errors);
      },
    });
  };

  const handleBulkRemove = ({ variables, onSuccess, onFailure }) => {
    const { ids } = variables;
    setLoadingMutation(true);

    bulkRemoveAssets({
      variables,
      onSuccess: () => {
        removeCollection({ setCollection, setMetadata, ids });
        setLoadingMutation(false);
        onSuccess && onSuccess();
      },
      onFailure: errors => {
        setLoadingMutation(false);
        onFailure && onFailure(errors);
      },
    });
  };

  const collectionIds = useMemo(() => collection?.map(entity => entity.id) || [], [collection]);

  const removeAssetsFromCollection = ids => {
    removeCollection({ setCollection, setMetadata, ids });
  };

  const value = {
    // Collection
    loading,
    setLoading,
    collection,
    setCollection,
    collectionIds,
    handeRefetch,
    onLoadMore,
    search,
    setSearch,
    limit,
    setLimit,

    // Metadata
    setMetadata,
    metadata,
    ...metadata,
    totalIds: metadata.totalIds || [],

    // Mutation
    loadingMutation,
    setLoadingMutation,
    removeAssetsFromCollection,
    removeAsset: handleRemoveAsset,
    createAsset: handleCreateAsset,
    updateAsset: handleUpdateAsset,
    removeImage: handleRemoveImage,
    createImage: handleCreateImage,
    updateImage: handleUpdateImage,
    bulkRemoveAssets: handleBulkRemove,
    bulkCreateImages: handleBulkCreateImages,
  };

  return (
    <AssetsCollectionContext.Provider value={value}>{children}</AssetsCollectionContext.Provider>
  );
};

export const useAssetsCollection = () => {
  const context = React.useContext(AssetsCollectionContext);
  if (!context) {
    throw new Error("useAssetsCollection must be used within a AssetsCollectionProvider");
  }
  return context;
};
