const caller = function(obj, path) {
  if (path == ".") return obj;

  for (var i = 0, path = path.split("."), len = path.length; i < len; i++) {
    if (obj[path[i]] === null || obj[path[i]] === undefined) return;
    obj = obj[path[i]];
  }

  return obj;
};

export const fieldAttrName = (field) => field.attrName || field.id;

export const callerField = (entity, field) =>
  caller(entity, fieldAttrName(field));

export default caller;
