import moment from "moment";
import sortBy from "lodash/sortBy";
import uniqBy from "lodash/uniqBy";

import timeOptions from "../constants/timeOptions";

export function getTimeOptions(startHours) {
  if (startHours) {
    const now = new Date();
    const mins = now.getMinutes();
    const quarterHours = Math.round(mins / 15);
    if (quarterHours === 4) {
      now.setHours(now.getHours() + 1);
    }
    const rounded = (quarterHours * 15) % 60;
    now.setMinutes(rounded);
    const currentTimeIndex = timeOptions.findIndex(v => v === moment(now).format("hh:mm a"));
    return timeOptions.slice(currentTimeIndex);
  } else {
    return timeOptions;
  }
}

export function getRequestors(requestors, value) {
  const list = requestors.map(v => v.name);
  return uniqBy(sortBy(value ? list.concat(value) : list, v => v)).map(value => [value, value]);
}
