import React, { useState } from "react";
import { TextField, makeStyles } from "@material-ui/core";
import { useField } from "formik";
import Box from "@material-ui/core/Box";
import FormControl from "@material-ui/core/FormControl";
import styled from "@emotion/styled";
import isEmpty from "lodash/isEmpty";
import ImageInput from "src/components/form/ImageInput";
import ButtonBase from "@material-ui/core/ButtonBase";

const BarcodeContainer = styled(ButtonBase)`
  svg {
    width: 100%;
    height: 200px;
  }
`;

const useStyles = makeStyles((theme) => ({
  root: {
    "& input": {
      textTransform: "uppercase",
    },
  },
}));

const ScanFileInput = ({ disabled, name, onUpload, label, filestack }) => {
  const [field, _meta, helpers] = useField(name);

  const onUploadField = (fileUrl, { onSuccess, onFailure }) => {
    onUpload(fileUrl, {
      onSuccess: () => {
        helpers.setValue(fileUrl);
        onSuccess();
      },
      onFailure,
    });
  };

  return (
    <ImageInput
      {...field}
      filestack={filestack}
      name={name}
      disabled={disabled}
      label={label}
      imageUrl={isEmpty(field.value) ? null : field.value}
      onUpload={onUploadField}
    />
  );
};

const ScanInput = ({
  imageName,
  onUpload,
  name,
  label,
  disabled,
  filestack = false,
  ...props
}) => {
  const classes = useStyles();
  const [field, meta, helpers] = useField(name);
  const error = meta.touched && meta.error;

  if (!meta.touched && !field.value && props.value) {
    helpers.setValue(props.value);
  }

  return (
    <>
      <FormControl fullWidth>
        <TextField
          {...props}
          {...field}
          label={label}
          error={!!error}
          helperText={error}
          variant="outlined"
          margin="none"
          className={classes.root}
          disabled={disabled}
          onChange={(event) => helpers.setValue(event.target.value)}
          value={field.value || ""}
        />
      </FormControl>

      <Box mt={1} mb={2}>
        {imageName && onUpload && (
          <ScanFileInput
            filestack={filestack}
            name={imageName}
            label={`Upload ${label}`}
            disabled={disabled}
            onUpload={onUpload}
          />
        )}
      </Box>
    </>
  );
};

export default React.memo(ScanInput);
