import React, { useMemo, useReducer } from "react";
import { uniq, filter } from "lodash";

const FavoriteReportFieldsContext = React.createContext(null);

const reducer = (state, action) => {
  switch (action.type) {
    case "addField":
      return {
        ...state,
        selectedFields: uniq([...state.selectedFields, action.field]),
      };
    case "removeField":
      return {
        ...state,
        selectedFields: filter(
          state.selectedFields,
          (item) => item !== action.field
        ),
      };
    default:
      throw new Error();
  }
};

const initialState = {
  selectedFields: [],
};

export const FavoriteReportFieldsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const toggleField = (field, value) => {
    const fieldTags = field.split(".");
    const name = fieldTags[fieldTags.length - 1];

    if (value) {
      dispatch({ type: "addField", field: name });
    } else {
      dispatch({ type: "removeField", field: name });
    }
  };

  const value = useMemo(
    () => ({
      toggleField,
      ...state,
    }),
    [state.selectedFields]
  );

  return (
    <FavoriteReportFieldsContext.Provider value={value}>
      {children}
    </FavoriteReportFieldsContext.Provider>
  );
};

export const useFavoriteReportFields = () =>
  React.useContext(FavoriteReportFieldsContext);

export default FavoriteReportFieldsContext;
