import React, {useState} from "react";
import isEqual from "react-fast-compare";

const UndoContext = React.createContext(null);

export const UndoProvider = ({ children }) => {
  const [list, setList] = useState([]);

  const addAction = item => {
    setList(v => {
      const lastElement = v[v.length - 1];
      if (isEqual(lastElement, item)) {
        return v;
      } else {
        return [...v, item];
      }
    });
  };

  const undo = () => {
    setList(v => v.slice(0, -1));
    return list[list.length - 1];
  };

  return <UndoContext.Provider value={{ addAction, undo }}>{children}</UndoContext.Provider>;
};

export const useUndo = () => React.useContext(UndoContext);
