import React, {useState} from "react";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import {makeStyles} from "@material-ui/core";
import TabForm, {ROLES, TABS} from "./TableTab";

const TabPanel = ({ children, value, tab, ...other }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== tab}
      id={`simple-tabpanel-${value}`}
      aria-labelledby={`simple-tab-${value}`}
      {...other}
    >
      {value === tab && children}
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  roleTitle: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    display: "block",
    lineHeight: "1.5rem",
  },
}));

const GridFields = ({ name, tabs = TABS, roles = Object.keys(ROLES) }) => {
  const classes = useStyles();
  const tabsToRender = tabs;
  const [currentTab, setCurrentTab] = useState("pages");

  return (
    <>
      <Grid container spacing={2}>
        <Grid container item xs={12} spacing={2} alignItems="center" justify="space-between">
          <Grid item xs={12}>
            <Box mt={2}>
              <Tabs
                scrollButtons="auto"
                variant="scrollable"
                textColor="secondary"
                value={currentTab}
                onChange={(_event, value) => setCurrentTab(value)}
              >
                {tabsToRender.map((tab, index) => (
                  <Tab key={`tab-${index}`} label={tab.label} value={tab.id} />
                ))}
              </Tabs>
            </Box>
            <Divider />
          </Grid>
        </Grid>
      </Grid>

      <Box mb={5}></Box>

      <Grid container spacing={1}>
        {roles.length > 1 && (
          <Grid item xs={2} md={1}>
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Role</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {roles.map(key => (
                    <TableRow key={key}>
                      <TableCell style={{ width: "50px" }}>
                        <span className={classes.roleTitle}>{ROLES[key]}</span>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        )}

        <Grid item xs={roles.length > 1 ? 10 : 12} md={roles.length > 1 ? 11 : 12}>
          {tabsToRender.map((tab, index) => (
            <TabPanel key={index} value={tab.id} tab={currentTab}>
              <TabForm name={name} tabKey={tab.id} roles={roles} features={tab.features} />
            </TabPanel>
          ))}
        </Grid>
      </Grid>
    </>
  );
};

export default GridFields;
