import React, {useState} from "react";
import {LinearProgress} from "@material-ui/core";

import useMutation from "../../hooks/useMutation";
import {IMPORT_ASSETS_MUTATION} from "../../constants/graphql";
import useNotification from "../../hooks/notification";
import UploadFileButton from "../../components/UploadFileButton";

const ImportAssetsButton = ({
  projectId,
  component,
  label,
  handleMenuClose,
  keepOpen,
  ...buttonProps
}) => {
  const [loading, setLoading] = useState(false);
  const [importAssets] = useMutation(IMPORT_ASSETS_MUTATION);
  const { notifySuccess, notifyError } = useNotification();

  const onUpload = file => {
    if (!file) return notifyError("You haven't uploaded file");

    setLoading(true);

    return importAssets({
      variables: { projectId, file },
      onSuccess: () => {
        setLoading(false);
        notifySuccess(
          "Importing In-Progess"
        );
        if (handleMenuClose) handleMenuClose();
      },
      onFailure: errors => {
        setLoading(false);
        notifyError(errors);
        if (handleMenuClose) handleMenuClose();
      },
    });
  };

  return (
    <>
      <UploadFileButton
        component={component}
        name="importAssets"
        disabled={loading}
        onUpload={onUpload}
        {...buttonProps}
      >
        {label || "Import Assets"}
      </UploadFileButton>

      {loading && <LinearProgress className="mt-1" />}
    </>
  );
};

export default ImportAssetsButton;
