import React from "react";
import Select from "src/components/form/Select";

const EntitySelect = ({ suggestions = [], suggestionLabel, ...rest }) => {
  const options = suggestions.map(suggestion => [suggestion.id, suggestion[suggestionLabel]])

  return (
    <Select
      options={options}
      {...rest}
    />
  );
};

export default EntitySelect;
