import React from "react";
import Pagination from "@material-ui/lab/Pagination";

export default function PaginationResults({ pagination, disabled, onChange, ...props }) {
  const configuration = pagination.totalPages
    ? {
        disabled: disabled,
        count: pagination.totalPages,
        page: pagination.currentPage,
        onChange,
      }
    : { disabled: true, count: 3, page: 1 };

  return <Pagination {...configuration} {...props} />;
}
