import React, {useRef, useState} from "react";
import {Link as RouterLink} from "react-router-dom";
import {useQuery, useSubscription} from "@apollo/react-hooks";
import clsx from "clsx";
import moment from "moment/moment";
import {makeStyles} from "@material-ui/core";
import Badge from "@material-ui/core/Badge";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import Popover from "@material-ui/core/Popover";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";

import {NEW_MESSAGE_SUBSCRIPTION, NOTIFICATIONS_QUERY} from "../../../../constants/graphql";
import useAuth from "../../../../hooks/useAuth";
import useNotification from "../../../../hooks/notification";
import {serverFormat} from "../../../../utils/dates";
import {useBanner} from "../../../../contexts/banner";

const useStyles = makeStyles(theme => ({
  popover: {
    width: 320,
  },
  badge: {
    height: 12,
    width: 12,
    borderRadius: 10,
    marginTop: 10,
    marginRight: 10,
  },
  hide: {
    display: "none",
  },
  icon: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
  },
}));

export default function Notifications() {
  const classes = useStyles();
  const ref = useRef(null);
  const [isOpen, setOpen] = useState(false);
  const [unread, setUnread] = useState(false);
  const [messages, setMessages] = useState([]);
  const { isAuthenticated, user } = useAuth();
  const { success } = useNotification();
  const { banner, closeBanner } = useBanner();
  const { data: { notifications = [] } = {}, refetch } = useQuery(NOTIFICATIONS_QUERY, {
    variables: { limit: 4 },
  });

  const handleOpen = () => {
    setOpen(true);
    setUnread(false);
  };

  const handleClose = () => setOpen(false);

  useSubscription(NEW_MESSAGE_SUBSCRIPTION, {
    variables: { userId: user?.id },
    onSubscriptionData: ({ subscriptionData }) => {
      refetch();
      setMessages([subscriptionData.data.newMessage, ...messages]);
      if (banner.open) {
        switch (banner.type) {
          case "report": {
            closeBanner();
            success({ hide: 10000, message: "Your report has been successfully generated!" });
            break;
          }
          case "export-excel": {
            closeBanner();
            success({ hide: 5000, message: "Your Excel report has been successfully generated!" });
            break;
          }
          case "download_images": {
            closeBanner();
            success({ hide: 5000, message: "Your image file has been successfully generated!" });
            break;
          }
        }
      } else {
        success({ hide: 10000, message: "New message received" });
      }
      setUnread(true);
    },
    skip: !isAuthenticated,
  });

  return (
    <>
      <Tooltip title="Notifications">
        <Badge
          id="notificationBadge"
          color="error"
          variant="dot"
          classes={{ badge: clsx(classes.badge, { [classes.hide]: !unread }) }}
        >
          <IconButton id="notificationIcon" color="inherit" ref={ref} onClick={handleOpen}>
            <NotificationsNoneOutlinedIcon />
          </IconButton>
        </Badge>
      </Tooltip>
      <Popover
        id="notificationsList"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        classes={{ paper: classes.popover }}
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
      >
        <Box p={2}>
          <Typography variant="h5" color="textPrimary">
            Notifications
          </Typography>
        </Box>
        {notifications.length === 0 ? (
          <Box p={2}>
            <Typography variant="h6" color="textPrimary">
              There are no notifications
            </Typography>
          </Box>
        ) : (
          <>
            <List disablePadding>
              {notifications.map((notification, index) => (
                <ListItem
                  id={`notificationItem-${index}`}
                  divider
                  key={notification.id}
                  button
                  component="a"
                  href={notification.link}
                  target="_blank"
                >
                  <ListItemText
                    primary={notification.title}
                    primaryTypographyProps={{ variant: "subtitle2", color: "textPrimary" }}
                    secondary={moment(notification.createdAt, serverFormat).format("lll")}
                  />
                </ListItem>
              ))}
            </List>
            <Box p={1} display="flex" justifyContent="center">
              <Button component={RouterLink} size="small" to="/notifications">
                See All
              </Button>
            </Box>
          </>
        )}
      </Popover>
    </>
  );
}
