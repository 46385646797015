import React from "react";
import { Draggable, Droppable } from "react-beautiful-dnd";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

import { ASSETS } from "./CartItemsReorder";
import { usePermissions } from "../../../contexts/permissions";

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? "lightblue" : "",
  padding: 4,
});

export default function DroppableItem({ isDragging, item, index }) {
  const { user } = usePermissions();
  const wizardMode = user.company?.settings?.wizard_mode;
  const [location, items] = item;
  const locationLabel = wizardMode ? "Start location" : "Delivery location";

  return (
    <Box display="flex" my={1} border={`1px solid ${isDragging ? "#3949ab" : "#3f51b5"}`}>
      <Box display="flex" flexGrow={1} p={1}>
        <Box minWidth={115} pr={1}>
          <Typography color="textPrimary">
            {index === 0 ? locationLabel : `Stop ${index + 1}`}:
          </Typography>
        </Box>
        <Box flexGrow={1}>
          <Typography color="textSecondary">{location}</Typography>
          <Droppable droppableId={`${index}`} type={ASSETS}>
            {(provided, snapshot) => (
              <div ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
                {items.map((v, i) => {
                  const { asset } = v;
                  const photo = asset?.photo;
                  return (
                    <Draggable
                      key={`${index}${i}`}
                      draggableId={`${index}${i}`}
                      index={i}
                    >
                      {(provided, snapshot) => (
                        <Box
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          mt={1}
                          border={`1px solid ${isDragging ? "#3949ab" : "#d5d5d5"}`}
                          bgcolor="white"
                        >
                          <Box
                            display="flex"
                            alignItems="center"
                            px={1}
                            py={0.5}
                            {...provided.dragHandleProps}
                          >
                            <Box
                              display="flex"
                              alignItems="center"
                              justifyContent="center"
                              bgcolor={photo ? undefined : "#f1f1f1"}
                              boxSizing="content-box"
                              overflow="hidden"
                              width={90}
                              height={60}
                              p={0.5}
                              mr={1}
                            >
                              {photo ? (
                                <img style={{ maxHeight: 60 }} src={photo} alt="" />
                              ) : (
                                <span>No image</span>
                              )}
                            </Box>
                            <Box>
                              <Typography color="textSecondary">{asset.description}</Typography>
                              <Box display="flex">
                                <Box display="flex" mr={1.5}>
                                  <Typography color="textSecondary">
                                    Quantity: {v.quantity}
                                  </Typography>
                                </Box>
                                <Box display="flex" mr={1.5}>
                                  <Typography color="textSecondary">
                                    Building: {v.building}
                                  </Typography>
                                </Box>
                                <Typography color="textSecondary">Room #: {v.room}</Typography>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </Box>
      </Box>
    </Box>
  );
}
