import React from "react";

import {useAssetsCollection} from "../../../contexts/assetsCollection";
import RackLocationsModal from "./RackLocationsModal";
import RackLocationsInput from "./RackLocationsInput";

export default function RackLocationsScrubber({ type, disabled, entity }) {
  const [open, setOpen] = React.useState(false);
  const { collection } = useAssetsCollection();
  const item = collection.find(v => v.id === entity.id);
  const rackLocations = item?.rackLocations || [];

  const toggleModal = () => {
    if (disabled) return;

    setOpen(v => !v);
  };

  return (
    <>
      <RackLocationsModal open={open} toggleModal={toggleModal} entity={item} />
      <RackLocationsInput
        disabled={disabled}
        rackLocations={rackLocations}
        toggleModal={toggleModal}
        onKeyDown={e => {
          if (e.key === "Enter") {
            toggleModal();
          }
        }}
      />
    </>
  );
}
