import React, {useState, useCallback} from "react";
import sortBy from "lodash/sortBy";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import {useQuery} from "@apollo/react-hooks";

import useMutation from "src/hooks/useMutation";
import {TRANSFER_SHIPMENT_MUTATION} from "src/constants/graphql/mutations";
import ActionModal from "src/components/modals/action-modal";
import {CLIENTS_QUERY} from "src/constants/graphql";
import EntitySelect from "src/components/EntitySelect";
import useNotification from "src/hooks/notification";

const getProjects = (clients, selectedClientId) => {
  const selectedClient = clients.find((client) => client.id === selectedClientId);

  if (!selectedClient) {
    return [];
  }

  const projectList = selectedClient.projects.filter((project) => project.active);

  return sortBy(projectList, "name");
};

export default function TransferShipmentModal({ shipment, onTransferSuccess, onClose }) {
    const { notifySuccess, notifyError } = useNotification();
    const [projectId, setProjectId] = useState(null);
    const [transferShipment] = useMutation(TRANSFER_SHIPMENT_MUTATION);
    const { data: { clients = [] } = {} } = useQuery(CLIENTS_QUERY);
  
    const clientId = shipment?.project?.client?.id;
    let projectList = getProjects(clients, clientId);
  
    const handleTransferShipment = useCallback(() => {
      if (!projectId) {
        notifyError("Please select a project");
        return;
      }
  
      transferShipment({
        variables: {
          shipmentId: shipment?.id,
          projectId,
        },
        onSuccess: () => {
          notifySuccess("Shipment successfully transferred");
  
          if (onTransferSuccess) {
            console.log('Calling refetch from onTransferSuccess');
            onTransferSuccess();
          }
  
          onClose();
        },
        onFailure: () => {
          notifyError("Something went wrong during shipment transfer");
        },
      });
    }, [shipment, projectId, transferShipment, onClose, onTransferSuccess, notifyError, notifySuccess]);
  
    return (
      <ActionModal open title="Transfer Shipment" maxWidth={440} onClose={onClose} disableTransition>
        <EntitySelect
          fullWidth
          label="Project"
          value={projectId}
          suggestions={projectList}
          onChange={(projectItem) => {
            setProjectId(projectItem.id);
          }}
        />
        <Box mt={4} />
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Button fullWidth variant="outlined" onClick={onClose}>
              Close
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              disabled={!projectId}
              fullWidth
              variant="contained"
              color="primary"
              onClick={handleTransferShipment}
            >
              Transfer
            </Button>
          </Grid>
        </Grid>
      </ActionModal>
    );
  }
  
