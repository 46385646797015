import React, {useEffect, useState} from "react";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";

import ActionModal from "../modals/action-modal";
import ScannerFields from "./ScannerFields";
import Scanner from "./Scanner";

const ScannerModal = ({ label, onDetected, onClose }) => {
  const [scanOpened, setScanOpened] = useState(false);
  const [barcode, setBarcode] = useState(null);
  const [error, setError] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const handleToggle = () => setScanOpened(v => !v);

  const handleDetectedBarcode = barcode => {
    setDisabled(true);
    const onFailure = errors => {
      setError(errors.code);
    };
    const result = onDetected(barcode, { onFailure });

    if (result?.then) {
      result.then(() => setDisabled(false));
    } else {
      setDisabled(false);
    }
  };

  useEffect(() => {
    if (!barcode) return;
    setError(null);
    const timeOutId = setTimeout(() => handleDetectedBarcode(barcode), 500);
    return () => clearTimeout(timeOutId);
  }, [barcode]);

  if (scanOpened) {
    return <Scanner onDetected={barcode => setBarcode(barcode)} onClose={handleToggle} />;
  }

  return (
    <ActionModal open maxWidth={440} onClose={onClose} title={label} disableTransition>
      <Box pt={1.5}>
        <ScannerFields disabled={disabled} onChange={setBarcode} barcode={barcode} error={error} />
      </Box>

      <Box mt={3} display="flex" justifyContent="flex-end">
        <Button key="override" variant="outlined" onClick={onClose}>
          Close
        </Button>
      </Box>
    </ActionModal>
  );
};

export default ScannerModal;
