import React from "react";
import { Formik } from "formik";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import DialogContent from "@mui/material/DialogContent";
import { useQuery } from "@apollo/react-hooks";
import CircularProgress from "@mui/material/CircularProgress";

import { CaptureProvider } from "../../../../../contexts/capture";
import { fetchAssetValues } from "../../../../../utils/asset";
import { AssetsProvider } from "../../../../../contexts/assets";
import { ClientsProvider } from "../../../../../contexts/clients";
import CollectionProvider from "../../../../../views/capture/CollectionProvider";
import { ASSET_QUERY, REMOVE_ASSET_MUTATION, UPDATE_ASSET_MUTATION } from "../../../../../constants/graphql";
import useMutation from "../../../../../hooks/useMutation";
import { useCustomFields as useCustomProjectFields } from "../../../../../contexts/customFields";
import ConfirmDeleteModal from "../../../../../components/images/ConfirmDeleteModal";
import { GET_SHIPMENT_QUERY } from "../../../../../constants/graphql/queries";
import useQueryParams from "../../../../../hooks/useQueryParams";
import { useItemSchema } from "../useItemSchema";
import FormPage from "./FormPage";

export default function ItemDetails({ id, shipmentItem, reFetch, onClose }) {
  const params = useQueryParams();
  const shipmentId = params.get("id");
  const addShipmentItem = params.get("addShipmentItem") === "true";
  const [openRemoveItem, setOpenRemoveItem] = React.useState(false);
  const [updateAsset] = useMutation(UPDATE_ASSET_MUTATION);
  const [display] = useCustomProjectFields();
  const { data: { asset = {} } = {}, loading } = useQuery(ASSET_QUERY, {
    variables: { id },
  });
  const [removeAsset, { loading: removing }] = useMutation(REMOVE_ASSET_MUTATION, {
    refetchQueries: [{ query: GET_SHIPMENT_QUERY, variables: { id: shipmentId } }],
  });
  const schema = useItemSchema(display);
  const initialValues = fetchAssetValues(asset);
  initialValues.availableQuantity = asset.availableQuantity;

  const toggleRemoveItem = () => {
    setOpenRemoveItem(v => !v);
  };

  const handleSave = ({ attachments, availableQuantity, ...values }) => {
    return updateAsset({
      variables: {
        id,
        input: values,
      },
    }).then(() => onClose());
  };

  const handleRemoveAsset = () => {
    return removeAsset({
      variables: {
        id: asset.id,
      },
    }).then(() => {
      onClose();
    });
  };

  const handleClose = () => {
    if (addShipmentItem) {
      toggleRemoveItem();
    } else {
      onClose();
    }
  };

  return (
    <Dialog open fullWidth maxWidth="xs" onClose={handleClose}>
      <DialogTitle sx={{ background: "#f4f6f8", display: "flex", justifyContent: "space-between" }}>
        <Box width="48px" />
        <Typography fontSize={24} fontWeight={500} color="textPrimary">
          Item Details
        </Typography>
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ background: "#f4f6f8" }}>
        {loading ? (
          <Box height={258} display="flex" justifyContent="center" alignItems="center">
            <CircularProgress color="primary" />
          </Box>
        ) : (
          <Box>
            <CaptureProvider>
              <ClientsProvider>
                <CollectionProvider>
                  <AssetsProvider>
                    <Formik
                      key={asset?.id}
                      initialValues={initialValues}
                      onSubmit={handleSave}
                      validationSchema={schema}
                    >
                      <FormPage
                        type="item"
                        currentAsset={asset}
                        onCloseModal={onClose}
                        handeRefetch={reFetch}
                        shipmentItem={shipmentItem}
                        removing={removing}
                        toggleRemoveItem={toggleRemoveItem}
                      />
                    </Formik>
                  </AssetsProvider>
                </CollectionProvider>
              </ClientsProvider>
            </CaptureProvider>
          </Box>
        )}
      </DialogContent>
      {openRemoveItem && (
        <ConfirmDeleteModal
          title="Delete Item"
          descr="Are you sure want to delete the selected item?"
          onClose={toggleRemoveItem}
          onDelete={handleRemoveAsset}
        />
      )}
    </Dialog>
  );
}
