import React from "react";

import {useAssetsCollection} from "../../../contexts/assetsCollection";
import MultipleDestinationRoomNumberModal
  from "../../../components/TablePro/fields/assets/MultipleDestinationRoomNumberModal";
import MultipleDestinationRoomNumberInput from "./MultipleDestinationRoomNumberInput";

export default function DestinationRoomNumbersScrubber({ type, disabled, entity }) {
  const [open, setOpen] = React.useState(false);
  const { collection } = useAssetsCollection();
  const item = collection.find(v => v.id === entity.id);

  const toggleModal = () => {
    if (disabled) return;

    setOpen(v => !v);
  };

  return (
    <>
      <MultipleDestinationRoomNumberModal open={open} toggleModal={toggleModal} entity={item} />
      <MultipleDestinationRoomNumberInput
        disabled={disabled}
        entity={entity}
        toggleModal={toggleModal}
        onKeyDown={e => {
          if (e.key === "Enter") {
            toggleModal();
          }
        }}
      />
    </>
  );
}
