import React, { Fragment } from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardContent,
  CardActions,
  Button,
  Box,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Formik, Form, FieldArray } from "formik";
import { object, string, array } from "yup";
import clsx from "clsx";
import { uniqueId } from "lodash";

import AdvancedSearchCriteria from "src/components/AdvancedSearch/AdvancedSearchCriteria";
import parseInitialValue from "src/utils/parseAdvancedSearchInitialValue";

const useStyles = makeStyles(theme => ({
  container: {
    position: "absolute",
    width: "100%",
    top: 0,
    left: 0,
    zIndex: 3,
    borderColor: theme.palette.grey[400]
  },
  actions: {
    padding: 16,
    justifyContent: "space-between"
  },
  button: {
    textTransform: "none",
    marginLeft: 12
  },
  cancelButton: {
    color: theme.palette.text.secondary
  },
  operator: {
    marginBottom: 16,
    color: theme.palette.secondary.main,
    fontWeight: "bold"
  }
}))

const MAX_CRITERIA = 2;

const validationSchema = object().shape({
  criteria: array().of(
    object().shape({
      field: string().required("Field is required"),
      text: string().required("Text is required"),
      matchType: string().required("Match type is required"),
    })
  ),
});

const AdvancedSearch = ({ onCancel, onSearch, initialValue, className }) => {
  const classes = useStyles();

  const initialValues = {
    criteria: parseInitialValue(initialValue),
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values) => {
        const searchString = values.criteria
          .map((criterion) => `${criterion.field} ${criterion.matchType} ${criterion.text}`)
          .join(" and ");
        onSearch(searchString);
      }}
      validationSchema={validationSchema}
    >
      {({ values }) => (
        <Form>
          <FieldArray name="criteria">
            {({ push, remove }) => (
              <Card variant="outlined" className={clsx(classes.container, className)}>
                <CardContent>
                  {values.criteria.map((criterion, index) => (
                    <Fragment key={criterion.id}>
                      {index > 0 && <Typography className={classes.operator}>AND</Typography>}
                      <AdvancedSearchCriteria
                        index={index}
                        removeCriterion={remove}
                      />
                    </Fragment>
                  ))}
                </CardContent>
                <CardActions className={classes.actions}>
                  {values.criteria.length < MAX_CRITERIA
                    ? <Button
                      color="secondary"
                      onClick={() => push({
                        id: uniqueId("criteria_"),
                        field: "",
                        text: "",
                        matchType: "~"
                      })}
                    >
                      Add Criteria
                    </Button>
                    : <Box/>
                  }
                  <Box>
                    <Button
                      className={clsx(classes.button, classes.cancelButton)}
                      variant="outlined"
                      onClick={onCancel}
                    >
                      Cancel
                    </Button>
                    <Button
                      className={classes.button}
                      color="secondary"
                      variant="contained"
                      type="submit"
                    >
                      Search
                    </Button>
                  </Box>
                </CardActions>
              </Card>
            )}
          </FieldArray>
        </Form>
      )}
    </Formik>
  )
}

AdvancedSearch.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  initialValue: PropTypes.string,
  className: PropTypes.string
};

export default AdvancedSearch;