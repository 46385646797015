import React from "react";
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

import {useCustomFields} from "../../../contexts/customFields";

const descriptionStyles = {
  maxWidth: "100%",
  maxHeight: "100%",
  textOverflow: "ellipsis",
};

export default function AssetCardTitle({ asset }) {
  const [display] = useCustomFields();

  const getDimension = (value = []) =>
    value
      .map(field =>
        [`${field.width}″&nbsp;W`, `${field.depth}″&nbsp;D`, `${field.height}″&nbsp;H`].join(
          "&nbsp;x&nbsp;"
        )
      )
      .join(", ");

  return (
    <Box mt={2}>
      {display.sc_item_code && (
        <Typography mt={1} variant="h5" align="center" color="textPrimary">
          {asset.itemCode}
        </Typography>
      )}
      <Typography
        align="center"
        style={descriptionStyles}
        dangerouslySetInnerHTML={{
          __html: [
            [
              display.sc_manufacture && asset.manufacturer,
              display.sc_description && asset.description,
            ]
              .filter(v => v)
              .join(" "),
            [display.sc_model && asset.model, getDimension(asset.dimensions)]
              .filter(v => v)
              .join(" "),
          ]
            .filter(v => v)
            .join(", "),
        }}
      />
      <Typography align="center">
        {[display.sc_po_number && asset.poNumber, display.sc_color && asset.color]
          .filter(v => v)
          .join(" - ")}{" "}
        {display.sc_condition && asset.condition && `(${asset.condition})`}
      </Typography>
    </Box>
  );
}

AssetCardTitle.propTypes = {
  asset: PropTypes.object.isRequired,
};
