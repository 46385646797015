import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { Form, Formik } from "formik";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Button from "@mui/material/Button";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@mui/material/CircularProgress";

import { UPDATE_COMPANY_SETTINGS_MUTATION } from "../../constants/graphql";
import Page from "../../components/Page";
import useMutation from "../../hooks/useMutation";
import { useCustomFields } from "../../contexts/customFields";
import useUpdateProjectSettings from "../../hooks/useUpdateProjectSettings";
import useNotification from "../../hooks/notification";
import useAuth from "../../hooks/useAuth";
import Views from "../../components/TablePro/Views";
import IncludeFields from "./IncludeFields";

export default function ProjectSettings({
  storageEnabled,
  originEnabled,
  destinationEnabled,
  ordersTableFieldsEnabled,
  reservationsTableFieldsEnabled,
  hideTitle = true,
  onClose,
}) {
  const { user, setUser } = useAuth();
  const { projectId } = useParams();
  const [updateCompanySettings] = useMutation(UPDATE_COMPANY_SETTINGS_MUTATION);
  const [updateProjectSettings] = useUpdateProjectSettings();
  const [settings, loading] = useCustomFields();
  const [submitLoading, setSubmitLoading] = useState(false);
  const { notifyError, notifySuccess } = useNotification();
  const { company } = user;

  const handleUpdateCompanyDefaultSettings = fieldSettings => {
    setSubmitLoading(true);
    return updateCompanySettings({
      variables: { fieldSettings },
      onSuccess: values => {
        setUser({ ...user, company: { ...company, fieldSettings: values.company.fieldSettings } });
        notifySuccess("Company settings has been updated");
        setSubmitLoading(false);
        if (onClose) {
          onClose();
        }
      },
      onFailure: errors => {
        notifyError(errors);
        setSubmitLoading(false);
      },
    });
  };

  const handleUpdateProjectSettings = fieldSettings => {
    setSubmitLoading(true);
    return updateProjectSettings({
      variables: { projectId, fieldSettings },
      onSuccess: () => {
        notifySuccess("Project's settings has been updated");
        setSubmitLoading(false);
        if (onClose) {
          onClose();
        }
      },
      onFailure: errors => {
        notifyError(errors);
        setSubmitLoading(false);
      },
    });
  };

  return loading ? null : (
    <Page>
      {hideTitle && (
        <Box mb={2} display="flex">
          <Typography color="textSecondary" variant="h2">
            Display Fields
          </Typography>
          <Views />
        </Box>
      )}
      <Formik
        enableReinitialize
        initialValues={settings}
        onSubmit={projectId ? handleUpdateProjectSettings : handleUpdateCompanyDefaultSettings}
      >
        <Form>
          <Box clone p={2}>
            <Paper>
              <IncludeFields
                storageEnabled={storageEnabled}
                originEnabled={originEnabled}
                destinationEnabled={destinationEnabled}
                ordersTableFieldsEnabled={ordersTableFieldsEnabled}
                reservationsTableFieldsEnabled={reservationsTableFieldsEnabled}
              />
            </Paper>
          </Box>
          <Box mt={2} display="flex" justifyContent="flex-end">
            <Button
              disabled={submitLoading}
              startIcon={submitLoading && <CircularProgress size={22} />}
              sx={{ minWidth: 120 }}
              type="submit"
              variant="contained"
              color="primary"
            >
              Save
            </Button>
          </Box>
        </Form>
      </Formik>
    </Page>
  );
}
