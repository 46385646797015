import React from "react";
import Box from "@mui/material/Box";
import ButtonBase from "@mui/material/ButtonBase";

export default function ScanditScanButton({ onClick }) {
  return (
    <Box
      position="fixed"
      bottom={25}
      left={0}
      right={0}
      zIndex={99}
      display="flex"
      justifyContent="center"
    >
      <ButtonBase sx={{ borderRadius: "50%", overflow: "hidden" }} onClick={onClick}>
        <Box bgcolor="white" p={0.5}>
          <Box p={0.25} bgcolor="black" borderRadius="50%">
            <Box width={60} height={60} bgcolor="white" borderRadius="50%" />
          </Box>
        </Box>
      </ButtonBase>
    </Box>
  );
}
