import React from "react";
import camelCase from "lodash/camelCase";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import {getOptions} from "../../../utils/form";
import Input from "../../form/Input";
import BooleanSelectField from "./BooleanSelectField";
import labels, {
  nemaConfigOptions,
  phaseOptions,
  plugConnectionOptions,
  voltsOptions,
} from "../../../constants/displayFields";
import Autocomplete from "../../../components/form/Autocomplete";

export default function ElectricalInfoFields({ fullWidth, display, disabledForm }) {
  const xs = fullWidth ? 12 : 6;

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Box mt={-1} mb={1}>
          <Typography fontWeight={500} fontSize={18}>
            Electrical
          </Typography>
        </Box>
      </Grid>
      {display.volts && (
        <Grid item xs={xs}>
          <Autocomplete
            saveCustomOption
            name={camelCase("volts")}
            label={labels.volts}
            options={getOptions(voltsOptions)}
            disabled={disabledForm}
          />
        </Grid>
      )}
      {display.amps_watts && (
        <Grid item xs={xs}>
          <Input name={camelCase("amps_watts")} label={labels.amps_watts} disabled={disabledForm} />
        </Grid>
      )}
      {display.btu && (
        <Grid item xs={xs}>
          <Input name={camelCase("btu")} label={labels.btu} disabled={disabledForm} />
        </Grid>
      )}
      {display.hertz && (
        <Grid item xs={xs}>
          <Input name={camelCase("hertz")} label={labels.hertz} disabled={disabledForm} />
        </Grid>
      )}
      {display.phase && (
        <Grid item xs={xs}>
          <Autocomplete
            saveCustomOption
            name={camelCase("phase")}
            label={labels.phase}
            options={getOptions(phaseOptions)}
            disabled={disabledForm}
          />
        </Grid>
      )}
      {display.nema_config && (
        <Grid item xs={xs}>
          <Autocomplete
            saveCustomOption
            name={camelCase("nema_config")}
            label={labels.nema_config}
            options={getOptions(nemaConfigOptions)}
            disabled={disabledForm}
          />
        </Grid>
      )}
      {display.plug_quantity && (
        <Grid item xs={xs}>
          <Input
            name={camelCase("plug_quantity")}
            label={labels.plug_quantity}
            disabled={disabledForm}
          />
        </Grid>
      )}
      {display.standby_power && (
        <Grid item xs={xs}>
          <BooleanSelectField
            name={camelCase("standby_power")}
            label={labels.standby_power}
            disabled={disabledForm}
          />
        </Grid>
      )}
      {display.normal_power && (
        <Grid item xs={xs}>
          <BooleanSelectField
            name={camelCase("normal_power")}
            label={labels.normal_power}
            disabled={disabledForm}
          />
        </Grid>
      )}
      {display.dedicated_power && (
        <Grid item xs={xs}>
          <BooleanSelectField
            name={camelCase("dedicated_power")}
            label={labels.dedicated_power}
            disabled={disabledForm}
          />
        </Grid>
      )}
      {display.ups && (
        <Grid item xs={xs}>
          <BooleanSelectField name={camelCase("ups")} label={labels.ups} disabled={disabledForm} />
        </Grid>
      )}
      {display.standby_power_sb && (
        <Grid item xs={xs}>
          <BooleanSelectField
            name={camelCase("standby_power_sb")}
            label={labels.standby_power_sb}
            disabled={disabledForm}
          />
        </Grid>
      )}
      {display.plug_connection && (
        <Grid item xs={xs}>
          <Autocomplete
            saveCustomOption
            name={camelCase("plug_connection")}
            label={labels.plug_connection}
            options={getOptions(plugConnectionOptions)}
            disabled={disabledForm}
          />
        </Grid>
      )}
    </Grid>
  );
}
