import React from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";

const ButtonComponent = ({ disabled, ...props }) => (
  <Button disabled={disabled} component="span" variant="outlined" {...props}>
    {props.children}
  </Button>
);

export default function UploadFileButton({
  component: Component,
  onUpload,
  disabled,
  name,
  id,
  multiple,
  accept,
  ...props
}) {
  const buttonId = id ? id : `contained-button-file-${name}`;

  const onFileAttach = event => {
    const {
      target: { validity, files },
    } = event;
    if (!validity.valid) return;

    onUpload(multiple ? files : files[0]);
    event.target.value = "";
  };

  const ComponentWrap = Component || ButtonComponent;

  return (
    <ComponentWrap disabled={disabled} {...props}>
      <Box display="none" clone>
        <input
          id={buttonId}
          name={name}
          disabled={disabled}
          onChange={onFileAttach}
          type="file"
          multiple={multiple}
          accept={accept}
        />
      </Box>
      <Box width="100%" clone>
        <label
          style={{ marginBottom: 0, cursor: "pointer", textAlign: "center" }}
          htmlFor={buttonId}
        >
          {props.children}
        </label>
      </Box>
    </ComponentWrap>
  );
}
