import React from "react";

import {
  useOnEnterEffect,
  useOnEscEffect,
  useOnReFocuse,
  useOnShiftEnterEffect,
  useOnShiftTabEffect,
  useOnTabEffect,
} from "../../../utils/keyEffects";

const MoneyField = ({
  field,
  onSubmit,
  setValue,
  value,
  innerRef,
  onRevert,
  setEdit,
  rowIndex,
  columnIndex,
  nextRow,
  prevRow,
  nextColumn,
  prevColumn,
  onClose,
  onChange,
  ...props
}) => {
  const onSubmitNextRow = () => {
    onSubmit();
    nextRow();
  };

  const onSubmitPrevRow = () => {
    onSubmit();
    prevRow();
  };

  const onSubmitNextCol = () => {
    onSubmit();
    nextColumn();
  };

  const onSubmitPrevCol = () => {
    onSubmit();
    prevColumn();
  };

  useOnEnterEffect(innerRef, onSubmitNextRow);
  useOnShiftEnterEffect(innerRef, onSubmitPrevRow);
  useOnReFocuse(innerRef, onSubmit);
  useOnEscEffect(innerRef, onRevert);
  useOnTabEffect(innerRef, onSubmitNextCol);
  useOnShiftTabEffect(innerRef, onSubmitPrevCol);

  return (
    <form style={{ width: "100%", height: "100%" }}>
      <input
        ref={innerRef}
        style={{ resize: "none" }}
        autoFocus
        type="number"
        step="0.01"
        value={value === null ? "" : value}
        onChange={e => setValue(parseFloat(e.target.value))}
        {...props}
      />
    </form>
  );
};

export default MoneyField;
