import React, { useCallback, useEffect, useRef, useState } from "react";
import sortBy from "lodash/sortBy";
import { useQuery } from "@apollo/react-hooks";
import Autocomplete, { createFilterOptions } from "@material-ui/lab/Autocomplete";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Skeleton from "@mui/material/Skeleton";

import { useCustomFields } from "../../../contexts/customFields";
import { GET_LOCATIONS_QUERY } from "../../../constants/graphql/queries";
import { onOptionSelected } from "./Address";

const filter = createFilterOptions();

export function filterOptions(options, params) {
  const filtered = filter(options, params);
  const { inputValue } = params;
  const isExisting = options.some(option => inputValue === option.title);
  if (inputValue !== "" && !isExisting) {
    filtered.push({
      inputValue,
      label: inputValue,
    });
  }
  return filtered;
}

export default function InventoryItemAddress({ disabled, error, onChange, inventoryItem }) {
  const { data: { locations = [] } = {}, loading } = useQuery(GET_LOCATIONS_QUERY);
  const [display] = useCustomFields();
  const streetRef = useRef();
  const cityRef = useRef();
  const zipRef = useRef();
  const [selected, setSelected] = useState(inventoryItem.location || "");
  const displayFields = inventoryItem.street || inventoryItem.city || inventoryItem.zip;
  const addressOptions = sortBy(locations.map(v => `${v.name}, ${v.address}`)).map(value => ({
    value,
    label: value,
  }));

  const handleChange = useCallback(
    field => e => {
      const item = { ...inventoryItem, [field]: e.target.value };
      const location = [item.street, item.city, item.zip].filter(v => v).join(", ");
      setSelected(location);
      onChange({ location, [field]: e.target.value });
    },
    [inventoryItem, setSelected, setSelected]
  );

  useEffect(() => {
    setSelected(inventoryItem.location);
  }, [inventoryItem.location]);

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        {loading ? (
          <Skeleton variant="rectangular" width="100%" height={40} />
        ) : (
          <Autocomplete
            disabled={disabled}
            size="small"
            freeSolo
            filterOptions={filterOptions}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            getOptionSelected={onOptionSelected}
            getOptionLabel={onOptionSelected}
            onChange={(e, value) => {
              const location = value?.inputValue || value?.value || "";
              const [, street = "", city = "", zipCode = ""] = location.split(",");
              const code = zipCode.trim().split(" ");
              setSelected(value);
              onChange({
                street: street.trim(),
                city: city.trim(),
                zip: code[code.length - 1],
                location,
              });
              setTimeout(() => {
                if (streetRef.current) {
                  streetRef.current.value = street.trim();
                }
                if (cityRef.current) {
                  cityRef.current.value = city.trim();
                }
                if (zipRef.current) {
                  zipRef.current.value = code[code.length - 1] || "";
                }
              });
            }}
            options={addressOptions}
            value={selected}
            renderInput={inputProps => (
              <TextField
                error={error}
                {...inputProps}
                label="Delivery Location"
                variant="outlined"
                margin="none"
              />
            )}
          />
        )}
      </Grid>
      {(selected || displayFields) && display.ii_location_address && (
        <>
          <Grid item xs={12} md={5}>
            <TextField
              key={inventoryItem.street}
              disabled={disabled}
              inputRef={streetRef}
              fullWidth
              label="Street"
              placeholder="Street"
              defaultValue={inventoryItem.street || ""}
              size="small"
              onBlur={handleChange("street")}
            />
          </Grid>
          <Grid item xs={6} md={5}>
            <TextField
              key={inventoryItem.city}
              disabled={disabled}
              inputRef={cityRef}
              fullWidth
              label="City"
              placeholder="City"
              defaultValue={inventoryItem.city || ""}
              size="small"
              onBlur={handleChange("city")}
            />
          </Grid>
          <Grid item xs={6} md={2}>
            <TextField
              key={inventoryItem.zip}
              disabled={disabled}
              inputRef={zipRef}
              fullWidth
              label="Zip Code"
              placeholder="Zip Code"
              defaultValue={inventoryItem.zip || ""}
              size="small"
              onBlur={handleChange("zip")}
            />
          </Grid>
        </>
      )}
    </Grid>
  );
}
