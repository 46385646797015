import React from "react";
import {Field, useFormikContext} from "formik";
import {useParams} from "react-router-dom";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

import Input from "../../../../components/form/Input";
import {useCustomFields} from "../../../../contexts/customFields";
import DateTimePicker from "../../../../components/ui/DateTimePicker";
import {usePermissions} from "../../../../contexts/permissions";
import {isAdminUser} from "../../../../constants/user";
import NextButton from "./NextButton";
import Section from "./Section";
import {useLocations} from "./Step4";
import LocationsReorder from "./LocationsReorder";
import Label from "./Label";

export const isStartDateGreaterEndDate = values => {
  return (
    values.startDate &&
    values.endDate &&
    new Date(`${values.startDate}T${values.startTime}`) >
      new Date(`${values.endDate}T${values.endTime}`)
  );
};

export const getMinStartDate = cutOff => {
  const now = new Date();
  return cutOff && cutOff !== "0"
    ? now.setHours(now.getHours() + parseInt(cutOff, 10))
    : undefined;
};

export default function Step2({ step }) {
  const { user } = usePermissions();
  const isAdmin = isAdminUser(user.email);
  const { values, setFieldValue } = useFormikContext();
  const { section } = useParams();
  const [display] = useCustomFields();
  const [list, setList] = useLocations();
  const selected = section === step;
  const minStartDate = getMinStartDate(user.company.settings.cut_off);
  const commentsName = user.company.settings.inventory_request_comments_name;

  return (
    <Section title={`${step} Delivery Details`} step={step}>
      {selected ? (
        <Box mt={3}>
          <Box mt={2} />
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Field name="startDate">
                {({ field, form, meta }) => {
                  const errorMessage = meta.error || form.errors[field.name];
                  const error = (meta.touched || form.submitCount > 0) && errorMessage;

                  return (
                    <DateTimePicker
                      label="Start Date and Time"
                      minDate={isAdmin ? undefined : minStartDate}
                      error={error}
                      helperText={errorMessage}
                      defaultValue={
                        values.startDate ? `${values.startDate}T${values.startTime}` : ""
                      }
                      onChange={({ date, time }) => {
                        form.setFieldValue("startDate", date);
                        form.setFieldValue("startTime", time);
                      }}
                    />
                  );
                }}
              </Field>
            </Grid>
            <Grid item xs={6}>
              <DateTimePicker
                label="End Date and Time"
                minDate={values.startDate ? `${values.startDate}T${values.startTime}` : undefined}
                defaultValue={values.endDate ? `${values.endDate}T${values.endTime}` : ""}
                onChange={({ date, time }) => {
                  setFieldValue("endDate", date);
                  setFieldValue("endTime", time);
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <Input name="contactName" label="Contact Name" />
            </Grid>
            <Grid item xs={6}>
              <Input name="contactPhoneNumber" label="Contact Phone Number" />
            </Grid>
          </Grid>
          {display.ir_comment && (
            <Box mt={2}>
              <Input type="text" name="comment" label={commentsName || labels.ir_comment} />
            </Box>
          )}
          <Box mt={1}>
            {list.length ? (
              <LocationsReorder items={list} setItems={setList} />
            ) : (
              <Box display="flex" my={1} border={`1px solid #3f51b5`}>
                <Box display="flex" flexGrow={1} p={1}>
                  <Box minWidth={115} pr={1}>
                    <Typography color="textPrimary">Start location</Typography>
                  </Box>
                  <Box flexGrow={1}>
                    <Typography color="textSecondary">{values.location}</Typography>
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
          <Box display="flex" mt={3}>
            <NextButton step={+step + 1} />
          </Box>
        </Box>
      ) : (
        <Box ml={35}>
          {values.startDate && (
            <Typography color="textSecondary">
              <Label>Start Date:</Label> {values.startDate} {values.startTime}
            </Typography>
          )}
          {values.endDate && (
            <Typography color="textSecondary">
              <Label>End Date:</Label> {values.endDate} {values.endTime}
            </Typography>
          )}
          {values.contactName && (
            <Typography color="textSecondary">
              <Label>Contact Name:</Label> {values.contactName}
            </Typography>
          )}
          {values.contactPhoneNumber && (
            <Typography color="textSecondary">
              <Label>Contact Phone Number:</Label> {values.contactPhoneNumber}
            </Typography>
          )}
          {display.ir_comment && values.comment && (
            <Box display="flex">
              <Label>Comments:</Label>
              <Box ml={1}>
                <Typography
                  color="textSecondary"
                  component="pre"
                  style={{ background: "white", padding: 0 }}
                >
                  {values.comment}
                </Typography>
              </Box>
            </Box>
          )}
          {list.map(([location], index) => (
            <Box
              key={location}
              display="flex"
              my={1}
              px={1}
              py={0.5}
              borderRadius={4}
              border="1px solid #d5d5d5"
            >
              <Box minWidth={115} pr={1}>
                <Typography color="textPrimary">
                  {index === 0 ? "Start location" : `Stop ${index + 1}`}:
                </Typography>
              </Box>
              <Typography color="textSecondary">{location}</Typography>
            </Box>
          ))}
        </Box>
      )}
    </Section>
  );
}
