import React, { useEffect } from "react";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { useField } from "formik";

import Checkbox from "src/components/form/Checkbox";
import labels from "src/constants/displayFields";
import DraggableTags from "../DraggableTags";
import { getDisplayFields } from "src/utils/asset";

const Layer1Fields = () => {
  const [originInfo, , originInfoHelpers] = useField("origin_info");
  const fields = getDisplayFields({ origin_info: originInfo.value });
  const [columnOrder, , columnOrderHelpers] = useField("column_order_packing.layer_1");
  const list = columnOrder.value || [];

  useEffect(() => {
    const newFields = fields.filter(v => !list.includes(v));
    const deleteFields = list.filter(v => !fields.includes(v));

    if (newFields.length > 0) {
      columnOrderHelpers.setValue(list.concat(newFields));
    }

    if (deleteFields.length > 0) {
      columnOrderHelpers.setValue(list.filter(v => !deleteFields.includes(v)));
    }
  }, [fields]);

  const tags = list.map(id => ({ id, content: labels[id] }));

  const handleRemove = id => {
    if (originInfo.value.hasOwnProperty(id)) {
      originInfoHelpers.setValue({ ...originInfo.value, [id]: false });
    }
  };

  const handleChange = list => {
    columnOrderHelpers.setValue(list.map(v => v.id));
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Typography variant="body1" color="textSecondary">
          Layer 1
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {tags.length > 0 && (
          <DraggableTags
            tags={tags}
            onRemove={handleRemove}
            onChange={handleChange}
          />
        )}
      </Grid>
      <Grid item xs={12}>
        <Box display="flex">
          <Checkbox
            name="origin_info.existing_building"
            label={labels.existing_building}
          />
          <Checkbox
            name="origin_info.existing_floor"
            label={labels.existing_floor}
          />
          <Checkbox
            name="origin_info.existing_room"
            label={labels.existing_room}
          />
          <Checkbox
            name="origin_info.origin_cubby_number"
            label={labels.origin_cubby_number}
          />
          <Checkbox
            name="origin_info.origin_employee_name"
            label={labels.origin_employee_name}
          />
          <Checkbox name="origin_info.quantity" label={labels.quantity} />
        </Box>
      </Grid>
    </Grid>
  );
};

export default Layer1Fields;
