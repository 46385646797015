import React from "react";

import MultipleDestinationRoomNumberComponent from "./MultipleDestinationRoomNumberComponent";
import MultipleDestinationRoomNumberModal from "./MultipleDestinationRoomNumberModal";

export default function MultipleDestinationRoomNumberInput({ entity, onClose }) {
  return (
    <>
      <MultipleDestinationRoomNumberModal open toggleModal={onClose} entity={entity} />
      <MultipleDestinationRoomNumberComponent entity={entity} />
    </>
  );
}
