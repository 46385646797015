import React from "react";
import snakeCase from "lodash/snakeCase";
import camelCase from "lodash/camelCase";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";

import labels from "../../constants/displayFields";
import Input from "../../components/form/Input";

const nameField = name => camelCase(name);

const ORIGIN_STRING_FIELDS = [
  "existingBuilding",
  "existingRoom",
  "existingFloor",
  "originRoomNumber",
  "originEmployeeName",
  "originComments",
  "originCubbyNumber",
];

export default function FieldsOrigin({ fullWidth, display, disabledForm }) {
  const sm = fullWidth ? 12 : 6;
  const buildFields = fields =>
    fields.reduce((result, key) => {
      const displayField = display[snakeCase(key)];
      return displayField ? [...result, snakeCase(key)] : result;
    }, []);

  const displaySection =
    display.existing_building ||
    display.existing_room ||
    display.existing_floor ||
    display.origin_room_number ||
    display.origin_employee_name ||
    display.origin_comments ||
    display.origin_cubby_number ||
    display.quantity ||
    display.department_name ||
    display.lab_name;

  return displaySection ? (
    <Card>
      <CardContent>
        <Box mt={-1} mb={1}>
          <Typography fontWeight={500} fontSize={18}>
            Origin
          </Typography>
        </Box>
        <Grid container spacing={1}>
          {buildFields(ORIGIN_STRING_FIELDS).map((fieldKey, index) => (
            <Grid key={`field-${index}`} item xs={12}>
              <Input name={nameField(fieldKey)} label={labels[fieldKey]} disabled={disabledForm} />
            </Grid>
          ))}
          {display.quantity && (
            <Grid item xs={12} sm={sm}>
              <Input
                name={nameField("quantity")}
                label={labels.quantity}
                type="number"
                disabled={disabledForm}
              />
            </Grid>
          )}
          {display.department_name && (
            <Grid item xs={12}>
              <Input
                name={nameField("department_name")}
                label={labels.department_name}
                disabled={disabledForm}
              />
            </Grid>
          )}
          {display.lab_name && (
            <Grid item xs={12}>
              <Input name={nameField("lab_name")} label={labels.lab_name} disabled={disabledForm} />
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  ) : null;
}
