import React, {useCallback} from "react";

import {
  useLeaveEffect,
  useOnEnterEffect,
  useOnEscEffect,
  useOnReFocuse,
  useOnShiftEnterEffect,
  useOnShiftTabEffect,
  useOnTabEffect,
} from "../../../utils/keyEffects";

export const parseValue = (inputType, value) =>
  inputType === "number" ? (value === "" || value === null ? 0 : parseInt(value)) : value;

export default function InputCell(allProps) {
  const {
    field,
    onSubmit,
    setValue,
    value,
    innerRef,
    onRevert,
    setEdit,
    rowIndex,
    columnIndex,
    nextRow,
    prevRow,
    nextColumn,
    prevColumn,
    onClose,
    onChange,
    ...props
  } = allProps;

  const onSubmitNextRow = () => {
    onSubmit();
    nextRow();
  };

  const onSubmitPrevRow = () => {
    onSubmit();
    prevRow();
  };

  const onSubmitNextCol = () => {
    onSubmit();
    nextColumn();
  };

  const onSubmitPrevCol = () => {
    onSubmit();
    prevColumn();
  };

  useOnEnterEffect(innerRef, onSubmitNextRow);
  useOnShiftEnterEffect(innerRef, onSubmitPrevRow);
  useOnReFocuse(innerRef, onSubmit);
  useOnEscEffect(innerRef, onRevert);
  useOnTabEffect(innerRef, onSubmitNextCol);
  useOnShiftTabEffect(innerRef, onSubmitPrevCol);
  useLeaveEffect(allProps);

  const handleChange = useCallback(
    e => {
      setValue(parseValue(field.inputType, e.target.value));
    },
    [setValue, field.inputType]
  );

  React.useEffect(() => {
    innerRef.current.select();
    const val = innerRef.current.value;
    innerRef.current.value = "";
    innerRef.current.value = val;
  }, []);

  return (
    <form style={{ width: "100%", height: "100%" }}>
      <input
        ref={innerRef}
        style={{ resize: "none" }}
        type={field.inputType || "text"}
        value={value === null ? "" : value}
        onChange={handleChange}
        {...props}
      />
    </form>
  );
}
