import React from "react";
import {makeStyles} from "@material-ui/core";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Table from "@material-ui/core/Table";
import Collapse from "@material-ui/core/Collapse";

import AssetRowNew from "../AssetRowNew";

const useRowStyles = makeStyles(theme => ({
  nestedCell: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  nestedTable: {
    marginBottom: theme.spacing(2),
  },
}));

const SCANNER_STATUSES = ["approved", "checked_out", "checked_in"];

export default function RequestRowAssets({ inventoryRequest, assetsOpened, reFetch }) {
  const inventoryRequestItems = inventoryRequest.inventoryItems;
  const classes = useRowStyles();
  const withScanner =
    inventoryRequestItems.every(item => item.status !== "reserved") &&
    inventoryRequestItems.find(item => SCANNER_STATUSES.includes(item.status));
  const withReview = inventoryRequestItems.find(item => item.status === "reserved");

  return (
    <TableCell className={classes.nestedCell} colSpan={9}>
      <Collapse in={assetsOpened} timeout="auto" unmountOnExit>
        <Table className={classes.nestedTable} aria-label="Inventory requests">
          <TableHead>
            <TableRow>
              <TableCell>Image</TableCell>
              <TableCell>Item ID</TableCell>
              <TableCell>Model</TableCell>
              <TableCell>Dimensions</TableCell>
              <TableCell>Quantity</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {inventoryRequestItems.map(inventoryItem => (
              <AssetRowNew
                key={`inventoryItem${inventoryItem.id}`}
                inventoryRequest={inventoryRequest}
                withScanner={withScanner}
                withReview={withReview}
                inventoryItem={inventoryItem}
                reFetch={reFetch}
              />
            ))}
          </TableBody>
        </Table>
      </Collapse>
    </TableCell>
  );
}
