import React from "react";
import {DraggableArea} from "react-draggable-tags";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Close from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";

const DraggableTags = ({ onChange, onRemove, tags }) => (
  <Box p={0.5} m={0} border="1px solid rgb(189, 189, 189)">
    <DraggableArea
      tags={tags}
      render={({ tag, index }) => (
        <Box key={index} mt={0.5} mx={0.5}>
          <Button variant="contained" color="primary" component="div">
            {tag.content}
            <Box ml={1} my={-1} mr={-1}>
              <IconButton
                onMouseDown={e => {
                  onRemove(tag.id);
                }}
              >
                <Close fontSize="small" htmlColor="white" />
              </IconButton>
            </Box>
          </Button>
        </Box>
      )}
      onChange={onChange}
    />
  </Box>
);

export default DraggableTags;
