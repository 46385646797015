import React, {useMemo, useState} from "react";
import {useParams} from "react-router-dom";
import {useQuery} from "@apollo/react-hooks";
import keyBy from "lodash/keyBy";
import uniqBy from "lodash/uniqBy";

import {INVENTORY_REQUESTS_WITH_PAGINATION_QUERY} from "../constants/graphql/queries";

const OrdersContext = React.createContext(null);

export const ProjectInventoryProvider = ({ children, type, limit, dateRange, initialSortBy }) => {
  const [inventoryRequests, setInventoryRequests] = useState([]);
  const [inventoryRequestsOrder, setInventoryRequestsOrder] = useState([]);
  const [inventoryItems, setInventoryItems] = useState([]);
  const [inventoryAssets, setInventoryAssets] = useState([]);
  const params = useParams();
  const [loading, setLoading] = useState(true);
  const [updating, setUpdating] = useState(false);
  const [clientId, setClientId] = useState(params.clientId);
  const [projectId, setProjectId] = useState(params.projectId || (clientId ? "all" : null));
  const [page, setPage] = useState(1);
  const [sortBy, setSortBy] = useState(initialSortBy);
  const [totalPages, setTotalPages] = useState();
  const [totalCount, setTotalCount] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");

  const updateInventoryAsset = (assetId, props) => {
    const inventoryAsset = inventoryAssets[assetId];
    if (!inventoryAsset) return;

    setInventoryAssets({
      ...inventoryAssets,
      [inventoryAsset.id]: { ...inventoryAsset, ...props },
    });
  };

  const updateInventoryRequest = (inventoryRequestId, props) => {
    const inventoryRequest = inventoryRequests[inventoryRequestId];
    setInventoryRequests({
      ...inventoryRequests,
      [inventoryRequestId]: { ...inventoryRequest, ...props },
    });
  };

  const updateInventoryItem = (inventoryItemId, props) => {
    const inventoryItem = inventoryItems[inventoryItemId];
    setInventoryItems({
      ...inventoryItems,
      [inventoryItemId]: { ...inventoryItem, ...props },
    });
  };

  const onCompletedFetch = response => {
    const inventoryRequests = response?.inventoryRequests?.collection;
    const metadata = response?.inventoryRequests?.metadata;
    if (!inventoryRequests) return;
    setTotalPages(metadata.totalPages);
    setTotalCount(metadata.totalCount);
    setCurrentPage(metadata.currentPage);

    setInventoryRequests(keyBy(inventoryRequests, "id"));
    setInventoryRequestsOrder(inventoryRequests.map(v => v.id));

    const items = inventoryRequests.flatMap(entity => entity.inventoryItems);

    setInventoryItems(keyBy(items, "id"));
    setInventoryAssets(
      keyBy(
        uniqBy(
          items.flatMap(entity => entity.asset),
          "id"
        ),
        "id"
      )
    );

    setLoading(false);
  };

  const { refetch } = useQuery(INVENTORY_REQUESTS_WITH_PAGINATION_QUERY, {
    skip: true,
    fetchPolicy: "no-cache",
    variables: {
      type,
      page,
      limit,
      search,
      dateRange,
      sortBy: sortBy ? [sortBy] : [],
    },
  });

  const fetch = () => {
    setLoading(true);
    refetch()
      .then(({ data }) => onCompletedFetch(data))
      .catch(() => setLoading(false));
  };

  const reFetch = () => {
    return refetch({ page }).then(({ data }) => onCompletedFetch(data));
  };

  const fetchPage = pageNumber => {
    setUpdating(true);
    setPage(pageNumber);
    return refetch({ page: pageNumber }).then(({ data }) => {
      onCompletedFetch(data);
      setUpdating(false);
    });
  };

  const handleSortBy = order => {
    setUpdating(true);
    setSortBy(order);
    return refetch({ sortBy: [order] }).then(({ data }) => {
      onCompletedFetch(data);
      setUpdating(false);
    });
  };

  const handleSearch = ({ search }) => {
    setUpdating(true);
    setSearch(search);
    return refetch({ page: 1, search }).then(({ data }) => {
      onCompletedFetch(data);
      setUpdating(false);
    });
  };

  const value = useMemo(
    () => ({
      totalPages,
      totalCount,
      currentPage,
      setPage,
      fetchPage,
      sortBy,
      handleSortBy,
      handleSearch,
      inventoryRequests,
      inventoryRequestsOrder,
      inventoryItems,
      inventoryAssets,
      updateInventoryRequest,
      updateInventoryItem,
      updateInventoryAsset,
      loading,
      updating,
      fetch,
      reFetch,
      clientId,
      setClientId,
      projectId: projectId === "all" ? null : projectId,
      inputProjectId: projectId,
      setProjectId,
      removeInventoryItem: id => {
        setInventoryItems(prev => {
          const data = { ...prev };
          delete data[id];
          return data;
        });
      },
    }),
    [
      totalPages,
      totalCount,
      currentPage,
      inventoryRequests,
      inventoryRequestsOrder,
      inventoryItems,
      inventoryAssets,
      loading,
      updating,
      clientId,
      projectId,
      setInventoryItems,
      reFetch,
      sortBy,
      handleSearch,
    ]
  );

  return <OrdersContext.Provider value={value}>{children}</OrdersContext.Provider>;
};

export const useProjectInventory = () => {
  const context = React.useContext(OrdersContext);
  if (!context) {
    throw new Error("useProjectInventory must be used within a OrdersProvider");
  }
  return context;
};
