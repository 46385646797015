import React from "react";

import {getOptions} from "../../../utils/form";
import Autocomplete from "../../../components/form/Autocomplete";

export default function BooleanSelectField({ name, disabled, label }) {
  return (
    <Autocomplete
      saveCustomOption
      name={name}
      label={label}
      disabled={disabled}
      options={getOptions([
        ["YES", "YES"],
        ["NO", "NO"],
      ])}
    />
  );
}
