import React, {useState} from "react";
import {Box, Button, Grid} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import {makeStyles, useTheme} from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/Delete";
import Skeleton from "@material-ui/lab/Skeleton";

import {getImage} from "../../utils/images";
import ConfirmDeleteModal from "../../components/images/ConfirmDeleteModal";

const useStyles = makeStyles(theme => ({
  root: props => ({
    border: props.selected ? `2px solid ${theme.palette.primary.main}` : null,
    height: 36,
    paddingTop: 0,
    paddingBottom: 0,
  }),
  imageContainer: {
    display: "flex",
    overflow: "auto",
    "-ms-overflow-style": "none",
  },
}));

export const AssetItemStub = () => {
  const classes = useStyles();

  return (
    <Box mt={1}>
      <Button fullWidth className={classes.root} variant="outlined" onClick={() => {}}>
        <Grid container spacing={1} alignItems="center">
          <Grid item xs={6} container wrap="nowrap" justify="flex-start">
            <Skeleton variant="rect" height="20" width="100%" />
          </Grid>
          <Grid item xs={6} container wrap="nowrap" justify="flex-end" alignItems="center">
            <Skeleton variant="rect" height="20" width="30%" />
          </Grid>
        </Grid>
      </Button>
    </Box>
  );
};

const AssetItem = ({
  currentAsset,
  setCurrentAsset,
  onRemove,
  onOpenImageModal,
  asset,
  selectedImage,
  onSelectImage,
}) => {
  const [deleteAsset, setDeleteAsset] = useState(false);
  const theme = useTheme();
  const classes = useStyles({ selected: currentAsset?.id === asset.id });

  const handleDelete = () =>
    onRemove({
      variables: {
        id: asset.id,
        projectId: asset.projectId,
      },
    });

  const handleToggleConfirmDeleteModal = () => setDeleteAsset(!deleteAsset);

  const label = `${asset.assetId || ""} ${asset.barcode || ""} ${asset.originRoomNumber ||
    ""} | ${asset.originEmployeeName || ""} | ${asset.description || ""}`;

  return (
    <>
      <Box mt={1}>
        <Button
          id={`assetItem-${asset.id}`}
          fullWidth
          className={classes.root}
          variant="outlined"
          onClick={() => setCurrentAsset(asset)}
        >
          <Grid container spacing={1} alignItems="center">
            <Grid item xs={6} container wrap="nowrap" justify="flex-start">
              <Typography noWrap={true} title={label}>
                {label}
              </Typography>
            </Grid>
            <Grid item xs={6} container wrap="nowrap" justify="flex-end" alignItems="center">
              <Box data-id="asset-images" className={classes.imageContainer} mr={1.5}>
                {asset.images.map((image, index) => (
                  <Box
                    mr={0.5}
                    key={index}
                    borderRadius={4}
                    clone
                    border={
                      selectedImage === image.url
                        ? `1px solid ${theme.palette.primary.main}`
                        : "1px solid white"
                    }
                  >
                    <img
                      style={{ maxHeight: "30px" }}
                      src={getImage(image.url)}
                      onClick={e => {
                        e.stopPropagation();
                        setCurrentAsset(asset);
                        onSelectImage(image.id);
                        onOpenImageModal(asset.id);
                      }}
                    />
                  </Box>
                ))}
              </Box>
              <DeleteIcon color="action" onClick={handleToggleConfirmDeleteModal} />
            </Grid>
          </Grid>
        </Button>
      </Box>
      {deleteAsset && (
        <ConfirmDeleteModal
          title="Delete Asset"
          descr="Are you sure you want to delete asset?"
          onClose={handleToggleConfirmDeleteModal}
          onDelete={handleDelete}
        />
      )}
    </>
  );
};

export default AssetItem;
