import React, {useState} from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import {DesktopDatePicker} from "@mui/x-date-pickers/DesktopDatePicker";
import TextField from "@mui/material/TextField";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";

import FilterNav from "../../layouts/ProjectLayout/FilterNav";

export default function SelectReceivingModal({
  name,
  title,
  buttonLabel,
  description,
  selectedClientId,
  selectedProjectId,
  onSubmit,
  onClose,
}) {
  const [clientId, setClientId] = useState(selectedClientId);
  const [projectId, setProjectId] = useState(selectedProjectId);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [openStartDate, setOpenStartDate] = useState(false);
  const [openEndDate, setOpenEndDate] = useState(false);
  const [receivingNotes, setReceivingNotes] = useState("");

  const selectProject = ({ clientId, projectId }) => {
    if (projectId) {
      setClientId(clientId);
      setProjectId(projectId);
    } else {
      setClientId(clientId);
    }
  };

  const selectClient = clientId => {
    setClientId(clientId);
  };

  const handleSubmit = () => {
    onSubmit({
      clientId,
      projectId: projectId === "all" ? null : projectId,
      receivingNotes,
      startDate,
      endDate,
    });
  };

  return (
    <Dialog open fullWidth maxWidth="sm" onClose={onClose}>
      <DialogTitle>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box width={48} />
          <Typography variant="h2" color="textSecondary" component="span">
            {title}
          </Typography>
          <IconButton color="textPrimary" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {description && (
            <Box mb={2}>
              <Typography>{description}</Typography>
            </Box>
          )}
          <Box mr="-9px">
            <FilterNav
              md={12}
              clientId={clientId}
              projectId={projectId}
              onSelectProject={selectProject}
              onSelectClient={selectClient}
              hideActions
            />
          </Box>
          {name !== "Receiving Report 3" && (
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <Box mt={1}>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={6}>
                    <Box mr="3px">
                      <DesktopDatePicker
                        label="Start Date"
                        inputFormat="MM/DD/YYYY"
                        open={openStartDate}
                        onOpen={() => setOpenStartDate(true)}
                        onClose={() => setOpenStartDate(false)}
                        value={startDate}
                        onChange={setStartDate}
                        renderInput={params => (
                          <TextField
                            fullWidth
                            size="small"
                            autoComplete="off"
                            onClick={e => setOpenStartDate(true)}
                            {...params}
                          />
                        )}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <DesktopDatePicker
                      label="End Date"
                      inputFormat="MM/DD/YYYY"
                      open={openEndDate}
                      onOpen={() => setOpenEndDate(true)}
                      onClose={() => setOpenEndDate(false)}
                      value={endDate}
                      onChange={setEndDate}
                      renderInput={params => (
                        <TextField
                          fullWidth
                          size="small"
                          autoComplete="off"
                          onClick={e => setOpenEndDate(true)}
                          {...params}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Box>
            </LocalizationProvider>
          )}
          {["Receiving Report 2", "Receiving Report 3"].includes(name) && (
            <Box mt={1.5}>
              <TextField
                fullWidth
                size="small"
                variant="outlined"
                label="Filter By Keyword(s)"
                value={receivingNotes}
                onChange={event => setReceivingNotes(event.target.value)}
              />
            </Box>
          )}
          <Box mt={4} display="flex" justifyContent="flex-end">
            <Button
              disabled={
                name === "Receiving Report"
                  ? !(projectId && startDate && endDate)
                  : name === "Receiving Report 2"
                  ? !(projectId && (receivingNotes || (startDate && endDate)))
                  : name === "Receiving Report 3"
                  ? !(projectId && receivingNotes)
                  : null
              }
              variant="contained"
              onClick={handleSubmit}
            >
              {buttonLabel}
            </Button>
          </Box>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
}
