import React, { useState, useEffect } from "react";
import Box from "@material-ui/core/Box";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import ListItemText from "@material-ui/core/ListItemText";
import CircularProgress from "@mui/material/CircularProgress";
import MuiMenu from "@mui/material/Menu";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Checkbox from "@material-ui/core/Checkbox";
import CheckIcon from "@mui/icons-material/Check";
import useNotification from "../../../hooks/notification";
import useMutation from "../../../hooks/useMutation";
import {DELETE_INVENTORY_ITEM_MUTATION} from "../../../constants/graphql/mutations";

// Priority order for status colors
const statusOrder = [
  { key: "canceled", label: "Cancelled" },
  { key: "completed", label: "Completed" },
  { key: "picked", label: "Picked" },
  { key: "scheduled", label: "Scheduled" },
  { key: "pending_approval", label: "Pending Approval" },
  { key: "submitted", label: "Submitted" },
];

// Order for dropdown menu
const dropdownStatusOrder = [
  { key: "pending_approval", label: "Pending Approval" },
  { key: "scheduled", label: "Scheduled" },
  { key: "picked", label: "Picked" },
  { key: "completed", label: "Completed" },
  { key: "canceled", label: "Cancelled" },
];

const statusColors = {
  submitted: "#E0E0E0",
  pending_approval: "#FFECB3",
  scheduled: "#FFE5CC",
  picked: "#CCE7FF",
  completed: "#D5F5D5",
  canceled: "#F8D7DA",
};

function synchronous(array, fn) {
  const results = [];
  return array.reduce(function(p, item) {
    return p.then(function() {
      return fn(item).then(function(data) {
        results.push(data);
        return results;
      });
    });
  }, Promise.resolve());
}

export default function VaStatus({ disabled, inventoryRequest, changeStatus, reFetch }) {
  const [loading, setLoading] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("");
  const { notifySuccess } = useNotification();
  const [deleteInventoryItem] = useMutation(DELETE_INVENTORY_ITEM_MUTATION);

  useEffect(() => {
    const activeStatus = statusOrder.find(
      (status) => inventoryRequest?.vaStatus[status.key] === true
    );
    if (activeStatus) setSelectedStatus(activeStatus.key);
  }, [inventoryRequest]);

  const handleChangeStatus = (status) => async (value) => {
    const checked = value.target.checked;
    if (status === "canceled" && checked && reFetch) {
      setLoading(true);
      try {
        await synchronous(
          inventoryRequest.inventoryItems.map((item) => item.id),
          (id) => deleteInventoryItem({ variables: { id } })
        );

        value.target.checked = checked;
        await changeStatus(status)(value);
        await reFetch();

        notifySuccess("Order successfully cancelled");
      } catch (error) {
        console.error("Error during cancellation:", error);
      } finally {
        setLoading(false);
      }
    } else {
      changeStatus(status)(value);
      reFetch();
    }
  };

  return (
    <PopupState variant="popover" popupId="status-menu">
      {(popupState) => (
        <ClickAwayListener onClickAway={popupState.close}>
          <Box maxWidth={300} display="flex" flexDirection="column">
            <FormControl fullWidth>
              <Box
                onClick={popupState.open}
                style={{
                  cursor: "pointer",
                  padding: "8px 12px",
                  border: `1px solid ${statusColors[selectedStatus || 'submitted']}`,
                  borderRadius: 4,
                  backgroundColor: statusColors[selectedStatus || 'submitted'],
                  color: "black",
                  textAlign: "center",
                  fontWeight: 500,
                }}
              >
                {statusOrder.find(status => status.key === selectedStatus)?.label || "Select Status"}
              </Box>
            </FormControl>

            <MuiMenu
              {...bindMenu(popupState)}
              anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
              transformOrigin={{ vertical: "top", horizontal: "left" }}
              PaperProps={{
                style: {
                  backgroundColor: "white",
                  boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.15)",
                  maxHeight: 300,
                  overflowY: "auto",
                },
              }}
            >
              {dropdownStatusOrder.map(({ key, label }) => (
                <MenuItem key={key} disabled={disabled} onClick={popupState.close}>
                  {inventoryRequest?.vaStatus[key] ? (
                    <CheckIcon style={{ marginRight: 8 }} />
                  ) : (
                    <Checkbox
                      checked={inventoryRequest?.vaStatus[key] || false}
                      onChange={handleChangeStatus(key)}
                      disabled={disabled}
                    />
                  )}
                  <ListItemText primary={label} />
                </MenuItem>
              ))}
            </MuiMenu>

            {loading && (
              <Box mt={2} display="flex" justifyContent="center">
                <CircularProgress size={20} />
              </Box>
            )}
          </Box>
        </ClickAwayListener>
      )}
    </PopupState>
  );
}
