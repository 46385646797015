import React, {useState} from "react";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import MenuItem from "@mui/material/MenuItem";
import SyncAltIcon from "@mui/icons-material/SyncAlt";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import DialogContentText from "@mui/material/DialogContentText";
import PopupState, { bindTrigger } from "material-ui-popup-state";

import {isReactComponent} from "../../utils/detect";

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const grid = 0;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  // padding: grid * 2,
  // margin: `0 0 ${grid}px 0`,
  marginTop: "4px",
  // change background colour if dragging
  background: isDragging ? "#d5d5d5" : "#fafafa",
  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = isDraggingOver => ({
  width: "100%",
  // background: isDraggingOver ? "lightblue" : undefined,
  padding: grid,
});
export default function Order({ fields: initHeaders, onChange, reorderFields }) {
  const [fields, setFields] = useState(initHeaders);

  const handleUpdate = newFields => onChange({ fields: newFields });

  const onDragEnd = result => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const newHeaders = reorder(fields, result.source.index, result.destination.index);
    setFields(newHeaders);
    handleUpdate(newHeaders);
  };

  const renderFieldsList = () => (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided, snapshot) => (
          <List
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={getListStyle(snapshot.isDraggingOver)}
          >
            {fields.map((field, index) => {
              const showField = field.visible && !field.inactive && field.id !== "BULK_CHECKBOX";

              return (
                <Draggable key={field.id} draggableId={field.id} index={index}>
                  {(provided, snapshot) => (
                    <ListItem
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      disablePadding
                      style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                      sx={{ p: 0, display: showField ? "block" : "none" }}
                    >
                      <ListItemButton>
                        {field.label && isReactComponent(field.label) ? (
                          <field.label />
                        ) : (
                          field.label
                        )}
                      </ListItemButton>
                    </ListItem>
                  )}
                </Draggable>
              );
            })}
            {provided.placeholder}
          </List>
        )}
      </Droppable>
    </DragDropContext>
  );

  if (reorderFields) {
    return renderFieldsList();
  }

  return (
    <PopupState variant="popover" popupId="reorder-popup-menu">
      {popupState => (
        <>
          <MenuItem {...bindTrigger(popupState)}>
            <SyncAltIcon
              sx={{ transform: "rotate(180deg)" }}
              fontSize="small"
              htmlColor="#546e7a"
            />
            <Box mr={1} />
            <Typography fontWeight={500} color="textSecondary" variant="body2">
              Reorder Columns
            </Typography>
          </MenuItem>

          <Dialog
            open={popupState.isOpen}
            maxWidth="sm"
            fullWidth
            onClose={popupState.close}
          >
            <DialogTitle
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                background: "#f4f6f8",
              }}
            >
              <Box width={48} />
              <Typography color="textSecondary" variant="h5" fontWeight={500} align="center">
                Reorder Columns
              </Typography>
              <IconButton onClick={popupState.close}>
                <CloseIcon />
              </IconButton>
            </DialogTitle>

            <DialogContent sx={{ background: "#f4f6f8" }}>
              <DialogContentText>{renderFieldsList()}</DialogContentText>
            </DialogContent>
          </Dialog>
        </>
      )}
    </PopupState>
  );
}
