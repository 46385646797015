import maxBy from "lodash/maxBy";

export function disableResizing(object) {
  object.setControlsVisibility({
    mt: false,
    mb: false,
    ml: false,
    mr: false,
    bl: false,
    br: false,
    tl: false,
    tr: false,
  });
}

export const disableResizingObjects = editor => {
  const objects = editor.getObjects();
  objects.forEach(object => {
    if (object.type === "group") {
      disableResizing(object);
    }
  });
};

export const disableObjectsMovement = editor => {
  const objects = editor.getObjects();
  objects.forEach(object => {
    object.set({
      lockMovementX: true,
      lockMovementY: true,
    });
    object.setControlsVisibility({
      mt: false,
      mb: false,
      ml: false,
      mr: false,
      bl: false,
      br: false,
      tl: false,
      tr: false,
      mtr: false,
    });
  });
};

export const updateStickerWidth = (stickerEditor, objects) => {
  const lastIcon = maxBy(objects, v => v.left);
  if (lastIcon) {
    stickerEditor.setWidth(lastIcon.left + lastIcon.width + 100);
  }
};

export const calculateMaxWidth = (objects) => {
  const lastIcon = maxBy(objects, v => v.left);
  let width = 1000;
  if (lastIcon) {
    width = lastIcon.left + lastIcon.width + 40;
  }
  return width; // Return the calculated width
};