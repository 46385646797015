import React, {useState} from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/AddCircle";

import ActionModal from "../../modals/action-modal";

const ArrayField = ({
  field,
  onSubmit,
  setValue,
  value,
  innerRef,
  onRevert,
  setEdit,
  rowIndex,
  columnIndex,
  nextRow,
  prevRow,
  nextColumn,
  prevColumn,
  onChange,
  onClose,
}) => {
  const [fields, setFields] = useState(value);

  const handleAddNew = () => setFields(fields => fields.concat(""));

  const handleRemove = selectedIndex =>
    setFields(fields => fields.filter((v, index) => selectedIndex !== index));

  const updateField = (index, value) => {
    setFields(fields => [...fields.slice(0, index), value, ...fields.slice(index + 1)]);
  };

  const handleSubmit = () => {
    onSubmit(fields);
    if (nextRow) {
      nextRow();
    }
  };

  return (
    <ActionModal open maxWidth={460} onClose={onClose} title={field.label} disableTransition>
      <Grid container spacing={2}>
        {fields.map((v, index) => (
          <Grid item xs={12} key={index}>
            <Box display="flex" alignItems="center">
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name={`${field.name}.${index}`}
                    label={field.label}
                    value={fields[index]}
                    onChange={event => updateField(index, event.target.value)}
                  />
                </Grid>
              </Grid>

              {fields.length > 1 && (
                <Box p={1} mr={-1} clone>
                  <IconButton
                    aria-label="delete"
                    variant="outlined"
                    onClick={() => handleRemove(index)}
                  >
                    <DeleteIcon color="action" />
                  </IconButton>
                </Box>
              )}
            </Box>
          </Grid>
        ))}

        <Grid item xs={12} container justify="center">
          <Box display="flex" justifyContent="center">
            <IconButton onClick={handleAddNew}>
              <AddIcon />
            </IconButton>
          </Box>
        </Grid>
      </Grid>

      <Box mb={3} />

      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Button fullWidth key="override" variant="outlined" color="primary" onClick={onClose}>
            Close
          </Button>
        </Grid>

        <Grid item xs={8}>
          <Button
            fullWidth
            key="override"
            variant="contained"
            color="primary"
            onClick={handleSubmit}
          >
            Save
          </Button>
        </Grid>
      </Grid>
    </ActionModal>
  );
};

export default React.memo(ArrayField);
