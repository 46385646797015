import React, {useState} from "react";
import Box from "@material-ui/core/Box";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import SizeIcon from "@material-ui/icons/FormatSize";
import startCase from "lodash/startCase";
import PopupState, {bindMenu, bindTrigger} from "material-ui-popup-state";
import MuiMenu from "@material-ui/core/Menu";
import {ListItemText} from "@material-ui/core";
import MoreIcon from "@material-ui/icons/MoreVert";
import {Description as ExcelIcon, Publish as ImportAssetsIcon} from "@material-ui/icons";

import useMutation from "../../hooks/useMutation";
import {useProjectInventory} from "../../contexts/projectInventory";
import useNotification from "../../hooks/notification";
import {usePermissions} from "../../contexts/permissions";
import InventoryTransactionModal from "../InventoryTransactionModal";
import ImportAssetsButton from "./ImportAssetsButton";
import LookupScanner from "../../components/barcode/LookupScanner";
import MenuActions from "../../components/MenuActions";
import {DOWNLOAD_ASSETS_IMAGES_MUTATION, EXPORT_EXCEL_DATA_MUTATION,} from "../../constants/graphql";
import {useBanner} from "../../contexts/banner";
import ProjectImageModal from "../ProjectImageModal";
import CollectionsIcon from '@mui/icons-material/Collections';

const reportGenerationNotification =
  "We started generating report. You'll receive notice when it's ready";

const MoreLabel = () => (
  <>
    <MoreIcon fontSize="small" color="action" />
    <Box mr={0.5} />
    <Typography fontWeight={600} color="textSecondary" variant="body2">
      More
    </Typography>
  </>
);

const SIZE_OPTIONS = ["small", "medium", "large", "x-large"];

export const SizeLabel = ({ value, variant = "body1", color }) => (
  <>
    <SizeIcon fontSize="small" color="action" />
    <Box mr={1} />
    <Typography fontWeight={600} color={color} variant={variant}>
      {startCase(value)}
    </Typography>
  </>
);

export default function AssetsTableActions({ moreActions, size, enableSize, onChange }) {
  const { projectId, clientId } = useProjectInventory();
  const [exportExcelData] = useMutation(EXPORT_EXCEL_DATA_MUTATION);
  const [downloadAssetsImages] = useMutation(DOWNLOAD_ASSETS_IMAGES_MUTATION);
  const { displayBanner } = useBanner();
  const [openGalleryModal, setOpenGalleryModal] = useState(false);

  const { notifySuccess, notifyError } = useNotification();
  const { can } = usePermissions();
  const [openInventoryModal, setOpenInventoryModal] = useState(false);
  const onCloseInventoryModal = () => setOpenInventoryModal(false);

  const [lookupScanner, setLookupScanner] = useState(false);
  const toggleLookupScanner = () => setLookupScanner(v => !v);

  const exportExcel = () => {
    exportExcelData({ variables: { projectId } });
    displayBanner({ message: "Generating file...", type: "export-excel" });
  };

  const downloadImages = () => {
    downloadAssetsImages({ variables: { projectId } });
    displayBanner({ message: "Generating file...", type: "download_images" });
  };

  const openGallery = () => {
    setOpenGalleryModal(true);
  };

  const closeGallery = () => {
    setOpenGalleryModal(false);
  };

  const isMoreActions =
    moreActions &&
    projectId &&
    (can("features.export_assets") ||
      can("features.export_assets") ||
      can("features.import_assets"));

  return (
    <>
      {isMoreActions && (
        <MenuActions
          id="moreActionsButton"
          style={{ textTransform: "none" }}
          size="small"
          label={<MoreLabel />}
        >
          {can("features.export_assets") && projectId && (
            <MenuItem data-id="exportAssetsExcel" onClick={exportExcel}>
              <Box mr={1}>
                <ExcelIcon />
              </Box>
              Export Excel
            </MenuItem>
          )}
          {can("features.export_assets") && projectId && (
            <MenuItem data-id="downloadAssetsImages" onClick={downloadImages}>
              <Box mr={1}>
                <ExcelIcon />
              </Box>
              Download Images
            </MenuItem>
          )}
          {can("features.import_assets") && projectId && (
            <ImportAssetsButton
              keepOpen
              component={MenuItem}
              label={
                <Box display="flex">
                  <Box mr={1}>
                    <ImportAssetsIcon />
                  </Box>
                  Import Assets
                </Box>
              }
              projectId={projectId}
              color="secondary"
              variant="outlined"
              size="small"
            />
          )}
          {projectId && (
            <MenuItem data-id="projectGallery" onClick={openGallery}>
              <Box mr={1}>
                <CollectionsIcon />
              </Box>
              Image Gallery
            </MenuItem>
          )}
          {enableSize && (
            <PopupState variant="popover" popupId="demo-popup-menu">
              {popupState => (
                <React.Fragment>
                  <MenuItem {...bindTrigger(popupState)}>
                    <SizeLabel value={size} />
                  </MenuItem>
                  <MuiMenu {...bindMenu(popupState)}>
                    {SIZE_OPTIONS.map(option => (
                      <MenuItem
                        key={option}
                        data-id="select-item"
                        selected={size === option}
                        onClick={() => {
                          onChange({ size: option });
                          popupState.close();
                        }}
                      >
                        <ListItemText primary={startCase(option)} />
                      </MenuItem>
                    ))}
                  </MuiMenu>
                </React.Fragment>
              )}
            </PopupState>
          )}
        </MenuActions>
      )}
      {openInventoryModal && (
        <InventoryTransactionModal
          title="Restock Inventory"
          open={openInventoryModal}
          onClose={onCloseInventoryModal}
          onSuccess={onCloseInventoryModal}
        />
      )}
      {lookupScanner && (
        <LookupScanner projectId={projectId} clientId={clientId} onClose={toggleLookupScanner} />
      )}
      <ProjectImageModal
        open={openGalleryModal}
        onClose={closeGallery}
      />
    </>
  );
}
