import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core";
import {useParams} from "react-router-dom";
import {Minus as MinusIcon, Plus as PlusIcon} from "react-feather";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import SvgIcon from "@material-ui/core/SvgIcon";
import TextField from "@material-ui/core/TextField";

import {useShopping} from "../../../contexts/shopping";
import useMutation from "../../../hooks/useMutation";
import {getInventoryFields} from "../../../views/shopping/Cart/CartItemFields";
import {UPDATE_SHOPPING_INVENTORY_ITEM_MUTATION} from "../../../constants/graphql/mutations";

export const useStyles = makeStyles(theme => ({
  quantityInput: ({ size }) => ({
    width: size == "medium" ? "90px" : "70px",
    "& input":
      size == "small"
        ? {
            fontSize: "0.875rem",
            padding: "10px 6px",
            height: "10px",
          }
        : {},
  }),
  quantityButtonMinus: ({ size }) => ({
    minWidth: size == "medium" ? "40px" : "20px",
    height: size == "medium" ? "40px" : "30px",
    marginRight: "-1px",
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    "& svg":
      size == "small"
        ? {
            fontSize: "0.875rem",
          }
        : {},
  }),
  quantityButtonPlus: ({ size }) => ({
    minWidth: size == "medium" ? "40px" : "20px",
    height: size == "medium" ? "40px" : "30px",
    marginLeft: "-1px",
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    "& svg":
      size == "small"
        ? {
            fontSize: "0.875rem",
          }
        : {},
  }),
}));

const AssetQuantityField = ({ disabled, inventoryItem, asset, defaultValues, size = "medium" }) => {
  const classes = useStyles({ size });
  const { projectId } = useParams();
  const { setInventoryItem, removeInventoryItem } = useShopping();
  const defaultQuantity = inventoryItem?.quantity;
  const [quantity, setQuantity] = useState(defaultQuantity);
  const [error, setError] = useState(null);
  const [updateInventoryItem, { loading }] = useMutation(UPDATE_SHOPPING_INVENTORY_ITEM_MUTATION);

  const handleAddInventoryItem = quantity => {
    const parsedQuantity = parseInt(quantity) || 0;

    if (parsedQuantity === 0 && !inventoryItem) return;

    if (parsedQuantity === 0) {
      return setError("Order Qty should not be 0");
    }

    const variables = getInventoryFields({
      ...inventoryItem,
      quantity: parsedQuantity,
      projectId,
      assetId: asset.id,
      ...defaultValues,
    });

    return updateInventoryItem({
      variables,
      onSuccess: ({ inventoryItem: updatedInventoryItem }) => {
        setError(null);
        updatedInventoryItem
          ? setInventoryItem(updatedInventoryItem)
          : removeInventoryItem(inventoryItem.id);
      },
      onFailure: errors => {
        setError(errors.quantity || errors.asset || errors.submit);
      },
    });
  };

  useEffect(() => {
    setError(null);
    if (quantity == defaultQuantity) return;
    const timeOutId = setTimeout(() => handleAddInventoryItem(quantity), 500);
    return () => clearTimeout(timeOutId);
  }, [quantity]);

  const incrementQuqantity = () => {
    const newQuantity = quantity ? parseInt(quantity) + 1 : 1;
    setQuantity(newQuantity);
  };

  const decrementQuqantity = () => {
    const newQuantity = quantity ? parseInt(quantity) - 1 : -1;
    newQuantity >= 0 && setQuantity(newQuantity);
  };

  return (
    <FormControl fullWidth>
      <Box>
        {!disabled && (
          <Button
            disabled={loading}
            className={classes.quantityButtonMinus}
            variant="outlined"
            size="small"
            onClick={decrementQuqantity}
          >
            <SvgIcon fontSize="small">
              <MinusIcon />
            </SvgIcon>
          </Button>
        )}
        <TextField
          id={`quantity-${asset.id}`}
          disabled={disabled || loading}
          className={classes.quantityInput}
          color="secondary"
          variant="outlined"
          fullWidth
          type="number"
          size="small"
          inputProps={{ min: 0, step: 1 }}
          onChange={event => setQuantity(event.target.value)}
          value={quantity || ""}
        />
        {!disabled && (
          <Button
            disabled={disabled || loading}
            className={classes.quantityButtonPlus}
            variant="outlined"
            size="small"
            onClick={incrementQuqantity}
          >
            <SvgIcon fontSize="small">
              <PlusIcon />
            </SvgIcon>
          </Button>
        )}
      </Box>
      {error && (
        <Box mt={1}>
          <FormHelperText htmlFor="barcode" error={!!error}>
            {error}
          </FormHelperText>
        </Box>
      )}
    </FormControl>
  );
};

export default AssetQuantityField;
