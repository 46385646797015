import React, {useState} from "react";
import Checkbox from "@material-ui/core/Checkbox";

const useTableBulk = totalIds => {
  const [selectedIds, setSelectedIds] = useState([]);
  const [bulkState, setBulkState] = useState(false);

  const toggleBulkState = () => {
    const newState = !bulkState;
    setBulkState(newState);
    newState ? setSelectedIds(totalIds) : setSelectedIds([]);
  };

  const toggleSelectedId = entityId => {
    const newSelectedIds = selectedIds.includes(entityId)
      ? selectedIds.filter(id => id !== entityId)
      : [...selectedIds, entityId];

    setSelectedIds(newSelectedIds);
    setBulkState(newSelectedIds.length === totalIds.length);
  };

  const label = <Checkbox checked={bulkState} onClick={toggleBulkState} />;

  const unSelectBulk = () => {
    setBulkState(false);
    setSelectedIds([]);
  };

  const Component = ({ entity }) => (
    <Checkbox
      checked={bulkState || selectedIds.includes(entity.id)}
      onClick={e => {
        e.stopPropagation();
        toggleSelectedId(entity.id);
      }}
    />
  );

  return {
    selectedIds,
    unSelectBulk,
    props: {
      label: label,
      component: Component,
    },
  };
};

export default useTableBulk;
