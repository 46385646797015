import React, { useCallback, useState } from "react";
import isEqual from "react-fast-compare";
import TableCell from "@material-ui/core/TableCell";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import Box from "@material-ui/core/Box";
import TableRow from "@material-ui/core/TableRow";
import { makeStyles } from "@material-ui/core";
import LinearProgress from "@mui/material/LinearProgress";

import timeOptions from "../../../constants/timeOptions";
import useMutation from "../../../hooks/useMutation";
import { DELETE_INVENTORY_ITEM_MUTATION, UPDATE_INVENTORY_ITEM_MUTATION } from "../../../constants/graphql/mutations";
import AssetQuantityField from "./AssetQuantityField";
import InventoryItemAddress from "../../shopping/Cart/InventoryItemAddress";
import { pricer } from "../../../utils/money";
import { getImage } from "../../shopping/Cart/CartItemFields";
import ItemId from "../../../views/shopping/Orders/ItemId";
import Dimensions from "../../../views/inventory/requests/Dimensions";
import DispositionAutocomplete from "src/views/inventory/requests/disposition/DispositionAutocomplete";
import Input from "../../../components/form/Input";
import Select from "../../../components/form/Select";

export function getInventoryFields(inventoryItem) {
  return {
    projectId: inventoryItem.projectId,
    assetId: inventoryItem.assetId,
    quantity: inventoryItem.quantity,
    deliveryDate: inventoryItem.deliveryDate,
    deliveryTime: inventoryItem.deliveryTime,
    building: inventoryItem.building || "",
    floor: inventoryItem.floor || "",
    room: inventoryItem.room || "",
    comment: inventoryItem.comment || "",
    location: inventoryItem.location || "",
    street: inventoryItem.street || "",
    city: inventoryItem.city || "",
    zip: inventoryItem.zip || "",
    poNumber: inventoryItem.poNumber || "",
    disposition: inventoryItem.disposition || "",
    idsrNumber: inventoryItem.idsrNumber || "",
    eocNumber: inventoryItem.eocNumber || "",
    pickedStatus: inventoryItem.pickedStatus,
  };
}

const useStyles = makeStyles(theme => ({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
  nestedCell: {
    paddingTop: 0,
    paddingBottom: 0,
    padding: 0,
    borderBottom: "unset",
  },
  nestedTable: {
    marginBottom: theme.spacing(2),
  },
}));

function CartItemFields({
  price,
  inventoryItem,
  display,
  submitCounter,
  asset,
  reFetch,
  removeInventoryItem,
  setFieldValue,
}) {
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const [updateItem] = useMutation(UPDATE_INVENTORY_ITEM_MUTATION);
  const [deleteInventoryItem] = useMutation(DELETE_INVENTORY_ITEM_MUTATION);
  const photo = getImage(asset);
  const index = inventoryItem.index;

  const handleDelete = useCallback(
    id => {
      deleteInventoryItem({
        variables: { id },
        onSuccess: () => {
          if (reFetch) {
            reFetch();
          }
          removeInventoryItem(id);
        },
      });
    },
    [deleteInventoryItem, removeInventoryItem, reFetch]
  );

  const handleChange = values => {
    setLoading(true);
    const variables = {
      id: inventoryItem.id,
      ...getInventoryFields({
        ...inventoryItem,
        projectId: inventoryItem.project.id,
        assetId: asset.id,
        ...values,
      }),
    };
    return updateItem({
      variables,
      onSuccess: () => {
        Object.entries(values).forEach(([key, value]) => {
          setFieldValue(`inventoryItems[${index}].${key}`, value);
        });
        setLoading(false);
      },
      onFailure: () => {
        setLoading(false);
      },
    });
  };

  return (
    <React.Fragment key={`cartItem${asset.id}`}>
      {loading && (
        <TableRow style={{ border: 0 }}>
          <TableCell colSpan={1000} style={{ padding: 0, border: 0 }}>
            <Box mt="-3px">
              <LinearProgress sx={{ height: "2px" }} />
            </Box>
          </TableCell>
        </TableRow>
      )}
      <TableRow className={classes.root} id={`cartItem-${asset.id}`}>
        {display.ii_image && (
          <TableCell>{photo ? <img style={{ maxWidth: 90 }} src={photo} /> : "No image"}</TableCell>
        )}
        {display.ii_item_id && (
          <TableCell>
            <ItemId asset={asset} />
          </TableCell>
        )}
        {display.ii_barcode && <TableCell>{asset.barcode}</TableCell>}
        {display.ii_description && <TableCell>{asset.description}</TableCell>}
        {display.ii_model && <TableCell>{asset.model}</TableCell>}
        {display.ii_dimensions && (
          <TableCell>
            <Dimensions dimensions={asset.dimensions} />
          </TableCell>
        )}
        {display.ii_qtyRequested && (
          <TableCell>
            <Box minWidth={140}>
              <AssetQuantityField
                disabled={asset.type === "Kit"}
                quantity={inventoryItem.quantity || ""}
                onChange={quantity => handleChange({ quantity })}
                size="small"
              />
            </Box>
          </TableCell>
        )}
        {display.ii_building && (
          <TableCell>
            <Input label="Building" name={`inventoryItems[${index}].building`} />
          </TableCell>
        )}
        {display.ii_floor && (
          <TableCell>
            <Input label="Floor" name={`inventoryItems[${index}].floor`} />
          </TableCell>
        )}
        {display.ii_room && (
          <TableCell>
            <Input label="Room" name={`inventoryItems[${index}].room`} />
          </TableCell>
        )}
        {display.ii_po_number && (
          <TableCell>
            <Input label="PO Number" name={`inventoryItems[${index}].poNumber`} />
          </TableCell>
        )}
        {display.ii_idsr_number && (
          <TableCell>
            <Input label="IDSR #" name={`inventoryItems[${index}].idsrNumber`} />
          </TableCell>
        )}
        {display.ii_eoc_number && (
          <TableCell>
            <Input label="EOC" name={`inventoryItems[${index}].eocNumber`} />
          </TableCell>
        )}
        {display.ii_deliveryDate && (
          <TableCell>
            <Input type="date" name={`inventoryItems[${index}].deliveryDate`} />
          </TableCell>
        )}
        {display.ii_deliveryTime && (
          <TableCell>
            <Select
              size="small"
              name={`inventoryItems[${index}].deliveryTime`}
              options={timeOptions.map(v => [v, v])}
            />
          </TableCell>
        )}
        {display.price && <TableCell>{pricer(price)}</TableCell>}
        <TableCell>
          <IconButton onClick={() => handleDelete(inventoryItem.id)}>
            <DeleteIcon />
          </IconButton>
        </TableCell>
      </TableRow>
      {display.ii_location && (
        <TableRow className={classes.root}>
          <TableCell colSpan="22">
            <Box mt={-2}>
              <InventoryItemAddress
                error={!inventoryItem.location && submitCounter > 0}
                hideDropdown={inventoryItem.street || inventoryItem.city || inventoryItem.zip}
                inventoryItem={inventoryItem}
                onChange={values => {
                  Object.entries(values).forEach(([key, value]) => {
                    setFieldValue(`inventoryItems[${index}].${key}`, value);
                  });
                }}
              />
            </Box>
          </TableCell>
        </TableRow>
      )}
      {display.ii_disposition && (
        <TableRow>
          <TableCell colSpan="22">
            <Box mt={-2}>
              <DispositionAutocomplete
                value={inventoryItem.disposition || ""}
                onChange={value => {
                  setFieldValue(`inventoryItems[${index}].disposition`, value);
                }}
              />
            </Box>
          </TableCell>
        </TableRow>
      )}
      {display.ii_notes && (
        <TableRow>
          <TableCell colSpan="22">
            <Box mt={-2}>
              <Input multiline rows={2} label="Notes" name={`inventoryItems[${index}].comment`} />
            </Box>
          </TableCell>
        </TableRow>
      )}
    </React.Fragment>
  );
}

export default React.memo(CartItemFields, isEqual);
