import React, { useState } from "react";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import LinearProgress from "@mui/material/LinearProgress";
import useNotification from "src/hooks/notification";
import ScanditScanner from "src/components/barcode/ScanditScanner/ScanditScanner"; 
import useAuth from "src/hooks/useAuth";

export default function ScanOutModal({ itemId, onScanComplete, onClose }) {
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  const [scanError, setScanError] = useState(false);
  const { notifyError, notifySuccess } = useNotification();
  const [openScanner, setOpenScanner] = useState(true);

  const handleScan = (scannedBarcode) => {
    setLoading(true);

    if (scannedBarcode === itemId) {
      notifySuccess(`Successfully picked Item ID: ${itemId}`);
      onScanComplete(true);
      setLoading(false);
      onClose();
    } else {
      notifyError(`Scanned barcode does not match expected Item ID: ${itemId}`);
      setScanError(true);
      setOpenScanner(false);
      setLoading(false);
    }
  };

  const handleScanAgain = () => {
    setScanError(false);
    setOpenScanner(true);
  };

  return (
    <Dialog open onClose={onClose} maxWidth="sm" fullWidth>
      {openScanner ? (
        <>
          <DialogTitle>Scan Out</DialogTitle>
          <DialogContent>
            <Typography variant="h6" gutterBottom>Item ID: {itemId}</Typography>
            <Box display="flex" flexDirection="column" height="100%" justifyContent="space-between">
              <ScanditScanner
                barcodeTrigger={user.company.settings.barcode_trigger}
                onDetected={handleScan}
                onClose={onClose}
              />
              {loading && <LinearProgress className="mt-2" />}
            </Box>
          </DialogContent>
        </>
      ) : (
        <>
          <DialogTitle>
            <Typography variant="h5" align="center" color="black" fontWeight={500}>
              Error
            </Typography>
          </DialogTitle>
          <DialogContent>
            <Box display="flex" alignItems="center" justifyContent="center" minHeight="100px">
              <Typography variant="body1" align="center" color="error" fontWeight={500}>
                The scanned item does not match the expected Item ID: {itemId}<br /><br />
                Please scan the correct item to proceed with this order
              </Typography>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleScanAgain} color="primary" variant="contained">
              Scan Again
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
}
