import React from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import useMutation from "src/hooks/useMutation";
import useWarehouseLocations from "src/hooks/warehouse/useWarehouseLocations";
import StorageLocationAutocomplete from "src/views/capture/RackLocations/StorageLocationAutocomplete/StorageLocationAutocomplete";
import useAuth from "src/hooks/useAuth";
import useNotification from "src/hooks/notification";

import { CREATE_INVENTORY_TRANSACTION_MUTATION } from "src/constants/graphql/mutations";

export default function NewLocationForm({
  clientId,
  triggerScan,
  setTriggerScan,
  onClose,
  code,
  rackLocations,
  oldRackLocations,
  id,
  onSuccess,
}) {
  const { user } = useAuth();
  const [createTransactionMutation] = useMutation(CREATE_INVENTORY_TRANSACTION_MUTATION);
  const { warehouseLocations, loading } = useWarehouseLocations(clientId);
  const { notifySuccess, notifyError } = useNotification();

  const initialValues = {
    rackLocations: rackLocations || [
      {
        warehouse: "",
        rack_location: [],
        sub_qty: 0,
      },
    ],
    notes: "",
    senderName: "",
    carrierName: "",
    trackingNumber: "",
    orderNumber: "",
    poNumber: "",
    quantity: 0,
    code: code || "",
    id: id || "",
    oldRackLocations: oldRackLocations || [],
  };

  const validationSchema = Yup.object().shape({
    rackLocations: Yup.array()
      .of(
        Yup.object().shape({
          warehouse: Yup.string().required("Warehouse is required"),
          rack_location: Yup.array()
            .min(1, "At least one storage location is required")
            .required("Storage location is required"),
        })
      )
      .min(1, "At least one rack location is required"),
  });

  const handleSubmit = (values, { setSubmitting }) => {

    const locations = values.rackLocations.map((location) => ({
      sub_qty: location.sub_qty,
      rack_location: location.rack_location,
      warehouse: location.warehouse,
    }));

    const transactionPayload = {
      code: values.code,
      notes: values.notes || "",
      senderName: values.senderName || "",
      carrierName: values.carrierName || "",
      trackingNumber: values.trackingNumber || "",
      orderNumber: values.orderNumber || "",
      poNumber: values.poNumber || "",
      quantity: values.quantity || 0,
      rackLocations: locations,
      rackLocationsChanges: [],
      oldRackLocations: values.oldRackLocations,
      id: values.id,
    };

    createTransactionMutation({
      variables: transactionPayload,
      onSuccess: (data) => {
        setSubmitting(false);
        notifySuccess("Asset successfully updated!");
        if (onSuccess) onSuccess(data, values);
      },
      onFailure: (errors) => {
        setSubmitting(false);
        notifyError(errors.submit || "Error updating asset.");
      },
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {({ values, handleSubmit, setFieldValue, isSubmitting }) => (
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            {values.rackLocations.map((location, index) => (
              <Grid item xs={12} key={index}>
                <StorageLocationAutocomplete
                  index={index}
                  name="rackLocations"
                  value={location}
                  loading={loading}
                  disabled={false}
                  warehouseLocations={warehouseLocations}
                  setFieldValue={setFieldValue}
                  triggerScan={triggerScan}
                  setTriggerScan={setTriggerScan}
                />
              </Grid>
            ))}
          </Grid>
          <Box mt={4} display="flex" justifyContent="flex-end">
            <Button variant="outlined" onClick={onClose} disabled={isSubmitting}>
              Cancel
            </Button>
            <Box ml={2}>
              <Button variant="contained" color="primary" type="submit" disabled={isSubmitting}>
                Submit
              </Button>
            </Box>
          </Box>
        </form>
      )}
    </Formik>
  );
}
