import React, {useState} from "react";
import {DraggableCore} from "react-draggable";
import {makeStyles} from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
  resizable: {
    position: "absolute",
    top: 0,
    bottom: 0,
    right: -8,
    cursor: "ew-resize",
    display: "none",
    padding: "0 8px",

    ".resizableWrap:hover &": {
      display: "block",
    },
  },
  resizableIndicator: {
    margin: "0 auto",
    width: 1,
    height: "100%",
    background: theme.palette.grey[300],
  },
}));

const ResizeBorder = ({
  onResizing,
  onStopReisizing,
  onMouseMove,
  onMouseOut,
  index,
  children,
}) => {
  const [initX, setInitX] = useState(0);
  const classes = useStyles();

  const onStartReisizing = index => (e, ui) => {
    setInitX(ui.lastX);
  };

  const onDragReisizing = index => (e, ui) => {
    onResizing(index, ui.lastX - initX);
    setInitX(ui.lastX);
  };

  return (
    <DraggableCore
      axis="x"
      onStart={onStartReisizing(index)}
      onDrag={onDragReisizing(index)}
      onStop={onStopReisizing}
      position={{ x: 0, y: 0 }}
      zIndex={999}
    >
      <div onMouseMove={onMouseMove} onMouseOut={onMouseOut} className={clsx(classes.resizable)}>
        <div className={clsx(classes.resizableIndicator)}></div>
      </div>
    </DraggableCore>
  );
};

export default ResizeBorder;
