import React, {useCallback, useLayoutEffect, useRef, useState} from "react";
import {unionBy} from "lodash/array";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Autocomplete, {createFilterOptions} from "@mui/material/Autocomplete";
import ClickAwayListener from "@mui/base/ClickAwayListener";

import {
  useOnEscEffect,
  useOnReFocuse,
  useOnShiftEnterEffect,
  useOnShiftTabEffect,
  useOnTabEffect,
} from "../../../utils/keyEffects";

const filter = createFilterOptions();

export default function AutocompleteField(allProps) {
  const {
    field,
    onSubmit,
    setValue,
    value,
    innerRef,
    onRevert,
    setEdit,
    rowIndex,
    columnIndex,
    nextRow,
    prevRow,
    nextColumn,
    prevColumn,
    moveToNextColumn,
    onClose,
    onChange,
    options,
    ...props
  } = allProps;

  const inputRef = useRef(null);
  const statusRef = useRef(null);
  const [open, setOpen] = useState(true);
  const [inputValue, setInputValue] = useState(value);

  const handleClose = v => {
    setOpen(false);
    onRevert();
    onClose();
  };

  const onSubmitPrevRow = () => {
    setOpen(false);
    onSubmit();
    prevRow();
  };

  const onSubmitNextCol = () => {
    const input = inputRef.current;
    setOpen(false);
    onSubmit(input.value);
    nextColumn();
  };

  const onSubmitPrevCol = () => {
    setOpen(false);
    onSubmit();
    prevColumn();
  };

  const handleSubmit = useCallback(
    value => {
      setValue(value);
      setTimeout(() => {
        onSubmit(value);
        setTimeout(() => {
          moveToNextColumn();
          setOpen(false);
        });
      });
    },
    [setValue, onSubmit, moveToNextColumn, setOpen]
  );

  useOnShiftEnterEffect(innerRef, onSubmitPrevRow);
  useOnTabEffect(innerRef, onSubmitNextCol);
  useOnShiftTabEffect(innerRef, onSubmitPrevCol);
  useOnReFocuse(innerRef, handleClose);
  useOnEscEffect(innerRef, handleClose);

  useLayoutEffect(() => {
    return () => {
      const input = inputRef.current;
      const currentValue = input.value;
      const previousValue = allProps.entity[field.id];
      const isSubmitted = statusRef.current === "submitted";

      if (!isSubmitted && currentValue !== previousValue) {
        onSubmit(currentValue);
      }
    };
  }, []);

  React.useEffect(() => {
    const input = inputRef.current;
    input.select();
    const val = input.value;
    input.value = "";
    input.value = val;
  }, []);

  const handleChangeValue = (event, newValue) => {
    let value = "";
    if (typeof newValue === "string") {
      value = newValue;
    } else if (newValue && newValue.inputValue) {
      value = newValue.inputValue;
    } else {
      value = newValue;
    }
    statusRef.current = "submitted";
    handleSubmit(value);
  };

  const filterOptions = (options, params) => {
    const filtered = filter(options, params);
    const { inputValue } = params;
    const isExisting = options.some(option => inputValue === option.title);

    if (inputValue !== "" && !isExisting) {
      filtered.push(inputValue);
    }

    return unionBy(filtered, v => v.inputValue || v);
  };

  const getOptionLabel = option => {
    // Value selected with enter, right from the input
    if (typeof option === "string") {
      return option;
    }
    // Add "xxx" option created dynamically
    if (option.inputValue) {
      return option.inputValue;
    }
    // Regular option
    return option.title;
  };

  return (
    <Box display="flex" flexGrow={1} mx={-2}>
      <ClickAwayListener onClickAway={() => onClose()}>
        <Autocomplete
          fullWidth
          label={props.label}
          open={open}
          inputValue={inputValue}
          onInputChange={e => {
            if (e) {
              setInputValue(e.target.value || "");
            }
          }}
          onChange={handleChangeValue}
          filterOptions={filterOptions}
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          getOptionLabel={getOptionLabel}
          freeSolo
          options={options.map(([value, title]) => title)}
          renderInput={params => (
            <TextField
              {...params}
              sx={{ height: "100%" }}
              variant="standard"
              inputRef={inputRef}
              InputProps={{
                sx: { height: "100%" },
                disableUnderline: true,
                ...params.InputProps,
              }}
            />
          )}
        />
      </ClickAwayListener>
    </Box>
  );
}
