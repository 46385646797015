import React from "react";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import takeWhile from "lodash/takeWhile";

const dragginOffset = ({ provided, snapshot }) => offset => {
  if (snapshot.isDragging) {
    const x = offset.x;
    const y = provided.draggableProps.style.top - offset.y || 0;
    provided.draggableProps.style.left = x;
    provided.draggableProps.style.top = y;
  }
};

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const Move = ({ onDragEnd, disabled, ...props }) => {
  const handleOnDragEnd = result => {
    if (!result.destination) {
      return;
    }
    onDragEnd(prevItems => reorder(prevItems, result.source.index, result.destination.index));
  };

  return (
    <DragDropContext onDragEnd={handleOnDragEnd}>
      <Droppable droppableId="droppable" direction="horizontal">
        {(provided, snapshot) => (
          <div ref={provided.innerRef} {...provided.droppableProps} {...props}>
            {props.children.map(
              (child, index) =>
                child && (
                  <Draggable
                    key={`draggable-${index}-${child.key}`}
                    draggableId={`draggable-${index}-${child.key}`}
                    index={child.props.index}
                    isDragDisabled={disabled}
                  >
                    {(provided, snapshot) => {
                      const { fields, field } = child.props;

                      // <---TEMP SOLUTION
                      const visibleFields = fields.filter(
                        fieldItem => fieldItem.visible && !fieldItem.inactive
                      );

                      const previousFields = takeWhile(visibleFields, h => h.id !== field.id);

                      const previousFieldsWidth = previousFields.reduce(
                        (sum, prevField) => sum + (prevField.width || 200),
                        0
                      );
                      // TEMP SOLUTION--->

                      dragginOffset({
                        provided,
                        snapshot,
                      })({ y: 285, x: previousFieldsWidth });

                      return React.cloneElement(child, {
                        ...child.props,
                        key: `draggableItem-${child.key}`,
                        innerRef: provided.innerRef,
                        ...provided.draggableProps,
                        ...provided.dragHandleProps,
                        style: {
                          ...child.props.style,
                          ...provided.draggableProps.style,
                        },
                      });
                    }}
                  </Draggable>
                )
            )}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default Move;
