import React from "react";
import CircularProgress from "@mui/material/CircularProgress";

import {useKitAssets} from "../../../contexts/kit-assets";
import AddAssetsModal from "./AddAssetsModal";

export default function CustomKit({ onClose }) {
  const { kit, loading } = useKitAssets();
  const assets = kit?.kitAssets?.map(v => ({ ...v.asset, quantity: v.quantity })) || [];
  return loading ? (
    <CircularProgress sx={{ ml: 1 }} size={22} />
  ) : (
    <AddAssetsModal title="Custom Kit" assets={assets} onClose={onClose} />
  );
}
