import React from "react";
import * as Yup from "yup";
import {Formik} from "formik";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import FormHelperText from "@material-ui/core/FormHelperText";
import Grid from "@material-ui/core/Grid";
import {useQuery} from "@apollo/react-hooks";

import useNotification from "../../hooks/notification";
import useMutation from "../../hooks/useMutation";
import {usePermissions} from "../../contexts/permissions";
import {useClients} from "../../contexts/clients";
import {COMPANY_USERS_QUERY, INVITE_USER_MUTATION} from "../../constants/graphql";
import {mapKeys} from "../../utils/parseErrors";
import Input from "../../components/form/Input";
import Select from "../../components/form/Select";
import Autocomplete from "../../components/form/Autocomplete";
import ModalSkeleton from "../../components/ModalSkeleton";
import useAuth from "../../hooks/useAuth";
import MultiSelect from "../../components/form/MultiSelect";

const validationSchema = Yup.object().shape({
  userId: Yup.string().nullable(),
  email: Yup.string()
    .email("Must be a valid email")
    .max(255)
    .required("Email is required"),
  role: Yup.string()
    .required("Role is required")
    .nullable(),
  projectIds: Yup.array().nullable(),
});

const ROLES = [
  ["shopper", "Shopper"],
  ["inventory", "Inventory"],
  ["manager", "Manager"],
];

export default function InviteForm({ client, onSuccess }) {
  const { clients } = useClients();
  const { data: { companyUsers = [] } = {}, loading } = useQuery(COMPANY_USERS_QUERY);
  const usersToInvite = companyUsers.filter(user => user.role !== "admin");
  const [inviteUser] = useMutation(INVITE_USER_MUTATION);
  const { notifySuccess } = useNotification();
  const { can } = usePermissions();
  const { user } = useAuth();
  const { settings } = user.company;

  // Case-insensitive sorting of clients and their projects
  const groupedProjectsList = clients.map(client => [
    client.name,
    client.projects.map(({ id, name }) => [id, name])
      .sort((a, b) => a[1].toLowerCase().localeCompare(b[1].toLowerCase()))
  ]).sort((a, b) => a[0].toLowerCase().localeCompare(b[0].toLowerCase()));

  const userRoles = can("role.admin") ? [...ROLES, ["admin", "Admin"]] : ROLES;

  if (loading) {
    return <ModalSkeleton />;
  }

  const onSubmit = (values, { setErrors, setStatus, setSubmitting }) => {
    setSubmitting(true);

    // Derive clientIds from selected projectIds
    const selectedProjectIds = values.projectIds;
    const selectedClients = clients.filter(client =>
      client.projects.some(project => selectedProjectIds.includes(project.id))
    );
    const selectedClientIds = selectedClients.map(client => client.id);

    const variables = {
      ...values,
      clientIds: selectedClientIds,
      email: values.email,
      userId: values.userId,
    };

    inviteUser({
      variables,
      onSuccess: () => {
        if (onSuccess) {
          onSuccess();
        }
        setSubmitting(false);
        setStatus({ success: true });
        notifySuccess("User has been invited");
      },
      onFailure: errors => {
        setStatus({ success: false });
        setErrors(mapKeys(errors, { user: "userId" }));
        setSubmitting(false);
      },
    });
  };

  const initialValues = {
    email: "",
    projectIds: client ? client.projects.map(project => project.id) : [],
    role: null,
    userId: null,
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ errors, handleBlur, isSubmitting, handleSubmit, values }) => (
        <form noValidate onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Input name="email" label="New User Email" onBlur={handleBlur} type="email" />
            </Grid>
            <Grid item xs={12}>
              <Select
                size="small"
                name="role"
                label="Permission Role"
                options={userRoles}
                value={values.role}
              />
            </Grid>
            <Grid item xs={12}>
              <MultiSelect
                fullWidth
                label={`${settings.filter_field2} Permissions`}
                name="projectIds"
                value={values.projectIds}
                groupOptions={groupedProjectsList}
              />
            </Grid>
            <Grid item xs={12}>
              {errors.submit && (
                <Box mt={3}>
                  <FormHelperText error>{errors.submit}</FormHelperText>
                </Box>
              )}
            </Grid>
          </Grid>
          <Box mt={3}>
            <Button
              id="inviteUserSubmit"
              color="secondary"
              disabled={isSubmitting}
              fullWidth
              size="large"
              type="submit"
              variant="contained"
            >
              Send Invite
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
}
