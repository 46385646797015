import React from "react";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Skeleton from "@material-ui/lab/Skeleton";

const list = Array.from(new Array(6));

export default function SkeletonResult() {
  return (
    <Grid container spacing={3}>
      {list.map((item, index) => (
        <Grid key={index} item md={4} xs={6}>
          <Skeleton variant="rect" width={"100%"} height={200} />
          <Box pt={2}>
            <Skeleton width="70%" />
            <Skeleton width="60%" />
          </Box>
        </Grid>
      ))}
    </Grid>
  );
}
