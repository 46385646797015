import React, {useRef} from "react";
import Box from "@mui/material/Box";

export default function SingleClickView({ value }) {
  const ref = useRef();

  const handleClick = () => {
    const event = new MouseEvent("dblclick", {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    ref.current.parentElement.dispatchEvent(event);
  };

  return (
    <Box
      ref={ref}
      width="calc(100% + 32px)"
      height="calc(100% + 16px)"
      display="flex"
      p="8px 16px"
      m="-8px -16px"
      alignItems="center"
      onClick={handleClick}
    >
      {value || ""}
    </Box>
  );
}
