import React from "react";

import RackLocationsModal from "./RackLocationsModal";
import RackLocationsOrdersModal from "./RackLocationsOrdersModal";
import RackLocationsInput from "./RackLocationsInput";

export default function RackLocationsCapture({ type, disabled, entity }) {
  const [open, setOpen] = React.useState(false);
  const rackLocations = entity.rackLocations || [];

  const toggleModal = () => {
    if (disabled) return;

    setOpen(v => !v);
  };

  return (
    <>
      {type === "orders" ? (
        <RackLocationsOrdersModal
          open={open}
          disabled={disabled}
          toggleModal={toggleModal}
          entity={entity}
        />
      ) : type === "scrubber" ? (
        <RackLocationsModal open={open} toggleModal={toggleModal} entity={entity} />
      ) : (
        <RackLocationsModal open={open} toggleModal={toggleModal} entity={entity} />
      )}
      <RackLocationsInput
        disabled={disabled}
        rackLocations={rackLocations}
        toggleModal={toggleModal}
        onKeyDown={e => {
          if (e.key === "Enter") {
            toggleModal();
          }
        }}
      />
    </>
  );
}
