import React, {useCallback, useState} from "react";
import {FixedSizeList as List} from "react-window";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";

import {getImage} from "../utils/images";

export function getAssetLabel(asset) {
  return `${asset.description || ""} ${asset.model || ""}`.trim();
}

function getLabel(asset) {
  return [asset.itemId, asset.description, asset.model]
    .filter(v => v)
    .join(" | ")
    .trim();
}

export default function SearchAutocomplete({ value, collection, onChange, selectAsset, size }) {
  const [showSuggestions, setShowSuggestions] = useState(false);

  const closeSuggestions = () => setShowSuggestions(false);

  const handleChange = useCallback(
    event => {
      setShowSuggestions(true);
      onChange(event.target.value);
    },
    [onChange, setShowSuggestions]
  );

  const Row = useCallback(
    ({ index, style }) => {
      const asset = collection[index];
      return (
        <div style={style}>
          <Box mt={1}>
            <Button
              fullWidth
              variant="outlined"
              onClick={() => {
                selectAsset(asset);
                onChange(getLabel(asset));
                closeSuggestions();
              }}
            >
              <Box
                display="flex"
                height="30px"
                width="100%"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box maxWidth="50%" whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
                  {getLabel(asset)}
                </Box>
                <Box data-id="asset-images">
                  {asset.images.map((image, index) => (
                    <Box mr={0.5} key={index} borderRadius={4} clone>
                      <img style={{ maxHeight: "30px" }} src={getImage(image.url)} />
                    </Box>
                  ))}
                </Box>
              </Box>
            </Button>
          </Box>
        </div>
      );
    },
    [collection]
  );

  return (
    <ClickAwayListener onClickAway={closeSuggestions}>
      <Box>
        <TextField
          fullWidth
          size={size}
          variant="outlined"
          label="Search Assets"
          value={value}
          onFocus={() => setShowSuggestions(true)}
          onChange={handleChange}
        />
        {showSuggestions && (
          <Box position="relative">
            <Box
              position="absolute"
              width="100%"
              height={250}
              bgcolor="white"
              border="1px solid #ddd"
              zIndex={10}
              overflow="auto"
            >
              <List height={250} itemCount={collection.length} itemSize={46} width="100%">
                {Row}
              </List>
            </Box>
          </Box>
        )}
      </Box>
    </ClickAwayListener>
  );
}
