import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";

import LogoImg from "../images/logo_black.svg";

export default function Logo(props) {
  return (
    <IconButton>
      <Tooltip arrow title="Home">
        <img className="logo" alt="Logo" src={LogoImg} {...props} />
      </Tooltip>
    </IconButton>
  );
}
