import React, {useEffect, useState} from "react";
import PublishIcon from "@material-ui/icons/Publish";
import {Box, IconButton, LinearProgress} from "@material-ui/core";

import {BULK_CREATE_ASSET_IMAGES_MUTATION} from "../../../../constants/graphql";
import useMutation from "../../../../hooks/useMutation";
import useNotification from "../../../../hooks/notification";
import {useOnEscEffect, useOnReFocuse, useOnShiftTabEffect, useOnTabEffect,} from "../../../../utils/keyEffects";

export default function FileField({
  field,
  onSubmit,
  setValue,
  value,
  innerRef,
  onRevert,
  setEdit,
  rowIndex,
  columnIndex,
  nextRow,
  prevRow,
  nextColumn,
  prevColumn,
  onChange,
  onClose,
  entity,
}) {
  const [loading, setLoading] = useState(false);
  const [addImageMutation] = useMutation(BULK_CREATE_ASSET_IMAGES_MUTATION);
  const { notifySuccess, notifyError } = useNotification();

  const onUpload = files => {
    setLoading(true);

    addImageMutation({
      variables: { assetId: entity.id, images: files },
      onSuccess: ({ asset }) => {
        setLoading(false);
        notifySuccess("Images successfully uploaded!");

        onChange(entity.id, { photo: asset.photo, images: asset.images }, { skipMutation: true });

        onClose();
      },
      onFailure: errors => {
        notifyError(errors.submit);
        setLoading(false);
      },
    });
  };

  useOnReFocuse(innerRef, loading ? null : onClose);
  useOnEscEffect(innerRef, onClose);
  useOnTabEffect(innerRef, onClose);
  useOnShiftTabEffect(innerRef, onClose);

  useEffect(() => {
    innerRef.current?.click();
  }, []);

  return (
    <form style={{ width: "100%", height: "100%" }}>
      <Box display="none" clone>
        <input
          id={`contained-button-file-${field.id}`}
          disabled={loading}
          style={{ resize: "none" }}
          autoFocus
          type="file"
          multiple={true}
          accept="image/*"
          onChange={event => onUpload(event.target.files)}
        />
      </Box>
      <Box width="100%" clone>
        {loading ? (
          <label>
            <LinearProgress />
          </label>
        ) : (
          <label ref={innerRef} htmlFor={`contained-button-file-${field.id}`}>
            <IconButton onClick={() => innerRef.current?.click()} disabled={loading}>
              <PublishIcon fontSize="small" />
            </IconButton>
          </label>
        )}
      </Box>
    </form>
  );
}
