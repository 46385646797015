import React, {useState} from "react";

import ImageField from "../../TableEntities/Fields/ImageField";
import ImageModal from "../../images/ImageModal";
import {sortImages} from "../../../utils/images";

const useAssetImageModal = ({ collection, updateImage, removeImage, bulkCreateImages, id }) => {
  const [selectedId, setSelectedId] = useState(null);
  const asset = collection.find(entity => entity.id === selectedId);
  const images = sortImages(asset?.images);

  const handleBulkCreateImages = ({ variables, onSuccess, onFailure }) => {
    bulkCreateImages({
      variables: { ...variables, assetId: asset?.id },
      onSuccess,
      onFailure,
    });
  };

  const ImageModalComponent = asset?.id && (
    <ImageModal
      open={true}
      asset={asset}
      images={images}
      selectedId={images[0]?.id}
      onClose={() => setSelectedId(null)}
      onRemove={removeImage}
      onUpdate={updateImage}
      onCreate={handleBulkCreateImages}
    />
  );

  const Component = ({ entity, value }) => (
    <ImageField onDoubleClick={() => setSelectedId(entity.id)} entity={entity} value={value} />
  );

  return {
    ImageModal: ImageModalComponent,
    props: {
      component: Component,
    },
  };
};

export default useAssetImageModal;
