import React, {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import ImageDND from "../../../views/capture/Images/ImageDND";
import ImagesSlider from "../../../views/capture/Images/ImagesSlider";
import ImageModal from "../../../components/images/ImageModal";

export default function Image({ images, onUpload, onRemove }) {
  const [index, setIndex] = useState(0);
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    if (images.length > 0) {
      setOpen(true);
    }
  };

  const handleCreate = ({ variables }) => {
    onUpload(variables.images);
    setOpen(false);
  };

  useEffect(() => {
    setIndex(0);
  }, [images.length]);

  return (
    <Box display="flex" justifyContent="center">
      <Box width={400} bgcolor="#f4f6f8" position="relative" onClick={handleOpen}>
        {images.length === 0 && <ImageDND onDrop={onUpload} />}
        <ImagesSlider list={images} index={index} onChangeIndex={setIndex} />
      </Box>
      {open && images[index] && (
        <ImageModal
          open
          edit={false}
          closeOnSave={true}
          selectedId={images[index].id}
          images={images}
          onClose={() => setOpen(false)}
          onRemove={onRemove}
          onCreate={handleCreate}
        />
      )}
    </Box>
  );
}
