import React from "react";
import {useParams} from "react-router-dom";
import {Formik} from "formik";
import IconButton from "@material-ui/core/IconButton";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import Backdrop from "@mui/material/Backdrop";
import CloseIcon from "@material-ui/icons/Close";

import {CaptureProvider} from "../../../../contexts/capture";
import {fetchAssetValues} from "../../../../utils/asset";
import {useAssetsCollection} from "../../../../contexts/assetsCollection";
import FormPage from "../../../../components/TablePro/components/assets/FormPage";
import {useEditAsset} from "../../../../components/TablePro/Table/EditAssetProvider";

const DisableTransitionComponent = ({ children }) => children;

export default function AssetDetailsModal({ entity, onClose }) {
  const { handeRefetch } = useAssetsCollection();
  const { setAsset } = useEditAsset();
  const { clientId } = useParams();
  const nav = window.location.pathname.split("/")[1];
  const displayClient = nav === "clients" && clientId === "null";
  const displayProject = nav === "clients";

  const openDuplicatedAsset = asset => {
    onClose();
    setAsset(asset);
  };

  return (
    <Dialog
      open
      fullWidth
      maxWidth="md"
      onClose={onClose}
      TransitionComponent={DisableTransitionComponent}
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          sx: {
            transition: "none !important",
          },
        },
      }}
    >
      <DialogTitle sx={{ background: "#f4f6f8", display: "flex", justifyContent: "space-between" }}>
        <Box width="48px" />
        <Typography fontSize={24} fontWeight={500} color="textPrimary">
          Asset Details
        </Typography>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ background: "#f4f6f8" }}>
        <Box>
          <CaptureProvider>
            <Formik key={entity?.id} initialValues={fetchAssetValues(entity)} onSubmit={() => {}}>
              <FormPage
                type="scrubber"
                displayClient={displayClient}
                displayProject={displayProject}
                currentAsset={entity}
                onCloseModal={onClose}
                handeRefetch={handeRefetch}
                openDuplicatedAsset={openDuplicatedAsset}
              />
            </Formik>
          </CaptureProvider>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
