import React, {useState} from "react";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import FormHelperText from "@material-ui/core/FormHelperText";

import useNotification from "../../hooks/notification";
import useMutation from "../../hooks/useMutation";
import {CHECK_OUT_MUTATION} from "../../constants/graphql";
import ActionModal from "../modals/action-modal";
import ScannerModal from "./ScannerModal";

const CheckOutModal = ({ onSuccess, onClose, barcode }) => {
  const { notifySuccess, notifyError } = useNotification();
  const [error, setError] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const [checkOutCode] = useMutation(CHECK_OUT_MUTATION);

  const handleCheckOut = () => {
    setError(null);
    setDisabled(true);

    return checkOutCode({
      variables: { code: barcode },
      onSuccess: ({ amount }) => {
        if (onSuccess) onSuccess();
        setDisabled(false);
        notifySuccess(`You have checked out ${amount} assets`);
        onClose();
      },
      onFailure: errors => {
        setDisabled(false);
        if (errors.submit) notifyError(errors.submit);
        setError(errors.code);
      },
    });
  };

  const onCheckOutDetected = (code, { onFailure } = {}) => {
    return checkOutCode({
      variables: { code },
      onSuccess: ({ amount }) => {
        if (onSuccess) onSuccess();
        notifySuccess(`You have checked out ${amount} assets`);
        onClose();
      },
      onFailure,
    });
  };

  if (barcode) {
    return (
      <ActionModal open maxWidth={440} onClose={onClose} disableTransition title="Check Out">
        <Box>
          <TextField
            fullWidth
            disabled={true}
            name="barcode"
            label="Barcode"
            value={barcode}
            variant="outlined"
          />
        </Box>

        {error && (
          <Box mt={1}>
            <FormHelperText htmlFor="barcode" error={!!error}>
              {error}
            </FormHelperText>
          </Box>
        )}

        <Box mt={3} display="flex" justifyContent="space-between">
          <Button key="override" variant="outlined" onClick={onClose}>
            Close
          </Button>

          <Button
            disabled={disabled}
            key="overrideConfirm"
            variant="contained"
            color="primary"
            onClick={handleCheckOut}
          >
            Confirm
          </Button>
        </Box>
      </ActionModal>
    );
  }

  return <ScannerModal onDetected={onCheckOutDetected} onClose={onClose} />;
};

export default CheckOutModal;
