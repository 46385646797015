import React from "react";
import orderBy from "lodash/orderBy";

import AutocompleteField from "src/components/TablePro/fields/AutocompleteField";
import {useCategories} from "src/views/capture/SustainabilitySheetContext";

export default function CategoryField(props) {
  const categories = useCategories();

  const categoryOptions = orderBy(
    categories.map(value => [value, value]),
    v => v[0]
  );

  return <AutocompleteField {...props} options={categoryOptions} />;
}
