import React from "react";

import MultipleDestinationRoomNumberModal
  from "../../../components/TablePro/fields/assets/MultipleDestinationRoomNumberModal";
import MultipleDestinationRoomNumberOrdersModal from "./MultipleDestinationRoomNumberOrdersModal";
import MultipleDestinationRoomNumberInput from "./MultipleDestinationRoomNumberInput";

export default function DestinationRoomNumbersCapture({ type, disabled, entity }) {
  const [open, setOpen] = React.useState(false);

  const toggleModal = () => {
    if (disabled) return;

    setOpen(v => !v);
  };

  return (
    <>
      {type === "orders" ? (
        <MultipleDestinationRoomNumberOrdersModal
          open={open}
          disabled={disabled}
          toggleModal={toggleModal}
          entity={entity}
        />
      ) : (
        <MultipleDestinationRoomNumberModal open={open} toggleModal={toggleModal} entity={entity} />
      )}
      <MultipleDestinationRoomNumberInput
        disabled={disabled}
        entity={entity}
        toggleModal={toggleModal}
        onKeyDown={e => {
          if (e.key === "Enter") {
            toggleModal();
          }
        }}
      />
    </>
  );
}
