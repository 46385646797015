import React from "react";
import { useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import { useMutation, useQuery } from "@apollo/react-hooks";
import CircularProgress from "@mui/material/CircularProgress";
import { CLIENTS_QUERY, REMOVE_ASSET_MUTATION } from "../../../constants/graphql";
import { GET_SHIPMENT_QUERY } from "../../../constants/graphql/queries";
import { DELETE_SHIPMENT_MUTATION } from "../../../constants/graphql/mutations";
import ConfirmDeleteModal from "../../images/ConfirmDeleteModal";
import useQueryParams from "../../../hooks/useQueryParams";
import { getShipmentValues } from "../../../utils/shipment";
import Shipment from "./Shipment/Shipment";
import Items from "./Items/Items";
import Details from "./Details/Details";
import OSAndD from "./OSAndD/OSAndD";
import Confirmation from "./Confirmation/Confirmation";

export default function ReceivingWizardModal({ onToggle }) {
  const [openRemoveShipment, setOpenRemoveShipment] = React.useState(false);
  const params = useQueryParams();
  const id = params.get("id");
  const editShipment = params.get("editShipment") === "true";
  const { projectId } = useParams();
  const step = params.get("step");
  const { data: { clients = [] } = {}, loading: loadingClients } = useQuery(CLIENTS_QUERY);
  const project = clients.flatMap(v => v.projects).find(v => v.id === projectId) || {};
  const [removeShipment] = useMutation(DELETE_SHIPMENT_MUTATION);
  const [removeAsset] = useMutation(REMOVE_ASSET_MUTATION);
  const { data: { shipment = {} } = {}, loading: loadingShipment } = useQuery(GET_SHIPMENT_QUERY, {
    variables: { id },
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-first",
    skip: !id,
  });
  const initialValues = getShipmentValues(shipment);

  const handleClose = () => {
    if (editShipment || ["details", "confirmation"].includes(step)) {
      onToggle();
    } else {
      setOpenRemoveShipment(true);
    }
  };

  const handleRemoveShipment = () => {
    return Promise.all([
      ...shipment.shipmentItems.map(item =>
        removeAsset({
          variables: { id: item.asset.id },
        })
      ),
      removeShipment({
        variables: { id },
      }),
    ]).then(() => {
      onToggle();
    });
  };

  return (
    <Dialog open maxWidth="sm" fullWidth onClose={handleClose}>
      <Box display="flex" flexDirection="column" height="100%">
        {loadingClients || loadingShipment ? (
          <Box p={3} display="flex" minHeight={400} alignItems="center" justifyContent="center">
            <CircularProgress color="primary" />
          </Box>
        ) : (
          <Box minHeight={400} height="100%">
            {step === "details" && <Details project={project} />}
            {step === "shipment" && <Shipment shipment={shipment} initialValues={initialValues} />}
            {step === "items" && <Items shipment={shipment} project={project} />}
            {step === "os&d" && <OSAndD shipment={shipment} />}
            {step === "confirmation" && <Confirmation shipment={shipment} onClose={handleClose} />}
          </Box>
        )}
      </Box>
      {openRemoveShipment && (
        <ConfirmDeleteModal
          title="Delete Shipment"
          descr="Are you sure want to delete this shipment?"
          onClose={() => setOpenRemoveShipment(null)}
          onDelete={handleRemoveShipment}
        />
      )}
    </Dialog>
  );
}
