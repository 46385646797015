import React from "react";
import orderBy from "lodash/orderBy";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";

export default function SelectWarehouse({ warehouses, changeWarehouse }) {
  const list = orderBy(warehouses, v => v.name.toLowerCase());

  return (
    <Box height="100%" minHeight="75vh" display="flex" justifyContent="center" alignItems="center">
      <Paper sx={{ pt: 3, pb: 2, minWidth: 320 }}>
        <Typography mb={1} fontSize={24} align="center">
          Select Warehouse
        </Typography>
        <List>
          {list.map(v => (
            <React.Fragment key={v.id}>
              <Divider />
              <ListItem disablePadding sx={{ p: 0 }}>
                <ListItemButton onClick={() => changeWarehouse(v.id)}>
                  <ListItemText sx={{ px: 2 }} primary={v.name} />
                </ListItemButton>
              </ListItem>
            </React.Fragment>
          ))}
        </List>
      </Paper>
    </Box>
  );
}
