import React from "react";
import Checkbox from "@material-ui/core/Checkbox";
import {makeStyles} from "@material-ui/core";

import {useOnEscEffect, useOnReFocuse} from "src/utils/keyEffects";

const useStyles = makeStyles(theme => ({
  checkboxInput: {
    width: "initial",
  },
}));

const CheckboxField = ({
  field,
  onSubmit,
  setValue,
  value,
  innerRef,
  onRevert,
  setEdit,
  rowIndex,
  columnIndex,
  nextRow,
  prevRow,
  nextColumn,
  prevColumn,
  onChange,
  onClose,
  ...props
}) => {
  const classes = useStyles({ field });

  const handleSubmit = value => {
    onSubmit(value);
    nextRow();
  };

  useOnReFocuse(innerRef, onSubmit);
  useOnEscEffect(innerRef, onRevert);

  return (
    <Checkbox
      open={true}
      ref={innerRef}
      data-id={field.id}
      labelId={field.id}
      id={field.id}
      value={Boolean(value)}
      {...props}
      className={classes.checkboxInput}
      onChange={event => handleSubmit(Boolean(event.target.value))}
    />
  );
};

export default CheckboxField;
