import React from "react";
import Typography from "@material-ui/core/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import WarningIcon from "@material-ui/icons/Warning";
import ActionModal from "src/components/modals/action-modal";

const ConfirmDeleteModal = ({ title, descr, onClose, onDelete }) => (
  <ActionModal
    maxWidth={440}
    title={
      <>
        <Box mr={1}>{title}</Box>
        <WarningIcon htmlColor="#ff9800" />
      </>
    }
    onClose={onClose}
  >
    <Box mt={1}>
      <Typography align="center" color="textSecondary">
        {descr}
      </Typography>
    </Box>
    <Box mt={2.5}>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <Button data-id="delete-asset-modal" onClick={onClose} variant="outlined" fullWidth>
            Cancel
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            data-id="confirm-delete"
            onClick={() => {
              onDelete();
              onClose();
            }}
            color="primary"
            variant="contained"
            fullWidth
          >
            Delete
          </Button>
        </Grid>
      </Grid>
    </Box>
  </ActionModal>
);

export default ConfirmDeleteModal;
