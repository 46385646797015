import React, { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/react-hooks";

import { CLIENTS_QUERY } from "../constants/graphql";
import useAuth from "../hooks/useAuth";

const CustomFieldsContext = React.createContext(null);

const initialState = {
  id: true,
  asset_id: true,
  barcode: true,
  barcode_number: true,
  color: true,
  condition: true,
  description: true,
  description_comments: true,
  destination_comments: true,
  destination_employee_name: true,
  destination_quantity: true,
  destination_room_number: true,
  dimensions: true,
  disposition: true,
  existing_building: true,
  existing_floor: true,
  existing_room: true,
  finishes: true,
  manufacturer: true,
  new_building: true,
  new_floor: true,
  new_room: true,
  origin_comments: true,
  origin_cubby_number: true,
  origin_employee_name: true,
  origin_room_number: true,
  photo: true,
  qr_code_image: true,
  quantity: true,
};

export const CustomFieldsProvider = ({ children }) => {
  const customFields = localStorage.getItem("customFields");
  const [state, setState] = useState(customFields ? JSON.parse(customFields) : initialState);

  const handleChange = value => {
    localStorage.setItem("customFields", JSON.stringify(value));
    setState(value);
  };

  return (
    <CustomFieldsContext.Provider value={[state, handleChange]}>
      {children}
    </CustomFieldsContext.Provider>
  );
};

export const fetchSettings = (clients, projectId) => {
  if (!projectId) return;

  return clients.reduce((p, v) => {
    const value = v.projects.find(item => item.id === projectId);
    return value ? value.fieldSettings : p;
  }, null);
};

export const useCustomFields = (props = {}) => {
  const { user } = useAuth();
  const { company } = user;
  const projectId = props.projectId || useParams().projectId;

  const { data: { clients = [] } = {}, loading } = useQuery(CLIENTS_QUERY);

  const customFields = useMemo(() => {
    return fetchSettings(clients, projectId) || company.fieldSettings;
  }, [clients, projectId, company, loading]);

  const value = useMemo(() => {
    return [customFields, loading];
  }, [customFields, projectId, loading]);

  return value;
};

export default CustomFieldsContext;
