import React from "react";

import {KitsCollectionProvider} from "../../../contexts/kitsCollection";
import {KITS_COLLECTION_TABLE_QUERY} from "../../../constants/graphql/queries";
import {KitAssetsProvider} from "../../../contexts/kit-assets";
import KitDetailsModal from "../../../components/TablePro/components/assets/KitDetailsModal";

export default function KitDetailsModalComponent({ asset, onClose }) {
  return (
    <KitsCollectionProvider variables={{}} query={KITS_COLLECTION_TABLE_QUERY}>
      <KitAssetsProvider id={asset.id}>
        <KitDetailsModal disabled entity={asset} onClose={onClose} />
      </KitAssetsProvider>
    </KitsCollectionProvider>
  );
}
