import React from "react";
import {useProject} from "./ProjectProvider";

const StorageEnabledContext = React.createContext(false);

export default function StorageEnabledProvider({ children }) {
  const { project } = useProject();
  const state = React.useState(project.storageEnabled);

  return <StorageEnabledContext.Provider value={state}>{children}</StorageEnabledContext.Provider>;
}

export const useStorageEnabled = () => {
  const context = React.useContext(StorageEnabledContext);
  if (context === undefined) {
    throw new Error("useStorageEnabled must be used within a StorageEnabledProvider");
  }
  return context;
};
