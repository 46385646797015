import React from "react";
import Box from "@mui/material/Box";

const parseString = value =>
  value
    .split(",")
    .pop()
    .split(" - ")
    .pop();

const parseArray = item => {
  const isString = item && typeof item.rack_location === "string";
  const isArray = item && Array.isArray(item.rack_location);
  if (isString) {
    return `${item.rack_location} ${item.sub_qty}`;
  } else if (isArray) {
    if (typeof item.rack_location[0] === "string") {
      return `${item.rack_location} ${item.sub_qty}`;
    } else {
      return `${item.rack_location[item.rack_location.length - 1]} ${item.sub_qty}`;
    }
  } else {
    return null;
  }
};

export default function AssetReplayLogRackLocation({ rackLocations }) {
  const getValue = value => {
    if (typeof value === "string") {
      return parseString(value);
    } else if (value && value.rack_location) {
      return parseArray(value);
    } else if (Array.isArray(value) && value.length > 0) {
      return parseArray(value[value.length - 1]);
    } else {
      return null;
    }
  };

  return rackLocations.map((value, i) => <Box key={i}>{getValue(value)}</Box>);
}
