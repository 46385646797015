import React from "react";
import camelCase from "lodash/camelCase";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import Autocomplete from "../../../components/form/Autocomplete";
import {getOptions} from "../../../utils/form";
import labels, {exhHeaderOptions, localGasTanksOptions, solveWasteCollOptions,} from "../../../constants/displayFields";
import BooleanSelectField from "./BooleanSelectField";
import Input from "../../form/Input";

export default function PlumbingInfoFields({ fullWidth, display, disabledForm }) {
  const xs = fullWidth ? 12 : 6;
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Box mt={-1} mb={1}>
          <Typography fontWeight={500} fontSize={18}>
            Plumbing
          </Typography>
        </Box>
      </Grid>
      {display.hse_co && (
        <Grid item xs={xs}>
          <BooleanSelectField
            name={camelCase("hse_co")}
            label={labels.hse_co}
            disabled={disabledForm}
          />
        </Grid>
      )}
      {display.local_gas_tanks && (
        <Grid item xs={xs}>
          <Autocomplete
            saveCustomOption
            name={camelCase("local_gas_tanks")}
            label={labels.local_gas_tanks}
            options={getOptions(localGasTanksOptions)}
            disabled={disabledForm}
          />
        </Grid>
      )}
      {display.hse_vac && (
        <Grid item xs={xs}>
          <BooleanSelectField
            name={camelCase("hse_vac")}
            label={labels.hse_vac}
            disabled={disabledForm}
          />
        </Grid>
      )}
      {display.local_vac_pump && (
        <Grid item xs={xs}>
          <BooleanSelectField
            name={camelCase("local_vac_pump")}
            label={labels.local_vac_pump}
            disabled={disabledForm}
          />
        </Grid>
      )}
      {display.icw && (
        <Grid item xs={xs}>
          <BooleanSelectField name={camelCase("icw")} label={labels.icw} disabled={disabledForm} />
        </Grid>
      )}
      {display.ihw && (
        <Grid item xs={xs}>
          <BooleanSelectField name={camelCase("ihw")} label={labels.ihw} disabled={disabledForm} />
        </Grid>
      )}
      {display.pcw_supply && (
        <Grid item xs={xs}>
          <BooleanSelectField
            name={camelCase("pcw_supply")}
            label={labels.pcw_supply}
            disabled={disabledForm}
          />
        </Grid>
      )}
      {display.pcw_return && (
        <Grid item xs={xs}>
          <BooleanSelectField
            name={camelCase("pcw_return")}
            label={labels.pcw_return}
            disabled={disabledForm}
          />
        </Grid>
      )}
      {display.ro && (
        <Grid item xs={xs}>
          <BooleanSelectField name={camelCase("ro")} label={labels.ro} disabled={disabledForm} />
        </Grid>
      )}
      {display.di && (
        <Grid item xs={xs}>
          <BooleanSelectField name={camelCase("di")} label={labels.di} disabled={disabledForm} />
        </Grid>
      )}
      {display.floor_sink && (
        <Grid item xs={xs}>
          <BooleanSelectField
            name={camelCase("floor_sink")}
            label={labels.floor_sink}
            disabled={disabledForm}
          />
        </Grid>
      )}
      {display.floor_drain && (
        <Grid item xs={xs}>
          <BooleanSelectField
            name={camelCase("floor_drain")}
            label={labels.floor_drain}
            disabled={disabledForm}
          />
        </Grid>
      )}
      {display.solve_waste_coll && (
        <Grid item xs={xs}>
          <Autocomplete
            saveCustomOption
            name={camelCase("solve_waste_coll")}
            label={labels.solve_waste_coll}
            options={getOptions(solveWasteCollOptions)}
            disabled={disabledForm}
          />
        </Grid>
      )}
      {display.exh_header && (
        <Grid item xs={xs}>
          <Autocomplete
            saveCustomOption
            name={camelCase("exh_header")}
            label={labels.exh_header}
            options={getOptions(exhHeaderOptions)}
            disabled={disabledForm}
          />
        </Grid>
      )}
      {display.vac_pump_vent_plumbing && (
        <Grid item xs={xs}>
          <BooleanSelectField
            name={camelCase("vac_pump_vent_plumbing")}
            label={labels.vac_pump_vent_plumbing}
            disabled={disabledForm}
          />
        </Grid>
      )}
      {display.exh_ceiling_blast_gate && (
        <Grid item xs={xs}>
          <BooleanSelectField
            name={camelCase("exh_ceiling_blast_gate")}
            label={labels.exh_ceiling_blast_gate}
            disabled={disabledForm}
          />
        </Grid>
      )}
      {display.exh_snorkel_arm_plumbing && (
        <Grid item xs={xs}>
          <BooleanSelectField
            name={camelCase("exh_snorkel_arm_plumbing")}
            label={labels.exh_snorkel_arm_plumbing}
            disabled={disabledForm}
          />
        </Grid>
      )}
      {display.exh_other && (
        <Grid item xs={xs}>
          <Input name={camelCase("exh_other")} label={labels.exh_other} disabled={disabledForm} />
        </Grid>
      )}
      {display.list_exh_type && (
        <Grid item xs={xs}>
          <Input
            name={camelCase("list_exh_type")}
            label={labels.list_exh_type}
            disabled={disabledForm}
          />
        </Grid>
      )}
    </Grid>
  );
}
