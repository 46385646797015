import React, {useState} from "react";
import {Box, Button, FormHelperText, TextField} from "@material-ui/core";

import useNotification from "../../hooks/notification";
import useMutation from "../../hooks/useMutation";
import {CHECK_IN_MUTATION} from "../../constants/graphql";
import ActionModal from "../modals/action-modal";
import ScannerModal from "./ScannerModal";

const CheckInModal = ({ onSuccess, onClose, barcode }) => {
  const { notifySuccess, notifyError } = useNotification();
  const [error, setError] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const [checkInCode] = useMutation(CHECK_IN_MUTATION, { onSuccess });

  const handleCheckIn = () => {
    setError(null);
    setDisabled(true);

    return checkInCode({
      variables: { code: barcode },
      onSuccess: ({ amount }) => {
        setDisabled(false);
        notifySuccess(`You have checked in ${amount} assets`);
        onClose();
      },
      onFailure: errors => {
        setDisabled(false);
        if (errors.submit) notifyError(errors.submit);
        setError(errors.code);
      },
    });
  };

  const onCheckInDetected = (code, { onFailure } = {}) => {
    return checkInCode({
      variables: { code },
      onSuccess: ({ amount }) => {
        notifySuccess(`You have checked in ${amount} assets`);
        onClose();
      },
      onFailure,
    });
  };

  if (barcode) {
    return (
      <ActionModal open maxWidth={440} onClose={onClose} disableTransition title="Check In">
        <Box>
          <TextField
            fullWidth
            disabled={true}
            name="barcode"
            label="Barcode"
            value={barcode}
            variant="outlined"
          />
        </Box>

        {error && (
          <Box mt={1}>
            <FormHelperText htmlFor="barcode" error={!!error}>
              {error}
            </FormHelperText>
          </Box>
        )}

        <Box mt={3} display="flex" justifyContent="space-between">
          <Button key="override" variant="outlined" onClick={onClose}>
            Close
          </Button>

          <Button
            disabled={disabled}
            key="override"
            variant="contained"
            color="primary"
            onClick={handleCheckIn}
          >
            Confirm
          </Button>
        </Box>
      </ActionModal>
    );
  }

  return <ScannerModal onDetected={onCheckInDetected} onClose={onClose} />;
};

export default CheckInModal;
