import React, {useRef, useState} from "react";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";

export default function MenuActions({ label, children, ...props }) {
  const sortRef = useRef(null);
  const [openSort, setOpenSort] = useState(false);

  const handleSortOpen = () => {
    setOpenSort(true);
  };

  const handleMenuClose = () => {
    setOpenSort(false);
  };

  return (
    <div>
      <Button {...props} onClick={handleSortOpen} ref={sortRef}>
        {label}
      </Button>
      <Menu anchorEl={sortRef.current} onClose={handleMenuClose} open={openSort} elevation={1}>
        {React.Children.toArray(children)
          .filter(v => v)
          .map((child, index) => {
            const { keepOpen, ...childProps } = child.props || {};
            const handleCloseProps = keepOpen ? { handleMenuClose } : {};
            return React.cloneElement(child, {
              ...childProps,
              key: `menuAction-${index}`,
              onClick: () => {
                child.props.onClick && child.props.onClick();
                if (!keepOpen) handleMenuClose();
              },
              ...handleCloseProps,
            });
          })}
      </Menu>
    </div>
  );
}
