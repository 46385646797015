import useMutation from "src/hooks/useMutation";
import {CLIENTS_QUERY, UPDATE_PROJECT_SETTINGS_MUTATION} from "src/constants/graphql";

export default function useUpdateProjectSettings() {
  return useMutation(UPDATE_PROJECT_SETTINGS_MUTATION, {
    update(
      cache,
      {
        data: {
          updateProjectSettings: { project },
        },
      }
    ) {
      const data = cache.readQuery({ query: CLIENTS_QUERY });
      cache.writeQuery({
        query: CLIENTS_QUERY,
        data: {
          clients: data.clients.map(v => ({
            ...v,
            projects: v.projects.map(p => {
              return p.id === project.id ? project : p;
            }),
          })),
        },
      });
    },
  });
}
