import React, {useState} from "react";
import {useHistory} from "react-router-dom";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";
import LinearProgress from "@material-ui/core/LinearProgress";

import {getBackgroundImage, sortImages} from "../../../../utils/images";
import ImageDND from "../../../../views/capture/Images/ImageDND";

import ImageModal from "../../../../components/images/ImageModal";
import useNotification from "../../../../hooks/notification";
import useQuery from "../../../../hooks/useQuery";
import useMutation from "../../../../hooks/useMutation";
import {
  BULK_CREATE_WAREHOUSE_LOCATION_IMAGES_MUTATION,
  DELETE_WAREHOUSE_LOCATION_IMAGE_MUTATION,
  UPDATE_WAREHOUSE_LOCATION_IMAGE_MUTATION,
} from "../../../../constants/graphql/mutations";

export default function LocationImage({ id, images }) {
  const query = useQuery();
  const history = useHistory();
  const [removeImageMutation] = useMutation(DELETE_WAREHOUSE_LOCATION_IMAGE_MUTATION);
  const [updateImageMutation] = useMutation(UPDATE_WAREHOUSE_LOCATION_IMAGE_MUTATION);
  const [bulkCreateImagesMutation] = useMutation(BULK_CREATE_WAREHOUSE_LOCATION_IMAGES_MUTATION);
  const { notifySuccess, notifyError } = useNotification();

  const updateImage = ({ variables, onSuccess, onFailure }) => {
    updateImageMutation({
      variables,
      onSuccess: ({ asset }) => {
        onSuccess && onSuccess({ asset });
      },
      onFailure: errors => {
        notifyError(errors);
        onFailure && onFailure(errors);
      },
    });
  };

  const bulkCreateImages = ({ variables, onSuccess, onFailure }) => {
    bulkCreateImagesMutation({
      variables,
      onSuccess: ({ asset }) => {
        onSuccess && onSuccess({ asset });
        notifySuccess("Images have been uploaded");
      },
      onFailure: errors => {
        notifyError(errors);
        onFailure && onFailure(errors);
      },
    });
  };

  const removeImage = ({ variables, onSuccess, onFailure }) => {
    removeImageMutation({
      variables,
      onSuccess: ({ asset }) => {
        onSuccess && onSuccess({ asset });
      },
      onFailure: errors => {
        notifyError(errors);
        onFailure && onFailure(errors);
      },
    });
  };

  const [loading, setLoading] = useState(false);
  const list = sortImages(images);
  const defaultIndex = list.findIndex(v => v.default);
  const [index, setIndex] = useState(defaultIndex === -1 ? 0 : defaultIndex);

  const handleOpen = () => {
    if (list.length > 0) {
      history.push({
        pathname: window.location.pathname,
        search: `?image=${list[index].id}`,
      });
    }
  };

  const handleClose = () => {
    history.push({
      pathname: window.location.pathname,
      search: "",
    });
  };

  const handlePrev = e => {
    e.stopPropagation();
    setIndex(prevState => (prevState === 0 ? list.length - 1 : prevState - 1));
  };

  const handleNext = e => {
    e.stopPropagation();
    setIndex(prevState => (prevState + 1 >= list.length ? 0 : prevState + 1));
  };

  const handleBulkCreateImages = ({ variables, onSuccess, onFailure }) => {
    bulkCreateImages({
      variables: { warehouseLocationImages: variables.images, warehouseLocationId: id },
      onSuccess,
      onFailure,
    });
  };

  const handleUpload = images => {
    setLoading(true);
    handleBulkCreateImages({
      variables: { warehouseLocationId: id, images },
      onSuccess: () => {
        setLoading(false);
      },
      onFailure: errors => {
        notifyError(errors);
        setLoading(false);
      },
    });
  };

  return (
    <>
      <Box position="relative" onClick={handleOpen}
        sx={{
          width: { xs: '90%', sm: '50%', md: '100%' },  // Adjusts image frame width based on screen size
          margin: '0 auto',
        }}
      >        
        {list.length > 0 ? (
          <>
            <Box
              position="absolute"
              borderRadius="40%"
              fontSize="16px"
              right={20}
              top={20}
              px="6px"
              py="4px"
              p2={0.25}
              color="white"
              bgcolor="rgb(0, 0, 0, 0.5)"
            >
              {index + 1}/{list.length}
            </Box>
            <Box
              top="calc(50% - 25px)"
              width="100%"
              position="absolute"
              display="flex"
              justifyContent="space-between"
            >
              {list.length > 1 ? (
                <IconButton onClick={handlePrev}>
                  <ExpandCircleDownIcon
                    fontSize="large"
                    sx={{ color: "white", transform: "rotate(90deg)" }}
                  />
                </IconButton>
              ) : (
                <div />
              )}
              {list.length > 1 && (
                <IconButton onClick={handleNext}>
                  <ExpandCircleDownIcon
                    fontSize="large"
                    sx={{ color: "white", transform: "rotate(-90deg)" }}
                  />
                </IconButton>
              )}
            </Box>
          </>
        ) : (
          <>
            {loading && <LinearProgress />}
            <ImageDND onDrop={handleUpload} />
          </>
        )}
        <Box
          width="100%"
          height="0"
          pb="80%"
          sx={{
            backgroundImage: getBackgroundImage(list[index]?.url, "width", 600),
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
            backgroundPosition: "center",
            backgroundColor: "#f4f6f8",
          }}
        />
      </Box>
      {query.get("image") && list.find(v => v.id === query.get("image")) && (
        <ImageModal
          open
          closeOnSave={true}
          selectedId={query.get("image")}
          asset={{}}
          images={list}
          onClose={handleClose}
          onRemove={removeImage}
          onUpdate={updateImage}
          onCreate={handleBulkCreateImages}
        />
      )}
    </>
  );
}
