import isEmpty from "lodash/isEmpty";
import useAuth from "src/hooks/useAuth";
import { useCustomProjectFields } from "src/contexts/clients";
import { useCustomFields } from "src/contexts/customFields";

export default function useCaptureFilterFields() {
  const [display] = useCustomFields();
  const { user } = useAuth();
  const displayProjectFields = useCustomProjectFields();

  if (user.company.settings.project_specific_fields && displayProjectFields) {
    return displayProjectFields;
  } else {
    return isEmpty(user.captureTableSettings) ? display : user.captureTableSettings;
  }
}
