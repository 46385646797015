import React from "react";
import { Autocomplete } from "@material-ui/lab";
import { TextField } from "@material-ui/core";
import { Checkbox } from "@material-ui/core";

const MultiSelect = ({ label, options, value, onChange, disabled, size = "medium", ...props }) => {
  return (
    <Autocomplete
      {...props}
      label={label}
      disablePortal
      renderTags={() => null}
      value={value}
      id="areaFilterId"
      options={options}
      disableCloseOnSelect
      getOptionLabel={option => option}
      onChange={(event, value) => onChange(value)}
      renderOption={(option, { selected }) => (
        <>
          <Checkbox style={{ marginRight: 8 }} checked={selected} />
          {option}
        </>
      )}
      renderInput={inputProps => (
        <TextField {...inputProps} size={size} variant="outlined" margin="none" label={label} />
      )}
    />
  );
};

export default MultiSelect;
