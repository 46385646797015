import React from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";

import {useAssetsCollection} from "../../../../contexts/assetsCollection";
import Attachments from "../../../../views/capture/Attachments";
import AttachmentsLabel from "./Attachments";

export default function AttachmentsField({ onClose, entity }) {
  const { setCollection } = useAssetsCollection();
  const assetAttachments = entity.attachments;

  const handleAdd = data => {
    const list = data.map(v => v.data.assetAddAttachment.attachment);
    setCollection(collections =>
      collections.map(v =>
        v.id === entity.id
          ? {
              ...v,
              attachments: v.attachments.concat(list),
            }
          : v
      )
    );
    return Promise.resolve();
  };

  const handleRemove = id => {
    setCollection(collections =>
      collections.map(v =>
        v.id === entity.id
          ? {
              ...v,
              attachments: v.attachments.filter(attachment => attachment.id !== id),
            }
          : v
      )
    );
    return Promise.resolve();
  };

  return (
    <>
      <AttachmentsLabel entity={entity} />
      <Dialog open fullWidth maxWidth="md" onClose={onClose}>
        <DialogTitle>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Typography fontSize={22} color="textPrimary" component="span">
              Attachments
            </Typography>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Attachments
              asset={entity}
              loading={false}
              assetAttachments={assetAttachments}
              onAdd={handleAdd}
              onDelete={handleRemove}
            />
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
}
