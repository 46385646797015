import React from "react";
import camelCase from "lodash/camelCase";
import Typography from "@mui/material/Typography";

import {useCapture} from "../../contexts/capture";

export default function AssetItemLabel({ asset }) {
  const { order } = useCapture();
  const selectedField = order ? camelCase(order.split(".")[0]) : undefined;

  if (selectedField) {
    if (selectedField === "dimensions") {
      return (
        <Typography color="#0e5294" lineHeight={1} variant="body2">
          {asset[selectedField].length
            ? asset[selectedField]
                .map(field => [field.width, field.depth, field.height].join("x"))
                .join(", ")
            : "N/A"}
        </Typography>
      );
    }

    return (
      <Typography color="#0e5294" lineHeight={1} variant="body2">
        {asset[selectedField] || "N/A"}
      </Typography>
    );
  }

  return null;
}
