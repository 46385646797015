import React, {createContext, useEffect, useState} from "react";
import SplashScreen from "src/components/SplashScreen";
import {FETCH_AUTH_USER_QUERY, SIGN_IN_USER_MUTATION} from "src/constants/graphql";
import {useQuery} from "@apollo/react-hooks";
import useMutation from "src/hooks/useMutation";
import {isEmpty} from "lodash";

const initialState = {
  isAuthenticated: false,
  user: null,
  loading: true,
};

export const setSession = accessToken => {
  if (accessToken) {
    localStorage.setItem("accessToken", accessToken);
  } else {
    localStorage.removeItem("accessToken");
  }
};

const AuthContext = createContext({
  ...initialState,
  login: () => Promise.resolve(),
  logout: () => {},
  register: () => Promise.resolve(),
});

export const AuthProvider = ({ children }) => {
  const [signInUser] = useMutation(SIGN_IN_USER_MUTATION);
  const [state, setState] = useState(initialState);
  const accessToken = localStorage.getItem("accessToken");

  const setUser = user => {
    if (!user) return;

    setSession(user.token);
    setState({ user: user, isAuthenticated: true });
  };

  const login = (email, password, { onSuccess, onFailure }) => {
    return signInUser({
      variables: { email, password },
      onSuccess: ({ user }) => {
        const subdomain = window.location.host.split(".")[0];
        if (subdomain === user.company.subdomain) {
          return setUser(user) && onSuccess(user);
        } else {
          const host = [user.company.subdomain, ...window.location.host.split(".").slice(1)].join(
            "."
          );
          const protocol = window.location.protocol;
          window.location.href = `${protocol}//${host}/auth?token=${user.token}`;
        }
      },
      onFailure,
    });
  };

  const logout = () => {
    setSession(null);
    setState({ isAuthenticated: false, user: null, loading: false });
  };

  const { loading, data: { user } = {} } = useQuery(FETCH_AUTH_USER_QUERY);

  useEffect(() => {
    if (loading) return;

    if (user && user.token) {
      setUser(user);
    } else {
      setState({ isAuthenticated: false, user: null });
    }
  }, [user, loading]);

  if (loading || (!isEmpty(accessToken) && user && !state.isAuthenticated)) {
    return <SplashScreen />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        company: state?.user?.company,
        login,
        logout,
        setUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
