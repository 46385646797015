import React from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import FormHelperText from "@mui/material/FormHelperText";

import useAuth from "../../../hooks/useAuth";
import Checkbox from "../../../components/form/Checkbox";
import Input from "../../form/Input";
import FullscreenModal from "../../modals/fullscreen-modal";
import { styles } from "./ProjectModal";

export function onKeyDown(event) {
  if (window.location.pathname.includes("/assets") && event.code === "Tab") {
    const inputs = [...document.querySelectorAll("input, select, button")];
    const index = inputs.findIndex(v => v === document.activeElement);
    inputs[index + 1].focus();
  }
}

export default function ClientModal({ initialValues, onSubmit, onClose }) {
  const { user } = useAuth();
  const { settings } = user.company;

  const validationSchema = Yup.object().shape(
    settings.requires_address
      ? {
          clientName: Yup.string().required(`${settings.filter_field1} required`),
          projectName: Yup.string().required(`${settings.filter_field2} required`),
          street: Yup.string().required("Street is required"),
          city: Yup.string().required("City is required"),
          zipCode: Yup.string().required("Zip Code is required"),
        }
      : {
          clientName: Yup.string().required(`${settings.filter_field1} required`),
          projectName: Yup.string().required(`${settings.filter_field2} required`),
        }
  );

  const formikOnSubmit = (values, { setErrors, setStatus, setSubmitting }) => {
    setSubmitting(true);
    const { street, street2, city, zipCode } = values;
    const submitValues = {
      ...values,
      address: { street, street2, city, zipCode },
    };
    onSubmit({
      variables: submitValues,
      onSuccess: () => {
        setSubmitting(false);
        setStatus({ success: true });
        onClose();
      },
      onFailure: errors => {
        setStatus({ success: false });
        setErrors(errors);
        setSubmitting(false);
      },
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={formikOnSubmit}
    >
      {({ errors, handleBlur, isSubmitting, handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <FullscreenModal
            open
            id="client"
            title={`New ${settings.filter_field1}/${settings.filter_field2}`}
            maxWidth={700}
            onClose={onClose}
            disableFullScreen
            actions={[
              <Button
                id="createClientAndProjectSubmit"
                key="create"
                variant="contained"
                color="primary"
                disabled={isSubmitting}
                type="submit"
                onClick={handleSubmit}
              >
                Create {settings.filter_field1} and {settings.filter_field2}
              </Button>,
            ]}
          >
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Input
                  autoFocus
                  name="clientName"
                  label={settings.filter_field1}
                  inputProps={{ onKeyDown }}
                />
              </Grid>
              <Grid item xs={12}>
                <Input
                  name="projectName"
                  label={settings.filter_field2}
                  inputProps={{ onKeyDown }}
                />
              </Grid>
              {settings.receiving_wizard && (
                <Grid item xs={12}>
                  <Box sx={styles.checkbox}>
                    <Checkbox name="storageEnabled" label={`Storage ${settings.filter_field2}`} />
                  </Box>
                </Grid>
              )}
              {settings.requires_address && (
                <>
                  <Grid item xs={12}>
                    <Input name="street" label="Street" inputProps={{ onKeyDown }} />
                  </Grid>
                  <Grid item xs={12}>
                    <Input name="street2" label="Second Street" inputProps={{ onKeyDown }} />
                  </Grid>
                  <Grid item xs={12}>
                    <Input name="city" label="City" inputProps={{ onKeyDown }} />
                  </Grid>
                  <Grid item xs={12}>
                    <Input name="zipCode" label="Zip" inputProps={{ onKeyDown }} />
                  </Grid>
                </>
              )}
            </Grid>
            {errors.submit && (
              <Box mt={2}>
                <FormHelperText error>{errors.submit}</FormHelperText>
              </Box>
            )}
          </FullscreenModal>
        </form>
      )}
    </Formik>
  );
}
