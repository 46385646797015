import React from "react";
import {makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,} from "@material-ui/core";
import Checkbox from "src/components/form/Checkbox";

const useStyles = makeStyles(() => ({
  checkBox: {
    marginRight: 0,
    justifyContent: "center",
  },
}));

const FEATURES_PERMISSIONS = {
  capture_inventory: "Capture inventory",
  capture_packing: "Capture packing",
  import_assets: "Import assets",
  export_assets: "Export assets",
  asset_tracking: "Asset tracking",
  inventory_tracking: "Inventory tracking",
  reporting: "Reporting",
};

const WRITE_PERMISSIONS = {
  write: "Write",
  remove: "Remove",
};

const CRUD_PERMISSIONS = {
  read: "Read",
  write: "Write",
  remove: "Remove",
};

const CLIENTS_PERMISSIONS = {
  read: "Read All",
  write: "Write",
  remove: "Remove",
};

const PAGES_PERMISSIONS = {
  dashboard: "Dashboard",
  shopping: "Shopping Catalog",
  shopping_cart: "Shopping Cart",
};

export const ROLES = {
  client: "Client",
  manager: "Manager",
  inventory: "Inventory",
  employee: "Employee",
  shopper: "Shopper",
  guest: "Guest",
};

export const TABS = [
  {
    id: "pages",
    label: "Pages",
    features: PAGES_PERMISSIONS,
  },
  {
    id: "features",
    label: "Features",
    features: FEATURES_PERMISSIONS,
  },
  {
    id: "assets",
    label: "Assets",
    features: CRUD_PERMISSIONS,
  },
  {
    id: "clients",
    label: "Clients",
    features: CLIENTS_PERMISSIONS,
  },
  {
    id: "users",
    label: "Users",
    features: WRITE_PERMISSIONS,
  },
  {
    id: "projects",
    label: "Projects",
    features: WRITE_PERMISSIONS,
  },
];

const TabForm = ({ name, tabKey, roles, features }) => {
  const classes = useStyles();
  const featureKeys = Object.keys(features);

  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            {featureKeys.map((key) => (
              <TableCell align="center" key={`assets-${key}`}>
                {features[key]}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {roles.map((roleKey) => (
            <TableRow key={roleKey}>
              {featureKeys.map((featureKey) => (
                <TableCell align="center" key={featureKey}>
                  <Checkbox
                    className={classes.checkBox}
                    name={
                      name
                        ? `${name}[${roleKey}][${tabKey}][${featureKey}]`
                        : `${roleKey}[${tabKey}][${featureKey}]`
                    }
                    label={null}
                  />
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TabForm;
