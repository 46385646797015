import React, {useState} from "react";
import {makeStyles} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import Close from "@material-ui/icons/Close";
import InnerImageZoom from "react-inner-image-zoom";
import "react-inner-image-zoom/lib/InnerImageZoom/styles.css";

import {getImageDegree, rotateImage} from "../../../utils/images";

const useStyles = makeStyles(theme => ({
  content: {
    padding: 0,
  },
}));

export default function ImageModal({ images, asset, selectedId: defaultId, onClose }) {
  const classes = useStyles();
  const [selectedId, setSelectedId] = useState(defaultId);

  const currentImage = images.find(image => selectedId === image.id) || images[0];

  const currentImageIndex = images.indexOf(currentImage);
  const InitialRotation = getImageDegree(currentImage?.url);
  const [rotate] = useState(InitialRotation);

  const prevImage = images[currentImageIndex - 1];
  const nextImage = images[currentImageIndex + 1];

  const imgUrl = rotateImage(currentImage?.url, "width", 1024, rotate);

  return (
    <Dialog fullWidth maxWidth="lg" open={true} onClose={onClose}>
      <Grid container justify="space-between" wrap="nowrap">
        <Grid />
        <Grid item style={{ padding: "8px" }}>
          {onClose && (
            <IconButton onClick={onClose}>
              <Close titleAccess="Close" />
            </IconButton>
          )}
        </Grid>
      </Grid>
      <DialogContent classes={{ root: classes.content }}>
        <Grid container>
          <Grid item xs={12}>
            {asset.description && (
              <Box mb={2}>
                <Typography variant="body1" align="center">
                  {asset.description}
                </Typography>
              </Box>
            )}
            {(asset.barcode || asset.barcodes) && (
              <Box mb={2}>
                <Typography variant="body1" align="center" color="textSecondary">
                  {asset.barcode} {asset.barcodes && asset.barcodes.map(v => v.code).join(" ")}
                </Typography>
              </Box>
            )}
            <Box display="flex">
              <Box clone display="flex" flexGrow={1} maxWidth={40}>
                <Button onClick={() => setSelectedId(prevImage?.id)}>
                  <NavigateBeforeIcon />
                </Button>
              </Box>
              {currentImage && (
                <Box
                  display="flex"
                  flexGrow={1}
                  justifyContent="center"
                  mr={0.5}
                  borderRadius={4}
                  mb={3}
                >
                  <InnerImageZoom src={imgUrl} zoomSrc={imgUrl} zoomScale={2} />
                </Box>
              )}
              <Box clone display="flex" flexGrow={1} maxWidth={40}>
                <Button onClick={() => setSelectedId(nextImage?.id)}>
                  <NavigateNextIcon />
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
