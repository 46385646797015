import React, { useCallback } from "react";
import { useField } from "formik";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import Input from "../Input";
import { useStyles } from 'src/components/Page';
import FormControl from "@material-ui/core/FormControl";

const ArrayField = ({ label, disabled, ...props }) => {
  const [field, , helpers] = useField(props);
  const fields = field.value || [];
  const fieldName = field.name;
  const classes = useStyles();

  const handleAddNew = useCallback(() => {
    helpers.setValue(fields.concat(""));
  }, [helpers, fields]);

  const handleRemove = useCallback(
    i => helpers.setValue(fields.filter((v, index) => i !== index)),
    [helpers, fields]
  );

  return (
    <Grid container spacing={1}>
      {fields.map((v, index) => (
        <Grid item xs={12} key={index}>
          <Box display="flex" alignItems="center">
            <FormControl fullWidth className={classes.margin}>
              <Input disabled={disabled} name={`${fieldName}.${index}`} label={label} />
            </FormControl>
            {fields.length > 1 && (
              <Box p={1} mr={-1} clone>
                <IconButton
                  aria-label="delete"
                  variant="outlined"
                  onClick={() => handleRemove(index)}
                >
                  <DeleteIcon color="action" />
                </IconButton>
              </Box>
            )}
          </Box>
        </Grid>
      ))}
      <Grid item xs={12}>
        <Button id={`addNew-${fieldName.split('.').join('-')}`} variant="outlined" onClick={handleAddNew}>
          Add New <AddIcon color="action" fontSize="small" />
        </Button>
      </Grid>
    </Grid>
  );
};

export default React.memo(ArrayField);
