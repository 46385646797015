import React, {useEffect} from "react";
import {useFormikContext} from "formik";
import Paper from "@material-ui/core/Paper";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Select from "../../form/Select";
import CurrencyInput from "./CurrencyInput";
import { billingDateOptions } from "./utils/billingDateOptions";

const billingTypes = ["Hundredweight", "By the Pallet", "Square Foot", "Cubic Foot"].map(v => [
  v,
  v,
]);

const billingMethods = ["Monthly Billing", "Anniversary Billing"].map(v => [v, v]);

export default function BillingSettings() {
  const { values, setFieldValue } = useFormikContext();

  useEffect(() => {
    if (values.billingMethod === "Monthly Billing" && values.billingDate !== "1") {
      setFieldValue("billingDate", "1"); // Default to 1st of the Month
    } else if (values.billingMethod === "Anniversary Billing" && values.billingDate === "1") {
      setFieldValue("billingDate", ""); // Reset when switching back to Anniversary Billing
    }
  }, [values.billingMethod, setFieldValue, values.billingDate]);

  return (
    <Paper>
      <Box p={2}>
        <Typography fontWeight={500}>Billing Settings</Typography>
        <Grid container spacing={1} sx={{ pt: 1.5 }}>
          <Grid item xs={12} sm={4}>
            <Select size="small" name="billingType" label="Billing Type" options={billingTypes} />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Select
              size="small"
              name="billingMethod"
              label="Billing Method"
              options={billingMethods}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Select
              size="small"
              name="billingDate"
              label="Billing Cycle Start Day"
              options={billingDateOptions}
              disabled={values.billingMethod === "Monthly Billing"} // Disable for Monthly Billing
            />
          </Grid>

          {values.billingType === "Hundredweight" && (
            <>
              <Grid item xs={12} sm={4}>
                <CurrencyInput name="cwtInRate" label="Warehouse IN Rate" />
              </Grid>
              <Grid item xs={12} sm={4}>
                <CurrencyInput name="cwtStorageRate" label="Storage Rate" />
              </Grid>
              <Grid item xs={12} sm={4}>
                <CurrencyInput name="cwtOutRate" label="Warehouse OUT Rate" />
              </Grid>
              <Grid item xs={12} sm={4}>
                <CurrencyInput name="minimumCharge" label="Minimum Charge" />
              </Grid>
            </>
          )}

          {values.billingType === "By the Pallet" && (
            <>
              <Grid item xs={12} sm={4}>
                <CurrencyInput name="normalPalletRate" label="Standard Pallet Rate" />
              </Grid>
              <Grid item xs={12} sm={4}>
                <CurrencyInput name="oversizePalletRate" label="Oversized Pallet Rate" />
              </Grid>
              <Grid item xs={12} sm={4}>
                <CurrencyInput name="minimumCharge" label="Minimum Charge" />
              </Grid>
            </>
          )}

          {values.billingType === "Square Foot" && (
            <>
              <Grid item xs={12} sm={4}>
                <CurrencyInput name="squareFootRate" label="Square Foot Rate" />
              </Grid>
              <Grid item xs={12} sm={4}>
                <CurrencyInput name="minimumCharge" label="Minimum Charge" />
              </Grid>
            </>
          )}

          {values.billingType === "Cubic Foot" && (
            <>
              <Grid item xs={12} sm={4}>
                <CurrencyInput name="cubicFootRate" label="Cubic Foot Rate" />
              </Grid>
              <Grid item xs={12} sm={4}>
                <CurrencyInput name="minimumCharge" label="Minimum Charge" />
              </Grid>
            </>
          )}
        </Grid>
      </Box>
    </Paper>
  );
}
