import React from "react";
import Popper from "@mui/material/Popper";

export default function PopperComponent(props) {
  const modifiers = [
    {
      name: "flip",
      options: {
        fallbackPlacements: [],
      },
    },
  ];

  return (
    <Popper
      {...props}
      style={{
        ...props.style,
        width: props.style.width > 320 ? props.style.width : 320,
      }}
      modifiers={modifiers}
      popperOptions={{
        placement: "bottom-start",
      }}
    />
  );
}
