import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "react-perfect-scrollbar/dist/css/styles.css";
import "react-quill/dist/quill.snow.css";
import "prismjs/prism";
import "prismjs/components/prism-bash";
import "prismjs/components/prism-javascript";
import "prismjs/components/prism-jsx";
import "nprogress/nprogress.css";
import React from "react";
import { enableES5 } from "immer";
import App from "./App";
import { SettingsProvider } from "src/contexts/SettingsContext";

enableES5();

const Init = () => {
  return (
    <SettingsProvider>
      <App />
    </SettingsProvider>
  );
};

export default Init;
