import React from "react";

import useAuth from "../../hooks/useAuth";
import AssetAuditLogs from "./AssetAuditLogs";
import AssetReplayLogs from "./AssetReplayLogs";

export default function ActivityLog({ type = "asset", assetId, defaultTab }) {
  const { user } = useAuth();

  // Check if the type is 'kit'. If true, always render AssetAuditLogs for kits
  if (type === 'kit') {
    return <AssetAuditLogs type={type} assetId={assetId} defaultTab={defaultTab} />;
  }

  return user.company.settings.asset_replay_logs ? (
    <AssetReplayLogs type={type} assetId={assetId} defaultTab={defaultTab} />
  ) : (
    <AssetAuditLogs type={type} assetId={assetId} defaultTab={defaultTab} />
  );
}
