import React from "react";
import sortBy from "lodash/sortBy";
import * as Yup from "yup";
import { Formik } from "formik";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import FormHelperText from "@mui/material/FormHelperText";

import Input from "../../form/Input";
import Select from "../../form/Select";
import useAuth from "../../../hooks/useAuth";
import FullscreenModal from "../../modals/fullscreen-modal";
import Checkbox from "../../../components/form/Checkbox";
import { onKeyDown } from "./ClientModal";

export const styles = {
  checkbox: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    height: 36.5,
    borderRadius: "4px",
    border: "1px solid rgba(0, 0, 0, 0.23)",
    "& label": {
      display: "flex",
      flexDirection: "row-reverse",
      justifyContent: "center",
      mx: 0,
      height: 36.5,
      "& > span > p": {
        color: "text.primary",
      },
    },
  },
};

export default function ProjectModal({ initialValues, clients, onSubmit, onClose }) {
  const { user } = useAuth();
  const { settings } = user.company;

  const validationSchema = Yup.object().shape(
    settings.requires_address
      ? {
          clientId: Yup.string().required(`${settings.filter_field1} required`),
          projectName: Yup.string().required(`${settings.filter_field2} required`),
          street: Yup.string().required("Street is required"),
          city: Yup.string().required("City is required"),
          zipCode: Yup.string().required("Zip Code is required"),
        }
      : {
          clientId: Yup.string().required(`${settings.filter_field1} required`),
          projectName: Yup.string().required(`${settings.filter_field2} required`),
        }
  );

  const formikOnSubmit = (values, { setErrors, setStatus, setSubmitting }) => {
    setSubmitting(true);
    const { street, street2, city, zipCode } = values;
    const submitValues = {
      ...values,
      address: { street, street2, city, zipCode },
    };
    onSubmit({
      variables: submitValues,
      onSuccess: () => {
        setSubmitting(false);
        setStatus({ success: true });
        onClose();
      },
      onFailure: errors => {
        setStatus({ success: false });
        setErrors(errors);
        setSubmitting(false);
      },
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={formikOnSubmit}
    >
      {({ errors, handleBlur, isSubmitting, handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <FullscreenModal
            open
            id="project"
            title={`New ${settings.filter_field2}`}
            maxWidth={700}
            onClose={onClose}
            disableFullScreen
            actions={[
              <Button
                key="create"
                variant="contained"
                color="primary"
                disabled={isSubmitting}
                type="submit"
                onClick={handleSubmit}
              >
                Create New {settings.filter_field2}
              </Button>,
            ]}
          >
            <Grid container spacing={1} sx={{ width: { md: 700 } }}>
              <Grid item xs={12}>
                <Select
                  autoFocus
                  size="small"
                  name="clientId"
                  SelectDisplayProps={{
                    onKeyDown() {
                      if (event.code === "Tab") {
                        const input = document.querySelector('[name="projectName"]');
                        input.focus();
                      }
                    },
                  }}
                  label={settings.filter_field1}
                  options={sortBy(clients, "name").map(v => [v.id, v.name])}
                />
              </Grid>
              <Grid item xs={12}>
                <Input
                  name="projectName"
                  label={settings.filter_field2}
                  autoComplete="off"
                  inputProps={{ onKeyDown }}
                />
              </Grid>
              {settings.receiving_wizard && (
                <Grid item xs={12}>
                  <Box sx={styles.checkbox}>
                    <Checkbox name="storageEnabled" label={`Storage ${settings.filter_field2}`} />
                  </Box>
                </Grid>
              )}
              {settings.requires_address && (
                <>
                  <Grid item xs={12}>
                    <Input name="street" label="Street" inputProps={{ onKeyDown }} />
                  </Grid>
                  <Grid item xs={12}>
                    <Input name="street2" label="Second Street" inputProps={{ onKeyDown }} />
                  </Grid>
                  <Grid item xs={12}>
                    <Input name="city" label="City" inputProps={{ onKeyDown }} />
                  </Grid>
                  <Grid item xs={12}>
                    <Input name="zipCode" label="Zip" inputProps={{ onKeyDown }} />
                  </Grid>
                </>
              )}
            </Grid>
            {errors.submit && (
              <Box mt={2}>
                <FormHelperText error>{errors.submit}</FormHelperText>
              </Box>
            )}
          </FullscreenModal>
        </form>
      )}
    </Formik>
  );
}
