import React, {useState} from "react";
import PropTypes from "prop-types";
import {Link as RouterLink} from "react-router-dom";
import orderBy from "lodash/orderBy";
import {colors, makeStyles} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import {pricer} from "../../../utils/money";
import {useShopping} from "../../../contexts/shopping";
import {usePermissions} from "../../../contexts/permissions";
import Label from "../../../components/Label";
import {useCustomFields} from "../../../contexts/customFields";
import useQuery from "../../../hooks/useQuery";
import ActivityLog from "../../asset/ActivityLog";
import AssetInfo from "../../../views/shopping/ProjectAssets/AssetInfo/AssetInfo";
import {getImage} from "../../../utils/images";
import AddToInventoryRequest from "./AddToInventoryRequest";
import AddToMyOrders from "./AddToMyOrders";
import AddToCart from "./AddToCart";
import AddToKit from "./AddToKit";
import AssetCardTitle from "./AssetCardTitle";
import useAuth from "../../../hooks/useAuth";

const useStyles = makeStyles(theme => ({
  image: {
    height: 200,
    backgroundSize: "contain",
    backgroundColor: theme.palette.background.dark,
  },
  likedButton: {
    color: colors.red[600],
  },
  membersIcon: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(1),
  },
  assetIdLabel: {
    position: "absolute",
    top: "5px",
    left: "5px",
  },
  pinkCard: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
    background: "#fce4ec",
  },
  card: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
  },
}));

const sliderSettings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const AssetCard = ({ asset, defaultValues = {}, onClickImage }) => {
  const { user } = useAuth();
  const [openActivityLog, setOpenActivityLog] = useState(false);
  const { can } = usePermissions();
  const [display] = useCustomFields();
  const classes = useStyles({ poNumber: asset.poNumber });
  const query = useQuery();
  const handleClickImage = image => onClickImage(asset, image);
  const { inventoryItems } = useShopping();
  const inventoryItem = inventoryItems[asset.id];
  const images = orderBy(asset.images, ["default", "id"]).reverse();
  const isShoppingOrder = location.pathname.includes("shopping/orders");
  const isKitting = location.pathname.includes("kitting");
  const { settings } = user.company;

  const toggleActivityLog = () => setOpenActivityLog(v => !v);

  return (
    <Card
      classes={{
        root:
          display.sc_po_number && asset.poNumber && asset.availableQuantity === 0
            ? classes.pinkCard
            : classes.card,
      }}
    >
      <Box px={3} pt={3} pb={1}>
        {images.length >= 1 ? (
          <Box position="relative">
            <Slider {...sliderSettings}>
              {images.map((image, index) => (
                <CardMedia
                  onClick={() => handleClickImage(image)}
                  key={`cardImg-${index}`}
                  className={classes.image}
                  image={image.url ? getImage(image.url, "width", 200) : "stub"}
                />
              ))}
            </Slider>
            {display.sc_asset_id_overlay && (
              <div className={classes.assetIdLabel}>
                <Label color="contrast">{asset.assetId}</Label>
              </div>
            )}
            {display.sc_item_id_overlay && (
              <div className={classes.assetIdLabel}>
                <Label color="contrast">{asset.itemId}</Label>
              </div>
            )}
          </Box>
        ) : (
          <CardMedia className={classes.image} image={"stub"} />
        )}
        <AssetCardTitle asset={asset} />
      </Box>
      {display.sc_storage_location && (
        <Box pt={2} display="flex" flexDirection="column" alignItems="center">
          <Typography variant="body2" color="textSecondary">
            {settings.filter_field2}
          </Typography>
          <Link
            variant="h5"
            color="textSecondary"
            to={`/shopping/${asset.project.id}`}
            component={RouterLink}
          >
            {asset.project.name}
          </Link>
        </Box>
      )}
      {display.sc_available_qty && (
        <Box pt={1} display="flex" justifyContent="center" alignItems="center">
          <Box mr={0.5}>
            <Typography id={`availableQuantity-${asset.id}`} variant="h5" color="textPrimary">
              {asset.availableQuantity}
            </Typography>
          </Box>
          <Typography variant="h5" color="textPrimary">
            Available
          </Typography>
          <Box ml={-1} mb={0.25}>
            <AssetInfo asset={asset} />
          </Box>
          {openActivityLog && (
            <Dialog
              open
              fullWidth
              maxWidth="lg"
              onClose={toggleActivityLog}
              aria-labelledby="dialog-title"
            >
              <DialogContent>
                <DialogContentText>
                  <ActivityLog assetId={asset.id} defaultTab="reserved_inventory" />
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Box display="flex" p={1.5}>
                  <Button variant="outlined" onClick={toggleActivityLog} color="primary">
                    Close
                  </Button>
                </Box>
              </DialogActions>
            </Dialog>
          )}
        </Box>
      )}
      {settings.reservations && (
        <Box pt={1} display="flex" justifyContent="center">
          <Box mr={0.5}>
            <Typography variant="h5" color="textSecondary">
              {asset.reservedQuantity}
            </Typography>
          </Box>
          <Typography variant="h5" color="textSecondary">
            Reserved
          </Typography>
        </Box>
      )}
      <Box pb={2} px={3} mt={1}>
        <Grid container justify="space-between" spacing={3}>
          {can("pages.shopping_cart") && display.sc_price && (
            <Grid item xs={4} container justify="flex-end">
              <Box>
                <Typography variant="body2" color="textSecondary">
                  Price
                </Typography>
                <Typography id={`totalPrice-${asset.id}`} variant="h5" color="textPrimary">
                  {pricer(asset.price)}
                </Typography>
              </Box>
            </Grid>
          )}
        </Grid>
      </Box>

      {can("pages.shopping_cart") && (
        <>
          <Divider />
          <Box display="flex" justifyContent="space-between" py={2} px={3}>
            {isKitting ? (
              <AddToKit size="small" asset={asset} defaultValues={defaultValues} />
            ) : isShoppingOrder ? (
              <AddToMyOrders size="small" asset={asset} defaultValues={defaultValues} />
            ) : query.get("inventoryRequestId") ? (
              <AddToInventoryRequest size="small" asset={asset} defaultValues={defaultValues} />
            ) : (
              <AddToCart size="small" asset={asset} defaultValues={defaultValues} />
            )}
            {inventoryItem?.quantity && display.sc_price && (
              <Box>
                <Typography variant="body2" color="textSecondary">
                  Total
                </Typography>
                <Typography id={`requestedPrice-${asset.id}`} variant="h4" color="textPrimary">
                  {pricer(inventoryItem.quantity * asset.price)}
                </Typography>
              </Box>
            )}
          </Box>
        </>
      )}
    </Card>
  );
};

AssetCard.propTypes = {
  className: PropTypes.string,
  asset: PropTypes.object.isRequired,
};

export default AssetCard;
