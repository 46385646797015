import { gql } from "apollo-boost";

import * as fragment from "./fragments";

export const EXPORT_DONATIONS_REPORT_MUTATION = gql`
  mutation ExportDonationsReport($projectId: ID!) {
    exportDonationsReport(projectId: $projectId) {
      success
    }
  }
`;

export const EXPORT_EXCEL_DATA_MUTATION = gql`
  mutation ExportExcelData($projectId: ID!) {
    exportExcelData(projectId: $projectId) {
      success
    }
  }
`;

export const DOWNLOAD_ASSETS_IMAGES_MUTATION = gql`
  mutation DownloadAssetsImages($projectId: ID!) {
    downloadAssetsImages(projectId: $projectId) {
      success
    }
  }
`;

export const CREATE_BARCODE_SCAN_MUTATION = gql`
  mutation CreateBarcode(
    $projectId: ID!,
    $clientId: ID!,
    $barcode: String!,
    $quantity: String!,
    $location: String!,
    $address: String!,
    $position: String!,
    $employeeName: String!
  ) {
    createBarcodeScan(
      projectId: $projectId,
      clientId: $clientId,
      barcode: $barcode,
      quantity: $quantity,
      location: $location,
      address: $address,
      position: $position,
      employeeName: $employeeName
    ) {
      barcodeScan {
        ${fragment.BARCODE_SCAN_FRAGMENT}
      }
    }
  }
`;
export const CREATE_IMAGE_MUTATION = gql`
  mutation CreateImage($assetId: ID!, $url: String!) {
    createImage(assetId: $assetId, url: $url) {
      asset {
        ${fragment.ASSET_FRAGMENT}
      }
      image {
        ${fragment.ASSET_IMAGE_FRAGMENT}
      }
    }
  }
`;

export const GENERATE_PROJECT_REPORT_MUTATION = gql`
  mutation GenerateProjectReport($projectId: ID!, $favoriteReportId: ID!, $mode: String, $filters: JSON!, $receivingNotes: String) {
    generateProjectReport(projectId: $projectId, mode: $mode, favoriteReportId: $favoriteReportId, filters: $filters, receivingNotes: $receivingNotes) {
      project {
        ${fragment.PROJECT_FRAGMENT}
      }
    }
  }
`;

export const GENERATE_PROJECT_REPORT_BY_CLIENT_MUTATION = gql`
  mutation GenerateProjectReportByClient($clientId: ID!, $favoriteReportId: ID!, $mode: String, $filters: JSON!, $receivingNotes: String) {
    generateProjectReportByClient(clientId: $clientId, mode: $mode, favoriteReportId: $favoriteReportId, filters: $filters, receivingNotes: $receivingNotes) {
      client {
        ${fragment.CLIENT_FRAGMENT}
      }
    }
  }
`;

export const CREATE_FAVORITE_REPORT_MUTATION = gql`
  mutation CreateFavoriteReport($payload: JSON!) {
    createFavoriteReport(payload: $payload) {
      favoriteReports {
        ${fragment.FAVORITE_REPORTS_FRAGMENT}
      }
    }
  }
`;
export const UPDATE_FAVORITE_REPORT_MUTATION = gql`
  mutation UpdateFavoriteReport($id: ID!, $payload: JSON!) {
    updateFavoriteReport(id: $id, payload: $payload) {
      favoriteReport {
        ${fragment.FAVORITE_REPORTS_FRAGMENT}
      }
    }
  }
`;

export const ASSET_MUTATION = gql`
  mutation SaveAndCreateNewAsset($input: AssetInput!) {
    saveAndCreateNewAsset(input: $input) {
      asset {
        ${fragment.ASSET_FRAGMENT}
        ${fragment.ASSET_MEDICAL_FRAGMENT}
      }
    }
  }
`;

// ######################################## NEW MUTATIONS #################################################

export const CREATE_PROJECT_MUTATION = gql`
  mutation CreateProject(
    $clientId: ID,
    $clientName: String,
    $projectName: String!,
    $address: AddressInput!,
    $storageEnabled: Boolean,
  ) {
    createProject(
      clientId: $clientId,
      clientName: $clientName,
      projectName: $projectName,
      address: $address,
      storageEnabled: $storageEnabled,
    ) {
      project {
        ${fragment.PROJECT_FRAGMENT}
      }
      client {
        ${fragment.CLIENT_FRAGMENT}
      }
      errors {
        ${fragment.ERROR_FRAGMENT}
      }
    }
  }
`;

export const UPDATE_PROJECT_MUTATION = gql`
  mutation UpdateProject(
    $id: ID!,
    $name: String,
    $disabled: Boolean,
    $address: AddressInput,
    $storageEnabled: Boolean,
    $customerName: String,
    $customerOwner: String,
    $customerNotes: String,
    $contactName: String,
    $contactEmail: String,
    $contactPhoneNumber: String,
    $customerEmailSendList: String,
    $osDEmailSendList: String,
    $billingEmailSendList: String,
    $billingType: String,
    $billingMethod: String,
    $billingDate: String,
    $cwtInRate: Float,
    $cwtStorageRate: Float,
    $cwtOutRate: Float,
    $normalPalletRate: Float,
    $oversizePalletRate: Float,
    $minimumCharge: Float,
    $squareFootRate: Float,
    $cubicFootRate: Float,
    $settings: JSON
  ) {
    updateProject(
      id: $id,
      name: $name,
      disabled: $disabled,
      address: $address,
      storageEnabled: $storageEnabled,
      customerName: $customerName,
      customerOwner: $customerOwner,
      customerNotes: $customerNotes,
      contactName: $contactName,
      contactEmail: $contactEmail,
      contactPhoneNumber: $contactPhoneNumber,
      customerEmailSendList: $customerEmailSendList,
      osDEmailSendList: $osDEmailSendList,
      billingEmailSendList: $billingEmailSendList,
      billingType: $billingType,
      billingMethod: $billingMethod,
      billingDate: $billingDate,
      cwtInRate: $cwtInRate,
      cwtStorageRate: $cwtStorageRate,
      cwtOutRate: $cwtOutRate,
      normalPalletRate: $normalPalletRate,
      oversizePalletRate: $oversizePalletRate,
      minimumCharge: $minimumCharge,
      squareFootRate: $squareFootRate,
      cubicFootRate: $cubicFootRate,
      settings: $settings
    ) {
      project {
        ${fragment.PROJECT_FRAGMENT}
      }
      errors {
        ${fragment.ERROR_FRAGMENT}
      }
    }
  }
`;

export const UPDATE_CLIENT_MUTATION = gql`
  mutation UpdateClient(
    $id: ID!,
    $name: String!,
    $disabled: Boolean!,
    $clientEmailSendList: String,
    $clientBillingEmailSendList: String,
    $clientOsdEmailSendList: String,
  ) {
    updateClient(
      id: $id,
      name: $name,
      disabled: $disabled,
      clientEmailSendList: $clientEmailSendList,
      clientBillingEmailSendList: $clientBillingEmailSendList,
      clientOsdEmailSendList: $clientOsdEmailSendList,
    ) {
      client {
        ${fragment.CLIENT_FRAGMENT}
      }
      errors {
        ${fragment.ERROR_FRAGMENT}
      }
    }
  }
`;

export const CREATE_BARCODE_MUTATION = gql`
  mutation CreateBarcode($assetId: ID!, $code: String!) {
    createBarcode(assetId: $assetId, code: $code) {
      asset {
        ${fragment.ASSET_FRAGMENT}
      }
      errors {
        ${fragment.ERROR_FRAGMENT}
      }
    }
  }
`;

export const UPDATE_BARCODE_MUTATION = gql`
  mutation UpdateBarcode($barcodeId: ID!, $code: String!) {
    updateBarcode(barcodeId: $barcodeId, code: $code) {
      asset {
        ${fragment.ASSET_FRAGMENT}
      }
      errors {
        ${fragment.ERROR_FRAGMENT}
      }
    }
  }
`;

export const DELETE_BARCODE_MUTATION = gql`
  mutation DeleteBarcode($id: ID!) {
    deleteBarcode(id: $id) {
      asset {
        ${fragment.ASSET_FRAGMENT}
      }
      errors {
        ${fragment.ERROR_FRAGMENT}
      }
    }
  }
`;

export const REMOVE_ASSET_MUTATION = gql`
  mutation AssetRemove($id: ID!) {
    assetRemove(id: $id) {
      asset {
        ${fragment.ASSET_FRAGMENT}
        ${fragment.ASSET_MEDICAL_FRAGMENT}
      }
    }
  }
`;

export const REMOVE_KIT_MUTATION = gql`
  mutation DeleteKit($id: ID!) {
    deleteKit(id: $id) {
      kit {
        ${fragment.KIT_FRAGMENT}
      }
    }
  }
`;
export const REMOVE_KIT_ASSET_MUTATION = gql`
  mutation DeleteKitAsset($id: ID!) {
    deleteKitAsset(id: $id) {
      kitAsset {
        id
      }
      errors {
        ${fragment.ERROR_FRAGMENT}
      }
    }
  }
`;

export const DELETE_IMAGE_MUTATION = gql`
  mutation RemoveAssetImage($id: ID!) {
    removeAssetImage(id: $id) {
      asset {
        id
        images {
          ${fragment.ASSET_IMAGE_FRAGMENT}
        }
        photo
      }
      image {
        ${fragment.ASSET_IMAGE_FRAGMENT}
      }
      errors {
        ${fragment.ERROR_FRAGMENT}
      }
    }
  }
`;

export const ADD_ASSET_IMAGE_MUTATION = gql`
  mutation AddAssetImage($assetId: ID!, $image: Upload!, $default: Boolean) {
    addAssetImage(assetId: $assetId, image: $image, default: $default) {
      asset {
        id
        images {
          ${fragment.ASSET_IMAGE_FRAGMENT}
        }
        photo
      }
      image {
        ${fragment.ASSET_IMAGE_FRAGMENT}
      }
      errors {
        ${fragment.ERROR_FRAGMENT}
      }
    }
  }
`;

export const BULK_CREATE_ASSET_IMAGES_MUTATION = gql`
  mutation BulkCreateAssetImages($assetId: ID!, $images: [Upload!]!) {
    bulkCreateAssetImages(assetId: $assetId, images: $images) {
      asset {
        id
        photo
        images {
          ${fragment.ASSET_IMAGE_FRAGMENT}
        }
      }
      errors {
        ${fragment.ERROR_FRAGMENT}
      }
    }
  }
`;

export const BULK_CREATE_WAREHOUSE_LOCATION_IMAGES_MUTATION = gql`
  mutation WarehouseLocationBulkCreateImages($warehouseLocationId: ID!, $warehouseLocationImages: [Upload!]!) {
    warehouseLocationBulkCreateImages(warehouseLocationId: $warehouseLocationId, warehouseLocationImages: $warehouseLocationImages) {
      warehouseLocation {
        id
        warehouseLocationImages {
          ${fragment.WAREHOUSE_LOCATION_IMAGE_FRAGMENT}
        }
      }
      errors {
        ${fragment.ERROR_FRAGMENT}
      }
    }
  }
`;

export const UPDATE_WAREHOUSE_LOCATION_IMAGE_MUTATION = gql`
  mutation WarehouseLocationUpdateImage($id: ID!, $url: String!, $default: Boolean) {
    warehouseLocationUpdateImage(id: $id, url: $url, default: $default) {
      warehouseLocation {
        id
        warehouseLocationImages {
          ${fragment.WAREHOUSE_LOCATION_IMAGE_FRAGMENT}
        }
      }
      warehouseLocationImage {
        ${fragment.WAREHOUSE_LOCATION_IMAGE_FRAGMENT}
      }
      errors {
        ${fragment.ERROR_FRAGMENT}
      }
    }
  }
`;

export const DELETE_WAREHOUSE_LOCATION_IMAGE_MUTATION = gql`
  mutation WarehouseLocationRemoveImage($id: ID!) {
    warehouseLocationRemoveImage(id: $id) {
      warehouseLocation {
        id
        warehouseLocationImages {
          ${fragment.WAREHOUSE_LOCATION_IMAGE_FRAGMENT}
        }
      }
      warehouseLocationImage {
        ${fragment.WAREHOUSE_LOCATION_IMAGE_FRAGMENT}
      }
      errors {
        ${fragment.ERROR_FRAGMENT}
      }
    }
  }
`;

export const UPDATE_IMAGE_MUTATION = gql`
  mutation AssetUpdateImage($id: ID!, $url: String!, $default: Boolean) {
    assetUpdateImage(id: $id, url: $url, default: $default) {
      asset {
        id
        images {
          ${fragment.ASSET_IMAGE_FRAGMENT}
        }
        photo
      }
      image {
        ${fragment.ASSET_IMAGE_FRAGMENT}
      }
      errors {
        ${fragment.ERROR_FRAGMENT}
      }
    }
  }
`;

export const CREATE_COMPANY_SUBSCRIPTION_MUTATION = gql`
  mutation CreateCompanySubscription($companyId: ID!, $companyPlanId: ID!) {
    createCompanySubscription(companyId: $companyId, companyPlanId: $companyPlanId) {
      companySubscription {
        id
        stripeCheckoutSessionId
      }
      errors {
        ${fragment.ERROR_FRAGMENT}
      }
    }
  }
`;

export const CANCEL_COMPANY_SUBSCRIPTION_MUTATION = gql`
  mutation CancelCompanySubscription($companyId: ID!) {
    cancelCompanySubscription(companyId: $companyId) {
      companySubscription {
        id
        stripeCheckoutSessionId
      }
      errors {
        ${fragment.ERROR_FRAGMENT}
      }
    }
  }
`;

export const SIGN_IN_USER_MUTATION = gql`
  mutation SignInUser($email: String!, $password: String!) {
    signInUser(email: $email, password: $password) {
      user {
        ${fragment.USER_FRAGMENT}
        companyUrl
      }
      errors {
        ${fragment.ERROR_FRAGMENT}
      }
    }
  }
`;

export const INVITE_USER_MUTATION = gql`
  mutation InviteUser($userId: ID, $email: String, $clientIds: [String!]!, $projectIds: [String!]!, $role: String) {
    inviteUser(userId: $userId, email: $email, clientIds: $clientIds, projectIds: $projectIds, role: $role) {
      user {
        ${fragment.USER_FRAGMENT}
      }
      errors {
        ${fragment.ERROR_FRAGMENT}
      }
    }
  }
`;

export const UPDATE_USER_PASSWORD_MUTATION = gql`
  mutation UpdateUserPassword($password: String!, $passwordConfirmation: String!, $oldPassword: String!) {
    updateUserPassword(password: $password, passwordConfirmation: $passwordConfirmation, oldPassword: $oldPassword) {
      user {
        ${fragment.USER_FRAGMENT}
      }
      errors {
        ${fragment.ERROR_FRAGMENT}
      }
    }
  }
`;

export const UPDATE_USER_PROFILE_MUTATION = gql`
  mutation UpdateUserProfile($email: String!, $name: String!, $phoneNumber: String!, $settings: JSON) {
    updateUserProfile(email: $email, name: $name, phoneNumber: $phoneNumber, settings: $settings) {
      user {
        ${fragment.USER_FRAGMENT}
      }
      errors {
        ${fragment.ERROR_FRAGMENT}
      }
    }
  }
`;

export const UPDATE_USER_MUTATION = gql`
  mutation UpdateUser(
    $captureTableSettings: JSON
    $assetsTableSettings: JSON
    $trackingTableSettings: JSON
    $kitsTableSettings: JSON
    $warehouseTableSettings: JSON
  ) {
    updateUser(
      captureTableSettings: $captureTableSettings
      assetsTableSettings: $assetsTableSettings
      trackingTableSettings: $trackingTableSettings
      kitsTableSettings: $kitsTableSettings
      warehouseTableSettings: $warehouseTableSettings
    ) {
      user {
        ${fragment.USER_FRAGMENT}
      }
      errors {
        ${fragment.ERROR_FRAGMENT}
      }
    }
  }
`;

export const UPDATE_USER_ACCOUNT_MUTATION = gql`
  mutation UpdateUserAccount(
    $id: ID!
    $removeClientIds: [ID!]
    $addClientIds: [ID!]
    $projectIds: [ID!]
    $role: String
    $name: String
    $settings: JSON
    ) {
    updateUserAccount(
      id: $id
      removeClientIds: $removeClientIds
      addClientIds: $addClientIds
      projectIds: $projectIds
      role: $role
      name: $name
      settings: $settings
      ) {
      user {
        ${fragment.USER_FRAGMENT}
      }
      errors {
        ${fragment.ERROR_FRAGMENT}
      }
    }
  }
`;

export const UPDATE_COMPANY_PERMISSIONS_MUTATION = gql`
  mutation UpdateCompanyPermissions($permissions: JSON!) {
    updateCompanyPermissions(permissions: $permissions) {
      company {
        permissions
      }
      errors {
        ${fragment.ERROR_FRAGMENT}
      }
    }
  }
`;

export const ACCEPT_INVITATION_MUTATION = gql`
  mutation AcceptInvitation($password: String!, $passwordConfirmation: String!, $accessToken: String!, $name: String!) {
    acceptInvitation(password: $password, passwordConfirmation: $passwordConfirmation, accessToken: $accessToken, name: $name) {
      user {
        ${fragment.USER_FRAGMENT}
      }
      errors {
        ${fragment.ERROR_FRAGMENT}
      }
    }
  }
`;

export const ADD_SHOPPING_INVENTORY_ITEM_MUTATION = gql`
  mutation AddShoppingInventoryItem($assetId: ID!, $quantity: Int!, $deliveryDate: String, $deliveryTime: String, $building: String, $floor: String, $room: String, $location: String, $comment: String, $street: String, $city: String, $zip: String, $poNumber: String, $idsrNumber: String, $eocNumber: String, $contactName: String, $contactPhoneNumber: String) {
    addShoppingInventoryItem(assetId: $assetId, quantity: $quantity, deliveryDate: $deliveryDate, deliveryTime: $deliveryTime, building: $building, floor: $floor, room: $room, location: $location, comment: $comment, street: $street, city: $city, zip: $zip, poNumber: $poNumber, idsrNumber: $idsrNumber, eocNumber: $eocNumber, contactName: $contactName, contactPhoneNumber: $contactPhoneNumber) {
     inventoryItem {
        ${fragment.SHOPPING_INVENTORY_ITEM_FRAGMENT}
      }
      asset {
        ${fragment.ASSET_FRAGMENT}
      }
      errors {
        ${fragment.ERROR_FRAGMENT}
      }
    }
  }
`;

export const CREATE_KIT_ASSET_MUTATION = gql`
  mutation CreateKitAsset($kitId: ID!, $input: KitAssetInput!) {
    createKitAsset(kitId: $kitId, input: $input) {
      kit {
        ${fragment.KIT_FRAGMENT}
      }
      errors {
        ${fragment.ERROR_FRAGMENT}
      }
    }
  }
`;

export const UPDATE_SHOPPING_INVENTORY_ITEM_MUTATION = gql`
  mutation UpdateShoppingInventoryRequestItem($inventoryItemId: ID!, $quantity: Int!, $deliveryDate: String, $deliveryTime: String, $building: String, $floor: String, $room: String, $location: String, $comment: String, $street: String, $city: String, $zip: String, $poNumber: String, $idsrNumber: String, $eocNumber: String) {
    updateShoppingInventoryRequestItem(inventoryItemId: $inventoryItemId, quantity: $quantity, deliveryDate: $deliveryDate, deliveryTime: $deliveryTime, building: $building, floor: $floor, room: $room, location: $location, comment: $comment, street: $street, city: $city, zip: $zip, poNumber: $poNumber, idsrNumber: $idsrNumber, eocNumber: $eocNumber) {
      inventoryItem {
        ${fragment.SHOPPING_INVENTORY_ITEM_FRAGMENT}
      }
      errors {
        ${fragment.ERROR_FRAGMENT}
      }
    }
  }
`;

export const MAKE_SHOPPING_INVENTORY_REQUEST_MUTATION = gql`
  mutation MakeShoppingInventoryRequest($input: ShoppingInventoryRequest!) {
    makeShoppingInventoryRequest(input: $input) {
      inventoryRequest {
        id
        workOrderNumber
        ${fragment.SHOPPING_INVENTORY_REQUEST_FRAGMENT}
      }
      stripeCheckoutSessionId
      errors {
        ${fragment.ERROR_FRAGMENT}
      }
    }
  }
`;

export const RESOLVE_INVENTORY_ITEM_MUTATION = gql`
  mutation ResolveInventoryItem($assetId: ID!, $inventoryRequestId: ID!, $status: String!) {
    resolveInventoryItem(assetId: $assetId, inventoryRequestId: $inventoryRequestId, status: $status) {
      inventoryRequest {
        id
        status
        reviewStatus
      }
      inventoryItem {
        id
        status
      }
      errors {
        ${fragment.ERROR_FRAGMENT}
      }
    }
  }
`;

export const CHECK_OUT_INVENTORY_ITEM_MUTATION = gql`
  mutation CheckOutInventoryItem($inventoryRequestId: ID!, $code: String!, $quantity: Int!) {
    checkOutInventoryItem(inventoryRequestId: $inventoryRequestId, code: $code, quantity: $quantity) {
      inventoryRequest {
        id
        reviewStatus
      }
      inventoryItem {
        id
        status
        quantity
        checkedOutQuantity
        checkedInQuantity
      }
      errors {
        ${fragment.ERROR_FRAGMENT}
      }
    }
  }
`;

export const CHECK_IN_INVENTORY_ITEM_MUTATION = gql`
  mutation CheckInInventoryItem($inventoryRequestId: ID!, $code: String!, $quantity: Int!) {
    checkInInventoryItem(inventoryRequestId: $inventoryRequestId, code: $code, quantity: $quantity) {
      inventoryRequest {
        id
        reviewStatus
      }
      inventoryItem {
        id
        status
        quantity
        checkedOutQuantity
        checkedInQuantity
      }
      errors {
        ${fragment.ERROR_FRAGMENT}
      }
    }
  }
`;

export const CHECK_OUT_MUTATION = gql`
  mutation CheckOut($code: String!) {
    checkOut(code: $code) {
      amount
      errors {
        ${fragment.ERROR_FRAGMENT}
      }
    }
  }
`;

export const CHECK_IN_MUTATION = gql`
  mutation CheckIn($code: String!) {
    checkIn(code: $code) {
      amount
      errors {
        ${fragment.ERROR_FRAGMENT}
      }
    }
  }
`;

export const UPDATE_PROJECT_SETTINGS_MUTATION = gql`
  mutation UpdateProjectSettings($projectId: ID!, $fieldSettings: JSON!) {
    updateProjectSettings(projectId: $projectId, fieldSettings: $fieldSettings) {
      project {
        id
        name
        disabled
        active
        fieldSettings
      }
      errors {
        ${fragment.ERROR_FRAGMENT}
      }
    }
  }
`;

export const UPDATE_COMPANY_SETTINGS_MUTATION = gql`
  mutation UpdateCompanyDefaultSettings($fieldSettings: JSON!) {
    updateCompanyDefaultSettings(fieldSettings: $fieldSettings) {
      company {
        fieldSettings
      }
      errors {
        ${fragment.ERROR_FRAGMENT}
      }
    }
  }
`;

export const IMPORT_ASSETS_MUTATION = gql`
  mutation ImportAssets($file: Upload!, $projectId: ID!) {
    importAssets(file: $file, projectId: $projectId) {
      success
      errors {
        ${fragment.ERROR_FRAGMENT}
      }
    }
  }
`;

export const IMPORT_PROCUREMENT_SHEET_MUTATION = gql`
  mutation ImportProcurementSheet($file: Upload!, $projectId: ID!) {
    importProcurementSheet(file: $file, projectId: $projectId) {
      success
      errors {
        ${fragment.ERROR_FRAGMENT}
      }
    }
  }
`;

export const CREATE_MOVE_MATRIX_MUTATION = gql`
  mutation CreateMoveMatrix($file: Upload!, $projectId: ID) {
    createMoveMatrix(file: $file, projectId: $projectId) {
      moveMatrices {
        ${fragment.MOVE_MATRIX_FRAGMENT}
      }
      errors {
        ${fragment.ERROR_FRAGMENT}
      }
    }
  }
`;

export const RESET_USER_PASSWORD_MUTATION = gql`
  mutation ResetUserPassword($password: String!, $passwordConfirmation: String!, $resetPasswordToken: String!) {
    resetUserPassword(password: $password, passwordConfirmation: $passwordConfirmation, resetPasswordToken: $resetPasswordToken) {
      user {
        ${fragment.USER_FRAGMENT}
      }
      errors {
        ${fragment.ERROR_FRAGMENT}
      }
    }
  }
`;

export const FORGET_PASSWORD_REQUEST_MUTATION = gql`
  mutation ForgetPasswordRequest($email: String!) {
    forgetPasswordRequest(email: $email) {
      success
      errors {
        ${fragment.ERROR_FRAGMENT}
      }
    }
  }
`;

export const REMOVE_CLIENT_MUTATION = gql`
  mutation RemoveClient($id: ID!) {
    removeClient(id: $id) {
      success
      errors {
        ${fragment.ERROR_FRAGMENT}
      }
    }
  }
`;

export const EXCLUDE_USER_MUTATION = gql`
  mutation ExcludeUser($userId: ID!, $clientIds: [ID!]!) {
    excludeUser(userId: $userId, clientIds: $clientIds) {
      success
      errors {
        ${fragment.ERROR_FRAGMENT}
      }
    }
  }
`;

export const REMOVE_PROJECT_MUTATION = gql`
  mutation RemoveProject($id: ID!) {
    removeProject(id: $id) {
      success
      errors {
        ${fragment.ERROR_FRAGMENT}
      }
    }
  }
`;

export const REMOVE_USER_MUTATION = gql`
  mutation RemoveUser($id: ID!) {
    removeUser(id: $id) {
      success
      errors {
        ${fragment.ERROR_FRAGMENT}
      }
    }
  }
`;

export const ASSETS_BULK_UPDATE_MUTATION = gql`
  mutation AssetsBulkUpdate($input: [AssetInput!]!) {
    assetsBulkUpdate(input: $input) {
      assets {
        ${fragment.ASSET_FRAGMENT}
      }
      errors {
        ${fragment.ERROR_FRAGMENT}
      }
    }
  }
`;

export const ASSETS_BULK_REMOVE_MUTATION = gql`
  mutation AssetsBulkRemove($ids: [ID!]!) {
    assetsBulkRemove(ids: $ids) {
      success
      errors {
        ${fragment.ERROR_FRAGMENT}
      }
    }
  }
`;

export const ADD_ASSET_ATTACHMENT_MUTATION = gql`
  mutation AssetAddAttachment($file: Upload!, $assetId: ID!) {
    assetAddAttachment(file: $file, assetId: $assetId) {
      attachment {
        ${fragment.ATTACHMENT_FRAGMENT}
      }
      errors {
        ${fragment.ERROR_FRAGMENT}
      }
    }
  }
`;

export const REMOVE_ASSET_ATTACHMENT_MUTATION = gql`
  mutation RemoveAssetAttachment($id: ID!, $assetId: ID!) {
    assetRemoveAttachment(id: $id, assetId: $assetId) {
      success
      errors {
        ${fragment.ERROR_FRAGMENT}
      }
    }
  }
`;

export const CREATE_ASSET_MUTATION = gql`
  mutation CreateAsset($projectId: ID!, $input: AssetInput!) {
    createAsset(projectId: $projectId, input: $input) {
      asset {
        ${fragment.ASSET_FRAGMENT}
        ${fragment.ASSET_MEDICAL_FRAGMENT}
        nextAssetId
        prevAssetId
      }
      errors {
        ${fragment.ERROR_FRAGMENT}
      }
    }
  }
`;

export const CREATE_KIT_MUTATION = gql`
  mutation CreateKit($projectId: ID!, $input: KitInput!) {
    createKit(projectId: $projectId, input: $input) {
      kit {
        ${fragment.ASSET_FRAGMENT}
      }
      errors {
        ${fragment.ERROR_FRAGMENT}
      }
    }
  }
`;

export const TRANSFER_ASSET_MUTATION = gql`
  mutation AssetTransfer($projectId: ID!, $assetId: ID!) {
    assetTransfer(projectId: $projectId, assetId: $assetId) {
      asset {
        ${fragment.ASSET_FRAGMENT}
        ${fragment.ASSET_MEDICAL_FRAGMENT}
        nextAssetId
        prevAssetId
      }
      errors {
        ${fragment.ERROR_FRAGMENT}
      }
    }
  }
`;

export const UPDATE_ASSET_MUTATION = gql`
  mutation UpdateAsset($id: ID!, $input: AssetInput!) {
    updateAsset(id: $id, input: $input) {
      asset {
        ${fragment.ASSET_FRAGMENT}
        ${fragment.ASSET_MEDICAL_FRAGMENT}
        nextAssetId
        prevAssetId
      }
      errors {
        ${fragment.ERROR_FRAGMENT}
      }
    }
  }
`;

export const CREATE_INVENTORY_TRANSACTION_MUTATION = gql`
  mutation CreateInventoryTransaction($code: String!, $quantity: Int!, $notes: String!, $senderName: String!, $carrierName: String!, $trackingNumber: String!, $orderNumber: String!, $poNumber: String!, $rackLocations: JSON!, $rackLocationsChanges: JSON!, $shipmentItemId: ID, $oldRackLocations: JSON, $assetId: ID) {
    createInventoryTransaction(code: $code, quantity: $quantity, notes: $notes, senderName: $senderName, carrierName: $carrierName, trackingNumber: $trackingNumber, orderNumber: $orderNumber, poNumber: $poNumber, rackLocations: $rackLocations, rackLocationsChanges: $rackLocationsChanges, shipmentItemId: $shipmentItemId, oldRackLocations: $oldRackLocations, assetId: $assetId) {
      asset {
        ${fragment.ASSET_FRAGMENT}
      }
      errors {
        ${fragment.ERROR_FRAGMENT}
      }
    }
  }
`;

export const ACCEPT_GUEST_MUTATION = gql`
  mutation AcceptGuest($token: String!) {
    acceptGuest(token: $token) {
      user {
        id
        token
      }
      errors {
        ${fragment.ERROR_FRAGMENT}
      }
    }
  }
`;

export const CREATE_ACCESS_LINK_MUTATION = gql`
  mutation CreateAccessLink(
    $projectIds: [String!]
    $permissions: JSON
    $active: Boolean
    $name: String
    ) {
    createAccessLink(
      projectIds: $projectIds
      permissions: $permissions
      active: $active
      name: $name
    ) {
      accessLink {
        ${fragment.ACCESS_LINK_FRAGMENT}
      }
      errors {
        ${fragment.ERROR_FRAGMENT}
      }
    }
  }
`;

export const CREATE_PROJECT_ACCESS_LINK_MUTATION = gql`
  mutation CreateProjectAccessLink($projectId: ID!) {
    createProjectAccessLink(projectId: $projectId) {
      accessLink {
        ${fragment.ACCESS_LINK_FRAGMENT}
      }
      errors {
        ${fragment.ERROR_FRAGMENT}
      }
    }
  }
`;

export const UPDATE_ACCESS_LINK_MUTATION = gql`
  mutation UpdateAccessLink(
    $id: ID!
    $projectIds: [String!]
    $permissions: JSON
    $active: Boolean
    $name: String
  ) {
    updateAccessLink(
      id: $id
      projectIds: $projectIds
      permissions: $permissions
      active: $active
      name: $name
    ) {
      accessLink {
        ${fragment.ACCESS_LINK_FRAGMENT}
      }
      errors {
        ${fragment.ERROR_FRAGMENT}
      }
    }
  }
`;

export const REMOVE_ACCESS_LINK_MUTATION = gql`
  mutation RemoveAccessLink($id: ID!) {
    removeAccessLink(id: $id) {
      success
      errors {
        ${fragment.ERROR_FRAGMENT}
      }
    }
  }
`;

export const ADD_INVENTORY_REQUEST_ATTACHMENT_MUTATION = gql`
  mutation InventoryRequestAddAttachment($file: Upload!, $inventoryRequestId: ID!) {
    inventoryRequestAddAttachment(file: $file, inventoryRequestId: $inventoryRequestId) {
      attachment {
        ${fragment.ATTACHMENT_FRAGMENT}
      }
      errors {
        ${fragment.ERROR_FRAGMENT}
      }
    }
  }
`;

export const REMOVE_INVENTORY_REQUEST_ATTACHMENT_MUTATION = gql`
  mutation InventoryRequestRemoveAttachment($inventoryRequestId: ID!, $id: ID!) {
    inventoryRequestRemoveAttachment(inventoryRequestId: $inventoryRequestId, id: $id) {
      success
      errors {
        ${fragment.ERROR_FRAGMENT}
      }
    }
  }
`;

export const UPDATE_INVENTORY_REQUEST_MUTATION = gql`
  mutation UpdateInventoryRequest($id: ID!, $input: UpdateInventoryRequestInput!) {
    updateInventoryRequest(id: $id, input: $input) {
      inventoryRequest {
        ${fragment.DETAILED_INVENTORY_REQUEST_FRAGMENT}
      }
      errors {
        ${fragment.ERROR_FRAGMENT}
      }
    }
  }
`;

export const DELETE_INVENTORY_REQUEST_MUTATION = gql`
  mutation DeleteInventoryRequest($id: ID!) {
    deleteInventoryRequest(id: $id) {
      success
      errors {
        ${fragment.ERROR_FRAGMENT}
      }
    }
  }
`;

export const DELETE_INVENTORY_ITEM_MUTATION = gql`
  mutation DeleteInventoryItem($id: ID!) {
    deleteInventoryItem(id: $id) {
      success
      errors {
        ${fragment.ERROR_FRAGMENT}
      }
    }
  }
`;

export const REORDER_INVENTORY_ITEMS_MUTATION = gql`
  mutation ReorderInventoryItems($id: ID!, $position: Int!) {
    reorderInventoryItems(id: $id, position: $position) { 
      inventoryItems {
        ${fragment.INVENTORY_ITEM_FRAGMENT}
      }
      errors {
        ${fragment.ERROR_FRAGMENT}
      }
    }
  }
`;

export const UPDATE_INVENTORY_ITEM_MUTATION = gql`
  mutation UpdateInventoryItem($assetId: ID!, $id: ID!, $quantity: Int!, $deliveryDate: String, $deliveryTime: String, $building: String, $floor: String, $room: String, $location: String, $comment: String, $street: String, $city: String, $zip: String, $poNumber: String, $idsrNumber: String, $eocNumber: String, $disposition: String, $pickedStatus: Boolean) {
    updateInventoryItem(assetId: $assetId, id: $id, quantity: $quantity, deliveryDate: $deliveryDate, deliveryTime: $deliveryTime, building: $building, floor: $floor, room: $room, location: $location, comment: $comment, street: $street, city: $city, zip: $zip, poNumber: $poNumber, idsrNumber: $idsrNumber, eocNumber: $eocNumber, disposition: $disposition, pickedStatus: $pickedStatus) {
      inventoryItem {
        ${fragment.SHOPPING_INVENTORY_ITEM_FRAGMENT}
      }
      errors {
        ${fragment.ERROR_FRAGMENT}
      }
    }
  }
`;

export const ADD_INVENTORY_ITEM_MUTATION = gql`
  mutation AddInventoryItem($assetId: ID!, $inventoryRequestId: ID!, $quantity: Int!, $location: String, $building: String, $city: String, $poNumber: String, $room: String, $street: String, $zip: String) {
    addInventoryItem(assetId: $assetId, inventoryRequestId: $inventoryRequestId, quantity: $quantity, location: $location, building: $building, city: $city, poNumber: $poNumber, room: $room, street: $street, zip: $zip) {
      inventoryItem {
        ${fragment.SHOPPING_INVENTORY_ITEM_FRAGMENT}
      }
      errors {
        ${fragment.ERROR_FRAGMENT}
      }
    }
  }
`;

export const REMOVE_LOCATION_MUTATION = gql`
  mutation DeleteLocation($id: ID!) {
    deleteLocation(id: $id) {
      success
      errors {
        ${fragment.ERROR_FRAGMENT}
      }
    }
  }
`;

export const REMOVE_REQUESTOR_MUTATION = gql`
  mutation DeleteRequestor($id: ID!) {
    deleteRequestor(id: $id) {
      success
      errors {
        ${fragment.ERROR_FRAGMENT}
      }
    }
  }
`;

export const CREATE_LOCATION_MUTATION = gql`
  mutation CreateLocation($name: String!, $address: String!) {
    createLocation(name: $name, address: $address) {
      location {
        ${fragment.LOCATION_FRAGMENT}
      }
      errors {
        ${fragment.ERROR_FRAGMENT}
      }
    }
  }
`;

export const CREATE_REQUESTOR_MUTATION = gql`
  mutation CreateRequestor($name: String!, $email: String!) {
    createRequestor(name: $name, email: $email) {
      requestor {
        ${fragment.REQUESTOR_FRAGMENT}
      }
      errors {
        ${fragment.ERROR_FRAGMENT}
      }
    }
  }
`;

export const DELETE_FAVORITE_REPORT = gql`
  mutation DeleteFavoriteReport($id: ID!) {
    deleteFavoriteReport(id: $id) {
      success
      errors {
        ${fragment.ERROR_FRAGMENT}
      }
    }
  }
`;

export const CREATE_PLAN = gql`
  mutation EquipmentPlannerCreateFloorPlan(
    $metadata: JSON!
    $projectId: ID!
    $state: JSON!
    $name: String!
  ) {
    equipmentPlannerCreateFloorPlan(
      metadata: $metadata
      projectId: $projectId
      state: $state
      name: $name
    ) {
      floorPlan {
        id
        name
      }
    }
  }
`;

export const DELETE_PLAN = gql`
  mutation EquipmentPlannerDeleteFloorPlan($floorPlanId: ID!) {
    equipmentPlannerDeleteFloorPlan(floorPlanId: $floorPlanId) {
      success
      errors {
        ${fragment.ERROR_FRAGMENT}
      }
    }
  }
`;

export const UPDATE_PLAN = gql`
  mutation EquipmentPlannerUpdateFloorPlan($metadata: JSON!, $floorPlanId: ID!, $state: JSON!) {
    equipmentPlannerUpdateFloorPlan(metadata: $metadata, floorPlanId: $floorPlanId, state: $state) {
      floorPlan {
        id
        state
      }
      errors {
        ${fragment.ERROR_FRAGMENT}
      }
    }
  }
`;

export const ADD_ICONS = gql`
  mutation EquipmentPlannerAddIconSet($floorPlanId: ID!, $state: JSON!) {
    equipmentPlannerAddIconSet(floorPlanId: $floorPlanId, state: $state) {
      iconSet {
        id
        state
      }
      errors {
        ${fragment.ERROR_FRAGMENT}
      }
    }
  }
`;

export const CREATE_VIEW = gql`
  mutation EquipmentPlannerCreatePlanView($floorPlanId: ID!) {
    equipmentPlannerCreatePlanView(floorPlanId: $floorPlanId) {
      planView {
        id
      }
      errors {
        ${fragment.ERROR_FRAGMENT}
      }
    }
  }
`;

export const UPDATE_PLANNER_VIEW = gql`
  mutation EquipmentPlannerUpgradePlanView($planViewId: ID!, $name: String!) {
    equipmentPlannerUpgradePlanView(planViewId: $planViewId, name: $name) {
      planView {
        id
        name
      }
      errors {
        ${fragment.ERROR_FRAGMENT}
      }
    }
  }
`;

export const UPDATE_PLANNER_FLOOR_PLAN = gql`
  mutation EquipmentPlannerUpgradeFloorPlan($floorPlanId: ID!, $name: String!) {
    equipmentPlannerUpgradeFloorPlan(floorPlanId: $floorPlanId, name: $name) {
      floorPlan {
        id
        name
        metadata
      }
    }
  }
`;

export const DELETE_PLANNER_VIEW = gql`
  mutation EquipmentPlannerDeletePlanView($planViewId: ID!) {
    equipmentPlannerDeletePlanView(planViewId: $planViewId) {
      errors {
        ${fragment.ERROR_FRAGMENT}
      }
    }
  }
`;

export const CREATE_SHIPMENT_MUTATION = gql`
  mutation CreateShipment($input: ShipmentInput!) {
    createShipment(input: $input) {
      shipment {
        ${fragment.SHIPMENT_FRAGMENT}
      }
    }
  }
`;

export const UPDATE_SHIPMENT_MUTATION = gql`
  mutation UpdateShipment($id: ID!, $input: ShipmentInput!) {
    updateShipment(id: $id, input: $input) {
      shipment {
        ${fragment.SHIPMENT_FRAGMENT}
      }
      errors {
        message
      }
    }
  }
`;

export const ADD_SHIPMENT_ITEMS_MUTATION = gql`
  mutation AddShipmentItems($assetId: ID!, $shipmentId: ID!, $quantity: Int!) {
    addShipmentItems(assetId: $assetId, shipmentId: $shipmentId, quantity: $quantity) {
      shipmentItem {
        assetId
        quantity
        shipmentId
        id
      }
    }
  }
`;

export const UPDATE_SHIPMENT_ITEMS_MUTATION = gql`
  mutation UpdateShipmentItems($id: ID!, $quantity: Int!) {
    updateShipmentItems(id: $id, quantity: $quantity) {
      shipmentItem {
        assetId
        quantity
        shipmentId
        id
      }
    }
  }
`;

export const DELETE_SHIPMENT_MUTATION = gql`
  mutation DeleteShipment($id: ID!) {
    deleteShipment(id: $id) {
      success
    }
  }
`;

export const ADD_SHIPMENT_ATTACHMENT_MUTATION = gql`
  mutation AddAttachmentShipment($file: Upload!, $shipmentId: ID!) {
    addAttachmentShipment(file: $file, shipmentId: $shipmentId) {
      attachment {
        ${fragment.ATTACHMENT_FRAGMENT}
      }
      errors {
        ${fragment.ERROR_FRAGMENT}
      }
    }
  }
`;

export const REMOVE_SHIPMENT_ATTACHMENT_MUTATION = gql`
  mutation RemoveAttachmentShipment($id: ID!, $shipmentId: ID!) {
    removeAttachmentShipment(id: $id, shipmentId: $shipmentId) {
      errors {
        ${fragment.ERROR_FRAGMENT}
      }
    }
  }
`;

export const ADD_OVERAGES_SHORTAGES_DAMAGE = gql`
  mutation AddOveragesShortagesDamage(
    $shipmentItemId: ID!
    $osdType: String!
    $osdNotes: String
    $osdQuantity: Int
  ) {
    addOveragesShortagesDamage(
      shipmentItemId: $shipmentItemId
      osdType: $osdType
      osdNotes: $osdNotes
      osdQuantity: $osdQuantity
    ) {
      overagesShortagesDamage {
        id
        osdType
        osdNotes
        osdQuantity
        shipmentItem {
          id
          shipmentId
        }
      }
    }
  }
`;
export const UPDATE_OVERAGES_SHORTAGES_DAMAGE = gql`
  mutation UpdateOveragesShortagesDamage(
    $id: ID!
    $osdType: String!
    $osdNotes: String!
    $osdQuantity: Int!
  ) {
    updateOveragesShortagesDamage(
      id: $id
      osdType: $osdType
      osdNotes: $osdNotes
      osdQuantity: $osdQuantity
    ) {
      overagesShortagesDamage {
        id
        osdType
        osdNotes
        osdQuantity
        shipmentItem {
          id
          shipmentId
        }
      }
    }
  }
`;

export const DELETE_OVERAGES_SHORTAGES_DAMAGE = gql`
  mutation DeleteOveragesShortagesDamage($id: ID!) {
    deleteOveragesShortagesDamage(id: $id) {
      errors {
        ${fragment.ERROR_FRAGMENT}
      }
    }
  }
`;

export const ADD_ATTACHMENT_OVERAGES_SHORTAGES_DAMAGE = gql`
  mutation AddAttachmentOveragesShortagesDamage($file: Upload!, $overagesShortagesDamageId: ID!) {
    addAttachmentOveragesShortagesDamage(file: $file, overagesShortagesDamageId: $overagesShortagesDamageId) {
      attachment {
        ${fragment.ATTACHMENT_FRAGMENT}
      }
      errors {
        ${fragment.ERROR_FRAGMENT}
      }
    }
  }
`;

export const REMOVE_ATTACHMENT_OVERAGES_SHORTAGES_DAMAGE = gql`
  mutation RemoveAttachmentOveragesShortagesDamage($id: ID!, $overagesShortagesDamageId: ID!) {
    removeAttachmentOveragesShortagesDamage(id: $id, overagesShortagesDamageId: $overagesShortagesDamageId) {
      errors {
        ${fragment.ERROR_FRAGMENT}
      }
    }
  }
`;

export const TRANSFER_SHIPMENT_MUTATION = gql`
  mutation TransferShipment($projectId: ID!, $shipmentId: ID!) {
    transferShipment(projectId: $projectId, shipmentId: $shipmentId) {
      shipment {
        ${fragment.SHIPMENT_FRAGMENT}
      }
      errors {
        ${fragment.ERROR_FRAGMENT}
      }
    }
  }
`;

export const WAREHOUSES_BULK_ASSIGN_CLIENTS = gql`
  mutation WarehousesBulkAssignClients($clientsWarehouses: JSON) {
    warehousesBulkAssignClients(clientsWarehouses: $clientsWarehouses) {
      warehouses {
        id
      }
      errors {
        ${fragment.ERROR_FRAGMENT}
      }
    }
  }
`;

export const WAREHOUSES_BULK_UNASSIGN_CLIENTS = gql`
  mutation WarehousesBulkUnassignClients($clientsWarehouses: JSON) {
    warehousesBulkUnassignClients(clientsWarehouses: $clientsWarehouses) {
      warehouses {
        id
      }
      errors {
        ${fragment.ERROR_FRAGMENT}
      }
    }
  }
`;
