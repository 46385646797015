import React, { createContext, useEffect, useState } from "react";
import SplashScreen from "src/components/SplashScreen";
import { ACCEPT_GUEST_MUTATION } from "src/constants/graphql";
import useMutation from "src/hooks/useMutation";
import { setSession } from "./JWTAuthContext";
import isEmpty from "lodash/isEmpty";

export const ACCESS_LINK_NAME = "access-link-token";

const fetchAccessLinkToken = () => {
  let url = new URL(window.location.href);
  let params = new URLSearchParams(url.search.slice(1));

  return params.get(ACCESS_LINK_NAME);
};

const AccessLinkContext = createContext({});

export const AccessLinkProvider = ({ children }) => {
  const [acceptGuest] = useMutation(ACCEPT_GUEST_MUTATION);
  const [loading, setLoading] = useState(true);
  const accessToken = fetchAccessLinkToken();

  useEffect(() => {
    if (isEmpty(accessToken)) return;

    setLoading(true);

    acceptGuest({
      variables: { token: accessToken },
      onSuccess: ({ user }) => {
        setLoading(false);
        setSession(user.token);
        window.location.replace(window.location.pathname);
      },
      onFailure: () => {
        setLoading(false);
        window.location.replace("/403");
      },
    });
  }, []);

  if (!isEmpty(accessToken) && loading) {
    return <SplashScreen />;
  }

  return (
    <AccessLinkContext.Provider value={{}}>
      {children}
    </AccessLinkContext.Provider>
  );
};

export default AccessLinkContext;
