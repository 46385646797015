import React, {useEffect, useState} from "react";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import {useQuery, useSubscription} from "@apollo/react-hooks";

import {ASSETS_COLLECTION_QUERY, INVENTORY_ASSET_STOCKED} from "../../../constants/graphql";
import Filter from "../../../views/shopping/ProjectAssets/Filter";
import Results from "../../../views/shopping/ProjectAssets/Results";
import DefaultItems, {initialValues} from "../../../views/shopping/ProjectAssets/DefaultItems";
import {ShoppingProvider} from "../../../contexts/shopping";
import useAuth from "../../../hooks/useAuth";

const INITIAL_FILTER = {
  search: "",
  limit: 24,
  page: 1,
  categories: [],
};

export const ProjectBrowseView = ({ projectId }) => {
  const { user } = useAuth();
  const [filter, setFilter] = useState(INITIAL_FILTER);
  const [assets, setAssets] = useState([]);
  const [metadata, setMetadata] = useState({});
  const [loading, setLoading] = useState(true);
  const [defaultValues, setDefaultValues] = useState(initialValues);
  const locationShortcut = user?.company?.settings?.location_shortcut;

  const { data: { categories = [] } = {}, refetch } = useQuery(ASSETS_COLLECTION_QUERY, {
    variables: projectId
      ? { ...filter, projectId, storePage: true }
      : { ...filter, storePage: true },
    onCompleted: ({ assetsCollection = {} } = {}) => {
      setAssets(assetsCollection?.collection);
      setMetadata(assetsCollection?.metadata || {});
      setLoading(false);
    },
  });

  useEffect(() => {
    setLoading(true);
    refetch({ filter }).then(() => setLoading(false));
  }, [filter, projectId]);

  useSubscription(INVENTORY_ASSET_STOCKED, {
    onSubscriptionData: ({ subscriptionData: { data: { inventoryAssetStocked } = {} } = {} }) => {
      const existingAssetIndex = _.findIndex(assets, asset => asset.id == inventoryAssetStocked.id);
      if (existingAssetIndex == null) return;
      setAssets(prevAssets =>
        Object.assign([], prevAssets, {
          [existingAssetIndex]: inventoryAssetStocked,
        })
      );
    },
  });

  const onChangeFilter = newFilter => setFilter({ ...filter, ...newFilter });

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={locationShortcut ? 6 : 12}>
          <Filter
            categories={categories}
            filter={filter}
            disabled={loading}
            onChange={onChangeFilter}
          />
        </Grid>
        {locationShortcut && (
          <Grid item xs={6}>
            <DefaultItems values={defaultValues} onChange={setDefaultValues} />
          </Grid>
        )}
      </Grid>
      <Box mt={2}>
        <Results
          defaultValues={defaultValues}
          disabled={loading}
          onChange={onChangeFilter}
          loading={loading}
          assets={assets}
          pagination={metadata}
        />
      </Box>
    </>
  );
};

export default ({ projectId }) => (
  <ShoppingProvider>
    <ProjectBrowseView projectId={projectId} />
  </ShoppingProvider>
);
