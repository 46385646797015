import React from "react";
import LinearProgress from "@mui/material/LinearProgress";
import {useQuery} from "@apollo/react-hooks";

import {WAREHOUSE_NAMES} from "../../constants/graphql/queries";
import SelectWarehouse from "./SelectWarehouse";
import Warehouse from "./Warehouse";

export const WarehouseContext = React.createContext(null);

export default function WarehouseList({ modal }) {
  const [id, setId] = React.useState();
  const [view, setView] = React.useState("table");
  const { data: { warehouses } = {}, loading } = useQuery(WAREHOUSE_NAMES, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-first",
  });

  const changeWarehouse = id => {
    setId(id);
    setView("table");
  };

  if (id) {
    return (
      <WarehouseContext.Provider value={warehouses.find(v => v.id === id)}>
        <Warehouse
          id={id}
          view={view}
          modal={modal}
          changeWarehouse={changeWarehouse}
          changeView={setView}
        />
      </WarehouseContext.Provider>
    );
  }

  return loading ? (
    <LinearProgress className="mt-2" />
  ) : (
    <SelectWarehouse warehouses={warehouses} changeWarehouse={changeWarehouse} />
  );
}
