import React, { useCallback, useState } from "react";
import clsx from "clsx";
import { makeStyles, Card } from "@material-ui/core";
import takeWhile from "lodash/takeWhile";
import isEmpty from "lodash/isEmpty";

const useStyles = makeStyles((theme) => ({
  frozenCell: {
    position: "sticky",
    zIndex: 2,
    backgroundColor: "inherit",
  },
  cell: {
    padding: 0,
    textAlign: "left",
    display: "flex",
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
  },
  cellWrap: {
    position: "relative",
    height: "100%",
    width: "100%",
  },
  cellContent: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    textAlign: "left",
    display: "flex",
    alignItems: "center",
    height: "100%",
    width: "100%",
  },
  frozenShadow: {
    boxShadow: "2px 0 2px 0 rgba(0, 0, 0, 0.1)",
  },
}));

import { MIN_WIDTH } from "./TableHeader/index";

const TableCell = ({
  fields,
  field,
  className,
  style,
  children,
  innerRef,
  nestedElement,
  contentProps,
  ...props
}) => {
  const showField = field.visible && !field.inactive;

  if (!showField)
    return <div style={{ display: "none" }} {...props} ref={innerRef}></div>;

  const classes = useStyles();

  const visibleFields = fields.filter(
    (fieldItem) => fieldItem.visible && !fieldItem.inactive
  );

  const previousFields = takeWhile(visibleFields, (h) => h.id !== field.id);

  const previousFieldsAreFrozen = previousFields.every((item) => item.isFrozen);

  const isFrozen = field.isFrozen && previousFieldsAreFrozen;
  const previousFieldsWidth = previousFields.reduce(
    (sum, prevField) => sum + (prevField.width || MIN_WIDTH),
    0
  );

  return (
    <div
      className={clsx(classes.cell, {
        [classes.frozenCell]: isFrozen,
      })}
      style={{
        left: previousFieldsWidth,
        width: field.width || MIN_WIDTH,
        minWidth: field.width || MIN_WIDTH,
        ...style,
      }}
      {...props}
      ref={innerRef}
    >
      <div className={classes.cellWrap}>
        <div
          className={clsx(classes.cellContent, className, {
            [classes.frozenShadow]: isFrozen,
          })}
          {...contentProps}
        >
          {children}
        </div>

        {nestedElement}
      </div>
    </div>
  );
};

export default TableCell;
