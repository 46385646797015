import React from "react";
import Box from "@mui/material/Box";

export default function Progress({ step }) {
  return (
    <Box position="relative">
      <Box
        position="absolute"
        ml={`${33.33 * step}%`}
        width="33.33%"
        borderTop="2px solid"
        borderColor="#1976d2"
      />
      <Box borderTop="2px solid #d5d5d5" />
    </Box>
  );
}
