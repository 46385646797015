import React from "react";
import moment from "moment";
import CircularProgress from "@mui/material/CircularProgress";
import {useHistory, useParams} from "react-router-dom";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import useMutation from "src/hooks/useMutation";
import {CREATE_SHIPMENT_MUTATION} from "src/constants/graphql/mutations";
import {serverFormatSeconds} from "src/utils/dates";
import Progress from "../Progress";
import Header from "../Header";
import useAuth from "../../../../hooks/useAuth";

export default function Details({ project }) {
  const history = useHistory();
  const { user } = useAuth();
  const { settings } = user.company;
  const [createShipment, { loading }] = useMutation(CREATE_SHIPMENT_MUTATION);
  const { projectId } = useParams();

  const nextStep = () => {
    createShipment({
      variables: {
        input: {
          projectId: parseInt(projectId),
          startTime: moment(new Date()).format(serverFormatSeconds),
        },
      },
    }).then(({ data }) => {
      const pathname = window.location.pathname;
      const params = new URLSearchParams(window.location.search);
      params.set("step", "shipment");
      params.set("shipmentId", data.createShipment.shipment.shipmentId);
      params.set("id", data.createShipment.shipment.id);
      history.push({ pathname, search: params.toString() });
    });
  };

  return (
    <>
      <Header title={`Key ${settings.filter_field2} Details`} />
      <Box
        p={2}
        minHeight={400}
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
      >
        <Box>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6}>
              <TextField
                size="small"
                fullWidth
                disabled
                value={project.name}
                label={`${settings.filter_field2} Name`}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                size="small"
                fullWidth
                disabled
                value={project.billingType}
                label="Billing Type"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                size="small"
                multiline
                fullWidth
                disabled
                value={project.customerNotes}
                label={`${settings.filter_field2} Notes`}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Progress step={0} />
      <Box px={2} py={1.5} display="flex" justifyContent="flex-end" alignItems="center">
        <Button
          disabled={loading}
          type="button"
          sx={{ textTransform: "none" }}
          variant="contained"
          color="primary"
          startIcon={loading && <CircularProgress size={22} />}
          onClick={nextStep}
        >
          Start
        </Button>
      </Box>
    </>
  );
}
