import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/react-hooks";
import { useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import InnerImageZoom from "react-inner-image-zoom";
import "react-inner-image-zoom/lib/InnerImageZoom/styles.css";

import { getDegree, getImageDegree, rotateImage } from "src/utils/images";
import { ASSETS_PROJECT_IMAGES_QUERY } from "src/constants/graphql/queries";

const useStyles = makeStyles(theme => ({
  content: {
    padding: 0,
  },
  infoContainer: {
    textAlign: 'center',
  },
  imageContainer: {
    position: "relative",
    textAlign: 'center',
  },
  navButtonContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  navButton: {
    height: '100%',
    minHeight: '40px',
    minWidth: '40px',
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  dialogActions: {
    justifyContent: 'flex-end',
    padding: theme.spacing(1),
  },
  image: {
    maxHeight: 'calc(100vh - 200px)',
    maxWidth: '100%',
  }
}));

const ProjectImageModal = ({
  open,
  edit = true,
  closeOnSave = false,
  selectedId: defaultId,
  onClose,
  onRemove,
  onUpdate,
  onCreate,
}) => {
  const classes = useStyles();
  const { projectId } = useParams();
  const [loading, setLoading] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [combinedImages, setCombinedImages] = useState([]);
  const [selectedId, setSelectedId] = useState(defaultId);
  const [fetchData, setFetchData] = useState(false);

  const { loading: loadingAssets, data: assetsCollectionData } = useQuery(ASSETS_PROJECT_IMAGES_QUERY, {
    variables: { projectId },
    skip: !fetchData,
  });

  useEffect(() => {
    if (open && projectId) {
      setFetchData(true);
    }
  }, [open, projectId]);

  useEffect(() => {
    if (assetsCollectionData && fetchData) {
      const assetsCollection = assetsCollectionData.assetsCollection || [];
      const images = assetsCollection.collection.flatMap(asset =>
        asset.images.map(image => ({
          ...image,
          assetId: asset.id,
          itemId: asset.itemId,
          manufacturer: asset.manufacturer,
          description: asset.description,
          dimensions: asset.dimensions,
          quantity: asset.quantity,
        }))
      );
      setCombinedImages(images);
      setSelectedId(images[0]?.id);
      setFetchData(false);
      setLoading(false);
    }
  }, [assetsCollectionData, fetchData]);

  const currentImage = combinedImages.find(image => selectedId === image.id) || combinedImages[0];
  const currentImageIndex = combinedImages.indexOf(currentImage);
  const InitialRotation = getImageDegree(currentImage?.url);
  const [rotate] = useState(InitialRotation);

  const prevImage = combinedImages[currentImageIndex - 1];
  const nextImage = combinedImages[currentImageIndex + 1];

  const imgUrl = rotateImage(currentImage?.url, "width", 1024, rotate);

  return (
    <Dialog fullWidth maxWidth="lg" open={open} onClose={onClose}>
      <DialogContent classes={{ root: classes.content }}>
        <Grid container>
          <Grid item xs={12}>
            {currentImage && (
              <Box className={classes.infoContainer}>
                <Typography variant="h4" style={{ color: "black" }}>
                  {currentImage.itemId}
                </Typography>
                <Typography variant="body1" style={{ color: "black" }}>
                  {currentImage.manufacturer} {currentImage.description} {currentImage.model}{" "}
                  {currentImage.dimensions?.[0]?.width}x{currentImage.dimensions?.[0]?.height}x
                  {currentImage.dimensions?.[0]?.depth} {currentImage.color}
                </Typography>
                <Typography variant="body1" style={{ color: "black" }}>
                  Quantity: {currentImage.quantity}
                </Typography>
              </Box>
            )}
            <Box display="flex" alignItems="center">
              <Box className={classes.navButtonContainer} flexGrow={1}>
                <IconButton
                  className={classes.navButton}
                  disabled={loading || !prevImage}
                  onClick={() => setSelectedId(prevImage?.id)}
                >
                  <NavigateBeforeIcon />
                </IconButton>
              </Box>
              {currentImage && (
                <Box className={classes.imageContainer} flexGrow={1} justifyContent="center" mx={0.5} borderRadius={4}>
                  <InnerImageZoom src={imgUrl} zoomSrc={imgUrl} zoomScale={2} className={classes.image} />
                </Box>
              )}
              <Box className={classes.navButtonContainer} flexGrow={1}>
                <IconButton
                  className={classes.navButton}
                  disabled={loading || !nextImage}
                  onClick={() => setSelectedId(nextImage?.id)}
                >
                  <NavigateNextIcon />
                </IconButton>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button onClick={onClose} color="secondary" variant="outlined">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ProjectImageModal;
