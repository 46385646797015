import React, {useState} from "react";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";

export const inFillDownAreaRow = (rowIndex, rootCoords, endCoords) => {
  if (isEmpty(rootCoords) || isEmpty(endCoords)) return false;

  return endCoords[0] >= rootCoords[0]
    ? rowIndex >= rootCoords[0] && rowIndex <= endCoords[0]
    : rowIndex <= rootCoords[0] && rowIndex >= endCoords[0];
};

export const inFillDownAreaCell = (coords, rootCoords, endCoords) => {
  if (isEmpty(rootCoords) || isEmpty(endCoords)) return false;

  return (
    inFillDownAreaRow(coords[0], rootCoords, endCoords) &&
    (endCoords[1] >= rootCoords[1]
      ? coords[1] >= rootCoords[1] && coords[1] <= endCoords[1]
      : coords[1] <= rootCoords[1] && coords[1] >= endCoords[1])
  );
};

const useFillDownCells = ({ onFillDown, rootCoords, setEndCoords, endCoords }) => {
  const [fillDownMode, setFillDownMode] = useState(false);
  const [insertMode, setInsertMode] = useState(false);

  const fillDownReset = () => {
    setFillDownMode(false);
    setEndCoords([]);
  };

  const onComplete = () => {
    if (isEmpty(rootCoords) || isEmpty(endCoords)) {
      fillDownReset(null);
      return;
    }

    setFillDownMode(false);
    insertMode && handleFillDown();
  };

  const handleFillDown = () => {
    if (!onFillDown || isEmpty(rootCoords) || isEmpty(endCoords)) {
      fillDownReset(null);
    }

    onFillDown([rootCoords, endCoords]);
    fillDownReset(null);
  };

  const onMouseMove = (rowIndex, cellIndex) => e => {
    if (isEqual([rowIndex, cellIndex], rootCoords)) {
      return;
    } else {
      fillDownMode && setEndCoords([rowIndex, cellIndex]);
    }
  };

  const onMouseDown = (rowIndex, cellIndex, insertMode) => e => {
    setEndCoords([]);
    setFillDownMode(true);
    setInsertMode(insertMode);
  };

  const fillDownElementProps = (rowIndex, cellIndex) => ({
    onMouseDown: onMouseDown(rowIndex, cellIndex, true),
  });

  const fillDownCellProps = (rowIndex, cellIndex) => ({
    onMouseDown: onMouseDown(rowIndex, cellIndex, false),
    onMouseMove: onMouseMove(rowIndex, cellIndex),
    onMouseUp: onComplete,
  });

  return {
    fillDownMode,
    fillDownElementProps,
    fillDownCellProps,
    fillDownReset,
  };
};

export default useFillDownCells;
