import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

export default function Empty() {
  return (
    <>
      <Box
        mt={3}
        mx={2}
        component="img"
        borderRadius={3}
        width="100%"
        maxWidth={400}
        minHeight="258px"
        src="/empty-shipment.png"
      />
      <Typography mt={4} maxWidth={400} fontWeight={500} align="center">
        All shipments contain items. Please list the items included in this shipment below:
      </Typography>
    </>
  );
}
