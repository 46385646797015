import React, {useState} from "react";
import SettingsApplicationsOutlinedIcon from "@mui/icons-material/SettingsApplicationsOutlined";
import Box from "@mui/material/Box";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import useAuth from "src/hooks/useAuth";

import FieldsModal from "./FieldsModal";

export default function Fields({ type, initialValues, reorderFields, onChangeOrder, onSubmit }) {
  const [open, setOpen] = useState();
  const {user} = useAuth();
  const {settings} = user.company;

  const handleToggle = () => {
    setOpen(v => !v);
  };

  return (
    <>
      {!settings.receiving_wizard && ( // Conditionally render Fields button 
        <>
          <Button style={{ textTransform: "none" }} size="small" onClick={handleToggle}>
            <SettingsApplicationsOutlinedIcon fontSize="small" htmlColor="#546e7a" />
            <Box mr={1} />
            <Typography fontWeight={600} color="textSecondary" variant="body2">
              Fields
            </Typography>
          </Button>
          {open && (
            <FieldsModal
              type={type}
              initialValues={initialValues}
              onToggle={handleToggle}
              onSubmit={onSubmit}
              reorderFields={reorderFields}
              onChangeOrder={onChangeOrder}
            />
          )}
        </>
      )}
    </>
  );
}
