import React, {useEffect, useState} from "react";
import sortBy from "lodash/sortBy";
import Grid from "@material-ui/core/Grid";
import {useQuery} from "@apollo/react-hooks";

import EntitySelect from "../../../components/EntitySelect";
import useAuth from "../../../hooks/useAuth";
import {CLIENTS_QUERY} from "../../../constants/graphql";
import ProjectActions from "./ProjectActions";
import ActionsModal from "./ActionsModal";

export const getInitClientId = (clients, selected) => {
  const client = clients.find(v => v.projects.some(project => project.id === selected));
  return client ? client.id : null;
};

export const getProjects = (clients, selectedClient) => {
  const projectList = clients.reduce(
    (prev, v) => (v.id === selectedClient ? v.projects.filter(project => project.active) : prev),
    []
  );
  return sortBy(projectList, "name");
};

export const getProjectsList = (clients, selectedClient, user) => {
  const projectList = getProjects(clients, selectedClient).filter(v =>
    user.projectIds.includes(v.id)
  );
  return [{ id: "all", name: "All" }, ...projectList];
};

export default function FilterNav({
  addAllOption,
  onSelectProject,
  onSelectClient,
  clientId,
  projectId,
  md = 7,
  hideActions,
}) {
  const { user } = useAuth();
  const { data: { clients = [] } = {} } = useQuery(CLIENTS_QUERY, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-first",
  });
  const { settings } = user.company;

  const initClientId = clientId
    ? clients.find(client => client.id === clientId)?.id || "null"
    : getInitClientId(clients, projectId);
  const [selectedClient, setSelectedClient] = useState(initClientId);

  const clientList = sortBy(
    clients.map(({ id, name }) => ({ id, name })),
    "name"
  ).filter(v => user.clientIds.includes(v.id));
  const projectList = getProjectsList(clients, selectedClient, user);

  const handleChangeClient = item => {
    const id = item.id === "null" ? null : item.id;
    setSelectedClient(id);
    if (onSelectClient) onSelectClient(id);
  };

  const handleChangeProject = projectItem => {
    onSelectProject({
      clientId: selectedClient,
      projectId: projectItem.id,
    });
  };

  useEffect(() => {
    setSelectedClient(initClientId);
  }, [initClientId]);

  return (
    <Grid container justify="space-between" alignItems="center" spacing={1}>
      <Grid container item xs={12} md={md} spacing={2}>
        <Grid item xs={6}>
          <EntitySelect
            fullWidth
            label={settings.filter_field1}
            name="client"
            value={selectedClient}
            suggestions={addAllOption ? [{ id: null, name: "All" }, ...clientList] : clientList}
            onChange={handleChangeClient}
          />
        </Grid>
        <Grid item xs={6}>
          <EntitySelect
            fullWidth
            disabled={!selectedClient}
            allowEmpty
            name="project"
            label={settings.filter_field2}
            value={projectId}
            suggestions={projectList}
            onChange={handleChangeProject}
          />
        </Grid>
      </Grid>
      {!hideActions && (
        <Grid container item xs={12} md={5} spacing={2} justify="flex-end">
          <Grid item xs={12}>
            {user.company.settings.capture_mode === "mobile" ? (
              <ActionsModal />
            ) : (
              <ProjectActions />
            )}
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}
