import React, {useState} from "react";
import {useParams} from "react-router-dom";

import AssetDetailsModal from "../components/assets/AssetDetailsModal";

const CaptureEditAssetContext = React.createContext(null);

export const EditAssetProvider = ({ children, id }) => {
  const { clientId } = useParams();
  const [asset, setAsset] = useState(null);

  return (
    <CaptureEditAssetContext.Provider value={{ setAsset }}>
      {children}
      {asset && (
        <AssetDetailsModal clientId={clientId} entity={asset} onClose={() => setAsset(null)} />
      )}
    </CaptureEditAssetContext.Provider>
  );
};

export const useEditAsset = () => React.useContext(CaptureEditAssetContext);
