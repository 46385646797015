import React, {useCallback, useState} from "react";
import {useHistory} from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import PopupState, {bindMenu, bindTrigger} from "material-ui-popup-state";
import MuiMenu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Link from "@mui/material/Link";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import DeleteIcon from "@mui/icons-material/Delete";
import IosShareIcon from "@mui/icons-material/IosShare";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import ConfirmDeleteModal from "../../../components/images/ConfirmDeleteModal";
import useAuth from "../../../hooks/useAuth";
import ShareModal from "../../../views/inventory/requests/share/ShareModal";
import AttachmentModal from "../../../views/inventory/requests/AttachmentModal";
import useQuery from "../../../hooks/useQuery";
import InventoryRequestModal from "../../../views/inventory/requests/InventoryRequestModal";

export default function OutboundActions({ item, onEdit, onRemove, refetch }) {
  const history = useHistory();
  const query = useQuery();
  const { user } = useAuth();
  const [openAttachmentModal, setOpenAttachmentModal] = useState(false);
  const [openShareModal, setOpenShareModal] = useState(false);
  const [openRemoveModal, setOpenRemoveModal] = React.useState(false);
  const selectedInventoryRequestId = query.get("inventoryRequestId");

  const toggleAttachmentModal = () => setOpenAttachmentModal(v => !v);

  const toggleShareModal = () => setOpenShareModal(v => !v);

  const toggleRemoveModal = () => {
    setOpenRemoveModal(v => !v);
  };

  const toggleEditModal = useCallback(() => {
    if (query.get("inventoryRequestId")) {
      history.push(window.location.pathname);
    } else {
      history.push({
        pathname: window.location.pathname,
        search: `?inventoryRequestId=${item.id}`,
      });
    }
  }, [query, history]);

  return (
    <>
      <IconButton onClick={() => toggleEditModal()}>
        <EditOutlinedIcon color="action" />
      </IconButton>
      <PopupState variant="popover" popupId="popup-menu">
        {popupState => (
          <React.Fragment>
            <IconButton {...bindTrigger(popupState)}>
              <MoreVertIcon color="action" />
            </IconButton>
            <MuiMenu {...bindMenu(popupState)}>
              <MenuItem
                target="_blank"
                component={Link}
                href={`/inventory_request_pdfs/${item.id}?token=${user.token}`}
                onClick={() => popupState.close()}
              >
                <ListItemIcon>
                  <ReceiptLongIcon />
                </ListItemIcon>
                <ListItemText primary="PDF Report" />
              </MenuItem>
              <MenuItem
                onClick={() => {
                  toggleAttachmentModal();
                  popupState.close();
                }}
              >
                <ListItemIcon>
                  <AttachFileIcon />
                </ListItemIcon>
                <ListItemText primary="Attachments" />
              </MenuItem>
              <MenuItem
                onClick={() => {
                  toggleShareModal();
                  popupState.close();
                }}
              >
                <ListItemIcon>
                  <IosShareIcon />
                </ListItemIcon>
                <ListItemText primary="Share Order" />
              </MenuItem>
              {user.role === "admin" && (
                <MenuItem
                  onClick={() => {
                    toggleRemoveModal();
                    popupState.close();
                  }}
                >
                  <ListItemIcon>
                    <DeleteIcon />
                  </ListItemIcon>
                  <ListItemText primary="Delete Order" />
                </MenuItem>
              )}
            </MuiMenu>
          </React.Fragment>
        )}
      </PopupState>
      {openAttachmentModal && (
        <AttachmentModal inventoryRequestId={item.id} onClose={toggleAttachmentModal} />
      )}
      {openShareModal && <ShareModal inventoryRequest={item} onClose={toggleShareModal} />}
      {openRemoveModal && (
        <ConfirmDeleteModal
          title="Delete Order"
          descr="Are you sure you want to delete this order?"
          onClose={toggleRemoveModal}
          onDelete={onRemove}
        />
      )}
      {selectedInventoryRequestId === item.id && (
        <InventoryRequestModal
          inventoryRequest={item}
          inventoryRequestItems={item.inventoryItems}
          onClose={toggleEditModal}
          reFetch={refetch}
        />
      )}
    </>
  );
}
