import React from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { InvitationProvider } from 'src/contexts/InvitationAuthContext';

import useInvitationAuth from 'src/hooks/useInvitationAuth'

const InvitationGuard = ({ children }) => {
  const { user, loading } = useInvitationAuth();

  if ((!user && !loading) || user?.invitationAccepted) {
    return <Redirect to="/login" />;
  }

  return (
    <>
      {children}
    </>
  );
};

InvitationGuard.propTypes = {
  children: PropTypes.node
};

export default (props) =>
  <InvitationProvider>
    <InvitationGuard {...props} />
  </InvitationProvider>
