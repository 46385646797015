import React, {useState} from "react";
import sortBy from "lodash/sortBy";
import {useFormikContext} from "formik";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

import {useCustomFields} from "../../../contexts/customFields";
import Input from "../../../components/form/Input";

export const addressOptions = sortBy([
  "Palo Alto Division, 3801 Miranda Ave., Palo Alto, CA 94304",
  "Menlo Park Division, 795 Willow Road, Menlo Park, CA 94025",
  "Livermore Division, 4951 Arroyo Road, Livermore, CA 94550",
  "Capitola Community Based Outpatient Clinic (CBOC), 1350 41st Ave, Suite 102, Capitola CA 95010",
  "Fremont CBOC, 39199 Liberty Street, Fremont, CA 94538",
  "Modesto CBOC, 1225 Oakdale Road, Modesto, CA 95355",
  "Monterey CBOC, 201 9th Street, Marina, CA 93933",
  "San Jose CBOC, 5855 Silver Creek Valley Place, San Jose, CA 95138",
  "Sonora CBOC, 13663 Mono Way, Sonora, CA 95370",
  "Stockton CBOC, 7777 South Freedom Road, French Camp, CA 95231",
  "Mountain View Site, 1776 Old Middlefield Way, Mountain View, CA 94043",
  "Sunnyvale Research Center, 1080 Innovation Way, Sunnyvale, CA 94089",
  "Cooperative Studies Program Coordinating Center, 701 N. Shoreline Blvd., Mountain View, CA 94",
]).map(v => {
  return { value: v, label: v };
});

export const onOptionSelected = option => {
  if (typeof option === "string") {
    const selected = addressOptions.find(v => v.value === option);
    return selected ? selected.label : option;
  }

  if (option.inputValue) {
    return option.label;
  }

  return option ? option.label : "";
};

export default function Address({ hideDropdown }) {
  const [display] = useCustomFields();
  const [selected, setSelected] = useState("");
  const formikProps = useFormikContext();

  const fields = (
    <>
      <Grid item xs={12}>
        <Input name="street" label="Street" />
      </Grid>
      <Grid item xs={6}>
        <Input name="city" label="City" />
      </Grid>
      <Grid item xs={6}>
        <Input name="zipCode" label="Zip Code" />
      </Grid>
    </>
  );

  if (hideDropdown) {
    return fields;
  }

  return (
    <>
      <Grid item xs={12}>
        <Autocomplete
          size="small"
          getOptionSelected={onOptionSelected}
          getOptionLabel={onOptionSelected}
          onChange={(e, value) => {
            const [, street, city, zipCode] = value.value.split(",");
            setSelected(value);
            formikProps.setFieldValue("street", street);
            formikProps.setFieldValue("city", city);
            formikProps.setFieldValue("zipCode", zipCode.trim().split(" ")[1]);
          }}
          options={addressOptions}
          value={selected}
          renderInput={inputProps => (
            <TextField {...inputProps} label="Add Location" variant="outlined" margin="none" />
          )}
        />
      </Grid>
      {selected && display.ir_location_address && fields}
    </>
  );
}
