import React from "react";
import Box from "@mui/material/Box";

export default function ScanditContainer({ children }) {
  return (
    <Box
      id="interactive"
      className="viewport"
      position="fixed"
      display="flex"
      zIndex={1999}
      width="100%"
      bgcolor="#1b262c"
      top={0}
      left={0}
      right={0}
      bottom={0}
    >
      {children}
    </Box>
  );
}
