import React from "react";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import MuiSelect from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";
import {FastField} from "formik";

const useStyles = makeStyles(theme => ({
  tagsRoot: {
    display: "flex",
    justifyContent: "flex-start",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(0.5),
      marginTop: theme.spacing(1),
    },
  },
  MenuItemLabel: {
    opacity: `1 !important`,
    paddingTop: theme.spacing(2),
  },
}));

const renderOptions = (options, fieldValue, classes) => {
  return options.map(([value, label, isMenuItemLabel]) => (
    <MenuItem
      data-id="select-item"
      key={label}
      value={value}
      disabled={isMenuItemLabel}
      className={isMenuItemLabel && classes.MenuItemLabel}
    >
      {isMenuItemLabel ? (
        <Typography color="textPrimary" variant="h4">
          {label}
        </Typography>
      ) : (
        <>
          <Checkbox checked={fieldValue.indexOf(value) > -1} />
          <ListItemText primary={label} />
        </>
      )}
    </MenuItem>
  ));
};

const renderGroupOptions = (mainOptions, fieldValue, classes) =>
  mainOptions.map(([label, options], index) => {
    const optionsWithLabel = [[null, label, true], ...options];
    return renderOptions(optionsWithLabel, fieldValue, classes);
  });

const Select = ({ options = [], groupOptions, label, InputLabelProps, ...props }) => {
  const classes = useStyles();
  const optionsValues = groupOptions ? groupOptions.map(option => option[1]).flat() : options;

  return (
    <FormControl fullWidth variant="outlined" size={props.size}>
      <FastField {...props}>
        {({ field, form, meta }) => {
          const error = meta.touched && meta.error;
          const fieldValue = field.value || [];

          const onChange = event => {
            form.setFieldValue(field.name, event.target.value);
            if (props.onChange) props.onChange(event, form);
          };

          const handleDelete = rejectedItem => () =>
            form.setFieldValue(
              field.name,
              field.value.filter(item => item != rejectedItem)
            );

          const fetchLabel = selectedValue => {
            const selectedOption = optionsValues.find(([value, _]) => value === selectedValue);

            return selectedOption && selectedOption[1];
          };

          return (
            <>
              <InputLabel {...InputLabelProps} id={field.name}>
                {label}
              </InputLabel>

              <MuiSelect
                multiple
                label={label}
                renderValue={selected => selected.map(value => fetchLabel(value)).join(", ")}
                labelId={field.name}
                id={field.name}
                {...props}
                value={fieldValue}
                onChange={onChange}
              >
                {groupOptions && renderGroupOptions(groupOptions, fieldValue, classes)}
                {options && renderOptions(options, fieldValue, classes)}
              </MuiSelect>

              <div className={classes.tagsRoot}>
                {fieldValue.map((item, index) => (
                  <Chip key={index} label={fetchLabel(item)} onDelete={handleDelete(item)} />
                ))}
              </div>

              {error && (
                <FormHelperText htmlFor={field.name} error={!!error}>
                  {error}
                </FormHelperText>
              )}
            </>
          );
        }}
      </FastField>
    </FormControl>
  );
};

export default Select;

export const MultiEntitySelect = ({ suggestions = [], suggestionLabel = "name", ...rest }) => {
  const options = suggestions.map(suggestion => [suggestion.id, suggestion[suggestionLabel]]);

  return <Select options={options} {...rest} />;
};
