import React, {useRef} from "react";
import {Link as RouterLink} from "react-router-dom";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";

import useAuth from "../../../../hooks/useAuth";

export default function AssetLink({ id, entity, value }) {
  const ref = useRef();
  const { user } = useAuth();

  const handleClick = () => {
    const event = new MouseEvent("dblclick", {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    ref.current.parentElement.dispatchEvent(event);
  };

  if (user.company.settings.capture_mode === "mobile") {
    return (
      <Box
        ref={ref}
        underline="none"
        sx={{ cursor: "pointer" }}
        color="primary.main"
        className="unfocus"
        onClick={handleClick}
      >
        {value || ""}
      </Box>
    );
  }

  return (
    <Link
      underline="none"
      className="unfocus"
      id={`assetLink-${id}-${entity.id}`}
      to={`/assets/${entity.id}`}
      component={RouterLink}
    >
      {value || ""}
    </Link>
  );
}
