import React, {useCallback, useEffect, useState} from "react";
import {SnackbarProvider} from "notistack";
import Snackbar from "@material-ui/core/Snackbar";
import AlertTitle from "@material-ui/lab/AlertTitle";
import Alert from "@material-ui/lab/Alert";
import Box from "@mui/material/Box";

import {toString as errorsToString} from "../utils/parseErrors";

const NotificationContext = React.createContext(null);

const titles = { success: "Success", error: "Error" };
const InitOptions = { open: false, message: "", status: "" };

const defaultAutoHideDuration = 3000;

export const NotificationProvider = ({ children }) => {
  const [autoHideDuration, setAutoHideDuration] = useState(defaultAutoHideDuration);
  const [options, setOptions] = useState(InitOptions);

  const handleClose = useCallback(() => {
    setOptions(o => ({ ...InitOptions, status: o.status }));
  }, [setOptions]);

  const notifySuccess = message => {
    setOptions({ open: true, message, status: "success" });
  };

  const success = ({ message, hide }) => {
    setAutoHideDuration(hide);
    setOptions({ open: true, message, status: "success" });
    setTimeout(() => {
      setAutoHideDuration(defaultAutoHideDuration);
    }, hide);
  };

  const notifyError = error => {
    setOptions({ open: true, message: errorsToString(error), status: "error" });
  };

  useEffect(() => {
    if (options.open) {
      const interval = setTimeout(() => {
        handleClose();
      }, autoHideDuration);
      return () => {
        clearTimeout(interval);
      };
    }
  }, [autoHideDuration, options.open, handleClose]);

  return (
    <>
      <NotificationContext.Provider value={{ success, notifySuccess, notifyError }}>
        <SnackbarProvider>{children}</SnackbarProvider>
      </NotificationContext.Provider>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={options.open}
        autoHideDuration={autoHideDuration}
      >
        <Box minWidth={300}>
          <Alert variant="filled" onClose={handleClose} severity={options.status}>
            <AlertTitle>{titles[options.status]}</AlertTitle>
            {options.message}
          </Alert>
        </Box>
      </Snackbar>
    </>
  );
};

export default NotificationContext;
