import React from "react";
import CheckboxMui from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {makeStyles} from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import FormControl from "@material-ui/core/FormControl";
import Typography from "@material-ui/core/Typography";
import {FastField} from "formik";

const useStyles = makeStyles(theme => ({
  checkboxMui: {
    padding: theme.spacing(1),
  },
}));

const Checkbox = ({
  label,
  row,
  labelPlacement = "end",
  options,
  className,
  category,
  ...props
}) => {
  const classes = useStyles();

  const onChange = onChangeField => event => {
    if (props.onChange) {
      // Input characters specifi behaviour. It returns 'false' when it's checked, and 'true' when is unchecked
      const checked =
        event.target.value == "" ? true : event.target.value !== "true";
      props.onChange(props.name, checked);
    }

    onChangeField(event);
  };

  return (
    <FormControl fullWidth>
      <Box mb={0} clone>
        <FormControlLabel
          className={className}
          control={
            <FastField type="checkbox" {...props}>
              {({ field, form, meta }) => (
                <CheckboxMui
                  {...field}
                  {...props}
                  className={classes.checkboxMui}
                  onChange={onChange(field.onChange)}
                />
              )}
            </FastField>
          }
          label={
            <Typography color="textSecondary" variant="body2">
              {label}
            </Typography>
          }
        />
      </Box>
    </FormControl>
  );
};
export default React.memo(Checkbox);
