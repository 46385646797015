import React from "react";

export default function NotificationLink({ value: notification }) {
  return notification.link ? (
    <a target="_blank" href={notification.link}>
      {notification.title}
    </a>
  ) : (
    notification.title
  );
}
