import React from "react";
import {useFormikContext} from "formik";
import Skeleton from "@mui/material/Skeleton";
import {useQuery} from "@apollo/react-hooks";

import {GET_REQUESTORS_QUERY} from "../../../../constants/graphql/queries";
import {getRequestors} from "../../../../utils/cart";
import Select from "../../../../components/form/Select";

export default function() {
  const { values } = useFormikContext();
  const { data: { requestors = [] } = {}, loading } = useQuery(GET_REQUESTORS_QUERY);
  const requestorOptions = getRequestors(requestors, values.designer);

  return loading ? (
    <Skeleton variant="rounded" width="100%" height={40} />
  ) : (
    <Select
      size="small"
      type="text"
      name="designer"
      label="Requestor"
      placeholder="qwe"
      options={requestorOptions}
    />
  );
}
