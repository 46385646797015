import React, {useRef} from "react";
import Box from "@mui/material/Box";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@material-ui/core/Tooltip";

export default function DropdownView({ value }) {
  const ref = useRef();

  const handleClick = () => {
    const event = new MouseEvent("dblclick", {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    ref.current.parentElement.dispatchEvent(event);
  };

  return (
    <Box
      ref={ref}
      width="calc(100% + 32px)"
      height="calc(100% + 16px)"
      display="flex"
      p="8px 16px"
      m="-8px -16px"
      alignItems="center"
      justifyContent="space-between"
    >
      <Box>{value || ""}</Box>
      <IconButton onClick={handleClick}>
        <Tooltip title="Open">
          <ArrowDropDownIcon color="action" />
        </Tooltip>
      </IconButton>
    </Box>
  );
}
