import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
  DialogActions
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Close from "@material-ui/icons/Close";

const ArrowBack = () => "<";

const useStyles = makeStyles(theme => ({
  paperRoot: props => ({
    [theme.breakpoints.up("sm")]: {
      maxWidth: props.maxWidth
    }
  }),
  titleRoot: {
    padding: 0,
    backgroundColor: "white",
    boxShadow: "0 1px 2px 0 rgba(27, 37, 64, 0.12)",
    zIndex: "2"
  },
  contentRoot: {
    overflowY: "auto",
    padding: "0px",
    display: "flex",
    flexDirection: "column"
  },
  scrollbar: {
    padding: "12px",
    [theme.breakpoints.up("sm")]: {
      padding: "16px"
    }
  }
}));

const FlexModal = ({
  maxWidth,
  onBack,
  onClose,
  showClose,
  title,
  actions,
  id = "modal",
  bodyClassName,
  disableTransition,
  children,
  ...rest
}) => {
  // Declare style hook to use classes
  const classes = useStyles({ maxWidth });
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));

  return (
    <Dialog
      {...rest}
      maxWidth={false}
      onClose={() => {
        if (onClose) {
          onClose();
        }
      }}
      PaperProps={{ classes: { root: classes.paperRoot } }}
      fullScreen={fullScreen}
      classes={{
        paperFullScreen: classes.paperFullScreen,
        paperScrollPaper: "w-100",
        scrollPaper: "p-0"
      }}
      aria-labelledby={id}
      transitionDuration={disableTransition ? 0 : undefined}
    >
      <DialogTitle classes={{ root: classes.titleRoot }} id={id}>
        <Grid container alignItems="center" wrap="nowrap">
          <div style={{ minHeight: "64px" }} />
          <Grid item style={{ padding: "8px" }}>
            {onBack && (
              <IconButton onClick={() => onBack()}>
                <ArrowBack titleAccess="Back" />
              </IconButton>
            )}
          </Grid>
          <Grid item xs={12}>
            <Typography color="textSecondary" variant="h2" className={onBack ? "pl-3" : ""}>
              {title}
            </Typography>
          </Grid>
          <Grid item style={{ padding: "8px" }}>
            {showClose && onClose && (
              <IconButton onClick={() => onClose()}>
                <Close titleAccess="Close" />
              </IconButton>
            )}
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent classes={{ root: classes.contentRoot }}>
        <div className={`${classes.scrollbar} ${bodyClassName}`}>
          {children}
        </div>
      </DialogContent>
      {actions && (
        <DialogActions
          style={{
            padding: "12px",
            backgroundColor: "white",
            borderTop: "1px solid rgba(220, 224, 230, 0.32)"
          }}
          className="m-0 p-sm-3"
        >
          {actions}
        </DialogActions>
      )}
    </Dialog>
  );
};

FlexModal.defaultProps = {
  open: true,
  showClose: true,
  disableTransition: false
};

export default FlexModal;
