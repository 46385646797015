import React, {useRef} from "react";
import Box from "@mui/material/Box";

export default function LocationNameLink({ id, entity, value }) {
  const ref = useRef();

  const handleClick = () => {
    const event = new MouseEvent("dblclick", {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    ref.current.parentElement.dispatchEvent(event);
  };

  return (
    <Box
      ref={ref}
      underline="none"
      sx={{ cursor: "pointer" }}
      color="primary.main"
      className="unfocus"
      onClick={handleClick}
    >
      {value || ""}
    </Box>
  );
}
