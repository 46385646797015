import { useQuery } from "@apollo/react-hooks";
import { ALL_WAREHOUSE_LOCATIONS, WAREHOUSE_LOCATIONS_BY_CLIENT } from "../../constants/graphql/queries";

export default function useWarehouseLocations(clientId) {
  const { data: { client = {} } = {}, loading: loadingWarehouseLocationsByClient } = useQuery(
    WAREHOUSE_LOCATIONS_BY_CLIENT,
    {
      variables: { id: clientId },
    }
  );
  const warehousesByClient = client?.clientsWarehouses?.map(v => v.warehouse) || [];
  const warehouseLocationsByClient = warehousesByClient.flatMap(v =>
    v.warehouseLocations.map(item => ({
      ...item,
      warehouseId: v.warehouseId,
      warehouseName: v.name,
    }))
  );
  const loadAllWarehouseLocations =
    !loadingWarehouseLocationsByClient && warehousesByClient.length === 0;
  const { data: { warehouses = [] } = {}, loading: loadingWarehouseLocations } = useQuery(
    ALL_WAREHOUSE_LOCATIONS,
    {
      skip: !loadAllWarehouseLocations,
    }
  );
  const warehouseLocations = warehouses.flatMap(v =>
    v.warehouseLocations.map(item => ({
      ...item,
      warehouseId: v.warehouseId,
      warehouseName: v.name,
    }))
  );

  const loading = loadingWarehouseLocations || loadingWarehouseLocationsByClient;
  return {
    warehouseLocations: loadAllWarehouseLocations ? warehouseLocations : warehouseLocationsByClient,
    loading,
  };
}
