import moment from "moment";

export const serverFormatSeconds = "YYYY-MM-DD hh:mm:ss A";
export const serverFormat = "YYYY-MM-DD hh:mm A";
export const momentFormat = "MM/DD/YYYY hh:mm A";
export const dateFnsFormat = "MM/dd/yyyy hh:mm a";

export const strftime = (date) => date && moment(date).format("lll");

export const strfdate = (date) => date && moment(date).format("ll");

export const serverStrftime = (date) =>
  date ? moment(date, serverFormat).format("lll") : null;
