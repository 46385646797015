import React from "react";
import TextField from "@material-ui/core/TextField";

export default function RackLocationsInput({ disabled, rackLocations, toggleModal, onKeyDown }) {
  return (
    <TextField
      fullWidth
      multiline
      disabled={disabled}
      size="small"
      variant="outlined"
      label="Storage Location(s)"
      value={rackLocations.map(v => `${v.rack_location} (${v.sub_qty})`).join("\n")}
      onClick={toggleModal}
      onKeyDown={onKeyDown}
    />
  );
}
