import React, {useState} from "react";
import Button from "@material-ui/core/Button";
import Box from "@mui/material/Box";
import PopupState, {bindMenu, bindTrigger} from "material-ui-popup-state";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import CustomKit from "./CustomKit";
import {KitAssetsProvider} from "../../../contexts/kit-assets";

export default function AddToCartAction({ asset, notAvailable, addInventoryItem }) {
  const [open, setOpen] = useState();

  const handleToggle = () => {
    setOpen(v => !v);
  };

  if (asset.type === "Asset") {
    return (
      <Button size="small" variant="outlined" disabled={notAvailable} onClick={addInventoryItem}>
        Add to cart
      </Button>
    );
  }

  return (
    <Box display="flex" alignItems="center">
      <Button
        size="small"
        variant="outlined"
        disabled={notAvailable}
        onClick={addInventoryItem}
        style={{
          marginRight: "1px",
          height: 31,
          borderTopRightRadius: 0,
          borderBottomRightRadius: 0,
        }}
      >
        Add to cart
      </Button>
      <PopupState variant="popover" popupId="demo-popup-menu">
        {popupState => (
          <React.Fragment>
            <Button
              size="small"
              variant="outlined"
              {...bindTrigger(popupState)}
              style={{
                padding: 0,
                height: 31,
                minWidth: 30,
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
              }}
            >
              <KeyboardArrowDownIcon
                sx={{ transform: popupState.open ? undefined : "rotate(180deg)" }}
              />
            </Button>
            <Menu {...bindMenu(popupState)}>
              <MenuItem
                onClick={() => {
                  handleToggle();
                  popupState.close();
                }}
              >
                Custom Kit
              </MenuItem>
            </Menu>
          </React.Fragment>
        )}
      </PopupState>
      {open && (
        <KitAssetsProvider id={asset.id}>
          <CustomKit asset={asset} onClose={handleToggle} />
        </KitAssetsProvider>
      )}
    </Box>
  );
}
