import React, {createContext, useContext} from "react";
import uniq from "lodash/uniq";
import {useQuery} from "@apollo/react-hooks";
import {SUSTAINABILITY_SHEET_DATA_QUERY} from "src/constants/graphql/queries";

export const SustainabilitySheetContext = createContext(null);
export const SustainabilitySheetLoadingContext = createContext(null);

export function SustainabilitySheetProvider({ children }) {
  const { data, loading } = useQuery(SUSTAINABILITY_SHEET_DATA_QUERY);

  return (
    <SustainabilitySheetLoadingContext.Provider value={loading}>
      <SustainabilitySheetContext.Provider value={data?.sustainabilitySheetData || []}>
        {children}
      </SustainabilitySheetContext.Provider>
    </SustainabilitySheetLoadingContext.Provider>
  );
}

export function useSustainabilitySheetLoading() {
  return useContext(SustainabilitySheetLoadingContext);
}

export function useSustainabilitySheet() {
  return useContext(SustainabilitySheetContext);
}

export function useCategories() {
  const list = useSustainabilitySheet();
  return uniq(list.map(({ category }) => category));
}

export function useSubcategories(category) {
  const list = useSustainabilitySheet();
  if (category) {
    return list.filter(v => v.category === category).map(({ subcategory }) => subcategory);
  }
  return uniq(list.map(({ subcategory }) => subcategory));
}
