import React from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import RefreshIcon from "@mui/icons-material/Refresh";

import Views from "./Views";

export default function Toolkit({ view, refetch, changeView }) {
  return (
    <Box display="flex">
      <Views view={view} changeView={changeView} />
      <Box mr={1} />
      <Button style={{ textTransform: "none" }} size="small" onClick={refetch}>
        <RefreshIcon color="action" />
        <Box mr={1} />
        <Typography fontWeight={600} color="textSecondary" variant="body2">
          Refresh
        </Typography>
      </Button>
    </Box>
  );
}
