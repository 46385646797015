import React, {useCallback} from "react";
import {Field, Form, Formik} from "formik";
import {useHistory} from "react-router-dom";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import {useMutation} from "@apollo/react-hooks";

import ActionModal from "../../components/modals/action-modal";
import FilterFields from "./fields/FilterFields";
import useNotification from "../../hooks/notification";
import {GENERATE_PROJECT_REPORT_MUTATION} from "../../constants/graphql";

const FILTERS = "filters";
const reportGenerationNotification =
  "We started generating report. You'll receive notice when it's ready";

const GenerateReportModal = ({
  projectId,
  favoriteReportId,
  title,
  onClose,
  onBack,
  onSubmit
}) => {
  const history = useHistory();
  const { notifySuccess, notifyError } = useNotification();
  const [generateProjectReport] = useMutation(GENERATE_PROJECT_REPORT_MUTATION);

  const handleSubmit = useCallback(
    async filters => {
      let filtersObject = {};

      filters.map(({ type, value }) => {
        if (!filtersObject[type]) filtersObject[type] = [];

        filtersObject[type] = [value, ...filtersObject[type]];
      });

      try {
        generateProjectReport({
          variables: { projectId, favoriteReportId, filters: filtersObject }
        })
          .then(() => {
            notifySuccess(reportGenerationNotification);
            onSubmit();
          })
          .catch(() => {
            notifyError("Something went wrong");
          });
      } catch (e) {
        notifyError("Something went wrong");
      }
    },
    [favoriteReportId, projectId, notifySuccess, notifyError]
  );

  const handleEdit = () => {
    onClose();
    history.push(`/projects/${projectId}/templates/${favoriteReportId}`);
  };

  return (
    <ActionModal
      open
      id="project"
      title={title}
      maxWidth={440}
      onClose={onClose}
      onBack={onBack}
      disableTransition
    >
      <Formik initialValues={{ [FILTERS]: [] }}>
        <Form>
          <Box mt={2} />
          <FilterFields name={FILTERS} />
          <Box mt={1} />
          <Box mt={3} display="flex" justifyContent="space-between">
            <Field name={FILTERS}>
              {({ field }) => (
                <Button
                  onClick={() => handleSubmit(field.value)}
                  variant="contained"
                  color="primary"
                >
                  Generate
                </Button>
              )}
            </Field>
            {favoriteReportId && (
              <Button variant="outlined" onClick={handleEdit}>
                Edit Report
              </Button>
            )}
          </Box>
        </Form>
      </Formik>
    </ActionModal>
  );
};

export default GenerateReportModal;
