import React, { useState } from "react";

const FullScreenContext = React.createContext(null);

export const FullScreenProvider = ({ children }) => {
  const [fullScreen, setFullScreen] = useState(false);

  const value = {
    fullScreen,
    setFullScreen,
  };

  return (
    <FullScreenContext.Provider value={value}>
      {children}
    </FullScreenContext.Provider>
  );
};

export const useFullScreen = () => React.useContext(FullScreenContext);
