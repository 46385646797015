import React from "react";
import {useField} from "formik";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import PopupState, {bindMenu, bindTrigger} from "material-ui-popup-state";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";

import Input from "../../../components/form/Input";
import useGroupByFields from "./useGroupByFields";

const FilterFields = ({ name, disabled }) => {
  const fields = useGroupByFields();
  const [field, , helpers] = useField({ name });
  const list = field.value || [];
  const labels = Object.fromEntries(fields);

  return (
    <>
      <PopupState variant="popover" popupId="add-filter">
        {popupState => (
          <div>
            <Button fullWidth variant="contained" color="primary" {...bindTrigger(popupState)}>
              Add Field +
            </Button>
            <Menu
              {...bindMenu(popupState)}
              anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
              transformOrigin={{ vertical: "top", horizontal: "left" }}
            >
              {fields.map(([value, label]) => (
                <MenuItem
                  key={value}
                  onClick={() => {
                    helpers.setValue(list.concat({ type: value, value: "" }));
                    popupState.close();
                  }}
                >
                  {label}
                </MenuItem>
              ))}
            </Menu>
          </div>
        )}
      </PopupState>
      <Box mt={3} />
      {list.length > 0 ? (
        list.map((v, i) => {
          const label = labels[v.type];

          return (
            <React.Fragment key={i}>
              {i > 0 && <Box mt={1} />}
              <Box display="flex" alignItems="center">
                {disabled ? (
                  <Box
                    display="flex"
                    justifyContent="center"
                    flexGrow={1}
                    height="40px"
                    borderRadius="4px"
                    p="10.5px"
                    border="1px solid rgba(0, 0, 0, 0.26)"
                  >
                    {label}
                  </Box>
                ) : (
                  <Input name={`${name}.${i}.value`} label={label} />
                )}
                <IconButton
                  id={`delete-${name}-${i}-value`}
                  variant="outlined"
                  onClick={() => helpers.setValue(field.value.filter((v, index) => i !== index))}
                >
                  <DeleteIcon color="action" />
                </IconButton>
              </Box>
            </React.Fragment>
          );
        })
      ) : (
        <Typography align="center" color="textSecondary">
          Empty
        </Typography>
      )}
    </>
  );
};

export default FilterFields;
