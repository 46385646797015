import React, { useCallback } from "react";
import { useFormikContext } from "formik";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import PerfectScrollbar from "react-perfect-scrollbar";

import DroppableItem from "./DroppableItem";
import useMutation from "../../../hooks/useMutation";
import { REORDER_INVENTORY_ITEMS_MUTATION } from "../../../constants/graphql/mutations";

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const LOCATIONS = "LOCATIONS";
export const ASSETS = "ASSETS";

export default function CartItemsReorder({ items, setOrderedList, display, reFetch }) {
  const { setFieldValue } = useFormikContext();
  const [reorderInventoryItems] = useMutation(REORDER_INVENTORY_ITEMS_MUTATION);

  const onDragEnd = useCallback(
    result => {
      if (!result.destination) {
        return;
      }

      // const newItems = reorder(items, result.source.index, result.destination.index);
      // setOrderedList(newItems);

      if (result.type === LOCATIONS) {
        const newItems = reorder(items, result.source.index, result.destination.index);
        newItems.forEach(([location], index) => {
          setFieldValue(`locationOrder[${location.split(",")[0]}]`, index);
        });
        setOrderedList(newItems);
      } else if (result.type === ASSETS) {
        const index = parseInt(result.destination.droppableId, 10);
        const inventoryItems = items[index][1];

        reorderInventoryItems({
          variables: {
            id: inventoryItems[result.source.index].id,
            position: inventoryItems[result.destination.index].position,
          },
        }).then(({ data }) => {
          if (data.reorderInventoryItems.inventoryItems) {
            setFieldValue("inventoryItems", data.reorderInventoryItems.inventoryItems);
          }
        });
        const list = reorder(inventoryItems, result.source.index, result.destination.index);
        const newItems = [...items];
        newItems[index][1] = list;
        setOrderedList(newItems);
      }
    },
    [items, setOrderedList, reFetch]
  );

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable" type={LOCATIONS}>
        {provided => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            <PerfectScrollbar>
              {items.map((item, index) => (
                <Draggable key={item[0] || "empty"} draggableId={item[0] || "empty"} index={index}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <DroppableItem
                        isDragging={snapshot.isDragging}
                        item={item}
                        display={display}
                        index={index}
                      />
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </PerfectScrollbar>
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}
