import React from "react";
import { makeStyles } from "@material-ui/core";
import { useFormikContext } from "formik";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import FormFields from "./FormFields";
import { useAssets } from "src/contexts/assets";

const useStyles = makeStyles(() => ({
  stickToBottom: {
    width: "100%",
    position: "sticky",
    bottom: "10px",
    left: 0,
    zIndex: 99,
    padding: "0",
    backgroundColor: "white",
  },
}));

export default function FormPage({ currentAsset, shipmentItem, type, removing, toggleRemoveItem }) {
  const classes = useStyles();
  const { submitForm, isSubmitting, errors } = useFormikContext();
  const formProps = useFormikContext();
  const { loadingMutation, updateAsset, createImage } = useAssets();

  return (
    <form
      id="assetForm"
      className="d-flex flex-column flex-grow-1 justify-content-between justify-content-md-start"
      style={{ position: "relative" }}
    >
      <FormFields
        type={type}
        asset={currentAsset}
        loading={loadingMutation}
        updateAsset={updateAsset}
        createImage={createImage}
        setErrors={formProps.setErrors}
        errors={formProps.errors}
        shipmentItem={shipmentItem}
      />
      <Box mb={2} />
      <Container className={classes.stickToBottom} maxWidth={false}>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Button
              fullWidth
              variant="outlined"
              disabled={removing}
              startIcon={removing && <CircularProgress size={22} />}
              onClick={toggleRemoveItem}
            >
              Delete
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={submitForm}
              disabled={isSubmitting}
              startIcon={isSubmitting && <CircularProgress size={22} />}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </Container>
    </form>
  );
}
