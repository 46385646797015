import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/opacity.css";

var ImageComponentsCache = {};

const ImageComponent = ({ src, ...props }) => {
  return (
    <>
      {src ? (
        ImageComponentsCache[src] === true ? (
          <img src={src} {...props} />
        ) : (
          <LazyLoadImage
            afterLoad={() => (ImageComponentsCache[src] = true)}
            effect="opacity"
            src={src}
            {...props}
          />
        )
      ) : (
        "No image"
      )}
    </>
  );
};

export default ImageComponent;
