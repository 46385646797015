import React from "react";
import {
  destinationInfo,
  electricalInfo,
  getInventoryItems,
  getOrders,
  getshoppingCart,
  getWarehouseInfo,
  getInventoryRequest,
  mechanicalInfo,
  originInfo,
  plumbingInfo,
  reservations,
} from "../../constants/fields";
import IncludeCheckboxes from "./IncludeCheckboxes";
import useAllFields from "../../hooks/useAllFields";
import useAuth from "../../hooks/useAuth";

export default function IncludeFields({
  storageEnabled,
  originEnabled,
  destinationEnabled,
  ordersTableFieldsEnabled,
  reservationsTableFieldsEnabled,
  component,
  hideFields = [],
}) {
  const { user } = useAuth();
  const { settings } = user.company;
  const { identifiers, descriptiveInfo, otherInfo } = useAllFields();
  const inventoryItems = getInventoryItems(settings);

  return (
    <IncludeCheckboxes
      component={component}
      hideFields={hideFields}
      descriptiveInfo={descriptiveInfo}
      destinationInfo={destinationInfo}
      electricalInfo={electricalInfo}
      getOrders={getOrders}
      getWarehouseInfo={getWarehouseInfo}
      identifiers={identifiers}
      inventoryItems={inventoryItems}
      getInventoryRequest={getInventoryRequest}
      mechanicalInfo={mechanicalInfo}
      originInfo={originInfo}
      otherInfo={otherInfo}
      plumbingInfo={plumbingInfo}
      reservations={reservations}
      getshoppingCart={getshoppingCart}
      storageEnabled={storageEnabled}
      originEnabled={originEnabled}
      destinationEnabled={destinationEnabled}
      ordersTableFieldsEnabled={ordersTableFieldsEnabled}
      reservationsTableFieldsEnabled={reservationsTableFieldsEnabled}
    />
  );
}
