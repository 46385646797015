import React from "react";
import {Formik} from "formik";
import {useParams} from "react-router-dom";

import {fetchAssetValues} from "../../../../utils/asset";
import FormPage from "../../../../views/capture/FormPage";

export default function AssetForm({ asset }) {
  const { clientId } = useParams();
  const nav = window.location.pathname.split("/")[1];
  const displayClient = nav === "clients" && clientId === "null";
  const displayProject = nav === "clients";

  return (
    <Formik key={asset?.id} initialValues={fetchAssetValues(asset)} onSubmit={() => {}}>
      <FormPage displayClient={displayClient} displayProject={displayProject} asset={asset} />
    </Formik>
  );
}
