import React from "react";
import {useFormikContext} from "formik";
import Box from "@material-ui/core/Box";

import Input from "../../../../components/form/Input";
import Items from "./items";

export default function LocationNotes({ disabled, list }) {
  const { values } = useFormikContext();
  const locationScope = values.location?.split(",")[0] || "";

  return list.length === 0 ? (
    <Box borderRadius={3} p={2} border="1px solid #3f51b5">
      <Input
        disabled={disabled}
        type="text"
        name={`locationNotes[${locationScope}]`}
        label={`${locationScope} Scope`}
      />
    </Box>
  ) : (
    <Items disabled={disabled} list={list} />
  );
}
