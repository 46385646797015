import React from "react";
import { ButtonBase } from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";

const CloseCameraButton = ({ onClick }) => (
  <ButtonBase
    data-id="close-camera"
    onClick={onClick}
    style={{ position: "absolute", right: 15, top: 15, zIndex: 99 }}
  >
    <ClearIcon htmlColor="white" fontSize="large" />
  </ButtonBase>
);

export default CloseCameraButton;
