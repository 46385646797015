import React, {useState} from "react";
import LinearProgress from "@material-ui/core/LinearProgress";
import Box from "@mui/material/Box";

import ImageModal from "../../../components/images/ImageModal";
import useNotification from "../../../hooks/notification";
import {useKitsCollection} from "../../../contexts/kitsCollection";
import ImagesSlider from "../../../views/capture/Images/ImagesSlider";
import {sortImages} from "../../../utils/images";
import ImageDND from "./ImageDND";

export default function ImagesKit({ disabled, asset: defaultAsset }) {
  const { collection, updateImage, bulkCreateImages, removeImage } = useKitsCollection();
  const asset = collection.find(v => v.id === defaultAsset.id);

  const { notifyError } = useNotification();
  const [loading, setLoading] = useState(false);
  const list = sortImages(asset?.images);
  const [open, setOpen] = useState(null);
  const defaultIndex = list.findIndex(v => v.default);
  const [index, setIndex] = useState(defaultIndex === -1 ? 0 : defaultIndex);

  const handleOpen = () => {
    if (!disabled && list.length > 0) {
      setOpen(true);
    }
  };

  const handleBulkCreateImages = ({ variables, onSuccess, onFailure }) => {
    bulkCreateImages({
      variables: { ...variables, assetId: asset?.id },
      onSuccess,
      onFailure,
    });
  };

  const handleUpload = images => {
    setLoading(true);
    handleBulkCreateImages({
      variables: { assetId: asset.id, images },
      onSuccess: () => {
        setLoading(false);
      },
      onFailure: errors => {
        notifyError(errors);
        setLoading(false);
      },
    });
  };

  return (
    <>
      <Box position="relative" onClick={handleOpen}
        sx={{
          width: { xs: '90%', sm: '50%', md: '100%' },  // Adjusts image frame width based on screen size
          margin: '0 auto',
        }}
      >        
        {list.length === 0 && (
          <>
            {loading && <LinearProgress />}
            {!disabled && <ImageDND onDrop={handleUpload} />}
          </>
        )}
        <ImagesSlider list={list} index={index} onChangeIndex={setIndex} />
      </Box>
      {open && list[index] && (
        <ImageModal
          open
          closeOnSave={true}
          selectedId={list[index].id}
          asset={asset}
          images={list}
          onClose={() => setOpen(false)}
          onRemove={removeImage}
          onUpdate={updateImage}
          onCreate={handleBulkCreateImages}
        />
      )}
    </>
  );
}
