import React, {useEffect} from "react";
import PropTypes from "prop-types";
import {useHistory} from "react-router-dom";
import clsx from "clsx";
import {CssBaseline, makeStyles} from "@material-ui/core";

import {THEMES} from "../../constants";
import {FullScreenProvider, useFullScreen} from "../../contexts/fullScreen";
import SideBar from "./SideBar";

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: "flex",
    height: "100%",
    overflow: "hidden",
    width: "100%",
  },
  wrapper: {
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",
    paddingTop: 64,
    ...(theme.name === THEMES.LIGHT
      ? {
          backgroundColor: theme.palette.primary.main,
        }
      : {}),
    ...(theme.name === THEMES.ONE_DARK
      ? {
          backgroundColor: theme.palette.background.default,
        }
      : {}),

    "&.full": {
      paddingTop: 0,
    },
  },
  contentContainer: {
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",
    position: "relative",
    backgroundColor: theme.palette.background.dark,
  },
  content: {
    flex: "1 1 auto",
    height: "100%",
    overflow: "auto",
  },
}));

const ProjectLayout = ({ children, ...props }) => {
  const classes = useStyles();
  const { location } = useHistory();
  const { fullScreen, setFullScreen } = useFullScreen();

  useEffect(() => {
    setFullScreen(false);
  }, [location.pathname]);

  return (
    <div className={classes.root}>
      <CssBaseline />

      {!fullScreen && <SideBar />}

      <div
        className={clsx(classes.wrapper, {
          full: fullScreen,
        })}
      >
        <div className={classes.contentContainer}>
          <div id="content" className={classes.content}>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

ProjectLayout.propTypes = {
  children: PropTypes.node,
};

export default props => (
  <FullScreenProvider>
    <ProjectLayout {...props} />
  </FullScreenProvider>
);
