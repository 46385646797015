import React, { useEffect, useMemo } from "react";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { useField } from "formik";

import Checkbox from "src/components/form/Checkbox";
import labels from "src/constants/displayFields";
import DraggableTags from "../DraggableTags";
import { getDisplayFields } from "src/utils/asset";
import Divider from "@material-ui/core/Divider";

const Layer1Fields = () => {
  const [include, , includeHelpers] = useField("include");
  const [descriptiveInfo, , descriptiveInfoHelpers] = useField(
    "descriptive_info"
  );
  const [destinationInfo, , destinationInfoHelpers] = useField(
    "destination_info"
  );
  const [columnOrder, , columnOrderHelpers] = useField("column_order_packing.layer_2");
  const list = columnOrder.value || [];

  const fields = useMemo(
    () =>
      getDisplayFields({
        include: include.value,
        descriptive_info: descriptiveInfo.value,
        destination_info: destinationInfo.value
      }),
    [include.value, descriptiveInfo.value, destinationInfo.value]
  );

  useEffect(() => {
    const newFields = fields.filter(v => !list.includes(v));
    const deleteFields = list.filter(v => !fields.includes(v));

    if (newFields.length > 0) {
      columnOrderHelpers.setValue(list.concat(newFields));
    }

    if (deleteFields.length > 0) {
      columnOrderHelpers.setValue(list.filter(v => !deleteFields.includes(v)));
    }
  }, [fields]);

  const tags = list.map(id => ({ id, content: labels[id] }));

  const handleRemove = id => {
    if (include.value.hasOwnProperty(id)) {
      includeHelpers.setValue({ ...include.value, [id]: false });
    }
    if (descriptiveInfo.value.hasOwnProperty(id)) {
      descriptiveInfoHelpers.setValue({
        ...descriptiveInfo.value,
        [id]: false
      });
    }
    if (destinationInfo.value.hasOwnProperty(id)) {
      destinationInfoHelpers.setValue({
        ...destinationInfo.value,
        [id]: false
      });
    }
  };

  const handleChange = list => {
    columnOrderHelpers.setValue(list.map(v => v.id));
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Typography variant="body1" color="textSecondary">
          Layer 2
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {tags.length > 0 && (
          <DraggableTags
            tags={tags}
            onRemove={handleRemove}
            onChange={handleChange}
          />
        )}
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={2}>
            <Typography color="textSecondary">Images</Typography>
          </Grid>
          <Grid item xs={12} md={2}>
            <Typography color="textSecondary">Identifiers</Typography>
          </Grid>
          <Grid item xs={12} md={3}>
            <Typography color="textSecondary">Descriptive Info</Typography>
          </Grid>
          <Grid item xs={12} md={3} />
          <Grid item xs={12} md={2} />
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12} md={2}>
            <Checkbox name="include[photo]" label={labels.photo} />
            <Checkbox name="include[barcode]" label={labels.barcode} />
            <Checkbox
              name="include[qr_code_image]"
              label={labels.qr_code_image}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <Checkbox name="include[asset_id]" label={labels.asset_id} />
            <Checkbox
              name="include[barcode_number]"
              label={labels.barcode_number}
            />
            <Checkbox name="include[disposition]" label={labels.disposition} />
          </Grid>
          <Grid item xs={12} md={3}>
            <Box display="flex" flexDirection="column">
              <Checkbox
                name="descriptive_info.notes_descriptions"
                label={labels.description}
              />
              <Checkbox
                name="descriptive_info.description_comments"
                label={labels.description_comments}
              />
              <Checkbox
                name="descriptive_info.manufacturer"
                label={labels.manufacturer}
              />
              <Checkbox name="descriptive_info.color" label={labels.color} />
              <Checkbox name="descriptive_info.finish" label={labels.finishes} />
              <Checkbox
                name="descriptive_info.condition"
                label={labels.condition}
              />
              <Checkbox
                name="descriptive_info.dimensions"
                label={labels.dimensions}
              />
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Layer1Fields;
