import React, {useState} from "react";
import {Field} from "formik";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";

import DimensionsModal from "../../components/TablePro/fields/DimensionsField";
import labels from "../../constants/displayFields";

export default function DimensionsField(props) {
  const [open, setOpen] = useState(false);

  const handleToggle = () => {
    if (props.disabled) return;

    setOpen(v => !v);
  };

  return (
    <Field {...props}>
      {({ field, form, meta }) => {
        const values = field.value || [];
        const errorMessage = meta.error || form.errors[field.name];
        const error = (meta.touched || form.submitCount > 0) && errorMessage;

        return (
          <>
            <FormControl fullWidth>
              <TextField
                size="small"
                disabled={props.disabled}
                autoComplete="off"
                label={'Dimensions (W" x D" x H")'}
                value={values
                  .map(field => [field.width, field.depth, field.height].join("x"))
                  .join(", ")}
                variant="outlined"
                margin="none"
                error={!!error}
                helperText={error}
                inputProps={{ onClick: handleToggle }}
                onKeyDown={e => {
                  if (e.key === "Enter") {
                    handleToggle();
                  }
                }}
              />
            </FormControl>
            {open && (
              <DimensionsModal
                field={{ label: labels.dimensions }}
                onSubmit={v => form.setFieldValue(field.name, v)}
                value={values}
                nextRow={undefined}
                onClose={handleToggle}
              />
            )}
          </>
        );
      }}
    </Field>
  );
}
