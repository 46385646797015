import React from "react";
import InputAdornment from "@mui/material/InputAdornment";
import Input from "../../../components/form/Input";

export default function CurrencyInput(props) {
  return (
    <Input
      type="number"
      step="0.01"
      InputProps={{
        startAdornment: (
          <InputAdornment sx={{ mr: "3px" }} position="start">
            $
          </InputAdornment>
        ),
      }}
      {...props}
    />
  );
}
