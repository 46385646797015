import React from "react";
import Paper from "@material-ui/core/Paper";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Input from "src/components/form/Input";
import useAuth from "src/hooks/useAuth";

export default function KeyDetails() {
  const { user } = useAuth();
  const { settings } = user.company;
  return (
    <Paper>
      <Box p={2}>
        <Typography fontWeight={500}>Key Details</Typography>
        <Grid container spacing={1} sx={{ pt: 1.5 }}>
          <Grid item xs={12} sm={4}>
            <Input disabled name="clientName" label={`${settings.filter_field1} Name`} />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Input name="name" label={`${settings.filter_field2} Name`} />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Input name="customerOwner" label={`${settings.filter_field2} Owner`} />
          </Grid>
          <Grid item xs={12}>
            <Input type="text" name="customerNotes" label={`${settings.filter_field2} Notes`} />
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
}
