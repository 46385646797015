import React from "react";
import { Button, Box } from "@material-ui/core";

const BigButton = (props) => (
  <Box display="flex" flexGrow={1} clone>
    <Button
      style={{
        fontSize: 22,
        width: "100%",
        height: props.height || 200,
      }}
      {...props}
    />
  </Box>
);

BigButton.defaultProps = {
  color: "primary",
  variant: "contained",
};

export default BigButton;
