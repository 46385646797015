import React, { useState } from "react";
import { useQuery } from "@apollo/react-hooks";
import useNotification from "src/hooks/notification";
import useMutation from "src/hooks/useMutation";
import { CLIENTS_QUERY, CREATE_PROJECT_MUTATION } from "src/constants/graphql";
import ClientModal from "src/components/modals/ProjectActions/ClientModal";
import ProjectModal from "src/components/modals/ProjectActions/ProjectModal";
import useAuth from "src/hooks/useAuth";

const ProjectActionsContext = React.createContext(null);

export const ProjectActionsProvider = ({ children, onSuccessProject, onSuccessClient }) => {
  const { user, setUser } = useAuth();
  const [openClient, setOpenClient] = useState(false);
  const [openProject, setOpenProject] = useState(false);
  const { notifySuccess } = useNotification();
  const { data: { clients = [] } = {} } = useQuery(CLIENTS_QUERY);
  const { settings } = user.company;
  const [createProject] = useMutation(CREATE_PROJECT_MUTATION, {
    update(
      cache,
      {
        data: {
          createProject: { client, project },
        },
      }
    ) {
      const data = cache.readQuery({
        query: CLIENTS_QUERY,
      });
      const isExisting = data.clients.find(c => c.id === client.id);

      if (isExisting) {
        setUser({
          ...user,
          projectIds: user.projectIds.concat(project.id),
        });
        cache.writeQuery({
          query: CLIENTS_QUERY,
          data: {
            clients: data.clients.map(v =>
              v.id === client.id
                ? {
                    ...client,
                    projects: v.client.projects.concat(client),
                  }
                : client
            ),
          },
        });
      } else {
        setUser({
          ...user,
          clientIds: user.clientIds.concat(client.id),
          projectIds: user.projectIds.concat(project.id),
        });
        cache.writeQuery({
          query: CLIENTS_QUERY,
          data: { clients: [...data.clients, { ...client, projects: [project] }] },
        });
      }
    },
  });

  const initialValues = {
    street: "",
    city: "",
    zipCode: "",
    storageEnabled: !!settings.receiving_wizard,
  };

  const handleToggleClient = () => setOpenClient(!openClient);
  const handleToggleProject = () => setOpenProject(!openProject);

  const handleSubmitClient = data => {
    return createProject({
      ...data,
      onSuccess: responseData => {
        notifySuccess(
          `${settings.filter_field1} and ${settings.filter_field2} have been successfully created!`
        );
        if (data.onSuccess) {
          data.onSuccess(responseData);
        }
        if (onSuccessClient) {
          onSuccessClient();
        }
      },
    });
  };

  const handleSubmitProject = data => {
    return createProject({
      ...data,
      onSuccess: responseData => {
        notifySuccess(`${settings.filter_field2} has been successfully created!`);
        if (data.onSuccess) {
          data.onSuccess(responseData);
        }
        if (onSuccessProject) {
          onSuccessProject();
        }
      },
    });
  };

  return (
    <>
      <ProjectActionsContext.Provider value={{ handleToggleClient, handleToggleProject }}>
        {children}
      </ProjectActionsContext.Provider>
      {openClient && (
        <ClientModal
          initialValues={initialValues}
          onClose={handleToggleClient}
          onSubmit={handleSubmitClient}
        />
      )}
      {openProject && (
        <ProjectModal
          clients={clients}
          initialValues={initialValues}
          onClose={handleToggleProject}
          onSubmit={handleSubmitProject}
        />
      )}
    </>
  );
};

export const useProjectActions = () => React.useContext(ProjectActionsContext);
