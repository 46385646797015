import React, {useEffect, useState} from "react";

const CaptureContext = React.createContext(null);

export const CaptureProvider = ({ children }) => {
  const initialOrder = localStorage.getItem("_order");
  const [order, setOrder] = useState(initialOrder || "item_id.desc");

  useEffect(() => {
    localStorage.setItem("_order", order);
  }, [order]);

  return <CaptureContext.Provider value={{ order, setOrder }}>{children}</CaptureContext.Provider>;
};

export const useCapture = () => React.useContext(CaptureContext);
