import React, {useCallback} from "react";
import {useFormikContext} from "formik";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";

import {REORDER_INVENTORY_ITEMS_MUTATION} from "../../../../constants/graphql/mutations";
import useMutation from "../../../../hooks/useMutation";
import {useShopping} from "../../../../contexts/shopping";
import {useCustomFields} from "../../../../contexts/customFields";
import DroppableItem from "./DroppableItem";

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export default function LocationsReorder({ items, setItems }) {
  const [display] = useCustomFields();
  const { setFieldValue } = useFormikContext();
  const { refetch } = useShopping();
  const [reorderInventoryItems] = useMutation(REORDER_INVENTORY_ITEMS_MUTATION);

  const reorderItems = useCallback(
    (id, position) => {
      reorderInventoryItems({
        variables: {
          id,
          position,
        },
      }).then(() => {
        if (refetch) {
          refetch();
        }
      });
    },
    [reorderInventoryItems, refetch]
  );

  const onDragEnd = useCallback(
    result => {
      // dropped outside the list
      if (!result.destination) {
        return;
      }

      if (result.type === "LOCATIONS") {
        const newItems = reorder(items, result.source.index, result.destination.index);
        newItems.forEach(([location], index) => {
          setFieldValue(`locationOrder[${location.split(",")[0]}]`, index);
        });
        setItems(newItems);
      } else {
        const inventoryItems = items[parseInt(result.type, 10)][1];
        reorderItems(
          inventoryItems[result.source.index].id,
          inventoryItems[result.destination.index].index + 1
        );
        const list = reorder(inventoryItems, result.source.index, result.destination.index);
        const newItems = [...items];
        newItems[result.type][1] = list;
        setItems(newItems);
      }
    },
    [items, setItems]
  );

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable" type="LOCATIONS">
        {provided => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {items.map((item, index) => (
              <Draggable key={item[0]} draggableId={item[0]} index={index}>
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                  >
                    <DroppableItem
                      index={index}
                      isDragging={snapshot.isDragging}
                      item={item}
                      display={display}
                    />
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}
