import {useSubscription} from "@apollo/react-hooks";

import {NEW_MESSAGE_SUBSCRIPTION} from "../constants/graphql";

export default function useAssetsImport(userId, callback) {
  return useSubscription(NEW_MESSAGE_SUBSCRIPTION, {
    variables: { userId },
    onSubscriptionData: ({ subscriptionData }) => {
      if (subscriptionData?.data?.newMessage?.text === "Assets Successfully Imported") {
        callback();
      }
    },
  });
}
