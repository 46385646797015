import React, {memo} from "react";
import {makeStyles} from "@material-ui/core";
import isEqual from "lodash/isEqual";
import clsx from "clsx";

import {MIN_HEIGHT} from "../TableHeader";
import {inFillDownAreaRow} from "../../../components/TablePro/extensions/useFillDownCells";
import TableCell, {isEqualCell, TableCellStub} from "./TableCell";

const useStyles = makeStyles(theme => ({
  tableRow: {
    display: "flex",
    backgroundColor: theme.palette.common.white,
  },
  tableRowOdd: {
    backgroundColor: theme.palette.grey[50],
  },
}));

export const isEqualRow = (prevProps, nextProps) => {
  const prev = prevProps.data;
  const next = nextProps.data;
  const rowIndex = nextProps.index;

  // Table fields
  if (prev.fullScreen !== next.fullScreen) {
    return false;
  }

  // Table fields
  if (prev.fields !== next.fields) {
    return false;
  }

  // Entity attributes

  if (
    !isEqual(
      Object.values(prev.entities[rowIndex] || {}).sort(),
      Object.values(next.entities[rowIndex] || {}).sort()
    )
  ) {
    return false;
  }

  // useEditableCells

  if (
    (rowIndex === prev.editCoords[0]) !== (rowIndex === next.editCoords[0]) ||
    rowIndex === next.editCoords[0]
  ) {
    return false;
  }

  if (
    (rowIndex === prev.rootCoords[0]) !== (rowIndex === next.rootCoords[0]) ||
    rowIndex === next.rootCoords[0]
  ) {
    return false;
  }

  if (
    inFillDownAreaRow(rowIndex, prev.rootCoords, prev.endCoords) !==
      inFillDownAreaRow(rowIndex, next.rootCoords, next.endCoords) ||
    inFillDownAreaRow(rowIndex, next.rootCoords, next.endCoords)
  ) {
    return false;
  }

  if (
    inFillDownAreaRow(rowIndex, prev.copyDiapason[0], prev.copyDiapason[1]) !==
    inFillDownAreaRow(rowIndex, next.copyDiapason[0], next.copyDiapason[1])
  ) {
    return false;
  }

  if (prev.fillDownMode !== next.fillDownMode) {
    return false;
  }

  return true;
};

const TableCellMemo = memo(TableCell, isEqualCell);

const TableRow = ({
  size,
  style,
  rowIndex,
  entities,
  fields,
  fieldsProps = {},
  onChange,
  fieldsWidth,

  fillDownElementProps,
  fillDownCellProps,
  fillDownMode,

  nextRow,
  prevRow,
  nextCell,
  prevCell,

  editCoords,
  rootCoords,
  endCoords,
  setEditCoords,
  setEndCoords,
  setSelectedCoords,
  copyDiapason,
  getSelectedEntities,
}) => {
  const classes = useStyles();
  const entity = entities[rowIndex];

  return (
    <div
      className={clsx(classes.tableRow, {
        [classes.tableRowOdd]: rowIndex % 2 === 0,
      })}
      style={{
        ...style,
        width: fieldsWidth,
        top: `${parseFloat(style.top) + MIN_HEIGHT}px`,
      }}
    >
      {fields.map((field, columnIndex) => {
        const showField = field.visible && !field.inactive;
        const loading = !entity;
        const cellKey = `entityCell--${field.id}-${rowIndex}-${columnIndex}`;
        const renderField = { ...field, ...fieldsProps[field.id] };

        if (!showField) return null;

        if (loading) {
          return (
            <TableCellStub
              key={cellKey}
              className={clsx(classes.cellContainer)}
              field={field}
              fields={fields}
            />
          );
        }

        return (
          <TableCellMemo
            key={cellKey}
            size={size}
            rowIndex={rowIndex}
            columnIndex={columnIndex}
            entity={entity}
            fields={fields}
            field={renderField}
            onChange={onChange}
            fillDownElementProps={fillDownElementProps}
            fillDownCellProps={fillDownCellProps}
            editCoords={editCoords}
            rootCoords={rootCoords}
            endCoords={endCoords}
            setEditCoords={setEditCoords}
            setEndCoords={setEndCoords}
            setSelectedCoords={setSelectedCoords}
            fillDownMode={fillDownMode}
            copyDiapason={copyDiapason}
            nextRow={nextRow}
            prevRow={prevRow}
            nextCell={nextCell}
            prevCell={prevCell}
            getSelectedEntities={getSelectedEntities}
          />
        );
      })}
    </div>
  );
};

export default TableRow;
