import React, {useState} from "react";

import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";

import {BULK_CREATE_ASSET_IMAGES_MUTATION} from "../../../../constants/graphql";
import useMutation from "../../../../hooks/useMutation";
import useNotification from "../../../../hooks/notification";
import {useOnEnterEffect} from "../../../../utils/keyEffects";

export default function FileField({ id, innerRef, onChange, selected, entity }) {
  const [loading, setLoading] = useState(false);
  const [addImageMutation] = useMutation(BULK_CREATE_ASSET_IMAGES_MUTATION);
  const { notifySuccess, notifyError } = useNotification();

  const onUpload = event => {
    const files = event.target.files;
    setLoading(true);
    addImageMutation({
      variables: { assetId: entity.id, images: files },
      onSuccess: ({ asset, kit }) => {
        setLoading(false);
        notifySuccess("Images successfully uploaded!");
        onChange(entity.id, { photo: asset.photo, images: asset.images }, { skipMutation: true });
        event.target.value = "";
      },
      onFailure: errors => {
        notifyError(errors.submit);
        setLoading(false);
      },
    });
  };

  const focusUploader = () => {
    innerRef.current?.click();
  };

  useOnEnterEffect(innerRef, selected ? focusUploader : null);

  return (
    <form style={{ width: "100%", height: "100%" }}>
      <Box display="none" clone>
        <input
          id={`contained-button-file-${id}-${entity.id}`}
          disabled={loading}
          style={{ resize: "none" }}
          type="file"
          multiple={true}
          accept="image/*"
          onChange={event => onUpload(event)}
        />
      </Box>
      <Box display="flex" alignItems="center" width="100%" height="100%">
        {loading ? (
          <CircularProgress size={26} />
        ) : (
          <label
            ref={innerRef}
            style={{ margin: 0 }}
            htmlFor={`contained-button-file-${id}-${entity.id}`}
          >
            <IconButton onClick={focusUploader} disabled={loading}>
              <CloudUploadOutlinedIcon color="action" />
            </IconButton>
          </label>
        )}
      </Box>
    </form>
  );
}
