import React from "react";
import upperFirst from "lodash/upperFirst";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";

const EntitySelect = ({
  label,
  name = "",
  value,
  onChange,
  suggestions = [],
  disabled = false,
}) => {
  const findEnitity = id => suggestions.find(e => e.id === id);
  const selected = suggestions.find(v => v.id === value)?.id || "";

  return (
    <FormControl variant="outlined" size="small" fullWidth>
      <InputLabel id={`EnitySelect-${name}`}>{label}</InputLabel>
      <Select
        label={label}
        disabled={disabled}
        name={name}
        data-id={`select${upperFirst(name)}`}
        labelId="EnitySelect"
        value={selected}
        onChange={v => onChange(findEnitity(v.target.value))}
      >
        {suggestions.map(item => (
          <MenuItem key={item.id} value={item.id} data-id="select-item">
            {item.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default EntitySelect;
