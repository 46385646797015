import React from "react";
import {Field, FieldArray} from "formik";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import AddIcon from "@mui/icons-material/Add";
import TextField from "@mui/material/TextField";

import AssetQuantityField from "../../../views/inventory/requests/AssetQuantityField";
import useAuth from "../../../hooks/useAuth";
import StorageLocationAutocomplete
  from "../../../views/capture/RackLocations/StorageLocationAutocomplete/StorageLocationAutocomplete";
import Input from "../../form/Input";

export default function RackLocations({
  locations,
  warehouseLocations,
  loadingWarehouseLocations,
}) {
  const { user } = useAuth();
  const isMobile = useMediaQuery(theme => theme.breakpoints.down("sm"));

  return (
    <Grid container spacing={2}>
      <FieldArray
        name="locations"
        render={arrayHelpers => (
          <>
            {locations.length > 0 &&
              locations.map((location, index) => {
                const number = index + 1;
                return (
                  <React.Fragment key={index}>
                    <Grid item xs={6}>
                      {user.company.settings.warehouse_restock_modal ? (
                        <StorageLocationAutocomplete
                          key={!location.new ? "disabled" : "enabled"}
                          index={index}
                          disabled={!location.new}
                          name="locations"
                          value={arrayHelpers.form.values.locations[index]}
                          loading={loadingWarehouseLocations}
                          warehouseLocations={warehouseLocations}
                          setFieldValue={arrayHelpers.form.setFieldValue}
                        />
                      ) : (
                        <Input
                          key={!location.new ? "disabled" : "enabled"}
                          disabled={!location.new}
                          name={`locations.${index}.rack_location[0]`}
                          label={`Storage Location ${number}`}
                          InputLabelProps={{ shrink: true }}
                        />
                      )}
                    </Grid>

                    {location.sub_qty !== 0 && (
                      <Grid item xs={2} sx={{ display: "flex", alignItems: "center" }}>
                        <Input
                          key={isMobile}
                          disabled
                          name={`locations.${index}.sub_qty`}
                          label={`Current QTY`}
                          InputLabelProps={
                            isMobile
                              ? {
                                  shrink: true,
                                  style: {
                                    whiteSpace: "nowrap",
                                    fontSize: 12,
                                    color: "#546e7a",
                                  },
                                }
                              : { shrink: true }
                          }
                        />
                      </Grid>
                    )}

                    <Grid item xs={4} sx={{ display: "flex", alignItems: "center" }}>
                      <Field
                        name={`locations.${index}.restock_sub_qty`}
                        label={`Restock Sub QTY ${number}`}
                      >
                        {({ field, form }) => {
                          return isMobile ? (
                            <Box display="flex">
                              <TextField
                                size="small"
                                label="Received QTY"
                                value={field.value}
                                InputLabelProps={{ shrink: true, style: { fontSize: 12 } }}
                                onChange={e =>
                                  form.setFieldValue(field.name, parseInt(e.target.value || 0, 10))
                                }
                              />
                              <Button
                                sx={{ minWidth: 20 }}
                                variant="contained"
                                color="primary"
                                type="button"
                                onClick={() =>
                                  form.setFieldValue(field.name, (field.value || 0) + 1)
                                }
                              >
                                <AddIcon fontSize="medium" />
                              </Button>
                            </Box>
                          ) : (
                            <AssetQuantityField
                              size="small"
                              quantity={field.value}
                              onChange={quantity => form.setFieldValue(field.name, quantity || 0)}
                              quantityProps={{
                                label: "QTY Received",
                                style: { minWidth: 110 },
                                InputLabelProps: {
                                  shrink: true,
                                },
                              }}
                            />
                          );
                        }}
                      </Field>
                    </Grid>
                  </React.Fragment>
                );
              })}

            <Grid item xs={12}>
              <Button
                variant="outlined"
                color="primary"
                type="button"
                onClick={() => arrayHelpers.push({ new: true, sub_qty: 0 })}
              >
                Add Another Location
              </Button>
            </Grid>
          </>
        )}
      />
    </Grid>
  );
}
