import React, {useState} from "react";
import {useQuery} from "@apollo/react-hooks";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import TextField from "@mui/material/TextField";

import labels from "../../constants/displayFields";
import {ASSET_ATTACHMENTS_QUERY} from "../../constants/graphql";
import Attachments from "./Attachments";

export default function FieldsAttachments({ asset, disabled }) {
  const [open, setOpen] = useState(false);
  const { loading, data: { assetAttachments = [] } = {}, refetch } = useQuery(
    ASSET_ATTACHMENTS_QUERY,
    {
      variables: { assetId: asset.id },
      fetchPolicy: "no-cache",
    }
  );

  const handleToggle = () => {
    if (disabled) return;
    setOpen(v => !v);
  };

  return (
    <>
      <TextField
        fullWidth
        size="small"
        value={assetAttachments.map(v => v.fileName || "No name").join("\n")}
        multiline
        rows={4}
        disabled={disabled}
        onClick={handleToggle}
        label={labels.attachments}
        onKeyDown={e => {
          if (e.key === "Enter") {
            handleToggle();
          }
        }}
      />
      {open && (
        <Dialog open fullWidth maxWidth="md" onClose={handleToggle}>
          <DialogTitle>
            <Box display="flex" alignItems="center" justifyContent="space-between">
              <Typography fontSize={22} color="textPrimary" component="span">
                Attachments
              </Typography>
              <IconButton onClick={handleToggle}>
                <CloseIcon />
              </IconButton>
            </Box>
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <Attachments
                asset={asset}
                loading={loading}
                assetAttachments={assetAttachments}
                onAdd={() => refetch()}
                onDelete={() => refetch()}
              />
            </DialogContentText>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
}
