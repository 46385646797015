import React from "react";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import FormGroup from "@material-ui/core/FormGroup";
import Checkbox from "./Checkbox";

const CheckboxGroup = ({ label, options }) => {
  return (
    <FormControl component="fieldset">
      <FormLabel component="legend">{label}</FormLabel>
      <FormGroup>
        {options.map(option => (
          <Checkbox key={option.value} {...option} />
        ))}
      </FormGroup>
    </FormControl>
  );
};

export default React.memo(CheckboxGroup);
