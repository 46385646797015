import useAuth from "./useAuth";
import { isARMSTRONG, isATLAS, isPNC } from "../utils/inventoryRequests";
import { destinationInfo, electricalInfo, mechanicalInfo, originInfo, plumbingInfo } from "../constants/fields";
import useAllFields from "./useAllFields";
import { useClients } from "src/contexts/clients";

const defaultSetting =
  "identifiers,descriptive_info,origin_info,destination_info,warehouse_info,electrical_info,plumbing_info,mechanical_info,other_info,inventory_items,inventory_request,shopping_cart";

export default function useFields() {
  const { user } = useAuth();
  const { currentProject } = useClients();
  const { company } = user;
  const { settings } = user.company;
  const columns = (company.settingsConfig || defaultSetting).split(",");
  const settingsConfig =
    isPNC || isATLAS || isARMSTRONG
      ? columns.filter(v => !["plumbing_info", "mechanical_info", "other_info"].includes(v))
      : columns;
  const { identifiers, warehouseInfo, descriptiveInfo, otherInfo } = useAllFields();
  const storageEnabled = currentProject?.storageEnabled;

  const fields = [
    {
      enabled: settingsConfig.includes("identifiers"),
      label: "Identifiers",
      list: identifiers,
    },
    {
      enabled: settingsConfig.includes("descriptive_info"),
      label: "Descriptive",
      list: descriptiveInfo,
    },
    {
      enabled:
        settingsConfig.includes("warehouse_info") && settings.inventory_tracking && storageEnabled,
      label: "Storage",
      list: warehouseInfo,
    },
    {
      enabled: settingsConfig.includes("origin_info") && !storageEnabled,
      label: "Origin",
      list: originInfo,
    },
    {
      enabled: settingsConfig.includes("destination_info") && !storageEnabled,
      label: "Destination",
      list: destinationInfo,
    },
    {
      enabled: settingsConfig.includes("electrical_info") && settings.medical_fields,
      label: "Electrical",
      list: electricalInfo,
    },
    {
      enabled: settingsConfig.includes("plumbing_info") && settings.medical_fields,
      label: "Plumbing",
      list: plumbingInfo,
    },
    {
      enabled: settingsConfig.includes("mechanical_info") && settings.medical_fields,
      label: "Mechanical",
      list: mechanicalInfo,
    },
    {
      enabled: settingsConfig.includes("other_info"),
      label: "Miscellaneous",
      list: otherInfo,
    },
  ];

  return fields.filter(v => v.enabled);
}
