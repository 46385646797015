export default function getDashboardLink() {
  const selectedPage = localStorage.getItem("selectedPage") || "capture-inventory";
  const clientId = localStorage.getItem("clientId");
  const projectId = localStorage.getItem("projectId");

  if (!clientId) {
    return `/dashboard`;
  } else if (projectId === "all") {
    return `/clients/${clientId}/${selectedPage}`;
  } else if (projectId) {
    return `/projects/${projectId}/${selectedPage}`;
  }
}
