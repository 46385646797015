import React from "react";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";

import {useCustomFields} from "../../../../contexts/customFields";
import {useShopping} from "../../../../contexts/shopping";
import Input from "../../../../components/form/Input";
import CartItemFields from "../CartItemFields";
import CartItemsHeader from "../CartItemsHeader";

export default function Items({ disabled, list }) {
  const [display] = useCustomFields();
  const { inventoryAssets } = useShopping();

  return list.map(([value, list], index) => (
    <Box mt={index === 0 ? 0 : 3} borderRadius={3} p={2} border="1px solid #3f51b5">
      <Input
        disabled={disabled}
        type="text"
        name={`locationNotes[${value.split(",")[0]}]`}
        label={`${value.split(",")[0]} Scope`}
      />
      <Box mt={2} />
      <Paper>
        <Table>
          <CartItemsHeader display={display} />
          <TableBody>
            {list.map(item => {
              const asset = inventoryAssets[item.assetId];
              const price = asset.price * item.quantity;
              return (
                <CartItemFields
                  key={item.id}
                  disabledQty
                  disabledAddress
                  disabled={disabled}
                  price={price}
                  display={display}
                  asset={asset}
                  inventoryItem={item}
                />
              );
            })}
          </TableBody>
        </Table>
      </Paper>
    </Box>
  ));
}
