import React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import dispositions from "src/constants/dispositions";

export default function DispositionAutocomplete({ value, onChange }) {
  return (
    <Autocomplete
      value={value}
      onChange={(e, value) => {
        onChange(value);
      }}
      onBlur={e => {
        if (e.target.value !== value) {
          onChange(e.target.value);
        }
      }}
      size="small"
      freeSolo
      filterOptions={(options, state) => options}
      options={dispositions}
      renderInput={params => <TextField {...params} label="Dispositions" />}
    />
  );
}
