import groupBy from "lodash/groupBy";
import {fabric} from "src/packs/fabric";

export const size = 13;

export function addFrame(editor, { number, left, top, height }) {
  const rect = new fabric.Rect({
    left: left - 1,
    top: top,
    stroke: "#f3ea06",
    fill: "transparent",
    width: size * 2 + 2,
    height: height,
  });
  editor.add(rect);

  const topLeft = new fabric.Rect({
    left: left - 2,
    top: top - 2,
    stroke: "black",
    width: 4,
    height: 1,
  });
  editor.add(topLeft);
  const topRight = new fabric.Rect({
    left: left + size * 2 - 2,
    top: top - 2,
    stroke: "black",
    width: 4,
    height: 1,
  });
  editor.add(topRight);
  const bottomLeft = new fabric.Rect({
    left: left - 2,
    top: top + height + 1,
    stroke: "black",
    width: 4,
    height: 1,
  });
  editor.add(bottomLeft);
  const bottomRight = new fabric.Rect({
    left: left + size * 2 - 2,
    top: top + height + 1,
    stroke: "black",
    width: 4,
    height: 1,
  });
  editor.add(bottomRight);

  const width = size * 2 + 3;
  const leftPX = left - 1;
  const topLine1 = new fabric.Line([leftPX, top - 1, leftPX + width, top - 1], {
    stroke: "#3f3e3e",
    strokeWidth: 0.3,
  });
  editor.add(topLine1);
  const topLine2 = new fabric.Line([leftPX, top - 0.5, leftPX + width, top - 0.5], {
    stroke: "#3f3e3e",
    strokeWidth: 0.3,
  });
  editor.add(topLine2);
  const bottomLine1 = new fabric.Line(
    [leftPX, top + height + 1.25, leftPX + width, top + height + 1.25],
    {
      stroke: "#3f3e3e",
      strokeWidth: 0.3,
    }
  );
  editor.add(bottomLine1);
  const bottomLine2 = new fabric.Line(
    [leftPX, top + height + 1.75, leftPX + width, top + height + 1.75],
    {
      stroke: "#3f3e3e",
      strokeWidth: 0.3,
    }
  );
  editor.add(bottomLine2);
  const text = new fabric.Text(`${number}`, {
    fontSize: 14,
    left: number > 9 ? left - size - 7 : left - size,
    top: top + (height / 2 - 10),
  });
  editor.add(text);
}

export function formatLocations(warehouseLocations) {
  return Object.entries(groupBy(warehouseLocations, v => v.aisle))
    .map(([label, items]) => [
      label,
      Object.entries(groupBy(items, v => v.shelf)).map(v =>
        Object.entries(groupBy(v[1], v => v.rack)).map(v => v[1])
      ),
    ])
    .map(v => ({ name: v[0], list: v[1] }));
}