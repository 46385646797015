import React from "react";
import {useQuery} from "@apollo/react-hooks";
import {useParams} from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import {CLIENTS_QUERY} from "../../constants/graphql";

const ProjectContext = React.createContext();

export default function ProjectProvider({ children }) {
  const { projectId } = useParams();
  const { data: { clients = [] } = {}, loading } = useQuery(CLIENTS_QUERY);
  const project = clients.flatMap(v => v.projects).find(v => v.id === projectId) || {};
  const client = clients.find(v => v.id === project?.clientId) || {};

  return (
    <ProjectContext.Provider value={{ project, client }}>
      {loading ? (
        <Box display="flex" justifyContent="center">
          <CircularProgress color="primary" size={22} />
        </Box>
      ) : (
        children
      )}
    </ProjectContext.Provider>
  );
}

export const useProject = () => {
  const context = React.useContext(ProjectContext);
  if (context === undefined) {
    throw new Error("useProject must be used within a ProjectProvider");
  }
  return context;
};
