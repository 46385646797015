import React from "react";
import orderBy from "lodash/orderBy";

import AutocompleteField from "src/components/TablePro/fields/AutocompleteField";
import {useSubcategories, useSustainabilitySheet,} from "src/views/capture/SustainabilitySheetContext";

export default function SubcategoryField(props) {
  const values = props.entity;
  const subcategories = useSubcategories(values.category);
  const list = useSustainabilitySheet();

  const subcategoryOptions = orderBy(
    subcategories.map(value => [value, value]),
    v => v[0]
  );

  const handleChange = value => {
    const selected = list.find(v => v.category === values.category && v.subcategory === value);
    if (selected) {
      const unitCost =
        typeof selected.unitCost === "string"
          ? selected.unitCost.replaceAll("$", "").trim()
          : selected.unitCost;
      props.onChange(values.id, {
        unitCost: parseInt(unitCost),
        weight: selected.unitWeight,
      });
    }
  };

  return (
    <AutocompleteField
      {...props}
      onSubmit={value => {
        props.onSubmit(value);
        handleChange(value);
      }}
      options={subcategoryOptions}
    />
  );
}
