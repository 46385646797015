import React from "react";
import Box from "@mui/material/Box";
import {getBackgroundImage} from "src/utils/images";

export default function AssetImage({ image, width, height }) {
  return (
    <Box
      width={width}
      minWidth={width}
      height={height}
      minHeight={height}
      borderRadius="4px"
      bgcolor="#f4f6f8"
      sx={{
        backgroundImage: getBackgroundImage(image, "width", width),
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain",
        backgroundPosition: "center",
      }}
    />
  );
}
