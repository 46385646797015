import { useEffect, useState } from "react";
import Quagga from "@ericblade/quagga2";

const initScanner = (onDetected, facingMode) => {
  Quagga.init(
    {
      inputStream: {
        name: "Live",
        type: "LiveStream",
        target: "#interactive",
        constraints: {
          facingMode,
          width: window.innerWidth
        }
      },
      decoder: {
        readers: ["code_39_reader", "code_128_reader"]
      },
      locate: true,
      locator: {
        halfSample: true
      }
    },
    function(err) {
      if (err) {
        return console.log(err);
      }
      Quagga.start();
    }
  );

  Quagga.onDetected(onDetected);
};

export const useQuagga = handleDetect => {
  const [facingMode, setFacingMode] = useState("environment");

  useEffect(() => {
    initScanner(handleDetect, facingMode);
  }, []);

  const handleToggleFacingMode = () => {
    const newMode = facingMode === "environment" ? "user" : "environment";
    setFacingMode(newMode);
    handleStop();
    initScanner(handleDetect, newMode);
  };

  const handleStop = () => {
    Quagga.stop();
    Quagga.offDetected(handleDetect);
  };

  return [handleStop, handleToggleFacingMode];
};
