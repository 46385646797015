import React, {useMemo, useState} from "react";
import Box from "@mui/material/Box";
import LinearProgress from "@material-ui/core/LinearProgress";
import {useQuery} from "@apollo/react-hooks";

import Page from "../../components/Page";
import WarehouseTable from "../../views/warehouse/WarehouseTable";
import {WAREHOUSE_INFO, WAREHOUSE_LOCATIONS_COLLECTION} from "../../constants/graphql/queries";
import WarehouseFloorPlan from "./WarehouseFloorPlan";
import WarehouseHeader from "./WarehouseHeader/WarehouseHeader";
import {formatLocations} from "./utils";

const WarehouseContext = React.createContext(null);

export const useWarehouseContext = () => {
  const context = React.useContext(WarehouseContext);
  if (!context) {
    throw new Error("useWarehouseContext must be used within a useWarehouseContext");
  }
  return context;
};

export default function Warehouse({ id, view, modal, changeWarehouse, changeView }) {
  const [updating, setUpdating] = React.useState(false);
  const [allWarehouseLocations, setAllWarehouseLocations] = React.useState([]);
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [occupied, setOccupied] = useState(null);
  const [search, setSearch] = useState("");
  const { data: { warehouse = {} } = {}, loading: loadingWarehouse } = useQuery(WAREHOUSE_INFO, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-first",
    variables: { id },
  });
  const variables = useMemo(() => {
    const values = {
      warehouseId: id,
      projectIds: selectedProjects,
      search,
    };
    if (typeof occupied === "boolean") {
      values.occupied = occupied;
    }
    return values;
  }, [id, selectedProjects, search, occupied]);

  const { data: { warehouseLocationsCollection } = {}, loading, refetch } = useQuery(
    WAREHOUSE_LOCATIONS_COLLECTION,
    {
      fetchPolicy: "network-only",
      nextFetchPolicy: "cache-first",
      variables,
    }
  );
  const collection = warehouseLocationsCollection?.collection || [];
  const list = formatLocations(collection);
  const Wrapper = useMemo(() => (modal ? Box : Page), [modal]);

  const handleRefetch = () => {
    setUpdating(true);
    refetch().then(() => setUpdating(false));
  };

  React.useEffect(() => {
    if (allWarehouseLocations.length === 0) {
      setAllWarehouseLocations(collection);
    }
  }, [collection]);

  return (
    <WarehouseContext.Provider value={{ refetchWarehouse: handleRefetch }}>
      {updating && <LinearProgress />}
      <Wrapper>
        {loadingWarehouse ? null : (
          <WarehouseHeader
            view={view}
            modal={modal}
            warehouse={warehouse}
            warehouseLocations={allWarehouseLocations}
            counter={collection.length}
            handleRefetch={handleRefetch}
            selectedProjects={selectedProjects}
            setSelectedProjects={setSelectedProjects}
            changeSearch={setSearch}
            changeWarehouse={changeWarehouse}
            changeView={changeView}
            occupied={occupied}
            setOccupied={setOccupied}
          />
        )}
        {loading ? (
          <LinearProgress className="mt-2" />
        ) : (
          <Box mt={2} border="1px solid transparent">
            {view === "table" ? (
              <WarehouseTable
                list={collection.map(v => ({ ...v, warehouseId: warehouse?.warehouseId }))}
                refetch={handleRefetch}
              />
            ) : (
              <WarehouseFloorPlan list={list} />
            )}
          </Box>
        )}
      </Wrapper>
    </WarehouseContext.Provider>
  );
}
