import React from "react";
import max from "lodash/max";
import Box from "@mui/material/Box";

import {disableObjectsMovement} from "../../utils/fabric";
import {fabric} from "../../packs/fabric";
import Scale from "./Scale";
import {addFrame, size} from "./utils";
import list from "./warehouseData";

const columnSpacing = size * 5;

function addRowLocations(editor, { left, item, top }) {
  item.forEach((location, locationIndex) => {
    const rect = new fabric.Rect({
      left: size * locationIndex + left,
      top,
      stroke: "white",
      fill: location ? "#72a264" : "#b13e3e",
      width: size,
      height: size,
    });
    rect.on("mousedown", () => {
      console.log("click", location ? "green" : "red");
    });
    editor.add(rect);
  });
}

export default function FloorPlan() {
  const [editor, setEditor] = React.useState();
  const maxColumn = max(list.map(v => v.list.length)) - 1;
  const maxHeight = size * max(list.map(v => v.list.flatMap(v => v).length + v.list.length));

  React.useEffect(() => {
    setEditor(
      new fabric.Canvas("editorCanvas", {
        width: window.innerWidth,
        height: window.innerHeight - 310,
        fireRightClick: true,
        backgroundColor: "#fff",
      })
    );
  }, []);

  React.useEffect(() => {
    const handleResize = () => {
      if (editor) {
        editor.setWidth(window.innerWidth);
        editor.setHeight(window.innerHeight - 310);
      }
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [editor]);

  React.useEffect(() => {
    if (editor) {
      list.forEach((row, rowIndex) => {
        const text = new fabric.Text(row.name, {
          fontSize: 8,
          left: rowIndex * columnSpacing,
          top: maxHeight - size / 1.5,
        });
        editor.add(text);

        row.list.forEach((col, colIndex) => {
          const columnIndex = maxColumn - colIndex;
          const spaceBetweenItems = size * columnIndex;

          const topFrame = size * col.length * columnIndex + spaceBetweenItems;

          addFrame(editor, {
            number: colIndex + 1,
            left: rowIndex * columnSpacing,
            top: topFrame,
            height: size * col.length,
          });

          col.forEach((item, itemIndex) => {
            const itemsLength = col.length * columnIndex * size;
            addRowLocations(editor, {
              left: rowIndex * columnSpacing,
              item,
              top: size * itemIndex + itemsLength + spaceBetweenItems,
            });
          });
        });
      });
      disableObjectsMovement(editor);
      editor.absolutePan({ x: -40, y: -editor.height + maxHeight + size });
    }
  }, [editor]);

  return (
    <Box>
      <canvas id="editorCanvas" />
      {editor && <Scale editor={editor} />}
    </Box>
  );
}
