import React, {useState} from "react";
import {useQuery} from "@apollo/react-hooks";

import {SHOPPING_INVENTORY_REQUESTS_COLLECTION_QUERY} from "../../../constants/graphql";

const OrdersContext = React.createContext(null);

const INITIAL_FILTER = {
  // limit: 6,
  // page: 1,
};

export const OrdersProvider = ({ children }) => {
  const [filter, setFilter] = useState(INITIAL_FILTER);
  const {
    data: { shoppingInventoryRequestsCollection: { collection = [], metadata = {} } = {} } = {},
    ...rest
  } = useQuery(SHOPPING_INVENTORY_REQUESTS_COLLECTION_QUERY, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-first",
    variables: filter,
  });

  return (
    <OrdersContext.Provider value={{ ...rest, collection, metadata, setFilter }}>
      {children}
    </OrdersContext.Provider>
  );
};

export const useOrders = () => {
  const context = React.useContext(OrdersContext);
  if (!context) {
    throw new Error("useOrders must be used within a OrdersProvider");
  }
  return context;
};
