import isEmpty from "lodash/isEmpty";

import useAuth from "../hooks/useAuth";
import { useCustomFields } from "../contexts/customFields";

export default function useCaptureFields() {
  const { user } = useAuth();
  const [display] = useCustomFields();
  return isEmpty(user.captureTableSettings) ? display : user.captureTableSettings;
}
