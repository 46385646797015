import React from "react";
import {Formik} from "formik";
import IconButton from "@material-ui/core/IconButton";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import CloseIcon from "@material-ui/icons/Close";

import {CaptureProvider} from "../../../../contexts/capture";
import {useKitsCollection} from "../../../../contexts/kitsCollection";
import FormPageKitting from "../../../../components/TablePro/components/assets/FormPageKitting";

export const fetchKitValues = asset => {
  if (!asset?.id) return {};

  return {
    manufacturer: asset.manufacturer || "",
    barcode: asset.barcode || "",
    description: asset.description || "",
    color: asset.color || "",
    model: asset.model || "",
    dimensions: asset.dimensions,
  };
};

export default function KitDetailsModal({ disabled, entity: item, onClose }) {
  const { handeRefetch, collection } = useKitsCollection();
  const entity = collection.find(v => v.id === item.id) || item;

  return (
    <Dialog open fullWidth maxWidth="md" onClose={onClose}>
      <DialogTitle sx={{ background: "#f4f6f8", display: "flex", justifyContent: "space-between" }}>
        <Box width="48px" />
        <Typography fontSize={24} fontWeight={500} color="textPrimary">
          Kit Details
        </Typography>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ background: "#f4f6f8" }}>
        <Box display="flex" flexGrow={1}>
          <CaptureProvider>
            <Formik
              key={entity?.id}
              enableReinitialize
              initialValues={fetchKitValues(entity)}
              onSubmit={() => {}}
            >
              <FormPageKitting
                disabled={disabled}
                type="scrubber"
                currentAsset={entity}
                onCloseModal={onClose}
                handeRefetch={handeRefetch}
              />
            </Formik>
          </CaptureProvider>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
