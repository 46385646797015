import React from "react";
import {useParams} from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import useAuth from "../../hooks/useAuth";
import ProjectProvider from "../../components/project/ProjectProvider";
import ProjectNav from "../../components/project/ProjectNav";
import StorageEnabledProvider from "../../components/project/StorageEnabledProvider";
import ProjectTabs from "./ProjectTabs";

export default function ProjectInfoModal({ onClose }) {
  const { projectId } = useParams();
  const { user } = useAuth();
  const { settings } = user.company;
  const initTab = settings.receiving_wizard === false ? "settings" : (projectId ? "inbound" : "reports");
  const [value, setValue] = React.useState(initTab);

  return (
    <Dialog open fullWidth maxWidth="md" onClose={onClose}>
      <DialogContent sx={{ bgcolor: "#f4f6f8" }}>
        <DialogContentText>
          <ProjectProvider>
            <StorageEnabledProvider>
              <Box display="flex" justifyContent="space-between">
                <Box width={40} />
                <Typography fontSize={26} fontWeight={600}>
                  {settings.filter_field2} Profile
                </Typography>
                <IconButton color="textPrimary" onClick={onClose}>
                  <CloseIcon />
                </IconButton>
              </Box>
              <Box mt={1.5} bgcolor="white">
                <ProjectNav value={value} onChange={setValue} />
              </Box>
              <Box mt={2} minHeight={200}>
                <ProjectTabs selected={value} onClose={onClose} />
              </Box>
            </StorageEnabledProvider>
          </ProjectProvider>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
}

