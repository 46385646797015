import React from "react";
import Box from "@material-ui/core/Box";
import {LazyLoadImage} from "react-lazy-load-image-component";
import {makeStyles} from "@material-ui/core";
import "react-lazy-load-image-component/src/effects/opacity.css";

import {getImage, sortImages} from "../../../utils/images";

var ImageFieldsCache = {};

const useStyles = makeStyles(theme => ({
  imageContainer: {
    display: "flex !important",
    alignItems: "center",
    height: "100%",
    width: "100%",
    "& img": {
      maxWidth: props => props.maxWidth,
      maxHeight: "100%",
    },
  },
}));

const ImageField = ({ entity, maxWidth = 100, onDoubleClick, onClick }) => {
  const classes = useStyles({ maxWidth });
  const images = sortImages(entity?.images);
  const src = images.length ? getImage(images[0].url, "width", 200) : undefined;
  const buttonProps = { onDoubleClick, onClick };

  return (
    <>
      {src ? (
        ImageFieldsCache[src] === true ? (
          <span className={classes.imageContainer} {...buttonProps}>
            <img style={{ maxWidth }} src={src} />
          </span>
        ) : (
          <LazyLoadImage
            {...buttonProps}
            wrapperClassName={classes.imageContainer}
            afterLoad={() => (ImageFieldsCache[src] = true)}
            effect="opacity"
            src={src}
          />
        )
      ) : (
        <Box
          width="100%"
          height="100%"
          display="flex"
          alignItems="center"
          onDoubleClick={e => {
            e.stopPropagation();
            e.preventDefault();
          }}
        >
          No image
        </Box>
      )}
    </>
  );
};

export default ImageField;
