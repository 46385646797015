import React, { useRef, useEffect } from "react";

import { Table as TableMui, Card, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    height: "100%",
  },
  tableContainer: {
    overflow: "auto",
    borderRadius: 2,
    maxHeight: "max-content",
    position: "relative",
    height: "400px",

    "&::-webkit-scrollbar": {
      width: 7,
      height: 7,
    },
    "&::-webkit-scrollbar-track-piece": {
      marginTop: 55,
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#888",
      opacity: 0.3,
      borderRadius: 100,

      "&:hover": {
        background: "#555",
      },
    },
  },
  tableWrapper: {
    position: "relative",
    width: "min-content",
    minWidth: "100%",
    height: "min-content",
    maxHeight: "100%",
  },
  table: {
    tableLayout: "fixed",
    display: "flex",
    flexDirection: "column",
  },
}));

const TableWrap = ({ onLoadMore, children }) => {
  const classes = useStyles();
  const tableRef = useRef(null);

  useEffect(() => {
    // const onScroll = (element) => {
    //   const isBottom =
    //     element.target.scrollHeight <=
    //     element.target.scrollTop + element.target.clientHeight + 100;
    //   if (isBottom) {
    //     onLoadMore();
    //   }
    // };
    // tableRef.current.addEventListener("scroll", onScroll);
    // return () => tableRef.current.removeEventListener("scroll", onScroll);
  });

  return (
    <div style={{ height: "100%", position: "relative" }}>
      <div className={classes.wrapper}>
        <div ref={tableRef} className={classes.tableContainer}>
          <div className={classes.tableWrapper}>
            <div className={classes.table}>{children}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TableWrap;
