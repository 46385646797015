import React from "react";
import Dropzone from "react-dropzone";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

export default function ImageDND({ onDrop }) {
  return (
    <Dropzone onDrop={onDrop}>
      {({ getRootProps, getInputProps }) => (
        <Box height="100%" width="100%" position="absolute">
          <div
            {...getRootProps()}
            style={{
              height: "100%",
              width: "100%",
              padding: 24,
            }}
          >
            <Box
              borderRadius="4px"
              display="flex"
              justifyContent="center"
              alignItems="center"
              border="2px dashed #d5d5d5"
              height="100%"
            >
              <div>
                <input {...getInputProps()} />
                <Typography textAlign="center">
                  Drag an image here or{" "}
                  <Box display="inline-block" color="primary.main" sx={{ cursor: "pointer" }}>
                    upload a file
                  </Box>
                </Typography>
              </div>
            </Box>
          </div>
        </Box>
      )}
    </Dropzone>
  );
}
