import React from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import {withStyles} from "@material-ui/core";
import TextField from "@mui/material/TextField";
import FormControl from "@material-ui/core/FormControl";

import FormLabel from "../../../components/FormLabel";
import RadioGroup from "../../../components/form/RadioGroup";
import Input from "../../../components/form/Input";
import CheckboxInput from "../../../components/form/Checkbox";
import IncludeReportFields from "../../../views/settings/IncludeReportFields";
import {useFavoriteReportFields} from "../../../contexts/FavoriteReportFields";
import Layer1Fields from "./Layer1Fields";
import Layer2Fields from "./Layer2Fields";
import Layer3Fields from "./Layer3Fields";
import NotificationField from "./NotificationField";
import ColumnOrderField from "./ColumnOrderField";
import GroupByFields from "./GroupByFields";
import FilterFields from "./FilterFields";
import useAuth from "../../../hooks/useAuth";

const FullCard = withStyles({
  root: {
    height: "100%",
  },
})(Card);

export const TYPE_OPTIONS = [
  ["tabular", "Tabular"],
  ["page_large_photo", "Tabular w/ Large Images"],
];

const STYLE_OPTIONS = [
  ["portrait", "Portrait"],
  ["landscape", "Landscape"],
];

const PAGE_SIZE_OPTIONS = [
  ["8.5x11", `Letter (8.5"x11")`],
  ["11x17", `Tabloid (11"x17")`],
];

export const FORMAT_OPTIONS = [
  ["inventory", "Inventory"],
  ["spec_sheet", "Spec Sheet"],
];

const FIELDS_LIMIT = 52;

function Content({ children }) {
  return (
    <Card>
      <CardContent>{children}</CardContent>
    </Card>
  );
}

const FavouriteReportFields = ({ isEdit, format, isSubmitting }) => {
  const isFormatPacking = format === "packing";
  const { toggleField, selectedFields: fields } = useFavoriteReportFields();
  const { user } = useAuth();
  const { company } = user;

  const Checkbox = props => {
    const name = `${props.category}.${props.name}`;
    const disabled = fields.includes(props.name) ? false : fields.length > FIELDS_LIMIT;

    return <CheckboxInput {...props} onChange={toggleField} disabled={disabled} name={name} />;
  };

  // Hide fields based on medical fields toggle
  const hideFields = [
    "shipments",
    "inventory_items",
    "inventory_request",
    "shopping_cart",
    "orders",
    "reservations",
  ];

  if (!user.company.settings.medical_fields) {
    hideFields.push("electrical_info","plumbing_info", "mechanical_info");
  }

  return (
    <>
      <FullCard>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={3}>
              <FormLabel>Report Setup</FormLabel>
              <Box pr={{ md: 5 }}>
                <Input name="name" label="Template Name" />
              </Box>
            </Grid>
            <Grid item xs={12} md={2}>
              <RadioGroup name="style" label="Page Orientation" options={STYLE_OPTIONS} />
            </Grid>
            <Grid item xs={12} md={2}>
              <RadioGroup name="page_size" label="Page size" options={PAGE_SIZE_OPTIONS} />
            </Grid>
            <Grid item xs={12} md={2}>
              <RadioGroup name="format" label="Report Type" options={FORMAT_OPTIONS} />
            </Grid>
            <Grid item xs={12} md={2}>
              <RadioGroup name="type" label="Report Format" options={TYPE_OPTIONS} />
            </Grid>
          </Grid>
        </CardContent>
      </FullCard>
      <Box mt={2} />
      <FullCard>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormLabel>Report Header</FormLabel>
              <Box display="flex" justifyContent="space-between">
                <Box flexGrow={1} maxWidth={320}>
                  <Input name="left_header_1" label="Left Header 1" />
                  <Box mt={1} />
                  <Input name="left_header_2" label="Left Header 2" />
                </Box>
                <Box display="flex" alignItems="flex-end" flexGrow={1} maxWidth={320}>
                  <Input
                    name="center_header"
                    label="Center Header"
                    inputProps={{ style: { textAlign: "center" } }}
                  />
                </Box>
                <Box flexGrow={1} maxWidth={320}>
                  <TextField
                    disabled
                    fullWidth
                    size="small"
                    label="Date Generated"
                    value="Month Day, Year"
                    sx={{ background: "#ECEBEB", borderRadius: "4px" }}
                    inputProps={{ style: { textAlign: "right" } }}
                  />
                  <Box mt={1} />
                  <TextField
                    disabled
                    fullWidth
                    size="small"
                    label="Page Number"
                    value="Page xx of xx"
                    sx={{ background: "#ECEBEB", borderRadius: "4px" }}
                    inputProps={{ style: { textAlign: "right" } }}
                  />
                </Box>
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      </FullCard>
      <Box mt={2} />
      <Grid item container xs={12} spacing={2}>
        <Grid item xs={6}>
          <FormControl fullWidth component={Content}>
            <FormLabel>Group By</FormLabel>
            <GroupByFields name="groups" disabled />
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth component={Content}>
            <FormLabel>Filter By</FormLabel>
            <FilterFields name="filters" />
          </FormControl>
        </Grid>
      </Grid>
      <NotificationField />
      {format !== "spec_sheet" &&
        (isFormatPacking ? (
          <>
            <Box mt={2} />
            <Layer1Fields />
            <Box mt={2} />
            <Layer2Fields />
            <Box mt={2} />
            <Layer3Fields />
          </>
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FullCard>
                <CardContent>
                  <FormLabel>Table Display Fields</FormLabel>
                  <ColumnOrderField />
                  <Box mb={3} />
                  <IncludeReportFields
                    component={Checkbox}
                    hideFields={hideFields}
                    displayFilter={true}
                  />
                </CardContent>
              </FullCard>
            </Grid>
          </Grid>
        ))}
      <Box mt={3} />
      <Button variant="contained" color="primary" type="submit" disabled={isSubmitting}>
        {isEdit ? "Save" : "Create"}{" "}
        {isSubmitting && (
          <Box ml={1.5} clone>
            <CircularProgress size={18} />
          </Box>
        )}
      </Button>
    </>
  );
};

export default React.memo(FavouriteReportFields);
